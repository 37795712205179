/* shared with dashboard, keep in sync */
/* modified to use MUI button instead of custom button */

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import MoreVertIcon from '@material-ui/icons/MoreVert'
import Menu from '@material-ui/core/Menu'
import Button from '@material-ui/core/Button'

import { SIZE, COLOR } from 'constants/enums'

import styles from './KebabButtonStyles'

const KebabButton = ({ classes, dataTest, children }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleChildrenClick = onClick => {
    onClick && onClick()
    handleClose()
  }

  return (
    <>
      <Button
        size={SIZE.medium}
        className={classes.iconButton}
        color={COLOR.secondary}
        startIcon={<MoreVertIcon />}
        onClick={handleClick}
        data-test={dataTest}
      />
      <Menu
        id="kebab-button"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {React.Children.map(children, child => {
          if (React.isValidElement(child)) {
            return React.cloneElement(child, {
              onClick: () => handleChildrenClick(child.props.onClick),
            })
          }
          return child
        })}
      </Menu>
    </>
  )
}

KebabButton.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.object,
  ]).isRequired,
  dataTest: PropTypes.string,
}

export default withStyles(styles)(KebabButton)
