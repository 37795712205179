/* WARNING: copied from dashboard; keep in sync */

import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useTitle } from 'hooks'

import TitleElement from './TitleElement'

/**
 * Updates the title for each page on location change
 *
 * There are three ways to use this:
 * 1. For a set of routes starting with a specific path:
 *   a. add your url constant to "constants/navigation"
 *   b. map your url to the desired title in "constants/titles"
 *
 * all routes that start with this pathname will now use this title
 *
 * 2. update via a hook
 *   a. import {`useTitle` } to your component / container
 *   b. invoke the HOF to and destructure `setPageTitle` so you have access to call the setter
 *   c. call `setPageTitle` with the desired title set to the `title` prop
 *     - (NOTE: no need to add the site name; the template will do that for you).
 *
 * 3. import { TitleElement }
 *   a. insert the component in your page
 *   b. the deepest one in the component hierarchy wins! (https://github.com/nfl/react-helmet/tree/1b57ddb1524bab195df3eeabafef67768a6d2a15#example)
 *   c. optionally disable site name by passing `siteName: false`
 *
 * @return {JSX.Element}
 */
const SiteTitle = () => {
  const location = useLocation()
  const { title, setPageTitle } = useTitle()

  useEffect(() => {
    setPageTitle({ page: location.pathname })
  }, [location])

  return <TitleElement title={title} />
}

export default SiteTitle
