import { useAPIContext } from 'context'
import { useResourceRQ } from 'hooks'
import {
  variantEndpoint,
  variantPrintLabelEndpoint,
  variantGenDsinEndpoint,
  variantListEndpoint,
  variantPurchaseOrderListEndpoint,
} from 'api/endpoints'

const useAdminVariant = (id, poId) => {
  const { api } = useAPIContext()

  const { data: variant, update: updateVariant } = useResourceRQ({
    endpoint: variantEndpoint,
    name: 'variant',
    args: [id],
    dependents: [variantListEndpoint],
    fetchingEnabled: Boolean(id),
  })

  const { operate: generateAndAssignPurchaseOrderVariantSIN } = useResourceRQ({
    endpoint: variantGenDsinEndpoint,
    args: [id],
    params: { purchase_order_id: poId },
    dependents: [
      variantEndpoint,
      variantListEndpoint,
      variantPurchaseOrderListEndpoint,
    ],
    fetchingEnabled: false,
  })

  const printLabels = async ({ sku, locationId, quantity, printerId }) => {
    try {
      await api.post(variantPrintLabelEndpoint(sku), {
        location_id: locationId,
        quantity,
        printer_id: printerId
      })
    } catch (e) {
      console.error(e)
    }
  }

  return {
    variant,
    updateVariant,
    generateAndAssignPurchaseOrderVariantSIN,
    printLabels,
  }
}

export default useAdminVariant
