import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'

import moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'

import { Box, Typography, Fab, IconButton } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import ReplayIcon from '@material-ui/icons/Replay'
import PublicIcon from '@material-ui/icons/Public'

import { formatDateToLocalTimezone } from 'utils/general'

import { useLocationsContext } from 'context'
import { deliveryIsSoon } from 'helpers/order'
import { API_PARAMS, LIST_PATHS } from 'constants/queryParams'
import { GIFT_ICON_POSITIONING } from 'constants/styles'
import { DELIVERY_METHOD_KEYS } from 'constants/enums'
import {
  useAdminOrders,
  useAdminUberOrderList,
  useLatestCallback,
  useQuery,
} from 'hooks'

import Tabs from 'components/Tabs'
import ScannerFab from 'components/ScannerFab'
import DeliveryTimer from 'components/DeliveryTimer'
import BottomDrawerMenu from 'components/BottomDrawerMenu'
import LocationDrawer from 'components/LocationDrawer'
import HeaderWithLocation from 'components/HeaderWithLocation'
import LoadingButton from 'components/LoadingButton'

import GiftIcon from 'icons/GiftIcon'
import UberIcon from 'icons/UberIcon'

import styles from './OrderListScreenStyles'

momentDurationFormatSetup(moment)

const UPDATE_TIMER_MS = 1000
const REFRESH_ORDERS_MS = 2500

const tabsToStatus = {
  0: 'paid',
  1: 'paid',
  2: 'picking',
  3: 'dispatched',
}

const uberTabsToStatus = {
  0: 'accepted',
  1: 'accepted',
  2: 'picking',
  3: 'fulfilled',
}

const PageCounter = ({ page, numPages, fetchingNewPage, handlePaging }) => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    data-test="page-counter"
  >
    Page {page} of {numPages}{' '}
    <IconButton
      disabled={fetchingNewPage || page === 1}
      size="small"
      onClick={handlePaging(1 - page)}
      data-test="back-to-first-page"
    >
      <ReplayIcon />
    </IconButton>
  </Box>
)

PageCounter.propTypes = {
  page: PropTypes.number,
  numPages: PropTypes.number,
  fetchingNewPage: PropTypes.bool,
  handlePaging: PropTypes.func,
}

// we want to highlight orders past the carrier shutoff of 3pm day of
const atOne = moment(new Date()).hour(13).minute(0)

const OrderListScreen = ({ classes }) => {
  // Order endpoints desparately need to be combined into a new single endpoint
  const { orders, findOrders, numPages, isLoading } = useAdminOrders({})

  const { location } = useLocationsContext()
  const { uberOrders } = useAdminUberOrderList({
    params: {
      'uber_eats_orders.location_id': location?.id,
      'uber_eats_orders.status': 'accepted,picking,fulfilled',
      'uber_eats_orders.status.rel': 'in',
    },
    enabled: false,
  })

  const [fetchingNewPage, setFetchingNewPage] = useState(false)
  const listRef = useRef(null)

  const { query, setQuery } = useQuery(false, {
    initFallback: {
      page: 1,
    },
  })

  const page = query?.page ? parseInt(query.page, 10) : 1

  const [tab, setTab] = useState(
    query?.default_tab ? parseInt(query.default_tab, 10) : 0
  )
  const [openLocationDrawer, setOpenLocationDrawer] = useState(false)
  const [now, setNow] = useState(moment())

  const fetchOrders = useLatestCallback((newPage = page) => {
    findOrders(
      {
        [LIST_PATHS.ORDERS_STATUS]: tabsToStatus[tab],
        [API_PARAMS.SORT]: LIST_PATHS.ORDERS_DELIVERY_STARTS,
        [LIST_PATHS.ORDERS_LOCATION_ID]: location?.id,
        fulfill_today: tab === 0,
      },
      newPage
    )
  })

  useEffect(() => {
    if (!isLoading && fetchingNewPage) {
      setFetchingNewPage(false)
      listRef.current.scrollTo({
        top: 0,
        behavior: 'auto',
      })
    }
  })

  // also forces the timer to tick every second
  useEffect(() => {
    const timer = setInterval(() => {
      setNow(moment())
    }, UPDATE_TIMER_MS)
    return () => clearInterval(timer)
  }, [])

  useEffect(() => {
    if (location) {
      fetchOrders()
    }
  }, [tab, location])

  useEffect(() => {
    const timer = setInterval(() => {
      fetchOrders()
    }, REFRESH_ORDERS_MS)
    return () => clearInterval(timer)
  }, [])

  const handlePaging = diff => () => {
    setQuery({ page: page + diff })
    setFetchingNewPage(true)
    fetchOrders(page + diff)
  }

  const tabContentsUber = uberOrders
    .filter(o => o.status === uberTabsToStatus[tab])
    .map((order, i) => {
      const borderColorStyle = {
        borderLeft: '4px solid #E52B20',
      }

      return (
        <div key={order.id}>
          <Link to={`/orders/uber/${order.id}`} className={classes.rowLink}>
            <Box
              className={classes.listRow}
              style={borderColorStyle}
              data-test={`order-item-${i}`}
            >
              <Box className={styles.listLeft} color="black">
                <Typography variant="body2">
                  {order.uber_eats_order_items
                    .map(it => it.quantity)
                    .reduce((acc, v) => acc + v)}{' '}
                  Items
                </Typography>
                <Typography variant="body1">
                  Order #{order.display_id}
                </Typography>
                <Box mt={1.8}>
                  <Typography variant="body2">Scheduled for ASAP</Typography>
                  <Box mt={-0.5}>
                    <Typography variant="caption">
                      {moment(order.estimated_ready_for_pickup_at).format(
                        'dddd'
                      )}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box>
                <UberIcon fontSize={64} />
              </Box>
            </Box>
          </Link>
        </div>
      )
    })

  const ordersData = Array.isArray(orders) ? orders : []

  const tabContentsFastAF = ordersData.map((order, i) => {
    const deliveryStartsAtText =
      order.delivery_starts_at && moment(order.delivery_starts_at).format('h')
    const deliveryEndsAtText =
      order.delivery_ends_at && moment(order.delivery_ends_at).format('ha')

    const shouldHighlight = deliveryIsSoon(now, order.delivery_starts_at)

    const borderColorStyle = {
      borderLeft: shouldHighlight ? '4px solid #E52B20' : 'none',
    }

    const packNextDay =
      order?.delivery_type === DELIVERY_METHOD_KEYS.global &&
      moment(order?.created_at) > atOne

    return (
      <div key={order.id}>
        <Link to={`/orders/${order.id}`} className={classes.rowLink}>
          <Box
            className={classes.listRow}
            style={borderColorStyle}
            data-test={`order-item-${i}`}
            bgcolor={packNextDay ? '#b3ffe9' : null}
          >
            <Box className={styles.listLeft} color="black">
              <Typography variant="body2">
                {order.total_line_items_quantity} Items
              </Typography>
              <Typography variant="body1">
                Order #{order.number}
                {!isEmpty(order.gift_detail) && (
                  <span style={GIFT_ICON_POSITIONING}>
                    <GiftIcon />
                  </span>
                )}
              </Typography>
              <Box mt={1.8}>
                <Typography variant="body2">
                  Scheduled for {deliveryStartsAtText}-{deliveryEndsAtText}
                </Typography>
                <Box mt={-0.5}>
                  <Typography variant="caption">
                    {moment(order.delivery_starts_at).format('dddd')}
                  </Typography>
                </Box>
              </Box>
              <Box mt={1.8}>
                <Typography variant="body1">
                  Placed at{' '}
                  {formatDateToLocalTimezone(
                    order.created_at,
                    'America/New_York',
                    'LT'
                  )}
                </Typography>
                <Box mt={-0.5}>
                  <Typography variant="caption">
                    {formatDateToLocalTimezone(
                      order.created_at,
                      'America/New_York'
                    )}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box display="flex" flexDirection="column">
              {order.shipped && (
                <Box alignSelf="flex-end">
                  <PublicIcon style={{ color: 'green' }} fontSize="large" />
                </Box>
              )}
              <DeliveryTimer deliveryStartsAt={order.delivery_starts_at} />
            </Box>
          </Box>
        </Link>
      </div>
    )
  })

  const tabContents = (
    <Box className={classes.listContainer} ref={listRef}>
      <PageCounter
        page={page}
        numPages={numPages}
        fetchingNewPage={fetchingNewPage}
        handlePaging={handlePaging}
      />
      {page === 1 && tabContentsUber}
      {tabContentsFastAF}
      {Array.isArray(orders) && (
        <>
          <PageCounter
            page={page}
            numPages={numPages}
            fetchingNewPage={fetchingNewPage}
            handlePaging={handlePaging}
          />
          <Box className={classes.pageButtons}>
            <LoadingButton
              loading={fetchingNewPage}
              variant="contained"
              color="primary"
              size="large"
              disabled={page <= 1}
              onClick={handlePaging(-1)}
              data-test="page-previous"
            >
              Previous
            </LoadingButton>
            <LoadingButton
              loading={fetchingNewPage}
              variant="contained"
              color="primary"
              size="large"
              disabled={page >= numPages}
              onClick={handlePaging(1)}
              data-test="page-next"
            >
              Next
            </LoadingButton>
          </Box>
        </>
      )}
    </Box>
  )

  return (
    <>
      <ScannerFab className={classes.scannerFab} />
      <Link to="/orders/search">
        <Fab className={classes.searchIcon} data-test="order-search">
          <SearchIcon style={{ color: 'white' }} />
        </Fab>
      </Link>
      <BottomDrawerMenu />
      <LocationDrawer
        drawerOpen={openLocationDrawer}
        setDrawerOpen={setOpenLocationDrawer}
      />
      <Box className={classes.root}>
        <HeaderWithLocation title="Orders" />
        <Tabs
          defaultTab={tab}
          tabLabels={['Today', 'All', 'Picking', 'Dispatched']}
          tabContents={[tabContents, tabContents, tabContents, tabContents]}
          onChange={i => setTab(i)}
          classes={{
            tabPanel: classes.tabPanel,
            root: classes.tabRoot,
          }}
        />
      </Box>
    </>
  )
}

OrderListScreen.propTypes = {
  classes: PropTypes.object.isRequired,
  text: PropTypes.string,
  onClick: PropTypes.func,
}

export default withStyles(styles)(OrderListScreen)
