/* WARNING: copied from dashboard; keep in sync */
import * as yup from 'yup'
import { LIST_PATHS } from './queryParams'
import { STATUS_DOT_VARIANTS } from './enums'
const {
  BRANDS_NAME,
  PURCHASE_ORDERS_ID,
  PURCHASE_ORDERS_ORDER_DATE,
  PURCHASE_ORDERS_TERMS,
  PURCHASE_ORDERS_ETA,
  PRODUCTS_VARIANTS_TITLE,
  VARIANTS_PRICE,
  VARIANTS_PO_QUANTITY,
} = LIST_PATHS

export const PURCHASE_ORDER_STATE_TYPES = {
  draft: 'draft',
  open: 'is_open',
  submitted: 'submitted',
  receivingInProgress: 'receiving_in_progress',
  partiallyReceived: 'partially_received',
  fullyReceived: 'fully_received',
  overReceived: 'over_received',
  closed: 'closed',
}

export const PURCHASE_ORDER_STATE_LABELS = [
  {
    value: PURCHASE_ORDER_STATE_TYPES.draft,
    label: 'Draft',
    indicator: STATUS_DOT_VARIANTS.gray,
  },
  {
    value: PURCHASE_ORDER_STATE_TYPES.open,
    label: 'Open',
    indicator: STATUS_DOT_VARIANTS.blue,
  },
  {
    value: PURCHASE_ORDER_STATE_TYPES.submitted,
    label: 'Submitted',
    indicator: STATUS_DOT_VARIANTS.green,
  },
  {
    value: PURCHASE_ORDER_STATE_TYPES.receivingInProgress,
    label: 'Receiving in Progress',
    indicator: STATUS_DOT_VARIANTS.blue,
  },
  {
    value: PURCHASE_ORDER_STATE_TYPES.partiallyReceived,
    label: 'Partially Received',
    indicator: STATUS_DOT_VARIANTS.blue,
  },
  {
    value: PURCHASE_ORDER_STATE_TYPES.fullyReceived,
    label: 'Fully Received',
    indicator: STATUS_DOT_VARIANTS.green,
  },
  {
    value: PURCHASE_ORDER_STATE_TYPES.overReceived,
    label: 'Over Received',
    indicator: STATUS_DOT_VARIANTS.orange,
  },
  {
    value: PURCHASE_ORDER_STATE_TYPES.closed,
    label: 'Closed',
    indicator: STATUS_DOT_VARIANTS.gray,
  },
]

export const TERM_POSSIBILITIES = [
  {
    label: 'Consignment',
    value: 'consignment',
  },
  {
    label: 'Wholesale (CC Pre-Pay)',
    value: 'wholesale_cc_pre_pay',
  },
  {
    label: 'Wholesale (Net 30)',
    value: 'wholesale_net_30',
  },
  {
    label: 'Wholesale (Net 60)',
    value: 'wholesale_net_60',
  },
  {
    label: 'Wholesale (Net 90)',
    value: 'wholesale_net_90',
  },
  {
    label: 'Retail',
    value: 'retail',
  },
]

export const TERM_POSSIBILITIES_ALL = [
  {
    label: 'All',
    value: '',
  },
  ...TERM_POSSIBILITIES,
]

export const PO_LIST_COLUMNS = [
  {
    title: 'PO #',
    sortKeys: [PURCHASE_ORDERS_ID],
  },
  {
    title: 'Vendor',
    sortKeys: [BRANDS_NAME],
  },
  { title: 'Location' },
  {
    title: 'Terms',
    sortKeys: [PURCHASE_ORDERS_TERMS],
  },
  { title: 'Order Date', sortKeys: [PURCHASE_ORDERS_ORDER_DATE] },
  {
    title: 'Estimated Arrival',
    sortKeys: [PURCHASE_ORDERS_ETA],
  },
  {
    title: 'Status',
  },
]

export const STATUS_FILTER_ITEMS = [
  {
    label: 'Active',
    value: 'active',
  },
  {
    label: 'Inactive',
    value: 'inactive',
  },
  {
    label: 'All',
    value: '',
  },
]

export const PO_FIELDS = {
  vendor: 'brand_id',
  location: 'location_id',
  paymentTerms: 'terms',
  orderDate: 'order_date',
  estimatedArrival: 'estimated_arrival_at',
  memo: 'memo',
}

export const PO_SCHEMAS = {
  [PO_FIELDS.vendor]: yup
    .string()
    .ensure()
    .required('Vendor is a required field'),
  [PO_FIELDS.location]: yup.string().required(),
  [PO_FIELDS.paymentTerms]: yup
    .string()
    .required('Payment Terms is a required field'),
  [PO_FIELDS.orderDate]: yup
    .string()
    .ensure()
    .required('Order Date is a required field'),
  [PO_FIELDS.estimatedArrival]: yup
    .string()
    .ensure()
    .required('Estimated Arrival is a required field'),
  [PO_FIELDS.memo]: yup.string(),
}

export const PO_FORM_DEFAULTS = {
  [PO_FIELDS.vendor]: '',
  [PO_FIELDS.location]: '',
  [PO_FIELDS.paymentTerms]: '',
  [PO_FIELDS.orderDate]: null,
  [PO_FIELDS.estimatedArrival]: null,
  [PO_FIELDS.memo]: '',
}

export const VARIANT_COLUMNS = [
  {
    title: 'Vendor Style #',
  },
  { title: 'Product Name', sortKeys: [PRODUCTS_VARIANTS_TITLE] },
  { title: 'SKU' },
  { title: 'Color' },
  { title: 'Size' },
  { title: 'MSRP', sortKeys: [VARIANTS_PRICE] },
  { title: 'Ordered', sortKeys: [VARIANTS_PO_QUANTITY] },
  { title: 'Received' },
  { title: 'Damaged' },
  { title: '' },
]
