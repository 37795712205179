import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined'

import { useLocationsContext } from 'context'

import Header from 'components/Header'
import LocationDrawer from 'components/LocationDrawer'

import styles from './HeaderWithLocationStyles'

const HeaderWithLocation = ({ classes, children, ...props }) => {
  const { location, locations } = useLocationsContext()
  const [openLocationDrawer, setOpenLocationDrawer] = useState(false)

  const needsLocation = !location?.city && locations

  useEffect(() => {
    if (needsLocation) {
      setOpenLocationDrawer(true)
    }
  }, [locations, location])

  return (
    <>
      <LocationDrawer
        drawerOpen={openLocationDrawer}
        setDrawerOpen={setOpenLocationDrawer}
      />
      <Header {...props}>
        {location?.city && (
           <Typography
             className={classes.locationText}
             variant="h1"
             onClick={() => setOpenLocationDrawer(true)}
             data-test="location-header"
             >
             <LocationOnOutlinedIcon style={{ color: '#305BF2' }} />
             {location?.city}
           </Typography>
        )}
        {!location?.city && (
           <Typography
             className={classes.locationText}
             variant="h1"
             onClick={() => setOpenLocationDrawer(true)}
             data-test="location-header"
             >
             <LocationOnOutlinedIcon style={{ color: '#305BF2' }} />
             Select Location
           </Typography>
        )}
      </Header>
    </>
  )
}

HeaderWithLocation.defaultProps = {}

HeaderWithLocation.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node,
}

export default withStyles(styles)(HeaderWithLocation)
