const jssStyles = theme => ({
  image: {
    width: '100%',
    height: 286,
    objectFit: 'cover',
  },
  row: {
    margin: theme.spacing(2),
  },
  colorDark: {
    color: theme.palette.grays.dark,
  },
  column: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  quantityLabel: {
    paddingRight: 2,
  },
  quantityRow: {
    '& span': {
      color: theme.palette.grays.dark,
    },
  },
  actions: {
    display: 'flex',
    '& button': {
      flexGrow: '1',
      margin: theme.spacing(1),
    },
  },
  markDamaged: {
    marginRight: theme.spacing(1),
  },
})

export default jssStyles
