import { ENVIRONMENT_INSTANCES } from 'constants/enums'

export const CLIENT_URL = process.env.REACT_APP_CLIENT_URL
export const SERVER_URL = process.env.REACT_APP_SERVER_URL
export const SERVER_ENV = process.env.REACT_APP_SERVER_ENV
export const APP_VERSION = process.env.REACT_APP_VERSION

export const IS_PRODUCTION = SERVER_ENV === ENVIRONMENT_INSTANCES.production

export const APP_ID = 'FASTAF_FULFILLMENT'
export const STORAGE_AUTH_TOKEN_ID = `${APP_ID}_AUTH_TOKEN`
export const STORAGE_LOCATION_ID = `${APP_ID}_LOCATION_ID`
export const STORAGE_INVENTORY_COUNT = `${APP_ID}_INVENTORY_COUNT`
export const STORAGE_INVENTORY_COUNT_V2 = `${APP_ID}_INVENTORY_COUNT_V2`
export const STORAGE_TRANSFER_ORDER_SHIPMENT_ITEMS = `${APP_ID}_TRANSFER_ORDER_SHIPMENT_ITEMS`
export const STORAGE_VARIANT_STATE = `${APP_ID}_VARIANT_STATE`
export const STORAGE_PRINTER_ID = `${APP_ID}_PRINTER_ID`
export const STORAGE_BARCODE_PRINTER_ID = `${APP_ID}_BARCODE_PRINTER_ID`
