const jssStyles = theme => ({
  roadieSelect: {
    margin: 0,
    padding: 0,
    paddingRight: theme.spacing(2.5),
    '& .MuiInputBase-root': {
      height: 60,
      paddingLeft: 4,
    },
  },
  selectedPrinter: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[500],
    borderRadius: 4,
    marginTop: theme.spacing(2),
    cursor: 'pointer',
  },
})

export default jssStyles
