const DETAILS_HEIGHT_PX = 122

const detailsShared = theme => ({
  position: 'absolute',
  left: 0,
  bottom: 0,
  width: '100%',
  minHeight: DETAILS_HEIGHT_PX,
  zIndex: 10,
  padding: theme.spacing(0, 2),
  backgroundColor: theme.palette.background.default,
  transition: 'height .8s ease',
})

const jssStyles = theme => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  details: {
    ...detailsShared(theme),
    height: '0%',
  },
  detailsExpanded: {
    ...detailsShared(theme),
    height: '100%',
    overflowY: 'auto',
  },
  content: {
    position: 'relative',
    height: '100%',
    width: '100%',
  },
  lineItems: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: DETAILS_HEIGHT_PX,
    right: 0,
    overflowY: 'auto',
  },
  accordionRoot: {
    backgroundColor: theme.palette.background.default,
  },
  summaryRoot: {
    padding: 0,
  },
  summaryContentRoot: {
    flexDirection: 'column',
  },
  summaryRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  detailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
  },
  divider: {
    width: '100vw',
    margin: '42px 0',
    marginLeft: -24,
  },
  orderedRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  orderedValue: {
    flexShrink: 0,
  },
  spaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  spaceTop: {
    marginTop: theme.spacing(2),
  },
  dark: {
    color: theme.palette.grays.dark,
  },
  closeDivider: {
    marginBottom: theme.spacing(1),
  },
  searchIcon: {
    width: 48,
    height: 48,
    background: theme.palette.primary.main,
    padding: 0,
    position: 'absolute',
    bottom: 130,
    right: 16,
  },
})

export default jssStyles
