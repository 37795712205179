import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Button, Typography } from '@material-ui/core'

import BottomDrawer from 'components/BottomDrawer'

import styles from './ConfirmationDrawerStyles'

const ConfirmationDrawer = ({
  classes,
  message,
  onYes,
  onNo,
  drawerOpen,
  setDrawerOpen,
}) => {
  const handleNo = () => {
    setDrawerOpen(false)
    onNo && onNo()
  }

  const handleYes = () => {
    setDrawerOpen(false)
    onYes && onYes()
  }

  return (
    <BottomDrawer
      Header={<Typography variant="h4">My Location</Typography>}
      isOpen={drawerOpen}
      onClose={handleNo}
    >
      <Typography variant="body1" className={classes.drawerConfirmText}>
        {message}
      </Typography>
      <div className={classes.buttonGroup}>
        <Button
          variant="outlined"
          className={classes.cancelButton}
          size="large"
          onClick={handleNo}
          onKeyDown={handleNo}
        >
          Nope
        </Button>
        <Button
          variant="contained"
          className={classes.okButton}
          color="primary"
          size="large"
          onClick={handleYes}
          onKeyDown={handleNo}
          data-test="confirm-yes"
        >
          Yeah
        </Button>
      </div>
    </BottomDrawer>
  )
}

ConfirmationDrawer.propTypes = {
  classes: PropTypes.object,
  message: PropTypes.string,
  onYes: PropTypes.func,
  onNo: PropTypes.func,
  drawerOpen: PropTypes.bool,
  setDrawerOpen: PropTypes.func,
}

export default withStyles(styles)(ConfirmationDrawer)
