import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import classNames from 'classnames'
import { Box, Typography, Divider, Button, Fab } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import Skeleton from '@material-ui/lab/Skeleton'

import DamagedIcon from 'icons/DamagedIcon'

import Header from 'components/Header'
import InventoryCounter from 'components/InventoryCounter'
import AssignBins from 'components/AssignBins'
import AssignUPC from 'components/AssignUPC'
import PrintLabelDialog from 'components/PrintLabelDialog'
import ConfirmationDialog from 'components/ConfirmationDialog'
import InputDialog from 'components/InputDialog'
import Scanner from 'components/Scanner'

import {
  useAdminTransferOrder,
  useAdminShipmentItems,
  useAlerts,
  useAdminVariant,
  useAdminPlateList,
} from 'hooks'

import { useLocationsContext } from 'context'

import {
  SHIPMENT_ITEM_STATE_TYPES,
  TRANSFER_ORDER_STATE_TYPES,
} from 'constants/transferOrders'
import { URL } from 'constants/navigation'
import { DEFAULT_PRODUCT_IMAGE } from 'constants/general'

import {
  loadState,
  saveStatus,
  saveReceived,
  saveDamaged,
  saveAssignedToBins,
} from '../helpers'

import styles from './TransferOrderVariantShowScreenStyles'

const SHOW_MODE = {
  VIEW: 'VIEW',
  SCANNER: 'SCANNER',
  ASSIGN_BIN: 'ASSIGN_BIN',
  ASSIGN_UPC: 'ASSIGN_UPC',
  PRODUCT_SEARCH: 'PRODUCT_SEARCH',
}

const itemToLookupBarcode = (variant, id) => {
  if (!variant) {
    return {}
  }

  return {
    barcode: variant.barcode,
    bin: '',
    consignment: null,
    images: variant.images,
    price: variant.price,
    product: variant.product,
    quantity: 1,
    shopify_id: variant.shopify_id,
    sku: variant.sku,
    total_discount: 0,
    color: variant?.color?.label,
    size: variant?.size,
    variant,
  }
}

const inventoryItem = (variant, quantity) => {
  if (!variant) {
    return {}
  }

  return {
    barcode: variant.barcode,
    bin: '',
    consignment: null,
    images: variant.images,
    price: variant.price,
    product: variant.product,
    quantity,
    shopify_id: variant.shopify_id,
    sku: variant.sku,
    total_discount: 0,
    color: variant?.color?.label,
    size: variant?.size,
    id: variant.id,
    variant,
  }
}

const shipmentItemStats = shipmentItem => {
  const received = shipmentItem?.received || 0
  const damaged = shipmentItem?.damaged || 0
  const quantity = shipmentItem?.quantity || 0
  const missing = Math.max(0, quantity - received - damaged)

  return {
    received,
    damaged,
    quantity,
    missing,
  }
}

const DetailsRow = ({ classes, title, value }) => (
  <Box className={classes.row}>
    <Typography variant="subtitle1" className={classes.colorDark}>
      {title}
    </Typography>
    <Typography
      variant="body2"
      className={classNames({ [classes.colorDark]: !value })}
      data-test={`${title}-details`}
    >
      {typeof value === 'undefined' ? (
        <Skeleton width="100px" />
      ) : (
        value || 'Not Set'
      )}
    </Typography>
  </Box>
)

DetailsRow.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  classes: PropTypes.object,
}

const QuantityRow = ({ classes, title, value, total }) => (
  <Box className={classNames(classes.row, classes.quantityRow)}>
    <Typography variant="subtitle1">Items {title}</Typography>
    <Box className={classes.column}>
      <Box component="span">
        <Typography
          component="span"
          variant="body2"
          className={classes.quantityLabel}
        >
          Qty
        </Typography>
        <Typography component="span" variant="body1" data-test={`${title}-qty`}>
          {typeof value === 'undefined' ? <Skeleton width="20px" /> : value}
        </Typography>
      </Box>
      <Typography variant="body1" component="span">
        of {typeof value === 'undefined' ? <Skeleton width="20px" /> : total}
      </Typography>
    </Box>
  </Box>
)

QuantityRow.propTypes = {
  title: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  total: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  classes: PropTypes.object,
}

const TransferOrderVariantShowScreen = ({
  classes,
  match: {
    params: { id, variantId },
  },
}) => {
  const { location } = useLocationsContext()
  const { showAlertError } = useAlerts()
  const history = useHistory()
  const [mode, setMode] = useState(SHOW_MODE.VIEW)

  const variantIdParsed = parseInt(variantId, 10)
  const { variant } = useAdminVariant(variantIdParsed)

  const { transferOrder, updateTransferOrder } =
    // eslint-disable-next-line radix
    useAdminTransferOrder(parseInt(id))

  const { bulkUpdatePlates } = useAdminPlateList({
    fetchList: false,
  })

  const shipmentItems = transferOrder?.shipment_items ?? []

  const shipmentItem =
    shipmentItems.find(
      shipment_item => shipment_item?.variant?.id === variantIdParsed
    ) ?? {}

  const { updateShipmentItem } = useAdminShipmentItems(shipmentItem?.id, id)

  const { received, damaged, quantity, missing } =
    shipmentItemStats(shipmentItem)

  const shipmentItemsState = loadState().to?.[id]
  const shipmentItemState = shipmentItemsState?.[shipmentItem?.id] ?? {
    status: SHIPMENT_ITEM_STATE_TYPES.readyToReceive,
  }

  const handleContinueClick = () => {
    let vid = null

    const firstReadyToRecieve = shipmentItems.filter(
      thisShipmentItem =>
        !Object.keys(shipmentItemsState)
          .map(key => Number(key))
          .includes(thisShipmentItem.id)
    )?.[0]?.variant?.id

    if (firstReadyToRecieve) {
      vid = firstReadyToRecieve
    }

    const firstScanningStarted = Object.entries(shipmentItemsState)
      .map(([key, value]) =>
        value.status === SHIPMENT_ITEM_STATE_TYPES.scanningStarted
          ? shipmentItems.filter(
              thisShipmentItem => thisShipmentItem.id === Number(key)
            )
          : false
      )
      .flat()
      .filter(Boolean)?.[0]?.variant?.id

    if (firstScanningStarted) {
      vid = firstScanningStarted
    }

    const firstScanningComplete = Object.entries(shipmentItemsState)
      .map(([key, value]) =>
        value.status === SHIPMENT_ITEM_STATE_TYPES.scanningComplete
          ? shipmentItems.filter(
              thisShipmentItem =>
                thisShipmentItem.id === Number(key) && value.recieved > 0
            )
          : false
      )
      .flat()
      .filter(Boolean)?.[0]?.variant?.id

    if (firstScanningComplete) {
      vid = firstScanningComplete
    }

    if (typeof vid === 'number') {
      history.push(
        `${URL.TRANSFER_ORDERS}/${id}${URL.TRANSFER_ORDER_VARIANTS}/${vid}`
      )
    } else {
      history.push(`${URL.TRANSFER_ORDERS}/${id}`)
    }
  }

  const handleProductScan = code => {
    const item = shipmentItems.find(
      i => i.variant.barcode === code || i.variant.dsin === code
    )
    if (item) {
      history.push(
        `${URL.TRANSFER_ORDERS}/${id}${URL.TRANSFER_ORDER_VARIANTS}/${item.variant.id}`
      )
    } else {
      showAlertError('Barcode not found')
    }
  }

  const setModeView = () => setMode(SHOW_MODE.VIEW)
  const setModeScanner = () => setMode(SHOW_MODE.SCANNER)
  const setModeAssignBin = () => setMode(SHOW_MODE.ASSIGN_BIN)
  const setModeProductSearch = () => setMode(SHOW_MODE.PRODUCT_SEARCH)

  const allowScanning = [
    SHIPMENT_ITEM_STATE_TYPES.readyToReceive,
    SHIPMENT_ITEM_STATE_TYPES.scanningStarted,
  ].includes(shipmentItemState?.status)

  const disableAssignBinButton =
    shipmentItemState.status === SHIPMENT_ITEM_STATE_TYPES.scanningComplete &&
    shipmentItemState.received === 0

  const shouldContinue =
    disableAssignBinButton ||
    shipmentItemState.status === SHIPMENT_ITEM_STATE_TYPES.assigningBinsComplete

  // Using a component so we can access the inventory count context
  const CompleteScanButton = ({ item, clearCache, className }) => {
    const handleCompleteScanClick = () => {
      saveStatus(
        id,
        shipmentItem.id,
        item.checked === 0
          ? SHIPMENT_ITEM_STATE_TYPES.assigningBinsComplete
          : SHIPMENT_ITEM_STATE_TYPES.scanningComplete
      )
      saveReceived(id, shipmentItem.id, item.checked)
      saveDamaged(id, shipmentItem.id, item.flagged)
      updateShipmentItem({ damaged: item.flagged })
      setModeView()
      clearCache()
    }

    return (
      <ConfirmationDialog
        title="Complete Scan"
        message="Are you sure to complete the scan?"
        onAccept={handleCompleteScanClick}
      >
        {({ open }) => (
          <Button
            data-test="complete-scan"
            variant="contained"
            size="large"
            className={className}
            onClick={open}
          >
            Complete Scan
          </Button>
        )}
      </ConfirmationDialog>
    )
  }

  CompleteScanButton.propTypes = {
    item: PropTypes.object,
    clearCache: PropTypes.func,
    className: PropTypes.string,
  }

  const handleManualEntry = (newReceived, newDamaged) => {
    saveReceived(id, shipmentItem.id, newReceived)
    saveDamaged(id, shipmentItem.id, newDamaged)
    saveStatus(
      id,
      shipmentItem.id,
      newReceived === 0
        ? SHIPMENT_ITEM_STATE_TYPES.assigningBinsComplete
        : SHIPMENT_ITEM_STATE_TYPES.scanningComplete
    )
    updateShipmentItem({ damaged: newDamaged })
  }

  const pickerActions =
    allowScanning && (variant?.barcode || variant?.dsin)
      ? ({ currentItem, clearCache, handleFlag, className }) => (
          <>
            <Button
              data-test="mark-damaged"
              variant="contained"
              size="large"
              startIcon={<DamagedIcon />}
              onClick={handleFlag}
              className={classNames(classes.markDamaged, className)}
            >
              Mark Damaged
            </Button>
            <CompleteScanButton
              item={currentItem}
              clearCache={clearCache}
              className={className}
            />
          </>
        )
      : () => <></>

  const handleStartScan = async openCountsDialog => {
    saveStatus(id, shipmentItem.id, SHIPMENT_ITEM_STATE_TYPES.scanningStarted)
    openCountsDialog()
  }

  const handleAssignBin = (bin, thisQuantity, expirationDate) => {
    bulkUpdatePlates({
      location_id: location.id,
      create: true,
      is_adjust: true,
      zero_other_locations: false,
      variants: [
        {
          id: variant.id,
          label: bin,
          quantity: thisQuantity,
          expiration_date: expirationDate,
        },
      ],
    })
    const assignedToBins =
      (shipmentItemState?.assignedToBins ?? 0) + thisQuantity

    saveAssignedToBins(id, shipmentItem.id, assignedToBins)

    updateShipmentItem({
      new_bins: bin,
      received: thisQuantity,
    })

    if (assignedToBins >= shipmentItemState?.received) {
      setModeView()
      saveStatus(
        id,
        shipmentItem.id,
        SHIPMENT_ITEM_STATE_TYPES.assigningBinsComplete
      )
    }
  }

  const handleAssignUPC = async upc => {
    await updateTransferOrder({ barcode: upc })
    setModeView()
  }

  const DETAILS = [
    {
      title: 'Product Name',
      value: variant?.product?.title,
    },
    {
      title: 'Color',
      value: variant?.color?.label,
    },
    {
      title: 'Size',
      value: variant?.size,
    },
    {
      title: 'SKU',
      value: variant?.sku,
    },
    {
      title: 'UPC/DSIN',
      value: variant?.barcode || variant?.dsin,
    },
  ]

  const QUANTITIES = [
    {
      title: 'Received',
      value:
        shipmentItemState?.status === SHIPMENT_ITEM_STATE_TYPES.scanningComplete
          ? shipmentItemState.received
          : received,
    },
    {
      title: 'Damaged',
      value:
        shipmentItemState?.status === SHIPMENT_ITEM_STATE_TYPES.scanningComplete
          ? shipmentItemState.damaged
          : damaged,
    },
    {
      title: 'Missing',
      value: Math.max(
        0,
        /* eslint-disable-next-line no-nested-ternary */
        shipmentItemState?.status === SHIPMENT_ITEM_STATE_TYPES.readyToReceive
          ? 0
          : shipmentItemState?.status !==
            SHIPMENT_ITEM_STATE_TYPES.assigningBinsComplete
          ? shipmentItem.quantity -
            (shipmentItemState.received ?? 0) -
            (shipmentItemState.damaged ?? 0)
          : missing
      ),
    },
  ]

  const parentHref = `${URL.TRANSFER_ORDERS}/${id}`
  const view =
    {
      [SHOW_MODE.VIEW]: (
        <Box className={classes.root}>
          <Header
            breadcrumbs={[
              { text: 'TO', href: URL.TRANSFER_ORDERS },
              { text: `#${id}`, href: parentHref },
              { text: variantId, active: true },
            ]}
            onBackClick={() => history.push(parentHref)}
          />
          <Box width="100%">
            {/* eslint-disable-next-line no-nested-ternary */}
            {variant?.images?.length > 0 ? (
              <img
                src={variant.images[0].thumbnail_url}
                alt="variant"
                className={classes.image}
              />
            ) : shipmentItems?.length === 0 ? (
              <Skeleton width="375px" height="286px" />
            ) : (
              <img
                src={DEFAULT_PRODUCT_IMAGE}
                alt="placeholder variant"
                className={classes.image}
              />
            )}
          </Box>
          {DETAILS.map(row => (
            <DetailsRow
              key={row.title}
              {...{
                ...row,
                classes,
              }}
            />
          ))}
          <Divider className={classes.divider} />
          {QUANTITIES.map(row => (
            <QuantityRow
              key={row.title}
              {...{
                ...row,
                classes,
                total: quantity,
              }}
            />
          ))}
          {shipmentItems?.length > 0 ? (
            <Box className={classes.actions} data-test="action-container">
              {(variant?.barcode || variant?.dsin) && (
                <PrintLabelDialog variant={variant}>
                  {({ open: openDialog }) => (
                    <Button
                      variant="outlined"
                      color="default"
                      onClick={openDialog}
                    >
                      Print Label
                    </Button>
                  )}
                </PrintLabelDialog>
              )}
              {transferOrder?.status !== TRANSFER_ORDER_STATE_TYPES.closed && (
                <ConfirmationDialog
                  title="Cycle Count In Progress"
                  message={
                    <Typography>
                      A Cycle Count is occurring with an item in this PO. PO
                      scanning cannot be started.
                    </Typography>
                  }
                  acceptText="OK"
                  showDeny={false}
                >
                  {({ open, setExtra }) => (
                    <InputDialog
                      title="Manual Entry"
                      message={
                        <Typography>
                          Enter number of received and damaged units.
                        </Typography>
                      }
                      label="Received"
                      label2="Damaged"
                      onConfirm={handleManualEntry}
                    >
                      {({ open: openManualInput }) => (
                        <ConfirmationDialog
                          title="Start Scan"
                          message={
                            <Typography>
                              Continue with Scanning or manually enter counts?
                            </Typography>
                          }
                          acceptText="Scan"
                          denyText="Manual"
                          onAccept={() => {
                            saveStatus(
                              id,
                              shipmentItem.id,
                              SHIPMENT_ITEM_STATE_TYPES.scanningStarted
                            )
                            setModeScanner()
                          }}
                          onDeny={openManualInput}
                        >
                          {({ open: openManual }) => (
                            <>
                              <Button
                                data-test="po-primary-action"
                                variant="contained"
                                color="primary"
                                onClick={
                                  /* eslint-disable-next-line no-nested-ternary */
                                  allowScanning
                                    ? () =>
                                        handleStartScan(
                                          openManual,
                                          open,
                                          setExtra
                                        )
                                    : shouldContinue
                                    ? handleContinueClick
                                    : setModeAssignBin
                                }
                              >
                                {/* eslint-disable-next-line no-nested-ternary */}
                                {allowScanning
                                  ? 'Start Scan'
                                  : shouldContinue
                                  ? 'Continue'
                                  : 'Assign Bin'}
                              </Button>
                              {shouldContinue && (
                                <Fab
                                  className={classes.searchIcon}
                                  data-test="to-search"
                                  onClick={() => setModeProductSearch()}
                                >
                                  <SearchIcon style={{ color: 'white' }} />
                                </Fab>
                              )}
                            </>
                          )}
                        </ConfirmationDialog>
                      )}
                    </InputDialog>
                  )}
                </ConfirmationDialog>
              )}
            </Box>
          ) : (
            <Skeleton width="359px" height="40px" />
          )}
        </Box>
      ),
      [SHOW_MODE.SCANNER]: (
        <InventoryCounter
          items={[inventoryItem(variant, quantity)]}
          onClose={setModeView}
          actions={pickerActions}
          type="to"
          parentId={id}
        />
      ),
      [SHOW_MODE.ASSIGN_BIN]: (
        <AssignBins
          lineItem={itemToLookupBarcode(variant, variantId)}
          maxQuantity={
            shipmentItemState.received - (shipmentItemState.assignedToBins ?? 0)
          }
          onAssignBin={handleAssignBin}
          onBack={() => {
            setModeView()
          }}
          currentBins={
            variant?.inventory
              ?.find(i => i.location.id === location.id)
              .bin?.split(',')
              ?.map(b => b.trim()) || []
          }
        />
      ),
      [SHOW_MODE.ASSIGN_UPC]: (
        <AssignUPC
          lineItem={itemToLookupBarcode(variant, variantId)}
          onAssignUPC={handleAssignUPC}
          onBack={() => {
            setModeView()
          }}
        />
      ),
      [SHOW_MODE.PRODUCT_SEARCH]: (
        <Scanner
          title="Find Product"
          onScan={handleProductScan}
          onClose={() => setModeView()}
          numbersOnly={false}
          verifyRemainder={false}
        />
      ),
    }[mode] ?? null

  return view
}

export default withStyles(styles)(TransferOrderVariantShowScreen)
