const jssStyles = theme => ({
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: '5px',
    backgroundColor: theme.palette.grays.light,
  },
  brand: {
    color: theme.palette.grays.dark,
  },
  clickable: {
    cursor: 'pointer',
  },
})

export default jssStyles
