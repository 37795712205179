import React from 'react'
import PropTypes from 'prop-types'

import * as colors from 'theme/colors'

const DefaultSizePx = 24

const CalendarIcon = ({ fill = colors.grays.dark, fontSize, className }) => (
  <svg
    width={fontSize || DefaultSizePx}
    height={fontSize || DefaultSizePx}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={fill}
      fillRule="evenodd"
      d="M16 1v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2h-1V1h-2zm3 18H5V8h14v11z"
      clipRule="evenodd"
    />
  </svg>
)

CalendarIcon.propTypes = {
  fill: PropTypes.string,
  fontSize: PropTypes.number,
  className: PropTypes.string
}

export default CalendarIcon
