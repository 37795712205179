const jssStyles = theme => ({
  root: {
    overflow: 'hidden',
    position: 'relative',
    height: '100%',
    width: '100%',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    padding: theme.spacing(5, 2),
    zIndex: 50,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  viewport: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    height: 0,
    overflow: 'visible',
    '& > video': {
      position: 'absolute',
      transform: 'translate(-50%, -50%) scale(.4)',
    },
    '& > canvas': {
      position: 'absolute',
      transform: 'translate(-50%, -50%)  scale(.4)',
    },
  },
})

export default jssStyles
