import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import CircularProgress from '@material-ui/core/CircularProgress'

import { MARGINS, SIZE, TEXT_FIELD_TYPES } from 'constants/enums'
import { syntheticEvent } from 'utils/general'

import styles from './TextFieldStyles'

const TextField = ({
  classes,
  name,
  margin,
  fullWidth,
  value,
  placeholder,
  innerLabel,
  label,
  disabled,
  error,
  helperText,
  InputProps,
  type,
  onChange,
  onKeyPress,
  inputRef,
  autoFocus,
  multiline,
  rowsMax,
  className,
  inputClassName,
  size,
  loading,
  dataTest,
  ...props
}) => {
  const localOnChange = event => {
    const val = event.target.value
    onChange && onChange(syntheticEvent(val, name))
  }

  const hasInnerLabel = Boolean(innerLabel)
  const hasLabel = Boolean(label)

  const labelClassNames = classNames({
    [classes.label]: true,
    [classes.labelError]: error,
    [classes.disabled]: disabled,
  })

  const formControlClassNames = classNames({
    [classes.formControl]: true,
    [classes.disabled]: disabled,
    [className]: Boolean(className),
  })

  const inputClasses = innerLabel
    ? {
        root: classes.input,
      }
    : {
        root: classes.input,
        input: inputClassName || classes.centeredInput,
        inputMarginDense: classes.centeredInputDense,
      }

  return (
    <>
      {hasLabel ? (
        <Typography variant="subtitle1" className={labelClassNames}>
          {label}
        </Typography>
      ) : null}
      <FormControl
        margin={margin}
        variant="outlined"
        className={formControlClassNames}
        fullWidth={fullWidth}
        disabled={disabled}
        error={error}
        size={size}
      >
        {hasInnerLabel ? <InputLabel>{innerLabel}</InputLabel> : null}
        <OutlinedInput
          name={name}
          classes={inputClasses}
          onKeyPress={onKeyPress}
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={localOnChange}
          inputRef={inputRef}
          multiline={multiline}
          rowsMax={rowsMax}
          autoFocus={autoFocus}
          endAdornment={loading && <CircularProgress />}
          data-test={dataTest}
          {...InputProps}
          {...props}
        />
        {helperText ? (
          <FormHelperText
            className={classNames({ [classes.disabled]: disabled })}
          >
            {helperText}
          </FormHelperText>
        ) : null}
      </FormControl>
    </>
  )
}

TextField.defaultProps = {
  margin: MARGINS.normal,
  type: TEXT_FIELD_TYPES.text,
  autoFocus: false,
  multiline: false,
  size: SIZE.medium,
}

TextField.propTypes = {
  classes: PropTypes.object.isRequired,
  fullWidth: PropTypes.bool,
  name: PropTypes.string,
  margin: PropTypes.oneOf(Object.keys(MARGINS)),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  label: PropTypes.string,
  innerLabel: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  type: PropTypes.oneOf(Object.keys(TEXT_FIELD_TYPES)),
  autoFocus: PropTypes.bool,
  multiline: PropTypes.bool,
  rowsMax: PropTypes.number,
  onChange: PropTypes.func,
  onKeyPress: PropTypes.func,
  InputProps: PropTypes.object,
  inputRef: PropTypes.func,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  size: PropTypes.oneOf([SIZE.small, SIZE.medium]),
  loading: PropTypes.bool,
  dataTest: PropTypes.string,
}

export default withStyles(styles)(TextField)
