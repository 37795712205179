import React from 'react'
import PropTypes from 'prop-types'

// Via wikipedia, in the public domain.
const GLSIcon = ({ size = 14 }) => (
  <svg
    x="0px"
    y="0px"
    viewBox="0 0 845.1 296.4"
    style={{ enableBackground: 'new 0 0 845.1 296.4' }}
  >
    <path
      className="st0"
      fill="#FDDB3F"
      d="M837.7,243c0-1,0-2,0-2.9c0.1-0.1,0.1,0,0.2,0c0,1,0,1.9,0,2.9C837.9,243.1,837.8,243.1,837.7,243z"
    />
    <path
      className="st1"
      fill="#FDEB94"
      d="M837.9,240.1c-0.1,0-0.1,0-0.2,0c0-0.7,0-1.4,0-2.1c0.1,0,0.1,0,0.2,0C837.9,238.7,837.9,239.4,837.9,240.1z"
    />
    <path
      className="st2"
      d="M837.7,243c0.1,0,0.1,0,0.2,0c0,0.6,0,1.1,0,1.7C837.5,244.2,837.8,243.6,837.7,243z"
    />
    <path
      className="st3"
      fill="#061AB1"
      d="M179.2,7.3c14,0.7,27.7,2.6,41.5,5.2c7.4,1.4,14.8,3,22,5.4c1.1,0.4,2.2,0.9,3.3,1.2c1,0.3,1.3,0.8,1.3,1.9
	c0,22.4,0,44.9,0,67.3c0,1.3,0,2.6,0,4c-4.2-1.2-8.3-2.4-12.4-3.6c-13.1-3.7-26.5-6.7-40-8.5c-12.4-1.7-24.9-2.2-37.4-0.5
	c-10.8,1.4-21.1,4.3-30.4,10.1c-11.7,7.3-19.2,17.8-23.6,30.7c-2.8,8.4-4.1,17.1-4.4,26c-0.3,7.9,0,15.8,1.2,23.7
	c1.5,9.3,4.2,18.2,9.5,26.1c7,10.3,16.9,16.2,28.8,18.9c6.5,1.5,13.1,2.3,19.7,1.6c12.4-1.2,23.3-5.8,31.5-15.7
	c0.4-0.4,0.7-0.9,1-1.4c0.1-0.1,0.1-0.2,0.1-0.3c-0.4-0.2-0.9-0.1-1.3-0.1c-15.8,0-31.7,0-47.5,0c-1.1,0-1.5-0.2-1.5-1.4
	c0-19.4,0-38.7,0-58.1c0-1.1,0.3-1.4,1.4-1.4c44.3,0,88.5,0,132.8,0c1.1,0,1.4,0.3,1.4,1.4c0,47.8,0,95.7,0,143.5
	c0,1-0.2,1.3-1.3,1.3c-17.9,0-35.8,0-53.8,0c-1.2,0-1.4-0.4-1.4-1.5c0.1-12.6,0.2-25.2,0.3-37.8c0-0.4,0-0.9-0.2-1.4
	c-0.4,0.7-0.7,1.4-1.1,2.1c-5.6,10.4-13.8,18.4-23.4,24.9c-13,8.7-27.3,14-42.7,16.6c-4.5,0.7-8.9,1.2-13.5,1.4
	c-0.5,0.2-1,0-1.5,0.1c-1,0-2,0-3,0c-0.6,0-1.2,0-1.7,0c-1.2,0-2.5,0-3.7,0c-0.6,0-1.2,0-1.9,0c-12.2,0-24.1-2-35.8-5.4
	c-6.6-1.9-13-4.4-19.2-7.5c-8.4-4.2-16.2-9.5-23.3-15.8c-7.7-6.9-14.7-14.6-20.4-23.3c-6.6-10-11.5-20.8-15-32.3
	c-2.1-7.1-3.8-14.4-4.8-21.8c-0.8-5.4-1.5-10.8-1.5-16.3c0-1.6-0.1-3.2-0.1-4.8c0-0.6,0-1.2,0-1.9c0-1.2,0-2.3,0-3.5
	c0-1.3,0-2.7,0-4c0-1.1,0-2.2,0-3.3c0-0.6,0-1.2,0-1.9c0-14,2.3-27.7,6.3-41.1c2.3-7.6,5.2-15,8.8-22.1c4.7-9.3,10.6-18,17.5-25.9
	c8.1-9.4,17.4-17.5,27.7-24.4c9.9-6.7,20.6-11.9,31.8-16c12.5-4.6,25.4-7.5,38.6-9.2c6.6-0.9,13.3-1.5,19.9-1.5c0.5,0,0.9,0,1.4,0
	c0.6,0,1.2,0,1.8,0c0.7-0.1,1.5,0.1,2.2-0.1c3.6,0,7.2,0,10.8,0c0.7,0.3,1.5,0.1,2.2,0.1c0.5,0,1,0,1.4,0
	C178.2,7.3,178.7,7.1,179.2,7.3z"
    />
    <path
      className="st3"
      fill="#061AB1"
      d="M595.1,289.1c-7.6-0.2-15.2-0.6-22.7-1.2c-10.2-0.8-20.3-2-30.4-3.7c-8.8-1.5-17.5-3.3-26-5.9
	c-1-0.3-1.3-0.7-1.3-1.8c0-23.9,0-47.8,0-71.7c0-1.5,0-1.5,1.4-1.1c11.5,3.6,23.3,6.3,35.2,8.6c9.1,1.8,18.3,3.3,27.5,4.5
	c7.3,0.9,14.7,1.5,22.1,1.7c6.6,0.2,13.2-0.1,19.7-1.3c3.7-0.7,7.3-1.8,10.5-3.9c4.4-2.8,6.3-7,5.4-11.8c-0.4-2.3-1.8-3.9-3.7-5.1
	c-3.1-2-6.5-3.1-10-3.9c-10.9-2.5-21.8-5-32.7-7.4c-12-2.6-23.7-6-34.9-11.3c-12-5.7-22.6-13.3-30.5-24.1
	c-6.5-8.9-10.4-18.9-12.4-29.7c-1.1-5.9-1.5-11.8-1.5-17.8c0-16.2,2.9-31.7,10.8-46c8.3-15.2,20.4-26.5,35.7-34.6
	c11.2-5.9,23.1-9.6,35.5-11.8c7.2-1.3,14.5-2,21.8-2.4c0.5-0.2,1,0,1.4-0.1c0.5,0,1.1,0,1.6,0c0.7-0.1,1.3,0.1,2-0.1
	c3.4,0,6.7,0,10.1,0c0.7,0.3,1.5,0.1,2.2,0.1c0.5,0,1.1,0,1.6,0c0.5,0,0.9,0,1.4,0c6.4,0,12.8,0.5,19.1,1.2c11,1.1,22,2.5,32.9,4.4
	c6.9,1.2,13.8,2.5,20.7,4.2c2,0.5,2,0.6,2,2.6c0,22.5,0,45,0,67.5c0,0.1,0,0.2,0,0.4c0,1.7-0.2,1.8-1.9,1.4c-8-2-16-3.8-24.1-5.3
	c-9.1-1.7-18.2-3.3-27.3-4.5c-6.3-0.8-12.6-1.5-19-2c-5.5-0.4-11.1-0.4-16.7-0.2c-5.8,0.2-11.5,1.1-16.7,3.9
	c-3.3,1.8-5.9,4.3-6.7,8.1c-0.9,4.1,0.7,7.5,4.4,9.9c2.7,1.8,5.7,2.9,8.8,3.7c9,2.3,18,4.3,27.1,6.5c8.3,2,16.7,3.8,24.8,6.5
	c11.6,3.9,22.7,8.9,32.5,16.5c10.5,8.1,18.4,18.3,23,30.8c2.7,7.4,4.3,15.1,5,23c0.5,6,0.6,12,0.2,18c-1.2,16.1-5.9,31-15.5,44.1
	c-5.8,8-12.9,14.6-20.9,20.3c-11.1,7.7-23.4,12.8-36.4,16.2c-7.4,1.9-14.9,3.2-22.4,4c-5.2,0.6-10.4,0.8-15.5,0.8
	c-0.7,0-1.4,0-2.1,0c-1.4,0-2.8,0-4.2,0c-0.9,0-1.8,0-2.7,0c-1.8,0-3.6,0-5.4,0C596.9,289.1,596,289.3,595.1,289.1z"
    />
    <path
      className="st4"
      fill="#FFD101"
      d="M837.9,245.4c-0.6,1.6-0.6,3.4-0.9,5.1c-1.8,8.8-5.6,16.4-11.8,22.9c-7.4,7.9-16.5,12.7-27.2,14.1
	c-14.8,2-27.8-2.2-38.7-12.5c-7.4-7-12-15.7-13.6-25.8c-2.2-14.2,1.3-27,10.6-37.9c7.2-8.4,16.3-13.8,27.3-15.4
	c17.8-2.7,32.7,2.9,44.2,16.8c6,7.3,9.2,15.8,10,25.2c0.2,0.7,0,1.4,0.1,2.2c0,0.9,0,1.8,0,2.8c0.1,0.6-0.1,1.2,0.1,1.8
	C837.9,244.9,837.9,245.2,837.9,245.4z"
    />
    <path
      className="st3"
      fill="#061AB1"
      d="M303.9,148.1c0-45,0-90.1,0-135.1c0-1.2,0.3-1.4,1.4-1.4c28.2,0,56.5,0,84.7,0c1.1,0,1.3,0.3,1.3,1.4
	c0,66.3,0,132.6,0,198.9c0,1.6,0,1.6,1.6,1.6c30.8,0,61.7,0,92.5,0c1.2,0,1.4,0.3,1.4,1.4c0,22.8,0,45.6,0,68.4
	c0,1.2-0.4,1.4-1.5,1.4c-60,0-120,0-180,0c-1.3,0-1.5-0.3-1.5-1.6C303.9,238.1,303.9,193.1,303.9,148.1z"
    />
  </svg>
)

GLSIcon.propTypes = {
  size: PropTypes.number,
}

export default GLSIcon
