const jssStyles = theme => ({
  root: {
    backgroundColor: theme.palette.colorsAF.semiGray,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    '& > div': {
      margin: theme.spacing(0, 0.5, 2),
    },
  },
  shadowed: {
    boxShadow: `2px 2px 8px ${theme.palette.colorsAF.semiGray}`,
  },
  buttons: {
    display: 'flex',
    '& button': {
      flexGrow: '1',
      margin: theme.spacing(1),
    },
  },
  upcContainer: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 6,
    backgroundColor: theme.palette.colorsAF.ultraLightGray,
    padding: theme.spacing(1),
  },
  lineItem: {
    backgroundColor: theme.palette.colorsAF.ultraLightGray,
    padding: theme.spacing(1),
    borderRadius: 6,
  },
  itemBox: {
    marginLeft: '30px',
    marginRight: '30px',
  },
})

export default jssStyles
