import * as yup from 'yup'
import { STATUS_DOT_VARIANTS } from './enums'

export const TRANSFER_ORDER_STATE_TYPES = {
  draft: 'draft',
  submitted: 'submitted',
  receiving: 'receiving',
  closed: 'closed',
}

export const SHIPMENT_ITEM_STATE_TYPES = {
  readyToReceive: 'readyToReceive',
  scanningStarted: 'scanningStarted',
  scanningComplete: 'scanningComplete',
  assigningBinsComplete: 'assigningBinsComplete',
}

export const TRANSFER_ORDER_STATE_LABELS = [
  {
    value: TRANSFER_ORDER_STATE_TYPES.draft,
    label: 'Open',
    indicator: STATUS_DOT_VARIANTS.blue,
  },
  {
    value: TRANSFER_ORDER_STATE_TYPES.submitted,
    label: 'Submitted',
    indicator: STATUS_DOT_VARIANTS.green,
  },
  {
    value: TRANSFER_ORDER_STATE_TYPES.receiving,
    label: 'Receiving',
    indicator: STATUS_DOT_VARIANTS.blue,
  },
  {
    value: TRANSFER_ORDER_STATE_TYPES.closed,
    label: 'Closed',
    indicator: STATUS_DOT_VARIANTS.gray,
  },
]

export const TRANSFER_ORDER_PURPOSES = {
  marketing: 'marketing',
  other: 'other',
}

export const TRANSFER_ORDER_PURPOSES_LABELS = [
  {
    value: TRANSFER_ORDER_PURPOSES.marketing,
    label: 'Marketing',
  },
  {
    value: TRANSFER_ORDER_PURPOSES.other,
    label: 'Other',
  },
]

export const TO_LIST_COLUMNS = [
  {
    title: 'Transfer #',
    // sortKeys: [TRANSFER_ORDERS_ID]
  },
  { title: 'From Location' },
  { title: 'To Location' },
  { title: 'Order Date' /* sortKeys: [TRANSFER_ORDERS_ORDER_DATE] */ },
  {
    title: 'ETA',
    // sortKeys: [TRANSFER_ORDERS_ETA]
  },
  {
    title: 'Status',
  },
]

export const STATUS_FILTER_ITEMS = [
  {
    label: 'Active',
    value: 'active',
  },
  {
    label: 'Inactive',
    value: 'inactive',
  },
  {
    label: 'All',
    value: '',
  },
]

export const TO_FIELDS = {
  origin_location_id: 'origin_location_id',
  origin_stock_location_id: 'origin_stock_location_id',
  destination_location_id: 'destination_location_id',
  destination_stock_location_id: 'destination_stock_location_id',
  order_date: 'order_date',
  estimated_arrival_at: 'estimated_arrival_at',
  memo: 'memo',
  // purpose: 'purpose'
}

export const TO_SUBMIT_EDITABLE_SCHEMAS = {
  [TO_FIELDS.estimated_arrival_at]: yup
    .string()
    .ensure()
    .required('Estimated Arrival is a required field'),
}

export const TO_SCHEMAS = {
  [TO_FIELDS.origin_location_id]: yup.string().required(),
  [TO_FIELDS.origin_stock_location_id]: yup.string().required(),
  [TO_FIELDS.destination_location_id]: yup.string().required(),
  [TO_FIELDS.destination_stock_location_id]: yup.string().required(),
  [TO_FIELDS.order_date]: yup
    .string()
    .ensure()
    .required('Order Date is a required field'),
  [TO_FIELDS.memo]: yup.string(),
  // [TO_FIELDS.purpose]: yup.string(),
  ...TO_SUBMIT_EDITABLE_SCHEMAS,
}

export const TO_FORM_DEFAULTS = {
  [TO_FIELDS.origin_location_id]: '',
  [TO_FIELDS.origin_stock_location_id]: '',
  [TO_FIELDS.destination_location_id]: '',
  [TO_FIELDS.destination_stock_location_id]: '',
  [TO_FIELDS.order_date]: null,
  [TO_FIELDS.estimated_arrival_at]: null,
  [TO_FIELDS.memo]: '',
  [TO_FIELDS.purpose]: '',
}

export const SHIPMENT_ITEM_COLUMNS = [
  {
    title: 'Vendor Style #',
  },
  { title: 'Product Name' },
  { title: 'SKU' },
  { title: 'Color' },
  { title: 'Size' },
  { title: 'MSRP' },
  { title: 'Ordered' },
  { title: 'Received' },
  { title: 'Damaged' },
  { title: '' },
]
