import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import moment from 'moment-timezone'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import { getSortedBinList } from 'utils/bin'
import { DEFAULT_PRODUCT_IMAGE } from 'constants/general'

import styles from './PlateItemStyles'

const PlateItem = ({ classes, className, plate }) => {
  const imgUrl = plate.variant.images?.[0]?.url ?? DEFAULT_PRODUCT_IMAGE

  const imageProps = { alignSelf: 'flex-start', mt: 2.5 }

  const sortedBins = getSortedBinList(plate.variant.all_locations.join(','))

  const currentBin = sortedBins.join(',')
  const barcode = ''

  return (
    <Box
      className={classNames({
        [classes.root]: true,
        [classes.listItem]: true,
        [className]: Boolean(classNames),
      })}
    >
      <Box className={classes.content} data-test={`plate-item-${barcode}`}>
        <Box width={100} ml={2} mr={2.2} {...imageProps}>
          <img alt="variant" src={imgUrl} className={classes.image} />
        </Box>
        <Box my={2}>
          <Typography data-test="sku" variant="body2">
            {plate.variant.sku}
          </Typography>
          {barcode && <Typography variant="body1">UPC: {barcode}</Typography>}
          <Typography variant="body1" data-test="bin">
            BIN: {currentBin}
          </Typography>
          {plate.variant?.color?.label && (
            <Typography className={classes.description} variant="body1">
              Color: {plate.variant?.color?.label}
            </Typography>
          )}
          {plate.variant?.size && (
            <Typography className={classes.description} variant="body1">
              Size: {plate.variant?.size}
            </Typography>
          )}
          <Typography className={classes.description} variant="body1">
            <strong>Quantity</strong>: {plate.quantity} (Total:{' '}
            {plate.variant.total_physical_quantity})
          </Typography>
          <Typography className={classes.description} variant="body1">
            <strong>Pull</strong>:{' '}
            {plate.pull_date ? moment(plate.pull_date).calendar() : 'N/A'}{' '}
          </Typography>
          <Typography className={classes.description} variant="body1">
            (Expires:{' '}
            {plate.expiration_date
              ? moment(plate.expiration_date).calendar()
              : 'N/A'}
            )
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

PlateItem.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  plate: PropTypes.object.isRequired,
}

export default withStyles(styles)(PlateItem)
