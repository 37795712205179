import { createMuiTheme } from '@material-ui/core/styles'

import layout from 'theme/layout'
import { lightPalette, darkPalette } from 'theme/palette'
import overrides from 'theme/overrides'
import typography from 'theme/typography'

export const darkTheme = createMuiTheme({
  layout,
  palette: darkPalette,
  overrides,
  typography,
})

export const lightTheme = createMuiTheme({
  layout,
  palette: lightPalette,
  overrides,
  typography,
})

export default lightTheme
