const jssStyles = theme => ({
  fullList: {
    padding: 0,
    marginTop: theme.spacing(1),
  },
  listItem: {
    padding: '16px 0',
    marginBottom: 8,
    border: '1px solid #E0E0E0',
    borderRadius: 5,
    textAlign: 'center',
  },
  listItemText: {
    fontSize: 14,
    fontWeight: 600,
  },
})

export default jssStyles
