import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'

import QBarcodeScanner from 'components/QBarcodeScanner'

import styles from './OrderScannerStyles'

const SCANNER_COOLDOWN_MS = 2000

const OrderScanner = ({ classes, onScan, header, children }) => {
  const scannerDisabledRef = useRef(false)

  const handleScan = ({ codeResult: { code } }) => {
    // TODO: Do something better than this mutex
    // Prevent scans from running concurrently
    if (scannerDisabledRef.current) {
      return
    }
    scannerDisabledRef.current = true

    try {
      if (onScan) {
        const res = onScan(code)
        if (!res) {
          scannerDisabledRef.current = false
        }
      }

      setTimeout(() => {
        scannerDisabledRef.current = false
      }, SCANNER_COOLDOWN_MS)
    } catch (e) {
      console.error('Error in handleScan', e)
      scannerDisabledRef.current = false
    }
  }

  return (
    <Box className={classes.root}>
      {header}
      <Box className={classes.scannerContainer}>
        <QBarcodeScanner onScan={handleScan} disabled={false} />
      </Box>
      {children}
    </Box>
  )
}

OrderScanner.propTypes = {
  classes: PropTypes.object.isRequired,
  onScan: PropTypes.func,
  header: PropTypes.node,
  children: PropTypes.node,
}

export default withStyles(styles)(OrderScanner)
