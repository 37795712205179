export const MARGINS = {
  none: 'none',
  normal: 'normal',
  dense: 'dense',
}

export const MESSAGE = {
  default: 'default',
  success: 'success',
  error: 'error',
  warning: 'warning',
  info: 'info',
}

export const NOTIFICATION_TYPE = {
  primary: 'primary',
  secondary: 'secondary',
}

export const SIZE = {
  small: 'small',
  medium: 'medium',
  large: 'large',
}

export const SIZE_PX = {
  small: 20,
  medium: 24,
  large: 35,
}

export const COLOR = {
  primary: 'primary',
  secondary: 'secondary',
}

export const TOOLTIP_VARIANT = {
  dark: 'dark',
  light: 'light',
  blue: 'blue',
}

export const TOOLTIP_PLACEMENT = {
  top: 'top',
  left: 'left',
  right: 'right',
  bottom: 'bottom',
}

export const TAB_VARIANT_TYPES = {
  fullWidth: 'fullWidth',
  scrollable: 'scrollable',
  standard: 'standard',
}

export const THEME_TYPES = {
  dark: 'dark',
  light: 'light',
}

/* WARNING: copied from dashboard; keep in sync */
export const STATUS_DOT_VARIANTS = {
  gray: 'gray',
  blue: 'blue',
  green: 'green',
  orange: 'orange',
  red: 'red',
}

export const CIRCLE_CHECK_STATUS = {
  checked: 'checked',
  warning: 'warning',
  unchecked: 'unchecked',
}

export const COUNT_STATUS = {
  inProgress: 'inProgress',
  complete: 'complete',
  flagged: 'flagged',
  broken: 'broken',
  missing: 'missing',
}

export const MESSAGE_BUFFER_TYPES = {
  error: 'error',
  info: 'info',
}

export const TEXT_FIELD_TYPES = {
  text: 'text',
  number: 'number',
  password: 'password',
}

export const ROADIE_ORDER_SIZES = {
  small: 'small',
  medium: 'medium',
  large: 'large',
  xlarge: 'xlarge',
  huge: 'huge',
}

export const DELIVERY_METHOD_KEYS = {
  doordash: 'doordash',
  fastaf: 'fastaf',
  onfleet: 'onfleet',
  roadie: 'roadie',
  uber: 'uber',
  global: 'global',
}

export const ENVIRONMENT_INSTANCES = {
  staging: 'staging',
  production: 'production',
  development: 'development',
}

export const PRODUCT_RESULTS_POPOVER_TYPES = {
  collection: 'collection',
  po: 'po',
}

export const PRINTER_TYPE = {
  LABEL: 'label',
  BARCODE: 'general',
}
