import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'

import EmptyIcon from '@material-ui/icons/NotInterested'

import Button from '@material-ui/core/Button'

import { useAdminUberOrder, useAdminPrinters, useAdminItemLocation } from 'hooks'
import {
  InventoryCountProvider,
  useInventoryCountContext,
  useLocalStorageContext,
} from 'context'
import {
  UBER_ORDER_STATES_TYPES,
  UBER_ORDER_STATE_MAP,
} from 'constants/general'
import { PRINTER_TYPE } from 'constants/enums'
import { waitAtLeastSec } from 'utils/general'
import { IS_PRODUCTION } from 'lib/config'
import OrderView from 'components/OrderView'
import InventoryCount from 'components/InventoryCount'
import PrintingSplash from 'components/PrintingSplash'
import ScheduleDispatch from 'components/ScheduleDispatch'
import MessageActionView from 'components/MessageActionView'

import FlagIcon from 'icons/FlagIcon'

const HideUntilContextLoaded = ({ children }) => {
  const { loaded } = useInventoryCountContext()
  return loaded ? children : null
}

HideUntilContextLoaded.propTypes = {
  children: PropTypes.node,
}

const SHOW_MODE = {
  VIEW: 'VIEW',
  SCAN: 'SCAN',
  DISPATCH: 'DISPATCH',
  PRINT: 'PRINT',
  DELIVERED: 'DELIVERED',
}

const UberOrderShowScreen = ({
  match: {
    params: { id },
  },
}) => {
  const history = useHistory()
  const { locationId, printerId } = useLocalStorageContext()
  const [mode, setMode] = useState(SHOW_MODE.VIEW)
  const [hasPrinted, setHasPrinted] = useState(false)
  const [printerError, setPrinterError] = useState(null)
  const {
    uberOrder,
    updateUberOrder: updateOrder,
    printOrderLabel,
    inCycleCount,
    short
  } = useAdminUberOrder(id)
  const { pick } = useAdminItemLocation()

  const order = {
    uber: true,
    first_name: uberOrder?.first_name,
    last_name: uberOrder?.last_name,
    number: uberOrder?.display_id,
    user_first_order: false,
    gift_detail: [],
    total_line_item_quantity: uberOrder?.uber_eats_order_items
      ?.map(it => it.quantity)
      ?.reduce((acc, v) => acc + v),
    id: `uber-${uberOrder?.id}`,
    status: UBER_ORDER_STATE_MAP[uberOrder?.status],
    line_items: uberOrder?.uber_eats_order_items?.map(ui => {
      return {
        ...ui,
        price: ui.variant.price,
        barcode: ui.variant.barcode,
        bin: ui.variant.bin,
      }
    }),
  }

  // using this to populate the RQ cache so it loads fast later
  useAdminPrinters({ locationId, type: PRINTER_TYPE.LABEL })

  const setModeView = () => setMode(SHOW_MODE.VIEW)
  const setModeScan = () => setMode(SHOW_MODE.SCAN)
  const setModePrint = () => setMode(SHOW_MODE.PRINT)
  const setModeDispatch = () => setMode(SHOW_MODE.DISPATCH)
  const setModeDelivered = () => setMode(SHOW_MODE.DELIVERED)

  const updateToPicking = async () => {
    await updateOrder({ status: UBER_ORDER_STATES_TYPES.picking })
    setModeScan()
  }

  const maybeUpdateToPicking = async (
    openPreventCycleCountDialog,
    setExtra
  ) => {
    const cycleCountVariants = await inCycleCount(id)
    if (cycleCountVariants.length > 0) {
      setExtra({ message: `Cycle Counts: ${cycleCountVariants.join(',')}` })
      openPreventCycleCountDialog()
      return
    }
    updateToPicking()
  }

  const print = async () => {
    setPrinterError(null)
    setHasPrinted(false)
    setModePrint()
    let err
    if (IS_PRODUCTION) {
      err = await waitAtLeastSec(1, () => printOrderLabel(id, printerId))
    }

    if (err) {
      setPrinterError(err?.data?.message ?? 'Unknown')
    } else {
      setHasPrinted(true)
    }
  }

  const updateToDispatched = async (
    deliveryMethod,
    roadieOrderSize,
    itemSingles
  ) => {
    const shorted = {}
    itemSingles.forEach(({ item: { bin, variant }, checked, flagged }) => {
      if (checked) {
        pick({
          location_id: locationId,
          label: bin,
          quantity: 1,
          variant_id: variant.id,
        })
      } else if (flagged) {
        shorted[variant.id] = {
          variant_id: variant.id,
          sku: variant.sku,
          quantity: (shorted[variant.id]?.quantity || 0) + 1
        }
      }
    })

    if (!isEmpty(shorted)) {
      short(id, Object.values(shorted))
    }
    await updateOrder({ status: UBER_ORDER_STATES_TYPES.fulfilled })
    print()
  }

  const updateToDelivered = async () => {
    await updateOrder({ status: UBER_ORDER_STATES_TYPES.delivered })
    setModeDelivered()
  }

  const navigateToOrdersList = () => {
    history.push('/orders')
  }

  const pickerActions = ({
    currentItem,
    handleFlag,
    handleBinEmpty,
    className,
  }) =>
    currentItem.item.bins.length > 1 ? (
      <Button
        fullWidth
        variant="contained"
        size="large"
        disabled={!currentItem || currentItem.checked || currentItem.flagged}
        startIcon={<EmptyIcon />}
        onClick={handleBinEmpty}
        className={className}
        data-test="bin-empty-button"
      >
        Bin Empty
      </Button>
    ) : (
      <Button
        fullWidth
        variant="contained"
        size="large"
        disabled={!currentItem || currentItem.checked || currentItem.flagged}
        startIcon={<FlagIcon fill="red" />}
        onClick={handleFlag}
        className={className}
        data-test="flag-short-button"
      >
        Flag Short
      </Button>
    )

  const view =
    {
      [SHOW_MODE.VIEW]: (
        <OrderView
          order={order}
          onClickMaybeStartPicking={maybeUpdateToPicking}
          onClickStartPicking={updateToPicking}
          onClickContinuePicking={setModeScan}
          onClickPrint={print}
          onClickDispatch={setModeDispatch}
          onClickDelivered={updateToDelivered}
        />
      ),
      [SHOW_MODE.SCAN]: (
        <InventoryCount actions={pickerActions} onClose={setModeView} />
      ),
      [SHOW_MODE.DISPATCH]: (
        <ScheduleDispatch
          order={order}
          updateToDispatched={updateToDispatched}
          onClose={setModeView}
        />
      ),
      [SHOW_MODE.PRINT]: (
        <PrintingSplash
          order={order}
          error={printerError}
          hasPrinted={hasPrinted}
          print={print}
          onClose={setModeView}
        />
      ),
      [SHOW_MODE.DELIVERED]: (
        <MessageActionView
          title="Order Delivered"
          description={
            <>
              You just helped deliver joy to
              <br />
              {order.first_name} {order.last_name}!
            </>
          }
          success
          actionText="Next Order"
          onClick={navigateToOrdersList}
          onBackClick={setModeView}
        />
      ),
    }[mode] ?? null

  return (
    <InventoryCountProvider order={order?.id ? order : { id: `uber-${id}` }}>
      <HideUntilContextLoaded>{view}</HideUntilContextLoaded>
    </InventoryCountProvider>
  )
}

UberOrderShowScreen.propTypes = {
  match: PropTypes.object,
}
export default UberOrderShowScreen
