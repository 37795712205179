const jssStyles = theme => ({
  headerIcon: {
    color: theme.palette.primary.contrastText,
    fontSize: 20,
    marginLeft: theme.spacing(2),
  },
  scannerContainer: {
    width: '100%',
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  },
  carouselContainer: {
    // On chrome the carousel would highlight, doesn't happen in safari
    '-webkit-tap-highlight-color': 'rgba(0,0,0,0)',
    // eslint-disable-next-line no-dupe-keys
    '-webkit-tap-highlight-color': 'transparent',
    '& .slider-slide:focus': {
      outline: 'none',
    },
  },
  itemBox: {
    padding: theme.spacing(0, 0.5),
  },
})

export default jssStyles
