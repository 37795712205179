import { useResourceRQ } from 'hooks'

import {
  transferOrderEndpoint,
  transferOrderListEndpoint,
  transferOrderSummaryEndpoint,
  shipmentItemEndpoint,
} from 'api/endpoints'

const useAdminTransferOrder = (transferOrderId, { type, onSuccess } = {}) => {
  const {
    create: createTransferOrder,
    data: transferOrder,
    update: updateTransferOrder,
    isLoading: isLoadingTransferOrder,
    isError: isTransferOrderError,
    error: transferOrderError,
  } = useResourceRQ({
    endpoint: transferOrderEndpoint,
    args: [transferOrderId, type],
    name: 'transfer_order',
    fetchingEnabled: typeof transferOrderId === 'number',
    dependents: [
      transferOrderListEndpoint,
      transferOrderSummaryEndpoint,
      shipmentItemEndpoint,
    ],
    onSuccess,
  })

  return {
    transferOrder,
    updateTransferOrder,
    createTransferOrder,
    isLoadingTransferOrder,
    isTransferOrderError,
    transferOrderError,
  }
}

export default useAdminTransferOrder
