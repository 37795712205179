import React from 'react'
import PropTypes from 'prop-types'

import uniqueId from 'lodash/uniqueId'
import Quagga from 'quagga'

import Box from '@material-ui/core/Box'
import { withStyles } from '@material-ui/core/styles'

import { useLatestCallback } from 'hooks'
import { colorsAF, warnings } from 'theme/colors'

import styles from './QBarcodeScannerStyles'

const CLEAR_DEBUG_TIMEOUT_MS = 1000

const clearDebug = (context, canvas) => {
  context.clearRect(
    0,
    0,
    parseInt(canvas.getAttribute('width'), 10),
    parseInt(canvas.getAttribute('height'), 10)
  )
}

const drawDebug = (result, context) => {
  if (result.box) {
    Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, context, {
      color: warnings.error,
      lineWidth: 2,
    })
  }

  if (result.codeResult && result.codeResult.code) {
    Quagga.ImageDebug.drawPath(result.line, { x: 'x', y: 'y' }, context, {
      color: colorsAF.fast,
      lineWidth: 3,
    })
  }
}

const QBarcodeScanner = ({ classes, onScan, disabled, children }) => {
  const [videoId] = React.useState(uniqueId('video-'))
  const clearTimeoutRef = React.useRef(null)
  const handleDetected = useLatestCallback(result => {
    !disabled && onScan(result)
  })

  const handleProcessed = useLatestCallback(result => {
    if (disabled || !result) {
      return
    }

    const canvas = Quagga.canvas.dom.overlay
    const context = Quagga.canvas.ctx.overlay
    clearDebug(context, canvas)
    drawDebug(result, context)
    clearTimeoutRef.current && clearTimeout(clearTimeoutRef.current)
    clearTimeoutRef.current = setTimeout(
      () => clearDebug(context, canvas),
      CLEAR_DEBUG_TIMEOUT_MS
    )
  })

  React.useLayoutEffect(() => {
    ;(async () => {
      Quagga.init(
        {
          inputStream: {
            name: 'Live',
            type: 'LiveStream',
            target: `#${videoId}`,
            constraints: {
              /* The WxH here, is used as an ideal resolution.
               * TODO(exhrizo): Determine the best resolution and settings to use
               * https://trello.com/c/KvTAwHZI/126-optimize-barcode-scanner-settings
               */
              width: 1000,
              height: 800,
              facingMode: 'environment',
            },
          },
          decoder: {
            readers: [
              'code_128_reader',
              'ean_reader',
              'ean_8_reader',
              'code_39_reader',
              'code_39_vin_reader',
              'codabar_reader',
              'upc_reader',
              'upc_e_reader',
              'i2of5_reader',
              '2of5_reader',
              'code_93_reader',
            ],
            debug: {
              drawScanline: true,
            },
          },
          area: {
            // defines rectangle of the detection/localization area
            top: '0%', // top offset
            right: '0%', // right offset
            left: '0%', // left offset
            bottom: '0%', // bottom offset
          },
          singleChannel: false, // true: only the red color-channel is read
        },
        err => {
          if (err) {
            console.error('Failed to initialize barcode reader', err)
            return
          }
          Quagga.start()
        }
      )

      Quagga.onProcessed(handleProcessed)
      Quagga.onDetected(handleDetected)
    })()
    return () => {
      document.querySelector(`#${videoId} video`).pause()
      Quagga.offProcessed(handleProcessed)
      Quagga.offDetected(handleDetected)
      Quagga.stop()
    }
  }, [])

  return (
    <Box className={classes.root}>
      <Box className={classes.overlay}>{children}</Box>
      <Box id={videoId} className={classes.viewport} />
    </Box>
  )
}

QBarcodeScanner.defaultProps = {
  disabled: false,
}

QBarcodeScanner.propTypes = {
  classes: PropTypes.object.isRequired,
  onScan: PropTypes.func,
  disabled: PropTypes.bool,
  children: PropTypes.node,
}

export default withStyles(styles)(QBarcodeScanner)
