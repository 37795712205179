const jssStyles = theme => ({
  root: {
    height: 74,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 2),
  },
  white: {
    color: theme.palette.primary.contrastText,
  },
  breadcrumb: {
    color: theme.palette.colorsAF.lightGray,
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.text.primary,
      textDecoration: 'none',
    },
  },
  backButton: {
    color: 'inherit',
  },
  backIcon: {
    fontSize: 18,
  },
  active: {
    color: theme.palette.text.primary,
  },
})

export default jssStyles
