const HEADER_TEXT_HEIGHT_PX = 74
const TABS_HEIGHT_PX = 60

const jssStyles = theme => ({
  root: {
    margin: '0 auto',
    height: HEADER_TEXT_HEIGHT_PX,
  },
  scannerFab: {
    bottom: TABS_HEIGHT_PX + 16,
    right: 130,
  },
  tabRoot: {
    height: TABS_HEIGHT_PX,
  },
  listContainer: {
    position: 'relative',
    overflowY: 'scroll',
    height: `calc(100% - ${TABS_HEIGHT_PX}px)`,
    maxHeight: '100%',
  },
  listRow: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginBottom: 8,
  },
  listLeft: {
    flexDirection: 'column',
  },
  rowLink: {
    textDecoration: 'none',
  },
  tabPanel: {
    position: 'absolute',
    width: '100%',
    bottom: 0,
    top: HEADER_TEXT_HEIGHT_PX,
  },
  searchIcon: {
    width: 48,
    height: 48,
    background: theme.palette.primary.main,
    padding: 0,
    position: 'absolute',
    bottom: TABS_HEIGHT_PX + 16,
    right: 72,
    zIndex: 1,
  },
  pageButtons: {
    display: 'flex',
    paddingBottom: 70,
    '& button': {
      flexGrow: '1',
      margin: theme.spacing(1),
    },
  },
})

export default jssStyles
