const jssStyles = theme => ({
  root: {
    height: 22,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 100,
    padding: theme.spacing(0, 1),
    marginRight: theme.spacing(0.5),
    '& svg': {
      marginRight: theme.spacing(0.5),
    },
  },
  gray: {
    color: theme.palette.colorsAF.lightGray,
    backgroundColor: theme.palette.colorsAF.semiGray,
  },
  blue: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.colorsAF.fast,
  },
  yellow: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.warnings.error,
  },
  red: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.warnings.danger,
  },
})

export default jssStyles
