import { useResourceListRQ } from 'hooks'
import { productsListEndpoint } from 'api/endpoints'

const useAdminProductList = (params, enabled = true) => {
  const {
    isFetching: isFetchingProducts,
    data: products,
    hasData: hasProducts,
    hasNextPage: hasProductsNext,
    fetchNextPage: fetchProductsNext,
  } = useResourceListRQ({ endpoint: productsListEndpoint, params, enabled })

  return {
    isFetchingProducts,
    products: products || [],
    hasProducts,
    hasProductsNext,
    fetchProductsNext,
  }
}

export default useAdminProductList
