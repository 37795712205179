const TABS_HEIGHT_PX = 60
const HEADER_TEXT_HEIGHT_PX = 74
const TEXT_FIELD_HEIGHT = 60

const jssStyles = theme => ({
  tabContainer: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(1),
  },
  tabRoot: {
    height: TABS_HEIGHT_PX,
  },
  tabPanel: {
    position: 'absolute',
    width: '100%',
    bottom: 0,
    top: HEADER_TEXT_HEIGHT_PX,
  },
  listContainer: {
    position: 'relative',
    overflowY: 'scroll',
    height: `calc(100% - ${TABS_HEIGHT_PX}px)`,
    maxHeight: '100%',
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
  },
  etaStatusRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  searchIcon: {
    width: 48,
    height: 48,
    background: theme.palette.primary.main,
    padding: 0,
    position: 'absolute',
    bottom: TABS_HEIGHT_PX + 16,
    right: 128,
  },
  newIcon: {
    width: 48,
    height: 48,
    background: theme.palette.primary.main,
    padding: 0,
    position: 'absolute',
    bottom: TABS_HEIGHT_PX + 16,
    right: 72,
  },
  searchInput: {
    marginBottom: 0,
    marginTop: 0,
    display: 'flex',
    margin: theme.spacing(1),
  },
  searchContainer: {
    position: 'absolute',
    width: '100%',
    bottom: 0,
    top: HEADER_TEXT_HEIGHT_PX + TEXT_FIELD_HEIGHT,
  },
})

export default jssStyles
