import { useResourceRQ } from 'hooks'

import { pickEndpoint } from 'api/endpoints'

const useAdminItemLocation = () => {
  const {
    update: pick,
    isError: isPickError,
    error: pickError,
  } = useResourceRQ({
    endpoint: pickEndpoint,
    args: [],
    name: 'item_location',
    fetchingEnabled: false,
  })

  return {
    pick,
    isPickError,
    pickError,
  }
}

export default useAdminItemLocation
