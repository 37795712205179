/* eslint-disable no-shadow */
// TODO - clean up shadowed variables
// https://trello.com/c/xE8oc1Mi/66-clean-up-shadowed-variables

import { useEffect, useState } from 'react'

import { useAPIContext } from 'context'
import { useAlerts, useLoaders } from 'hooks'

/* useResource
  A base hook that allows for CRUD operations of a REST API that follows
  standard REST patterns of GET POST PUT and DELETE to create, update, create and
  destroy objects.

  @param id - The resource ID to auto fetch
  @param url - The API endpoint. The is set dynamically using setEndpoint
  @param name - The name of the resource needed when using POST and PUT
*/

const useResourceFulfillment = ({ url = '/', name, ...props }) => {
  const { api } = useAPIContext()
  const { isLoading, showLoading, hideLoading } = useLoaders()

  // eslint-disable-next-line
  const { showAlertError, showAlertSuccess, showAlertWarning } = useAlerts()

  const [isLoaded, setIsLoaded] = useState(false)
  const [isEmpty, setIsEmpty] = useState(false)
  const [id, setId] = useState(props.id)
  const [data, setData] = useState({ id: props.id })
  const [meta, setMeta] = useState({})
  const [params, setParams] = useState({})
  const [page, setPage] = useState(1)
  const [perPage] = useState(20)
  const [numPages, setNumPages] = useState(1)
  const [totalCount, setTotalCount] = useState(0)
  const [errors, setErrors] = useState([])

  const handleErrors = e => {
    hideLoading()
    setIsLoaded(false)
    setErrors(e?.data)
    console.error('useResource Error:', e)
  }

  const findOne = async id => {
    if (!id) showAlertError('No ID was provided')
    try {
      showLoading()
      setIsLoaded(false)
      setId(id)
      const res = await api.get(`${url}/${id}`)
      setData(res.data)
      setMeta(res.meta)
      setIsLoaded(true)
      hideLoading()
      return res.data
    } catch (e) {
      handleErrors(e)
    }
    hideLoading()
  }

  const findAll = async (params, page = 1) => {
    try {
      showLoading()
      setParams(params)
      setPage(page)
      setIsLoaded(false)
      const res = await api.get(url, {
        params: {
          ...params,
          page,
          per_page: perPage,
        },
      })
      setData(res.data)
      if (res.meta) {
        setMeta(res.meta)
        setPage(res.meta.page)
        setNumPages(res.meta.num_pages)
        setTotalCount(res.meta.totalCount)
      }
      // eslint-disable-next-line
      setIsEmpty(res.data.length > 0 ? false : true)
      setIsLoaded(true)
      hideLoading()
      return res.data
    } catch (e) {
      handleErrors(e)
    }
    hideLoading()
  }

  const create = async resource => {
    try {
      showLoading()
      const res = await api.post(url, {
        [name]: resource,
      })
      if (res.data && res.data.id) {
        setData(res.data)
        setIsLoaded(true)
        setId(res.data.id)
      }
      hideLoading()
      return res.data
    } catch (e) {
      handleErrors(e)
    }
  }

  const update = async resource => {
    setId(resource.id)
    try {
      showLoading()
      const res = await api.put(`${url}/${resource.id}`, {
        [name]: resource,
      })
      hideLoading()
      return res.data
    } catch (e) {
      handleErrors(e)
    }
  }

  const destroy = async resource => {
    try {
      showLoading()
      await api.delete(`${url}/${resource.id}`)
      setData({ data: {} })
      hideLoading()
    } catch (e) {
      handleErrors(e)
    }
  }

  const goToPage = page => findAll(params, page)

  const uploadFile = async (file, attributeName) => {
    try {
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      }
      const formData = new FormData()
      formData.append(`${name}[${attributeName}]`, file)
      await api.put(`${url}/${id}`, formData, config)
    } catch (e) {
      handleErrors(e)
    }
  }

  const deleteFile = async type => {
    showLoading()
    await api.post(`${url}/${id}/delete_file`, {
      type,
    })
    hideLoading()
  }

  useEffect(() => {
    if (props.id) setId(props.id)
  }, [props.id])

  return {
    id,
    isLoading,
    isLoaded,
    isEmpty,
    data,
    errors,
    meta,
    findOne,
    findAll,
    update,
    create,
    destroy,
    goToPage,
    uploadFile,
    deleteFile,
    params,
    page,
    numPages,
    perPage,
    totalCount,
  }
}

export default useResourceFulfillment
