/* WARNING: copied from dashboard; keep in sync */
import { useAPIContext } from 'context'
import { useResourceList, useResource } from 'hooks'

const API_BRANDS = '/api/v1/admin/brands'
const BRAND = 'brand'

const useAdminBrands = () => {
  const { api, downloadApi } = useAPIContext()

  const { createMultipartFormData: createBrand } = useResource({
    url: API_BRANDS,
    name: BRAND,
    storeState: false,
  })

  const {
    isLoading: isLoadingBrands,
    data: brands,
    hasData: hasBrands,
    hasNext: hasBrandsNext,
    list: fetchBrands,
    listNext: fetchBrandsNext,
    relist: refetchBrands,
    updateItemsLocally: updateBrandsLocally,
  } = useResourceList({ url: API_BRANDS })

  const {
    read: readBrand,
    data: brand,
    hasData: hasBrand,
    update: updateBrand,
    updateMultipartFormData: updateBrandWithImage,
  } = useResource({
    url: API_BRANDS,
    name: 'brand',
  })

  const getBrandPayments = async id => {
    try {
      const res = await api.get(`${API_BRANDS}/${id}/payments`)
      return res.data
    } catch (e) {
      console.error(e)
      return false
    }
  }

  const updatePublishStatuses = async (brandsToUpdate, active) => {
    try {
      await api.put(`${API_BRANDS}/bulk_update_status`, {
        brand_ids: brandsToUpdate.map(c => c.id),
        active,
      })
      refetchBrands()
      return true
    } catch (e) {
      console.error(e)
      return false
    }
  }

  const exportReports = (
    brandId,
    dateRange,
    customerReport,
    inventoryReport,
    paymentReport
  ) => {
    const files = []
    if (customerReport) {
      files.push('customer')
    }
    if (inventoryReport) {
      files.push('inventory')
    }
    if (paymentReport) {
      files.push('payment')
    }

    if (files.length === 0) {
      console.info('No report selected.')
      return
    }

    if (files.length === 1) {
      downloadApi.get(
        `${API_BRANDS}/${brandId}/${files[0]}_report_csv?date_range=${dateRange[0]},${dateRange[1]}`
      )
      return
    }

    downloadApi.get(
      `${API_BRANDS}/${brandId}/reports?date_range=${dateRange[0]},${
        dateRange[1]
      }&files=${files.join(',')}`
    )
  }

  return {
    createBrand,
    isLoadingBrands,
    brands,
    hasBrands,
    hasBrandsNext,
    fetchBrands,
    fetchBrandsNext,
    readBrand,
    hasBrand,
    brand,
    updateBrand,
    updateBrandWithImage,
    updatePublishStatuses,
    updateBrandsLocally,
    getBrandPayments,
    exportReports,
  }
}

export default useAdminBrands
