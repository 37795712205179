import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import NativeSelect from '@material-ui/core/NativeSelect'

import { useAdminPredefinedParcelList, useAdminShipment } from 'hooks'

import { TEXT_FIELD_TYPES } from 'constants/enums'
import MessageActionView from 'components/MessageActionView'
import TextField from 'components/TextField'

import styles from './PrepareShipmentStyles'

const PrepareShipment = ({ classes, order, onClose, onComplete }) => {
  const [dims, setDims] = useState('')
  const [length, setLength] = useState('')
  const [width, setWidth] = useState('')
  const [height, setHeight] = useState('')
  const [weight, setWeight] = useState('')

  const [preparing, setPreparing] = useState(false)

  const { predefinedParcels } = useAdminPredefinedParcelList()
  const { createShipment } = useAdminShipment({ enabled: false })

  const onClickLetsGo = async () => {
    setPreparing(true)
    await createShipment({ length, width, height, weight, order_id: order.id })
    setPreparing(false)
    onComplete()
  }

  const setSizes = val => {
    setDims(val)
    if (val === '') {
      setLength('')
      setHeight('')
      setWidth('')
    } else if (val !== 'custom') {
      const id = parseInt(val, 10)
      const { length, width, height } = predefinedParcels.find(x => x.id === id)
      setLength(length)
      setWidth(width)
      setHeight(height)
    }
  }

  return !preparing ? (
    <MessageActionView
      title="Prepare Shipment"
      actionText="Prepare"
      onClick={onClickLetsGo}
      onBackClick={onClose}
      disabled={!(length && width && height && weight)}
    >
      <Box>
        <Box textAlign="center" fontSize={140} p={1}>
          <NativeSelect
            fullWidth
            value={dims}
            onChange={e => setSizes(e.target.value)}
          >
            <option value="">Select Box Size</option>
            {predefinedParcels.map(({ label, id, length, width, height }) => (
              <option key={id} value={id}>
                {label} {length}x{width}x{height}
              </option>
            ))}
            <option value="custom">Custom</option>
          </NativeSelect>
        </Box>
        {dims === 'custom' && (
          <Box display="flex" pl={1} pr={1}>
            <TextField
              classes={classes}
              innerLabel="L (in)"
              type={TEXT_FIELD_TYPES.number}
              value={length}
              onChange={({ target: { value } }) => setLength(value)}
            />
            <TextField
              classes={classes}
              innerLabel="W (in)"
              type={TEXT_FIELD_TYPES.number}
              value={width}
              onChange={({ target: { value } }) => setWidth(value)}
            />
            <TextField
              classes={classes}
              innerLabel="H (in)"
              type={TEXT_FIELD_TYPES.number}
              value={height}
              onChange={({ target: { value } }) => setHeight(value)}
            />
          </Box>
        )}
        <Box textAlign="center" fontSize={140} pr={1} pl={1}>
          <TextField
            fullWidth
            innerLabel="Weight (lbs)"
            type={TEXT_FIELD_TYPES.number}
            value={weight}
            onChange={({ target: { value } }) => setWeight(value)}
          />
        </Box>
      </Box>
    </MessageActionView>
  ) : (
    <MessageActionView
      title="Preparing"
      description={<>For order #{order?.number}.</>}
      actionHasSpinner
      onBackClick={onClose}
    />
  )
}

PrepareShipment.defaultProps = {}

PrepareShipment.propTypes = {
  classes: PropTypes.object,
  order: PropTypes.object,
  onClose: PropTypes.func,
  onComplete: PropTypes.func,
}

export default withStyles(styles)(PrepareShipment)
