// PROTOTYPE, not finished
import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'

import { Box, Typography, Button } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'

import Header from 'components/Header'

import { useAdminCycleCountPlate } from 'hooks'

import { DEFAULT_PRODUCT_IMAGE } from 'constants/general'

import styles from './CCScreenStyles'

const DetailsRow = ({ title, value }) => (
  <Box margin="8px">
    <Typography variant="subtitle1">{title}</Typography>
    <Typography variant="body2" data-test={`${title}-details`}>
      {typeof value === 'undefined' ? (
        <Skeleton width="100px" />
      ) : (
        value ?? 'Not Set'
      )}
    </Typography>
  </Box>
)

DetailsRow.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
}

const CCScreen = ({
  classes,
  match: {
    params: { cycle_count_id, cycle_count_plate_id },
  },
}) => {
  const history = useHistory()
  const cycleCountId = parseInt(cycle_count_id, 10)
  const plateId = parseInt(cycle_count_plate_id, 10)
  const { cycleCountPlate, isLoadingCycleCountPlate } = useAdminCycleCountPlate(
    { cycleCountId, plateId }
  )

  const variant = cycleCountPlate?.plate?.variant

  const actualCount = null
  const discrepancy = null

  const imageStyles = {
    width: '100%',
    height: '286px',
    objectFit: 'cover',
  }

  const showView = (
    <>
      <Header
        breadcrumbs={[
          { text: 'Cycle Count', href: '/' },
          { text: `${cycleCountId}`, active: true },
        ]}
        onBackClick={() => history.push('/')}
      />
      <Box width="100%">
        {/* eslint-disable-next-line no-nested-ternary */}
        {variant?.images.length > 0 ? (
          <img
            src={variant.images[0].thumbnail_url}
            alt="variant"
            style={imageStyles}
          />
        ) : isLoadingCycleCountPlate ? (
          <Skeleton width="375px" height="286px" />
        ) : (
          <img
            src={DEFAULT_PRODUCT_IMAGE}
            alt="placeholder variant"
            styles={imageStyles}
          />
        )}
      </Box>
      <DetailsRow title="Brand" value={variant?.product?.brand} />
      <DetailsRow title="Product Name" value={variant?.product?.title} />
      <DetailsRow title="Color" value={variant?.color?.label} />
      <DetailsRow title="Size" value={variant?.size} />
      <DetailsRow title="SKU" value={variant?.sku} />
      <DetailsRow title="UPC/DSIN" value={variant?.barcode || variant?.dsin} />
      <DetailsRow
        title="Bin"
        value={variant?.available_for_sale_inventory?.bin}
      />
      <DetailsRow
        title="Current Available for Sale"
        value={variant?.available_for_sale_inventory?.quantity}
      />
      <DetailsRow
        title="Units at Location"
        value={cycleCountPlate?.plate?.quantity}
      />
      <DetailsRow title="Actual Count" value={actualCount} />
      <DetailsRow title="Discrepancy" value={discrepancy} />
      <DetailsRow
        title="Discrepancy Notes"
        value={variant?.purchase_order_variant_note}
      />
      <Box className={classes.actions}>
        <Button variant="contained" color="primary">
          Start
        </Button>
      </Box>
    </>
  )

  return showView
}

CCScreen.propTypes = {
  match: PropTypes.object.isRequired,
  classes: PropTypes.object,
}

export default withStyles(styles)(CCScreen)
