import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import MuiDialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'

import styles from './DialogStyles.js'

export const DialogTitle = withStyles(styles)(
  ({ children, classes, close, ...other }) => {
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="body2">{children}</Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={close}
        >
          <CloseIcon />
        </IconButton>
      </MuiDialogTitle>
    )
  }
)

export const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(0, 2),
  },
}))(MuiDialogContent)

export const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1, 2),
    display: 'flex',
    '& > *': {
      width: '100%',
    },
  },
}))(MuiDialogActions)

// Based on MUI Dialog example
const Dialog = ({ classes, children, content }) => {
  const [open, setOpen] = useState(false)
  const [extra, setExtra] = useState({})

  const handleClose = () => {
    setOpen(false)
  }

  const state = {
    isOpen: open,
    open: () => setOpen(true),
    close: handleClose,
    extra,
    setExtra,
  }

  return (
    <>
      {children && children(state)}
      <div>
        <MuiDialog
          open={open}
          onClose={handleClose}
          classes={{
            paper: classes.paper,
            scrollPaper: classes.scrollPaper,
          }}
        >
          {content(state)}
        </MuiDialog>
      </div>
    </>
  )
}

Dialog.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.func,
  content: PropTypes.func.isRequired,
}

export default withStyles(styles)(Dialog)
