import React from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'

import MessageActionView from 'components/MessageActionView'
import { useLocalStorageContext } from 'context'
import { useAdminPrinters } from 'hooks'

const PrintingSplash = ({ order, error, hasPrinted, print, onClose }) => {
  const history = useHistory()
  const { printerId, locationId } = useLocalStorageContext()

  const { printers } = useAdminPrinters({ locationId })

  const activePrinter = printers?.find(printer => printer.id === printerId)

  const navigateToOrdersList = () => {
    history.push('/orders')
  }

  if (error) {
    return (
      <MessageActionView
        title="Unable to Print Label"
        description={
          <>
            Error: {error}
            <br />
            Printer may be turned off or not connected to the network.
            <br />
            Please Try Again.
          </>
        }
        actionText="Try Again"
        actionHasError
        onClick={print}
        onBackClick={onClose}
      />
    )
  }

  return !hasPrinted ? (
    <MessageActionView
      title="Printing Label"
      description={<>For order #{order?.number}.</>}
      actionHasSpinner
      onBackClick={onClose}
    />
  ) : (
    <MessageActionView
      title="Order Ready for Pick Up"
      description={
        <>
          You just helped deliver joy to
          <br />
          {order.first_name} {order.last_name}!
          <br />
          Printed on {activePrinter?.name}
        </>
      }
      success
      actionText="Next Order"
      onClick={navigateToOrdersList}
      onBackClick={onClose}
    />
  )
}

PrintingSplash.defaultProps = {}

PrintingSplash.propTypes = {
  order: PropTypes.object,
  error: PropTypes.string,
  hasPrinted: PropTypes.bool,
  print: PropTypes.func,
  onClose: PropTypes.func,
}

export default PrintingSplash
