import { colorsAF } from 'theme/colors'

export default {
  root: {
    color: 'inherit',
    '&:hover': {
      // Can't directly override .MuiTableRow-root.MuiTableRow-hover:hover
      backgroundColor: `${colorsAF.lightGray} !important`,
    },
  },
}
