import React from 'react'
import PropTypes from 'prop-types'

import Button from '@material-ui/core/Button'

import Dialog, {
  DialogTitle,
  DialogContent,
  DialogActions,
} from 'components/Dialog'

const ConfirmationDialog = ({
  children,
  title,
  message,
  onAccept,
  onDeny,
  acceptText = 'Yeah',
  denyText = 'Nope',
  showDeny = true,
}) => {
  const handleAccept = closeDialog => () => {
    onAccept && onAccept()
    closeDialog()
  }
  const handleDeny = closeDialog => () => {
    onDeny && onDeny()
    closeDialog()
  }
  return (
    <Dialog
      content={({ close: closeDialog, extra }) => (
        <>
          <DialogTitle close={closeDialog}>{title}</DialogTitle>
          <DialogContent>
            {message} {extra?.message && extra.message}
          </DialogContent>
          <DialogActions>
            {showDeny && (
              <Button
                data-test="button-deny"
                variant="contained"
                onClick={handleDeny(closeDialog)}
              >
                {denyText}
              </Button>
            )}
            <Button
              data-test="button-accept"
              variant="contained"
              color="primary"
              onClick={handleAccept(closeDialog)}
            >
              {acceptText}
            </Button>
          </DialogActions>
        </>
      )}
    >
      {children}
    </Dialog>
  )
}

ConfirmationDialog.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element, PropTypes.func]),
  title: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.string,PropTypes.node]),
  onAccept: PropTypes.func,
  onDeny: PropTypes.func,
  acceptText: PropTypes.string,
  denyText: PropTypes.string,
  showDeny: PropTypes.bool,
}

export default ConfirmationDialog
