import { primary, colorsAF } from 'theme/colors'

export default {
  root: {
    borderRadius: 2,
  },
  bar: {
    borderRadius: 2,
  },
  colorPrimary: {
    backgroundColor: colorsAF.semiGray,
  },
  barColorPrimary: {
    backgroundColor: primary,
  },
}
