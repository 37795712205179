const jssStyles = theme => ({
  root: {
    backgroundColor: theme.palette.colorsAF.white,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    overflow: 'hidden',
    padding: theme.spacing(0, 4),
    flexDirection: 'column',
    marginBottom: 0,
  },
  barcodeInput: {
    padding: '20px 2px',
  },
  binGroup: {
    flexDirection: 'row',
    '& > *:not(:first-child)': {
      marginTop: 0,
    },
    '& > *': {
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  textField: {
    marginBottom: theme.spacing(1),
  },
})

export default jssStyles
