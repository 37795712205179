import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import IconButton from '@material-ui/core/IconButton'
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera'
import KeyboardIcon from '@material-ui/icons/Keyboard'
import CropFreeIcon from '@material-ui/icons/CropFree'

import IRScanner from 'components/IRScanner'
import OrderManualPicker from 'components/OrderManualPicker'
import OrderScanner from 'components/OrderScanner'
import BinSelector from 'components/BinSelector'

import Header from 'components/Header'

import styles from './ScannerStyles'

const Scanner = ({
  classes,
  onScan,
  onClose,
  title,
  barcode,
  numbersOnly,
  children,
  irScanner = true,
  manualPicker = true,
  imageScanner = true,
  binSelector = false,
  ...otherProps
}) => {
  const [SelectedScanner, setSelectedScanner] = useState(
    (irScanner && IRScanner) ||
      (manualPicker && OrderManualPicker) ||
      (binSelector && BinSelector)
  )

  return (
    <SelectedScanner
      {...otherProps}
      onScan={onScan}
      barcode={barcode}
      numbersOnly={numbersOnly}
      header={
        <Header title={title} onBackClick={onClose} className={classes.header}>
          {binSelector && (
            <IconButton
              size="small"
              onClick={() => setSelectedScanner(BinSelector)}
              data-test="bin-selector"
            >
              <KeyboardIcon className={classes.headerIcon} />
            </IconButton>
          )}
          {manualPicker && (
            <IconButton
              size="small"
              onClick={() => setSelectedScanner(OrderManualPicker)}
              data-test="manual-scanner"
            >
              <KeyboardIcon className={classes.headerIcon} />
            </IconButton>
          )}
          {imageScanner && (
            <IconButton
              size="small"
              onClick={() => setSelectedScanner(OrderScanner)}
            >
              <PhotoCameraIcon className={classes.headerIcon} />
            </IconButton>
          )}
          {irScanner && (
            <IconButton
              size="small"
              onClick={() => setSelectedScanner(IRScanner)}
            >
              <CropFreeIcon className={classes.headerIcon} />
            </IconButton>
          )}
        </Header>
      }
    >
      {children}
    </SelectedScanner>
  )
}

Scanner.defaultProps = {
  title: '',
}

Scanner.propTypes = {
  classes: PropTypes.object.isRequired,
  onScan: PropTypes.func,
  title: PropTypes.string,
  onClose: PropTypes.func,
  barcode: PropTypes.string,
  numbersOnly: PropTypes.bool,
  children: PropTypes.node,
  irScanner: PropTypes.bool,
  manualPicker: PropTypes.bool,
  imageScanner: PropTypes.bool,
  binSelector: PropTypes.bool,
}

export default withStyles(styles)(Scanner)
