import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { useHistory } from 'react-router-dom'
import {
  Box,
  Typography,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
} from '@material-ui/core'
import { ExpandLess } from '@material-ui/icons'
import Skeleton from '@material-ui/lab/Skeleton'

import Header from 'components/Header'
import PurchaseOrderStatus from 'components/PurchaseOrderStatus'
import LineItem from 'components/LineItem'

import { useAdminPurchaseOrder, useAdminPurchaseOrderVariantList } from 'hooks'

import { PURCHASE_ORDER_STATE_TYPES } from 'constants/purchaseOrders'
import { URL } from 'constants/navigation'

import styles from './PurchaseOrderShowScreenStyles'

const PurchaseOrderShowScreen = ({
  classes,
  match: {
    params: { id },
  },
}) => {
  const history = useHistory()
  const [expanded, setExpanded] = React.useState(false)

  const {
    variants: purchaseOrderVariantsUnsorted,
    variantsMeta,
    isFetching: isFetchingVariants,
    // eslint-disable-next-line radix
  } = useAdminPurchaseOrderVariantList(parseInt(id), { purchase_order_id: id })

  const purchaseOrderVariants = [...purchaseOrderVariantsUnsorted].sort(
    (a, b) => a.id - b.id
  )

  const {
    purchase_order_variant_past_damaged_sum: damaged,
    purchase_order_variant_quantity_sum: quantity,
    purchase_order_variant_past_received_sum: received,
    purchase_order_variant_total_price_sum: totalPrice,
  } = variantsMeta || {}

  const { purchaseOrder } = useAdminPurchaseOrder(
    // eslint-disable-next-line radix
    parseInt(id)
  )

  const lineItems = purchaseOrderVariants.map(v => {
    const variantRecievedCount = v.purchase_order_variant_past_received || 0
    const variantDamagedCount = v.purchase_order_variant_past_damaged || 0
    const variantQuantity = v.purchase_order_variant_quantity
    const complete = v.purchase_order_variant_receiving_complete
    return {
      id: v.id,
      numberPicked: variantRecievedCount,
      numberDamaged: variantDamagedCount,
      numberMissing: complete
        ? variantQuantity - variantRecievedCount - variantDamagedCount
        : 0,
      complete,
      quantity: v.purchase_order_variant_quantity,
      price: v.price,
      images: v.images,
      barcode: v.barcode,
      bin: v.available_for_sale_inventory.bin,
      product: v.product,
      variant: v.variant,
      sku: v.sku,
      color: v?.color?.label,
      size: v?.size,
    }
  })

  const missing =
    purchaseOrder?.status !== PURCHASE_ORDER_STATE_TYPES.submitted
      ? Math.max(0, (quantity || 0) - ((received || 0) + (damaged || 0)))
      : 0

  const SummaryRow = ({ label, amount }) => (
    <>
      <Typography variant="body2" className={classes.spaceTop}>
        {label}
      </Typography>
      <Box className={classes.spaceBetween}>
        <Typography variant="body2" className={classes.dark}>
          Total items {label.toLowerCase()}
        </Typography>
        <Typography variant="body2">{amount}</Typography>
      </Box>
    </>
  )
  SummaryRow.propTypes = {
    label: PropTypes.string,
    amount: PropTypes.string,
  }

  const handleContinueClick = () => {
    const unfinishedScanIndex = purchaseOrderVariants.findIndex(
      v =>
        v.purchase_order_variant_receiving_started &&
        !v.purchase_order_variant_receiving_complete
    )
    const scanIndex = purchaseOrderVariants.findIndex(
      v => !v.purchase_order_variant_receiving_complete
    )
    const assignBinIndex = purchaseOrderVariants.findIndex(
      v =>
        v.purchase_order_variant_receiving_complete &&
        v.purchase_order_variant_past_received -
          v.purchase_order_variant_quantity_in_bins !==
          0
    )
    const navigateToIndex =
      // eslint-disable-next-line no-nested-ternary
      assignBinIndex >= 0
        ? assignBinIndex
        : unfinishedScanIndex > 0
        ? unfinishedScanIndex
        : scanIndex
    history.push(
      `${URL.PURCHASE_ORDERS}/${id}${URL.PURCHASE_ORDER_VARIANTS}/${purchaseOrderVariants[navigateToIndex].id}`
    )
  }

  const poCloseable = [
    PURCHASE_ORDER_STATE_TYPES.partiallyReceived,
    PURCHASE_ORDER_STATE_TYPES.overReceived,
    PURCHASE_ORDER_STATE_TYPES.fullyReceived,
  ].includes(purchaseOrder?.status)

  return (
    <Box className={classes.root}>
      <Header
        onBackHistoryBack
        breadcrumbs={[
          { text: 'PO', href: URL.PURCHASE_ORDERS },
          { text: `#${id}`, active: true },
        ]}
      />
      <Box className={classes.content}>
        <Box className={classes.lineItems}>
          {lineItems.map(lineItem => (
            <LineItem
              key={lineItem.id}
              lineItem={lineItem}
              showQuantity
              showCounts
              numberChecked={lineItem.numberPicked}
              numberDamaged={lineItem.numberDamaged}
              numberMissing={lineItem.numberMissing}
              quantity={lineItem.quantity}
              complete={lineItem.complete}
              showStatus
              setMissing={null}
              missing={missing}
              showPrice={false}
              showSku
              showColor
              showSize
              onClick={() =>
                history.push(
                  `${URL.PURCHASE_ORDERS}/${id}${URL.PURCHASE_ORDER_VARIANTS}/${lineItem.id}`
                )
              }
            />
          ))}
        </Box>
        <Box className={expanded ? classes.detailsExpanded : classes.details}>
          <Accordion
            className={classes.accordionRoot}
            square
            elevation={0}
            expanded={expanded}
            onChange={() => setExpanded(!expanded)}
          >
            <AccordionSummary
              classes={{
                root: classes.summaryRoot,
                content: classes.summaryContentRoot,
              }}
              expandIcon={<ExpandLess />}
            >
              <Box className={classes.summaryRow}>
                <Box>
                  <Typography variant="body2">Status</Typography>
                  {purchaseOrder?.status ? (
                    <PurchaseOrderStatus status={purchaseOrder.status} />
                  ) : (
                    <Skeleton width="60px" />
                  )}
                </Box>
                <Typography variant="body2">
                  {received || 0} Received
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails className={classes.detailsContainer}>
              <Typography variant="body2">Ordered</Typography>
              {!(
                Boolean(purchaseOrder) && purchaseOrderVariants.length !== 0
              ) && <Skeleton width="340px" />}
              {purchaseOrderVariants.map(v => (
                <Box
                  key={v.id}
                  className={classNames(classes.orderedRow, classes.dark)}
                >
                  <Typography variant="body1">
                    {[v.product.title, v?.color?.label, v?.size]
                      .filter(variant => Boolean(variant))
                      .join(', ')}
                  </Typography>
                  <Typography variant="body1" className={classes.orderedValue}>
                    ${v.price} x {v.purchase_order_variant_quantity}
                  </Typography>
                </Box>
              ))}
              <Box
                className={classNames(classes.spaceBetween, classes.spaceTop)}
              >
                <Typography variant="body2">Quantity</Typography>
                <Typography variant="body2">{quantity}</Typography>
              </Box>
              <Box className={classes.spaceBetween}>
                <Typography variant="body2">Total</Typography>
                <Typography variant="body2">${totalPrice}</Typography>
              </Box>
              <Divider className={classes.divider} />
              <SummaryRow label="Received" amount={received || 0} />
              <SummaryRow label="Damaged" amount={damaged || 0} />
              <SummaryRow label="Missing" amount={missing} />
            </AccordionDetails>
          </Accordion>
          <Divider className={classes.closeDivider} />
          {!poCloseable && (
            <Button
              data-test="continue-button"
              variant="contained"
              onClick={handleContinueClick}
              color="primary"
              fullWidth
              disabled={
                purchaseOrder?.status === PURCHASE_ORDER_STATE_TYPES.closed ||
                isFetchingVariants
              }
            >
              {purchaseOrder?.status === PURCHASE_ORDER_STATE_TYPES.closed
                ? 'Closed'
                : 'Continue'}
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  )
}

PurchaseOrderShowScreen.propTypes = {
  classes: PropTypes.object,
  match: PropTypes.object,
}

export default withStyles(styles)(PurchaseOrderShowScreen)
