import React, { useState, createContext, useMemo } from 'react'
import PropTypes from 'prop-types'

export const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null)
  // Authentication is triggered on page load
  const [isInitialized, setIsInitialized] = useState(false)

  const value = useMemo(
    () => ({
      currentUser,
      setCurrentUser,
      isInitialized,
      setIsInitialized,
    }),
    [currentUser, isInitialized]
  )

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

AuthProvider.propTypes = {
  children: PropTypes.node,
}
