import { useInfiniteQuery } from 'react-query'

import { useAPIContext } from 'context'

/* Provides a React Query based interface to the FastAF paginated list
endpoints for fetching pages and combining them into an array. */

/* Arguments:
 *
 * endpoint: a function that is invoked by useResourceListRQ applying args.
 * args: arguments for endpoints functions, like a resource ID, can be
   left empty or left out if not applicable to the 'list' endpoint
 * params: an object representing the query parameters, compatible
 *         with useQuery */

/* Example usage:
 * const useAdminPurchaseOrderList = (queryParams) => {
 *   const {
 *     isFetching: isFetchingPurchaseOrders,
 *     data: purchaseOrders,
 *     hasData: hasPurchaseOrders,
 *     hasNextPage: hasPurchaseOrdersNext,
 *     fetchNextPage: fetchPurchaseOrdersNext,
 *   } = useResourceListRQ({ endpoint: purchaseOrderListEndpoint, params: queryParams })
 *   ...
 * } */

const useResourceListRQ = ({
  endpoint,
  args = [],
  params = {},
  enabled = true,
  options,
}) => {
  const { api } = useAPIContext()

  // eslint-disable-next-line prefer-spread
  const url = endpoint.apply(null, args)
  const queryKey = [url, params]

  const {
    data: rawData,
    error,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetching,
    isIdle,
    isError,
  } = useInfiniteQuery(
    queryKey,
    ({ pageParam = 1 }) =>
      api.get(url, { params: { page: pageParam, ...params } }),
    {
      getNextPageParam: (lastPage, pages) => lastPage.meta.page + 1,
      enabled,
      ...options,
    }
  )

  /* We have to massage this a bit since our list endpoints don't put
     the array at top-level. */
  const data = rawData?.pages.map(x => x.data).flat()
  const hasData = Boolean(
    rawData?.pages.length > 0 && rawData.pages[0].data.length > 0
  )

  const meta =
    rawData?.pages && rawData.pages.length > 0 && rawData.pages[0].meta

  return {
    data,
    hasData,
    isError,
    error,
    isFetching,
    isLoading,
    isIdle,
    fetchNextPage,
    hasNextPage,
    meta,
    count: meta?.total_count,
  }
}

export default useResourceListRQ
