const DETAILS_HEIGHT_PX = 87

const orderDetailsShared = theme => ({
  position: 'absolute',
  left: 0,
  bottom: 0,
  width: '100%',
  minHeight: DETAILS_HEIGHT_PX,
  zIndex: 10,
  padding: theme.spacing(0, 2),
  backgroundColor: theme.palette.background.default,
  transition: 'height .8s ease',
})

const jssStyles = theme => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    position: 'relative',
    height: '100%',
    width: '100%',
  },
  orderDetails: {
    ...orderDetailsShared(theme),
    height: '0%',
  },
  orderDetailsExpanded: {
    ...orderDetailsShared(theme),
    height: '100%',
    overflowY: 'auto',
  },
  lineItems: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: DETAILS_HEIGHT_PX,
    right: 0,
    overflowY: 'auto',
  },
  actionContainer: {
    zIndex: 100,
    backgroundColor: theme.palette.background.default,
    borderTop: `1px solid ${theme.palette.colorsAF.semiGray}`,
    padding: theme.spacing(3, 2),
    display: 'flex',
    '& .MuiButtonBase-root:not(:first-child)': {
      marginLeft: theme.spacing(1),
    },
  },
})

export default jssStyles
