import moment from 'moment-timezone'

// can be used in an async func: await delay(sec)
export const delay = sec => new Promise(res => setTimeout(res, sec * 1000))

export const waitAtLeastSec = async (sec, callback) =>
  Promise.all([delay(sec), callback()]).then(([_, res]) => res)

export const capitalize = s => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export const setOptions = (items, key, value) => {
  if (!items) return null
  const options = []
  let list = []
  list = items
  list.forEach(item => {
    let label = item[value]
    if (Array.isArray(value)) {
      // eslint-disable-next-line no-eval
      label = value.map(v => eval(`item.${v}`)).join(' - ')
    }
    return options.push({ value: item[key], label })
  })
  return options
}

export const buildOptions = (items, key, value) => {
  if (!items) return null
  const options = []
  let list = []
  if (items.data) {
    list = items.data
  } else {
    list = items
  }
  list.forEach(item => {
    let label = item[value]
    if (Array.isArray(value)) {
      // eslint-disable-next-line no-eval
      label = value.map(v => eval(`item.${v}`)).join(' - ')
    }
    return options.push({ value: item[key], label })
  })
  return options
}

export const formatDigits = (amount, decimalCount = 2) => {
  const decimal = '.'
  const thousands = ','
  try {
    // eslint-disable-next-line no-param-reassign
    decimalCount = Math.abs(decimalCount)
    // eslint-disable-next-line no-param-reassign
    decimalCount = Number.isNaN(decimalCount) ? 2 : decimalCount

    const negativeSign = amount < 0 ? '-' : ''

    // eslint-disable-next-line radix
    const i = parseInt(
      // eslint-disable-next-line no-param-reassign
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString()
    const j = i.length > 3 ? i.length % 3 : 0

    const formatted =
      negativeSign +
      (j ? i.substr(0, j) + thousands : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${thousands}`) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : '')
    return formatted
  } catch (e) {
    console.error(e)
  }
}

export const formatCurrency = amount => `$${formatDigits(amount, 2)}`

export const formatCents = amount => formatCurrency(amount / 100)

export const formatNumber = count => {
  // eslint-disable-next-line radix
  let value = parseInt(count)
  let precision = 0
  let scale = ''

  if (value >= 1000000) {
    precision = 0
    scale = 'M'
    value /= 1000000
  } else if (value >= 10000) {
    precision = 0
    scale = 'k'
    value /= 1000
  } else if (value >= 1000) {
    precision = 1
    scale = 'k'
    value /= 1000
  }
  value = value.toFixed(precision)
  return `${value}${scale}`
}

export const syntheticEvent = (value, name, type = 'text') => ({
  target: {
    value,
    name,
    type,
  },
})

export const pluralize = string => {
  if (!string) {
    return
  }
  const regex = /$s/
  let plural = string
  if (!string.match(regex)) {
    if (string.charAt(string.length - 1) === 'y') {
      plural = `${string.slice(0, string.length - 1)}ies`
    } else {
      plural = `${string}s`
    }
  }
  return plural
}

export const truncate = (text, len) => {
  if (!text) {
    return
  }
  let truncated = text.slice(0, len)
  if (text.length > len) {
    truncated += '...'
  }
  return truncated
}

export const generateToken = (len = 5) =>
  [...Array(len)]
    // eslint-disable-next-line no-bitwise
    .map(i => (~~(Math.random() * 36)).toString(36))
    .join('')
    .toUpperCase()

export const isPersisted = resp => resp && resp.data && resp.data.id

export const isLoaded = resp => !!(resp && resp.data)

export const isEmpty = resources => {
  return !(resources && resources.data && resources.data.length > 0)
}

export const isPaginated = resources => {
  return (
    resources &&
    resources.meta &&
    resources.meta.page &&
    resources.meta.perPage &&
    resources.meta.totalCount
  )
}

export const storageAvailable = type => {
  try {
    const storage = window[type]
    const x = '__storage_test__'
    storage.setItem(x, x)
    storage.removeItem(x)
    return true
  } catch (e) {
    return false
  }
}

export const withSign = num => (num >= 0 ? `+${num}` : `${num}`)

// copied from dashboard
// Replicate in Firefox and Safari, what Chrome has as Event.path
export const getDOMElementsPathToRoot = elem => {
  const path = []
  let currentElem = elem
  while (currentElem) {
    path.push(currentElem)
    currentElem = currentElem.parentElement
  }
  if (path.indexOf(window) === -1 && path.indexOf(document) === -1)
    path.push(document)
  if (path.indexOf(window) === -1) path.push(window)
  return path
}

export const formatDateToLocalTimezone = (
  date,
  timezone = 'America/New_York',
  format = 'L'
) => moment(date).tz(timezone).format(format)
