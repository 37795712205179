/* WARNING: copied from dashboard; keep in sync */
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import * as propTypes from 'constants/propTypes'
import { STATUS_DOT_VARIANTS } from 'constants/enums'

import styles from './StatusDotStyles'

const StatusDot = ({
  classes,
  className,
  variant,
  halfFilled,
  filled,
  liveEffect,
  text,
}) => (
  <Box
    className={classNames({
      [classes.root]: true,
      [classes.grayStatus]: variant === STATUS_DOT_VARIANTS.gray,
      [classes.blueStatus]: variant === STATUS_DOT_VARIANTS.blue,
      [classes.greenStatus]: variant === STATUS_DOT_VARIANTS.green,
      [classes.orangeStatus]: variant === STATUS_DOT_VARIANTS.orange,
      [classes.redStatus]: variant === STATUS_DOT_VARIANTS.red,
      [className]: Boolean(className),
    })}
  >
    <Box
      className={classNames({
        [classes.borderDot]: true,
        [classes.filled]: !halfFilled && filled,
        [classes.halfFilled]: halfFilled,
        [classes.liveEffect]: liveEffect,
      })}
    >
      <Box className={classes.pulseDot} />
    </Box>
    <Typography
      variant="body2"
      className={classes.text}
      data-test="status-dot-text"
    >
      {text}
    </Typography>
  </Box>
)

StatusDot.defaultProps = {
  filled: true,
  halfFilled: false,
}

StatusDot.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  variant: propTypes.statusDotVariants,
  filled: PropTypes.bool,
  halfFilled: PropTypes.bool,
  pulseEffect: PropTypes.bool,
  text: PropTypes.string,
  liveEffect: PropTypes.bool,
}

export default withStyles(styles)(StatusDot)
