import { useAPIContext } from 'context'
import {
  useAlerts,
  useResourceList,
  useResourceRQ,
  useResourceListRQ,
  useLoaders,
} from 'hooks'
import {
  variantEndpoint,
  variantListEndpoint,
  variantBulkUpdateEndpoint,
  purchaseOrderEndpoint,
} from 'api/endpoints'
import {
  LIST_PATHS,
  LOW_STOCK_INVENTORY,
  HIGH_STOCK_INVENTORY,
  SOLD_OUT_INVENTORY,
} from 'constants/queryParams'

const API_VARIANTS = '/api/v1/admin/variants'

const useAdminVariantsRQ = (params, enabled = true) => {
  const { api } = useAPIContext()
  const { showAlertError, showAlertSuccess } = useAlerts()
  const { showLoading, hideLoading } = useLoaders()

  const {
    isFetching,
    isLoading,
    data: variants,
    hasData: hasVariants,
    hasNextPage: hasVariantsNext,
    fetchNextPage: fetchVariantsNext,
    meta: variantsMeta,
  } = useResourceListRQ({ endpoint: variantListEndpoint, params, enabled })

  const { list: getHighStockCount, count: highStockCount } = useResourceList({
    url: API_VARIANTS,
    perPage: 1,
  })

  const { list: getLowStockCount, count: lowStockCount } = useResourceList({
    url: API_VARIANTS,
    perPage: 1,
  })

  const fetchVariantsAndSummary = theseParams => {
    const locationParam =
      LIST_PATHS.VARIANTS_LOCATIONS_ID in theseParams
        ? {
            [LIST_PATHS.VARIANTS_LOCATIONS_ID]:
              theseParams[LIST_PATHS.VARIANTS_LOCATIONS_ID],
          }
        : {}
    getHighStockCount({ ...HIGH_STOCK_INVENTORY, ...locationParam })
    getLowStockCount({ ...LOW_STOCK_INVENTORY, ...locationParam })
  }

  const { list: getSoldOutVariantsCount, count: soldOutVariantsCount } =
    useResourceList({
      url: API_VARIANTS,
      perPage: 1,
    })

  const fetchSoldOutVariantsCount = () =>
    getSoldOutVariantsCount(SOLD_OUT_INVENTORY)

  const setVariantQuantities = async ({ locationId, variantIds, quantity }) => {
    try {
      showLoading()
      await api.put(`${API_VARIANTS}/bulk_set_inventory`, {
        variant_ids: variantIds,
        location_id: locationId,
        quantity,
      })
      showAlertSuccess('Inventory changes have been saved.')
      hideLoading()
    } catch (e) {
      hideLoading()
      console.error(e)
      showAlertError('There was an error setting inventory quantity')
    }
  }

  const adjustVariantQuantities = async ({
    variantIds,
    locationId,
    quantity,
  }) => {
    try {
      showLoading()
      await api.put(`${API_VARIANTS}/bulk_adjust_inventory`, {
        variant_ids: variantIds,
        location_id: locationId,
        quantity,
      })
      showAlertSuccess('Inventory changes have been saved.')
      hideLoading()
    } catch (e) {
      hideLoading()
      console.error(e)
      showAlertError('There was an error adjusting inventory quantity')
    }
  }

  const updateVariantQuantities = async ({
    variants: variantsToUpdate,
    isAdjust,
    quantity,
    locationId,
  }) => {
    const variantIds = variantsToUpdate.map(v => v.id)
    if (isAdjust) {
      adjustVariantQuantities({ variantIds, locationId, quantity })
    } else {
      setVariantQuantities({ variantIds, locationId, quantity })
    }
  }

  const { operate: bulkUpdateVariants } = useResourceRQ({
    endpoint: variantBulkUpdateEndpoint,
    args: [],
    name: 'variants',
    dependents: [variantEndpoint, purchaseOrderEndpoint],
    fetchingEnabled: false,
    inputProcessor: ({
      variants: variantsToUpdate,
      bin,
      weight,
      length,
      width,
      height,
      unit,
      locationId,
      quantity,
      appendBin = false,
    }) => {
      const data = {
        unit,
        length,
        width,
        height,
        weight,
        bin,
        location_id: locationId,
        quantity,
      }

      Object.keys(data).forEach(k => {
        if (data[k] === null || data[k] === undefined) {
          delete data[k]
        }
      })

      if (!data.bin) {
        delete data.location_id
      }

      const updateList = variantsToUpdate.map(v => ({
        id: v.id,
        ...data,
      }))

      return { append_bin: appendBin, variants: updateList }
    },
  })

  return {
    isFetching,
    isLoading,
    variants: variants || [],
    lowStockCount,
    highStockCount,
    hasVariants,
    hasVariantsNext,
    fetchVariantsNext,
    fetchVariantsAndSummary,
    fetchSoldOutVariantsCount,
    soldOutVariantsCount,
    updateVariantQuantities,
    bulkUpdateVariants,
    variantsMeta,
  }
}

export default useAdminVariantsRQ
