import PropTypes from 'prop-types'

import * as enums from './enums'

export const size = PropTypes.oneOf([...Object.values(enums.SIZE)])

export const tooltipVariants = PropTypes.oneOf([
  ...Object.values(enums.TOOLTIP_VARIANT),
])

export const tooltipPlacements = PropTypes.oneOf([
  ...Object.values(enums.TOOLTIP_PLACEMENT),
])

export const selectValue = props => {
  if ('value' in props) {
    if (props.multiple && !Array.isArray(props.value)) {
      return new Error(
        'Invalid prop `value` supplied to Select with multiple=true, expected an array.'
      )
    }
    if (!props.multiple && typeof props.value !== 'string') {
      return new Error(
        'Invalid prop `value` supplied to Select with multiple=false, expected a string.'
      )
    }
  }
}

export const circleCheckStatus = PropTypes.oneOf([
  ...Object.values(enums.CIRCLE_CHECK_STATUS),
])

export const countStatus = PropTypes.oneOf([
  ...Object.values(enums.COUNT_STATUS),
])

export const statusDotVariants = PropTypes.oneOf([
  ...Object.values(enums.STATUS_DOT_VARIANTS),
])
