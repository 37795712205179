import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import { Box, Button } from '@material-ui/core'

import Dialog, {
  DialogTitle,
  DialogContent,
  DialogActions,
} from 'components/Dialog'
import TextField from 'components/TextField'
import RadioGroup from 'components/RadioGroup'

import { TEXT_FIELD_TYPES } from 'constants/enums'

import styles from './AdjustmentDialogStyles.js'

const Padding = ({ children }) => (
  <Box ml={1} mr={1}>
    {children}
  </Box>
)

Padding.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.object,
    PropTypes.func,
  ]),
}

const AdjustmentDialog = ({ classes, children, onConfirm, onCancel }) => {
  const [val, setVal] = useState('')
  const [exp, setExp] = useState('')
  const [selected, setSelected] = useState('')
  const handleAccept = closeDialog => () => {
    onConfirm &&
      onConfirm(selected === 'expired' ? `expired_product: ${exp}` : val)
    closeDialog()
  }
  const handleDeny = closeDialog => () => {
    onCancel && onCancel()
    closeDialog()
  }
  const type = TEXT_FIELD_TYPES.text

  return (
    <Dialog
      className={classes.root}
      content={({ close: closeDialog }) => (
        <>
          <DialogTitle close={closeDialog}>Adjustment Notes</DialogTitle>
          <DialogContent>
            <RadioGroup
              onChange={setSelected}
              options={[
                {
                  label: 'Custom',
                  value: 'custom',
                  expanded: (
                    <Padding>
                      <TextField
                        innerLabel="Notes"
                        type={type}
                        value={`${val}`}
                        onChange={({ target: { value } }) => setVal(value)}
                        dataTest="Notes-input"
                      />
                    </Padding>
                  ),
                },
                {
                  label: 'Expired',
                  value: 'expired',
                  expanded: (
                    <Padding>
                      <TextField
                        type="number"
                        innerLabel="Num Units Expired"
                        value={exp}
                        onChange={({ target: { value } }) => setExp(value)}
                      />
                    </Padding>
                  ),
                },
              ]}
            />
          </DialogContent>
          <DialogActions>
            <Button
              data-test="button-deny"
              variant="contained"
              onClick={handleDeny(closeDialog)}
            >
              Cancel
            </Button>
            <Button
              data-test="button-accept"
              variant="contained"
              color="primary"
              disabled={val.length === 0 && !exp}
              onClick={handleAccept(closeDialog)}
            >
              Confirm
            </Button>
          </DialogActions>
        </>
      )}
    >
      {children}
    </Dialog>
  )
}

AdjustmentDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.object,
    PropTypes.func,
  ]),
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
}

export default withStyles(styles)(AdjustmentDialog)
