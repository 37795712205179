import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Typography, List, ListItem, ListItemText } from '@material-ui/core'

import BottomDrawer from 'components/BottomDrawer'
import ConfirmationDrawer from 'components/ConfirmationDrawer'

import { useLocalStorageContext, useLocationsContext } from 'context'

import styles from './LocationDrawerStyles'

const LocationDrawer = ({ classes, drawerOpen, setDrawerOpen }) => {
  const { setLocationId } = useLocalStorageContext()
  const { location, locations } = useLocationsContext()
  const [confirmLocation, setConfirmLocation] = useState(null)
  const [confirmationDrawerOpen, setConfirmationDrawerOpen] = useState(false)

  const handleDrawerClose = () => setDrawerOpen(false)
  const needsLocation = !location && locations
  const handleLocationConfirm = newLocation => {
    if (needsLocation) {
      setLocationId(newLocation.id)
      handleDrawerClose()
    } else {
      handleDrawerClose()
      setConfirmLocation(newLocation)
      setConfirmationDrawerOpen(true)
    }
  }
  const handleLocationChange = async newLocation => {
    setConfirmationDrawerOpen(false)
    setLocationId(newLocation.id)
  }

  return (
    <>
      <BottomDrawer
        Header={<Typography variant="h4">My Location</Typography>}
        isOpen={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        <List
          className={classes.fullList}
          onClick={handleDrawerClose}
          onKeyDown={handleDrawerClose}
        >
          <List className={classes.fullList} data-test="darkstore-list">
            {locations &&
              locations.map(thisLocation => (
                <ListItem
                  className={classes.listItem}
                  button
                  onClick={() => handleLocationConfirm(thisLocation)}
                  key={thisLocation.id}
                  data-test={`${thisLocation.name}-darkstore-item`}
                >
                  <ListItemText
                    classes={{ primary: classes.listItemText }}
                    primary={thisLocation.name}
                  />
                </ListItem>
              ))}
          </List>
        </List>
      </BottomDrawer>
      <ConfirmationDrawer
        onYes={() => handleLocationChange(confirmLocation)}
        message="Are you sure you want to change your location?"
        drawerOpen={confirmationDrawerOpen}
        setDrawerOpen={setConfirmationDrawerOpen}
      />
    </>
  )
}

LocationDrawer.propTypes = {
  classes: PropTypes.object,
  drawerOpen: PropTypes.bool,
  setDrawerOpen: PropTypes.func,
}

export default withStyles(styles)(LocationDrawer)
