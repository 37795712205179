import { primary } from 'theme/colors'

export default {
  root: {
    background: primary,

    '&:hover': {
      background: `${primary} !important`,
    },
  },
}
