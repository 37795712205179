const jssStyles = theme => ({
  root: {
    backgroundColor: theme.palette.colorsAF.darkGray,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  headerIcon: {
    color: theme.palette.primary.contrastText,
    fontSize: 20,
    marginLeft: theme.spacing(2),
  },
  scannerContainer: {
    width: '100%',
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  },
})

export default jssStyles
