import { useResourceListRQ } from 'hooks'
import { predefinedParcelsEndpoint } from 'api/endpoints'

const useAdminPredefinedParcelList = ({ params } = {}, enabled = true) => {
  const {
    isFetching: isFetchingPredefinedParcels,
    data: predefinedParcels,
    hasData: hasPredefinedParcels,
    hasNextPage: hasPredefinedParcelsNext,
    fetchNextPage: fetchPredefinedParcelsNext,
    meta,
  } = useResourceListRQ({
    endpoint: predefinedParcelsEndpoint,
    params,
    enabled,
  })

  return {
    isFetchingPredefinedParcels,
    predefinedParcels: predefinedParcels || [],
    hasPredefinedParcels,
    hasPredefinedParcelsNext,
    fetchPredefinedParcelsNext,
    summary: meta?.summary,
  }
}

export default useAdminPredefinedParcelList
