import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import { IconButton, Box, Typography, Badge } from '@material-ui/core'

import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered'
import ReceiptIcon from '@material-ui/icons/Receipt'
import SyncAltIcon from '@material-ui/icons/SyncAlt'
import CropFreeIcon from '@material-ui/icons/CropFree'

import { black, yellow } from 'theme/colors'
import {
  useAuth,
  useAdminOrders,
  useAdminUberOrderList,
  useAdminPurchaseOrderList,
  useAdminTransferOrderList,
  useConditionalEffect,
} from 'hooks'
import { useLocationsContext } from 'context'
import { API_PARAMS, LIST_PATHS, REL } from 'constants/queryParams'
import { PURCHASE_ORDER_STATE_TYPES } from 'constants/purchaseOrders'
import { TRANSFER_ORDER_STATE_TYPES } from 'constants/transferOrders'
import { RECORD_TYPE } from 'constants/inventoryRecords'
import { URL } from 'constants/navigation'

import HeaderWithLocation from 'components/HeaderWithLocation'

import styles from './HomeScreenStyles'

const MenuButton = ({ to, Icon, text, color = 'primary', count }) => {
  return (
    <IconButton
      component={Link}
      to={to}
      variant="contained"
      color={color}
      aria-label={text}
      data-test={`button-${text.replace(' ', '-')}`}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        fontSize={80}
        ml={4}
        mr={4}
      >
        <Badge badgeContent={count} invisible={!count} color="error">
          <Icon fontSize="inherit" />
        </Badge>
        <Typography>{text}</Typography>
      </Box>
    </IconButton>
  )
}

MenuButton.propTypes = {
  to: PropTypes.string.isRequired,
  Icon: PropTypes.elementType.isRequired,
  text: PropTypes.string.isRequired,
  color: PropTypes.string,
  count: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

const HomeScreen = ({ classes }) => {
  const { currentUser } = useAuth()
  const { location } = useLocationsContext()
  const locationId = location?.id
  const {
    totalCount: totalOrders,
    findOrders,
    isLoading: isLoadingOrders,
  } = useAdminOrders({})
  const { totalUberOrders } = useAdminUberOrderList({
    params: {
      'uber_eats_orders.location_id': locationId,
      'uber_eats_orders.status': 'accepted',
      'uber_eats_orders.status.rel': 'in',
    },
    refetch: false,
    enabled: !!locationId,
  })
  const { totalUberOrders: totalUberOrdersFulfilled } = useAdminUberOrderList({
    params: {
      'uber_eats_orders.location_id': locationId,
      'uber_eats_orders.status': 'fulfilled',
      'uber_eats_orders.status.rel': 'in',
    },
    refetch: false,
    enabled: !!locationId,
  })
  const { totalPurchaseOrders } = useAdminPurchaseOrderList(
    {
      [API_PARAMS.SORT]: LIST_PATHS.PURCHASE_ORDERS_ETA,
      [LIST_PATHS.PURCHASE_ORDERS_STATUS_FILTER]:
        PURCHASE_ORDER_STATE_TYPES.submitted,
      [LIST_PATHS.PURCHASE_ORDERS_STATUS_REL]: REL.IN,
      [LIST_PATHS.PURCHASE_ORDERS_LOCATION_ID]: locationId,
    },
    RECORD_TYPE.PURCHASE_ORDER,
    !!location?.id
  )
  const { totalTransferOrders } = useAdminTransferOrderList(
    {
      [API_PARAMS.SORT]: LIST_PATHS.TRANSFER_ORDERS_ETA,
      [LIST_PATHS.TRANSFER_ORDERS_STATUS_FILTER]:
        TRANSFER_ORDER_STATE_TYPES.submitted,
      [LIST_PATHS.TRANSFER_ORDERS_LOCATION_ID]: locationId,
    },
    null,
    !!location?.id
  )

  const needsLocation = !location?.city

  useConditionalEffect(() => {
    if (locationId && !isLoadingOrders) {
      findOrders(
        {
          [LIST_PATHS.ORDERS_STATUS]: 'paid',
          [API_PARAMS.SORT]: LIST_PATHS.ORDERS_DELIVERY_STARTS,
          [LIST_PATHS.ORDERS_LOCATION_ID]: locationId,
        },
        0
      )
    }
  }, [locationId])

  if (needsLocation) {
    return (
      <>
        <HeaderWithLocation title="Home" />
        <Box display="flex" alignItems="center" flexDirection="column">
          <Typography>
            <strong>{currentUser?.email}</strong>
          </Typography>
        </Box>
        <Box mt={4} display="flex" flexDirection="column" alignItems="center">
          <Typography>
            <em>Select a location above to continue</em>
          </Typography>
        </Box>
      </>
    )
  }

  const ordersCount =
    typeof totalOrders !== 'undefined' && typeof totalUberOrders !== 'undefined'
      ? totalUberOrders + totalOrders
      : ''
  const poCount =
    typeof totalPurchaseOrders !== 'undefined' ? totalPurchaseOrders : ''

  const toCount =
    typeof totalTransferOrders !== 'undefined' ? totalTransferOrders : ''

  return (
    <>
      <HeaderWithLocation title="Home" />
      <Box display="flex" alignItems="center" flexDirection="column">
        <Typography variant="body2" color="textSecondary">
          <strong>{currentUser?.email}</strong>
        </Typography>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        flexDirection="column"
        visibility={
          totalUberOrdersFulfilled && totalUberOrdersFulfilled > 0
            ? 'visible'
            : 'hidden'
        }
        mt={1}
        mb={1}
        mr={4}
        ml={4}
        p={1}
        border={`1px solid ${black}`}
        bgcolor={yellow}
      >
        <Typography>
          <strong>
            <Link to="/orders?default_tab=2" color={black}>
              {totalUberOrdersFulfilled} UberEats Orders awaiting delivery
            </Link>
          </strong>
        </Typography>
      </Box>
      <Box>
        <MenuButton
          to="/orders"
          Icon={ShoppingBasketIcon}
          text="Orders"
          count={ordersCount}
        />
        <MenuButton to="/pos" Icon={ReceiptIcon} text="PO" count={poCount} />
        <MenuButton
          to="/tos"
          Icon={SyncAltIcon}
          text="Transfer"
          count={toCount}
        />
        <MenuButton
          to={URL.CYCLE_COUNTS}
          Icon={FormatListNumberedIcon}
          text="Cycle Count"
        />
        <MenuButton
          to="/logout"
          Icon={ExitToAppIcon}
          text="Sign Out"
          color="secondary"
        />
        <MenuButton
          to={URL.LOCATION}
          Icon={CropFreeIcon}
          text="Scan Location"
        />
      </Box>
    </>
  )
}

HomeScreen.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(HomeScreen)
