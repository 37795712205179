import { STATUS_DOT_VARIANTS } from './enums'

export const CYCLE_COUNT_STATES = {
  created: 'draft',
  counting: 'started_counting',
  counted: 'finished_counting',
  cancelled: 'closed',
}

export const CYCLE_COUNT_STATE_LABELS = [
  {
    value: CYCLE_COUNT_STATES.created,
    label: 'Created',
    indicator: STATUS_DOT_VARIANTS.green,
  },
  {
    value: CYCLE_COUNT_STATES.counting,
    label: 'Counting',
    indicator: STATUS_DOT_VARIANTS.blue,
  },
  {
    value: CYCLE_COUNT_STATES.counted,
    label: 'Counted',
    indicator: STATUS_DOT_VARIANTS.gray,
  },
  {
    value: CYCLE_COUNT_STATES.cancelled,
    label: 'Cancelled',
    indicator: STATUS_DOT_VARIANTS.gray,
  },
]
