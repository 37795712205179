/* WARNING: copied from dashboard; keep in sync */
import { useState } from 'react'
import ReactDOM from 'react-dom'

import { useAPIContext } from 'context'
import { useLoaders } from 'hooks'

/* useResource
   A base hook that allows for CRUD operations of a REST API that follows
   standard REST patterns of GET POST PUT and DELETE to read, update, create and
   destroy objects.

   @param url - The API endpoint. The is set dynamically using setEndpoint
   @param name - The name of the resource needed when using POST and PUT
 */

// TODO(@exrhizo): I added the storeState flag, but it is not needed.
const useResource = ({ url = '/', name, storeState = true }) => {
  const { api } = useAPIContext()
  const { showLoading, hideLoading } = useLoaders()

  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState(null)
  const [errors, setErrors] = useState(null)

  const handleErrors = e => {
    hideLoading()
    setErrors(e.data)
    console.error('useResource Error:', e)
  }

  const read = async id => {
    try {
      setIsLoading(true)
      const res = await api.get(`${url}/${id}`)
      // TODO(@exrhizo): Remove these, it's supposed to be default in React 17.
      ReactDOM.unstable_batchedUpdates(() => {
        setIsLoading(false)
        setData(res.data)
      })
      return res.data
    } catch (e) {
      ReactDOM.unstable_batchedUpdates(() => {
        setIsLoading(false)
        handleErrors(e)
      })
    }
  }

  const create = async resource => {
    try {
      showLoading()
      const res = await api.post(`${url}`, {
        [name]: resource,
      })
      storeState && setData(res.data)
      hideLoading()
      return res.data
    } catch (e) {
      handleErrors(e)
    }
  }

  const createMultipartFormData = async resource => {
    try {
      showLoading()
      const formData = new FormData()
      Object.entries(resource).forEach(([key, value]) =>
        formData.append(`${name}[${key}]`, value)
      )
      const res = await api.post(`${url}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      storeState && setData(res.data)
      hideLoading()
      return res.data
    } catch (e) {
      handleErrors(e)
    }
  }

  const update = async resource => {
    try {
      showLoading()
      const { id, ...rest } = resource

      const res = await api.put(`${url}/${id}`, {
        [name]: rest,
      })
      storeState && setData(res.data)
      hideLoading()
      return res.data
    } catch (e) {
      handleErrors(e)
    }
  }

  const updateMultipartFormData = async resource => {
    try {
      showLoading()
      const { id, ...rest } = resource
      const formData = new FormData()
      Object.entries(rest).forEach(([key, value]) =>
        formData.append(`${name}[${key}]`, value)
      )
      const res = await api.put(`${url}/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      storeState && setData(res.data)
      hideLoading()
      return res.data
    } catch (e) {
      handleErrors(e)
    }
  }

  const bulkUpdate = async resource => {
    try {
      showLoading()
      const { id, ...rest } = resource
      const res = await api.post(`${url}/bulk_import`, rest)
      storeState && setData(res.data)
      hideLoading()
      return res.data
    } catch (e) {
      handleErrors(e)
    }
  }

  // Note: delete is a JS keyword
  const destroy = async resource => {
    try {
      showLoading()
      await api.delete(`${url}/${resource.id}`)
      setData(null)
      hideLoading()
    } catch (e) {
      handleErrors(e)
    }
  }

  return {
    data,
    isLoading,
    hasData: data !== null,
    errors,
    read,
    update,
    bulkUpdate,
    create,
    createMultipartFormData,
    updateMultipartFormData,
    destroy,
    setData,
  }
}

export default useResource
