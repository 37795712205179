export const BoxShadow =
  '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)'

export const MINIMAL_LABEL = theme => ({
  fontWeight: 500,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
  fontSize: 10,
  lineHeight: '13px',
  textTransform: 'uppercase',
  letterSpacing: 1,
})

export const GIFT_ICON_POSITIONING = {
  paddingLeft: 12
}
