import React from 'react'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { MdFiberNew } from 'react-icons/md'
import PublicIcon from '@material-ui/icons/Public'

import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import { ORDER_STATES_TYPES } from 'constants/general'
import { URL } from 'constants/navigation'
import { GIFT_ICON_POSITIONING } from 'constants/styles'

import { useInventoryCountContext, useLocationsContext } from 'context'
import { useAppVersion } from 'hooks'
import Header from 'components/Header'
import LineItem from 'components/LineItem'
import OrderDetails from 'components/OrderDetails'
import ConfirmationDialog from 'components/ConfirmationDialog'
import PrinterSelect from 'components/PrinterSelect'
import { ReactComponent as BoltIcon } from 'assets/bolt.svg'

import GiftIcon from 'icons/GiftIcon'
import UberIcon from 'icons/UberIcon'
import CryptoMerchIcon from 'icons/CryptoMerchIcon'

import styles from './OrderViewStyles'

const OrderView = ({
  classes,
  order,
  onClickMaybeStartPicking,
  onClickStartPicking,
  onClickContinuePicking,
  onClickPrint,
  onClickPrepareShipment,
  onClickDispatch,
  onClickDelivered,
}) => {
  const { location } = useLocationsContext()
  const [expanded, setExpanded] = React.useState(false)
  const { itemCounts, allCheckedOrFlagged } = useInventoryCountContext()

  const { status } = order
  const isPaid = status === ORDER_STATES_TYPES.paid
  const isPicking = status === ORDER_STATES_TYPES.picking
  const isDispatched = status === ORDER_STATES_TYPES.dispatched
  const isDelivered = status === ORDER_STATES_TYPES.delivered

  const shipmentPrepared = !(
    order.delivery_method === 'global' &&
    (order?.shipment === null || order?.shipment?.status === 'order_placed')
  )

  const { updatePending } = useAppVersion()
  if (updatePending) {
    window.location.reload(true) // force refetch from server
  }

  return (
    <Box className={classes.root}>
      <Header
        breadcrumbs={[
          { text: 'Orders', href: URL.ORDERS },
          {
            text: order?.number,
            active: true,
          },
        ]}
        onBackHistoryBack
      >
        <>
          {order?.user_first_order && <MdFiberNew fontSize={36} />}
          {!isEmpty(order.gift_detail) && (
            <span style={GIFT_ICON_POSITIONING}>
              <GiftIcon size={36} />
            </span>
          )}
          {order?.crypto_merch && <CryptoMerchIcon />}
          {order?.uber && <UberIcon fontSize={64} />}
          {order?.location_id &&
            location?.id &&
            order?.location_id !== location?.id && <PublicIcon />}
        </>
      </Header>
      <Box flex={1}>
        <Box className={classes.content}>
          <Box className={classes.lineItems}>
            {itemCounts.map(({ item, numberChecked, numberFlagged, bins }) => {
              return (
                <LineItem
                  key={item.id}
                  lineItem={item}
                  showCounts={!isDispatched}
                  numberChecked={numberChecked}
                  numberFlagged={numberFlagged}
                  bins={bins}
                />
              )
            })}
          </Box>
          {!order?.uber && (
            <Box
              className={
                expanded ? classes.orderDetailsExpanded : classes.orderDetails
              }
            >
              <OrderDetails
                order={order}
                expanded={expanded}
                onChange={() => setExpanded(!expanded)}
              />
            </Box>
          )}
        </Box>
      </Box>
      <Box className={classes.actionContainer}>
        {isPaid && (
          <ConfirmationDialog
            title="Cycle Count In Progress"
            message={
              <Typography>
                A Cycle Count is occurring with an item in this order. Order
                picking cannot be started.
              </Typography>
            }
            acceptText="OK"
            denyText="Force Picking"
            onDeny={onClickStartPicking}
          >
            {({ open, setExtra }) => (
              <ConfirmationDialog
                title="Picking Already Started"
                message={
                  <Typography>
                    Someone else may have already started to pick this order.
                  </Typography>
                }
                acceptText="OK"
                denyText="Force Picking"
                onDeny={onClickStartPicking}
              >
                {({ open: openPickingStartedAlready }) => (
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    size="large"
                    startIcon={<BoltIcon />}
                    onClick={() =>
                      onClickMaybeStartPicking(
                        open,
                        setExtra,
                        openPickingStartedAlready
                      )
                    }
                    data-test="picking-button"
                  >
                    Start Picking
                  </Button>
                )}
              </ConfirmationDialog>
            )}
          </ConfirmationDialog>
        )}
        {isPicking && !allCheckedOrFlagged && (
          <Button
            fullWidth
            variant="contained"
            color="primary"
            size="large"
            startIcon={<BoltIcon />}
            onClick={onClickContinuePicking}
          >
            Continue Picking
          </Button>
        )}
        {isPicking && allCheckedOrFlagged && !shipmentPrepared && (
          <Button
            fullWidth
            variant="contained"
            color="primary"
            size="large"
            onClick={onClickPrepareShipment}
            data-test="prepare-shipment-button"
          >
            Prepare Shipment
          </Button>
        )}
        {isPicking && allCheckedOrFlagged && shipmentPrepared && (
          <Button
            fullWidth
            variant="contained"
            color="primary"
            size="large"
            onClick={onClickDispatch}
            data-test="dispatch-button"
          >
            Dispatch
          </Button>
        )}
        {(isDispatched || isDelivered) && (
          <Box width="100%">
            <Box>
              <PrinterSelect />
            </Box>
            <Box display="flex">
              <Button
                fullWidth
                variant="outlined"
                color="default"
                size="large"
                onClick={onClickPrint}
              >
                Reprint
              </Button>
              {isDispatched && order?.uber && (
                <Button
                  fullWidth
                  variant="contained"
                  size="large"
                  color="primary"
                  onClick={onClickDelivered}
                >
                  Deliver
                </Button>
              )}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  )
}

OrderView.propTypes = {
  classes: PropTypes.object.isRequired,
  order: PropTypes.object,
  status: PropTypes.string,
  onClickStartPicking: PropTypes.func,
  onClickMaybeStartPicking: PropTypes.func,
  onClickContinuePicking: PropTypes.func,
  onClickPrint: PropTypes.func,
  onClickPrepareShipment: PropTypes.func,
  onClickDispatch: PropTypes.func,
  onClickDelivered: PropTypes.func,
}

export default withStyles(styles)(OrderView)
