const URL_PURCHASE_ORDERS = '/pos'
const URL_TRANSFER_ORDERS = '/tos'

export const URL = {
  LOGIN: '/login',
  LOGOUT: '/logout',
  FORGOT_PASSWORD: '/forgot_password',
  ORDERS: '/orders',
  ORDERS_SEARCH: '/search',
  PURCHASE_ORDERS: URL_PURCHASE_ORDERS,
  PURCHASE_ORDERS_SEARCH: `${URL_PURCHASE_ORDERS}/search`,
  PURCHASE_ORDER_VARIANTS: '/variants',
  TRANSFER_ORDERS: URL_TRANSFER_ORDERS,
  TRANSFER_ORDERS_SEARCH: `${URL_TRANSFER_ORDERS}/search`,
  TRANSFER_ORDER_VARIANTS: '/variants',
  SCANNER: '/scanner',
  CYCLE_COUNTS: '/cycle-counts',
  CYCLE_COUNT_VARIANTS: '/variants',
  CYCLE_COUNTS_CREATE: '/cycle-counts-create',
  HOME: '/',
  LOCATION: '/location',
}
