import React from 'react'
import PropTypes from 'prop-types'

import * as propTypes from 'constants/propTypes'
import { colorsAF, warnings } from 'theme/colors'
import { CIRCLE_CHECK_STATUS } from 'constants/enums'

const DEFAULT_SIZE_PX = 24

const CircleCheckIndicator = ({ status, fontSize }) =>
  ({
    [CIRCLE_CHECK_STATUS.unchecked]: (
      <svg
        width={fontSize}
        height={fontSize}
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 24.3452C18.6274 24.3452 24 18.9726 24 12.3452C24 5.7178 18.6274 0.345215 12 0.345215C5.37258 0.345215 0 5.7178 0 12.3452C0 18.9726 5.37258 24.3452 12 24.3452Z"
          fill={colorsAF.mediumGray}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 24.3452C18.6274 24.3452 24 18.9726 24 12.3452C24 5.7178 18.6274 0.345215 12 0.345215C5.37258 0.345215 0 5.7178 0 12.3452C0 18.9726 5.37258 24.3452 12 24.3452ZM16.8994 10.5047L15.5104 9.11576L10.7376 13.8886L8.59148 11.7426L7.00049 13.3336L9.14657 15.4796L9.14649 15.4797L10.5354 16.8687L10.5355 16.8686L10.536 16.8691L12.127 15.2781L12.1265 15.2776L16.8994 10.5047Z"
          fill={colorsAF.semiGray}
        />
      </svg>
    ),
    [CIRCLE_CHECK_STATUS.warning]: (
      <svg
        width={fontSize}
        height={fontSize}
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 24.3452C18.6274 24.3452 24 18.9726 24 12.3452C24 5.7178 18.6274 0.345215 12 0.345215C5.37258 0.345215 0 5.7178 0 12.3452C0 18.9726 5.37258 24.3452 12 24.3452Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 24.3452C18.6274 24.3452 24 18.9726 24 12.3452C24 5.7178 18.6274 0.345215 12 0.345215C5.37258 0.345215 0 5.7178 0 12.3452C0 18.9726 5.37258 24.3452 12 24.3452ZM11 14.3452V7.34521H13V14.3452H11ZM11 17.3452V15.3452H13V17.3452H11Z"
          fill={warnings.error}
        />
      </svg>
    ),
    [CIRCLE_CHECK_STATUS.checked]: (
      <svg
        width={fontSize}
        height={fontSize}
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 24.3452C18.6274 24.3452 24 18.9726 24 12.3452C24 5.7178 18.6274 0.345215 12 0.345215C5.37258 0.345215 0 5.7178 0 12.3452C0 18.9726 5.37258 24.3452 12 24.3452Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 24.3452C18.6274 24.3452 24 18.9726 24 12.3452C24 5.7178 18.6274 0.345215 12 0.345215C5.37258 0.345215 0 5.7178 0 12.3452C0 18.9726 5.37258 24.3452 12 24.3452ZM16.8994 10.5047L15.5104 9.11576L10.7376 13.8886L8.59148 11.7426L7.00049 13.3336L9.14657 15.4796L9.14649 15.4797L10.5354 16.8687L10.5355 16.8686L10.536 16.8691L12.127 15.2781L12.1265 15.2776L16.8994 10.5047Z"
          fill={colorsAF.fast}
        />
      </svg>
    ),
  }[status] ?? null)

CircleCheckIndicator.defaultProps = {
  status: CIRCLE_CHECK_STATUS.unchecked,
  fontSize: DEFAULT_SIZE_PX,
}

CircleCheckIndicator.propTypes = {
  status: propTypes.circleCheckStatus,
  fontSize: PropTypes.number,
}

export default CircleCheckIndicator
