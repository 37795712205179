import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import classNames from 'classnames'
import { Box, Typography, Divider, Button } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'

import DamagedIcon from 'icons/DamagedIcon'

import Header from 'components/Header'
import InventoryCounter from 'components/InventoryCounter'
import AssignBins from 'components/AssignBins'
import AssignUPC from 'components/AssignUPC'
import PrintLabelDialog from 'components/PrintLabelDialog'
import ConfirmationDialog from 'components/ConfirmationDialog'
import InputDialog from 'components/InputDialog'

import {
  useAdminPurchaseOrderVariantList,
  useAdminPurchaseOrder,
  useAdminVariant,
  useAdminPlateList,
} from 'hooks'

import { useLocationsContext } from 'context'

import { PURCHASE_ORDER_STATE_TYPES } from 'constants/purchaseOrders'
import { URL } from 'constants/navigation'
import { DEFAULT_PRODUCT_IMAGE } from 'constants/general'

import styles from './PurchaseOrderVariantShowScreenStyles'

const SHOW_MODE = {
  VIEW: 'VIEW',
  SCANNER: 'SCANNER',
  ASSIGN_BIN: 'ASSIGN_BIN',
  ASSIGN_UPC: 'ASSIGN_UPC',
}

const itemToLookupBarcode = (variant, id) => {
  if (!variant) {
    return {}
  }
  return {
    barcode: variant.barcode,
    bin: '',
    consignment: null,
    images: variant.images,
    price: variant.price,
    product: variant.product,
    quantity: 1,
    shopify_id: variant.shopify_id,
    sku: variant.sku,
    total_discount: 0,
    color: variant?.color?.label,
    size: variant?.size,
    variant,
  }
}

const inventoryItem = (variant, quantity) => {
  if (!variant) {
    return {}
  }
  return {
    barcode: variant.barcode,
    bin: '',
    consignment: null,
    images: variant.images,
    price: variant.price,
    product: variant.product,
    quantity,
    shopify_id: variant.shopify_id,
    sku: variant.sku,
    total_discount: 0,
    color: variant?.color?.label,
    size: variant?.size,
    id: variant.id,
    variant,
  }
}

const variantStats = variant => {
  const receivingComplete = variant?.purchase_order_variant_receiving_complete
  const received = variant?.purchase_order_variant_received || 0
  const damaged = variant?.purchase_order_variant_damaged || 0
  const pastReceived = variant?.purchase_order_variant_past_received || 0
  const pastDamaged = variant?.purchase_order_variant_past_damaged || 0
  const quantity = variant?.purchase_order_variant_quantity || 0
  const missing = receivingComplete
    ? Math.max(0, quantity - pastReceived - pastDamaged)
    : 0

  const quantityInBins = Number.isInteger(
    variant?.purchase_order_variant_quantity_in_bins
  )
    ? variant.purchase_order_variant_quantity_in_bins
    : 0
  const allReceivedInBins = receivingComplete && quantityInBins === pastReceived
  return {
    receivingComplete,
    received,
    damaged,
    pastReceived,
    pastDamaged,
    quantity,
    missing,
    quantityInBins,
    allReceivedInBins,
  }
}

const variantsSums = variants => {
  const stats = variants.map(variantStats)
  return {
    quantity: stats.reduce((acc, v) => acc + v.quantity, 0),
    received: stats.reduce((acc, v) => acc + v.received, 0),
    damaged: stats.reduce((acc, v) => acc + v.damaged, 0),
    pastReceived: stats.reduce((acc, v) => acc + v.pastReceived, 0),
    pastDamaged: stats.reduce((acc, v) => acc + v.pastDamaged, 0),
    allReceivedInBins:
      stats.map(v => v.allReceivedInBins).indexOf(false) === -1,
  }
}

const PurchaseOrderVariantShowScreen = ({
  classes,
  match: {
    params: { id, variantId },
  },
}) => {
  const history = useHistory()
  const { location } = useLocationsContext()
  const [mode, setMode] = useState(SHOW_MODE.VIEW)
  const [updatingStatus, setUpdatingStatus] = useState(false)

  const {
    updatePurchaseOrderVariants,
    isFetching: isLoadingPurchaseOrderVariants,
    variants: purchaseOrderVariants,
    // eslint-disable-next-line radix
  } = useAdminPurchaseOrderVariantList(parseInt(id), { purchase_order_id: id })

  const { purchaseOrder, updatePurchaseOrder, inCycleCount } =
    // eslint-disable-next-line radix
    useAdminPurchaseOrder(parseInt(id))

  const { bulkUpdatePlates } = useAdminPlateList({
    fetchList: false,
  })

  useEffect(() => {
    // make sure our variants are loaded
    if (!updatingStatus && purchaseOrderVariants.length > 0) {
      const {
        quantity,
        pastReceived: received,
        pastDamaged: damaged,
        allReceivedInBins,
      } = variantsSums(purchaseOrderVariants)
      if (
        purchaseOrder?.status !== PURCHASE_ORDER_STATE_TYPES.closed &&
        purchaseOrder?.status !== PURCHASE_ORDER_STATE_TYPES.submitted &&
        allReceivedInBins
      ) {
        const diff = quantity - (received + damaged)
        const status =
          // eslint-disable-next-line no-nested-ternary
          diff < 0
            ? PURCHASE_ORDER_STATE_TYPES.overReceived
            : diff > 0
            ? PURCHASE_ORDER_STATE_TYPES.partiallyReceived
            : PURCHASE_ORDER_STATE_TYPES.fullyReceived
        if (purchaseOrder.status !== status) {
          setUpdatingStatus(true)
          try {
            updatePurchaseOrder({ status })
          } finally {
            setUpdatingStatus(false)
          }
        }
      }
    }
  }, [purchaseOrderVariants, purchaseOrder])

  // eslint-disable-next-line radix
  const variantIdParsed = parseInt(variantId)
  // we have to do it this way currently because it is the only way
  // the server will attach PO specific variant fields to a variant
  const variant = purchaseOrderVariants.find(v => v.id === variantIdParsed)

  const { generateAndAssignPurchaseOrderVariantDSIN, updateVariant } =
    useAdminVariant(variantId, id)

  const DetailsRow = ({ title, value }) => (
    <Box className={classes.row}>
      <Typography variant="subtitle1" className={classes.colorDark}>
        {title}
      </Typography>
      <Typography
        variant="body2"
        className={classNames({ [classes.colorDark]: !value })}
        data-test={`${title}-details`}
      >
        {typeof value === 'undefined' ? (
          <Skeleton width="100px" />
        ) : (
          value || 'Not Set'
        )}
      </Typography>
    </Box>
  )
  DetailsRow.propTypes = {
    title: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }

  const QuantityRow = ({ title, value, total }) => (
    <Box className={classNames(classes.row, classes.quantityRow)}>
      <Typography variant="subtitle1">Items {title}</Typography>
      <Box className={classes.column}>
        <Box component="span">
          <Typography
            component="span"
            variant="body2"
            className={classes.quantityLabel}
          >
            Qty
          </Typography>
          <Typography
            component="span"
            variant="body1"
            data-test={`${title}-qty`}
          >
            {typeof value === 'undefined' ? <Skeleton width="20px" /> : value}
          </Typography>
        </Box>
        <Typography variant="body1" component="span">
          of {typeof value === 'undefined' ? <Skeleton width="20px" /> : total}
        </Typography>
      </Box>
    </Box>
  )
  QuantityRow.propTypes = {
    title: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    total: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }

  const {
    received,
    damaged,
    pastReceived,
    pastDamaged,
    quantity,
    missing,
    quantityInBins,
    allReceivedInBins,
  } = variantStats(variant)

  const allowScanning = allReceivedInBins || (received === 0 && damaged === 0)

  const parentHref = `${URL.PURCHASE_ORDERS}/${id}`

  const setModeView = () => setMode(SHOW_MODE.VIEW)
  const setModeScanner = () => setMode(SHOW_MODE.SCANNER)
  const setModeAssignBin = () => setMode(SHOW_MODE.ASSIGN_BIN)
  const setModeAssignUPC = () => setMode(SHOW_MODE.ASSIGN_UPC)

  // Using a component so we can access the inventory count context
  const CompleteScanButton = ({ item, clearCache, className }) => {
    const handleCompleteScanClick = () => {
      setModeView()
      clearCache()
      updatePurchaseOrderVariants(
        [
          {
            id: variant?.id,
            received: item.checked,
            damaged: item.flagged,
            past_received: item.checked + pastReceived,
            past_damaged: item.flagged + pastDamaged,
            receiving_complete: true,
          },
        ],
        false
      )
    }

    return (
      <ConfirmationDialog
        title="Complete Scan"
        message="Are you sure to complete the scan?"
        onAccept={handleCompleteScanClick}
      >
        {({ open }) => (
          <Button
            data-test="complete-scan"
            variant="contained"
            size="large"
            className={className}
            onClick={open}
          >
            Complete Scan
          </Button>
        )}
      </ConfirmationDialog>
    )
  }
  CompleteScanButton.propTypes = {
    item: PropTypes.object,
    clearCache: PropTypes.bool,
    className: PropTypes.string,
  }

  const handleManualEntry = (newReceived, newDamaged) => {
    if (purchaseOrder?.status === PURCHASE_ORDER_STATE_TYPES.submitted) {
      updatePurchaseOrder({
        status: PURCHASE_ORDER_STATE_TYPES.receivingInProgress,
      })
    }
    updatePurchaseOrderVariants(
      [
        {
          id: variant?.id,
          received: newReceived,
          damaged: newDamaged,
          past_received: pastReceived + newReceived,
          past_damaged: pastDamaged + newDamaged,
          receiving_complete: true,
        },
      ],
      false
    )
  }

  const pickerActions =
    allowScanning && (variant?.barcode || variant?.dsin)
      ? ({ currentItem, clearCache, handleFlag, className }) => (
          <>
            <Button
              data-test="mark-damaged"
              variant="contained"
              size="large"
              startIcon={<DamagedIcon />}
              onClick={handleFlag}
              className={classNames(classes.markDamaged, className)}
            >
              Mark Damaged
            </Button>
            <CompleteScanButton
              item={currentItem}
              clearCache={clearCache}
              className={className}
            />
          </>
        )
      : () => <></>

  const handleMaybeStartScan = async (
    openCountsDialog,
    openDialog,
    setExtra
  ) => {
    const cycleCountVariants = await inCycleCount(id)
    if (cycleCountVariants.length > 0) {
      setExtra({ message: `Cycle Counts: ${cycleCountVariants.join(',')}` })
      openDialog()
      return
    }
    openCountsDialog()
  }

  const handleStartScan = () => {
    if (purchaseOrder?.status === PURCHASE_ORDER_STATE_TYPES.submitted) {
      updatePurchaseOrder({
        status: PURCHASE_ORDER_STATE_TYPES.receivingInProgress,
      })
    }
    updatePurchaseOrderVariants(
      [
        {
          id: variant?.id,
          receiving_started: true,
        },
      ],
      false
    )
    setModeScanner()
  }

  const handleAssignBin = (bin, thisQuantity, expirationDate) => {
    bulkUpdatePlates({
      location_id: location.id,
      create: true,
      is_adjust: true,
      zero_other_locations: false,
      variants: [
        {
          id: variant.id,
          label: bin,
          quantity: thisQuantity,
          expiration_date: expirationDate
        },
      ],
    })
    updatePurchaseOrderVariants(
      [
        {
          id: variant?.id,
          quantity_in_bins:
            variant.purchase_order_variant_quantity_in_bins + thisQuantity,
          new_bins: bin,
          update_inventory: true,
        },
      ],
      false
    )
    if (!(thisQuantity < pastReceived - quantityInBins)) {
      setModeView()
    }
  }

  const handleAssignDsin = () => {
    generateAndAssignPurchaseOrderVariantDSIN()
  }

  const handleAssignUPC = async upc => {
    await updateVariant({ barcode: upc })
    setModeView()
  }

  const view =
    {
      [SHOW_MODE.VIEW]: (
        <Box className={classes.root}>
          <Header
            breadcrumbs={[
              { text: 'PO', href: URL.PURCHASE_ORDERS },
              { text: `#${id}`, href: parentHref },
              { text: variantId, active: true },
            ]}
            onBackClick={() => history.push(parentHref)}
          />
          <Box width="100%">
            {/* eslint-disable-next-line no-nested-ternary */}
            {variant?.images.length > 0 ? (
              <img
                src={variant.images[0].thumbnail_url}
                alt="variant"
                className={classes.image}
              />
            ) : isLoadingPurchaseOrderVariants ? (
              <Skeleton width="375px" height="286px" />
            ) : (
              <img
                src={DEFAULT_PRODUCT_IMAGE}
                alt="placeholder variant"
                className={classes.image}
              />
            )}
          </Box>
          <DetailsRow title="Brand" value={variant?.product?.brand} />
          <DetailsRow title="Product Name" value={variant?.product?.title} />
          <DetailsRow title="Color" value={variant?.color?.label} />
          <DetailsRow title="Size" value={variant?.size} />
          <DetailsRow title="SKU" value={variant?.sku} />
          <DetailsRow
            title="UPC/DSIN"
            value={variant?.barcode || variant?.dsin}
          />
          <DetailsRow
            title="Available for Sale"
            value={variant?.available_for_sale_inventory?.quantity}
          />
          <Divider className={classes.divider} />
          <QuantityRow title="Received" value={pastReceived} total={quantity} />
          <QuantityRow title="Damaged" value={pastDamaged} total={quantity} />
          <QuantityRow title="Missing" value={missing} total={quantity} />
          {purchaseOrderVariants.length > 0 ? (
            <Box className={classes.actions} data-test="action-container">
              {(variant?.barcode || variant?.dsin) && (
                <PrintLabelDialog variant={variant}>
                  {({ open: openDialog }) => (
                    <Button
                      variant="outlined"
                      color="default"
                      onClick={openDialog}
                    >
                      Print Label
                    </Button>
                  )}
                </PrintLabelDialog>
              )}
              {purchaseOrder?.status !== PURCHASE_ORDER_STATE_TYPES.closed && (
                <ConfirmationDialog
                  title="Cycle Count In Progress"
                  message={
                    <Typography>
                      A Cycle Count is occurring with an item in this PO. PO
                      scanning cannot be started.
                    </Typography>
                  }
                  acceptText="OK"
                  showDeny={false}
                >
                  {({ open, setExtra }) => (
                    <InputDialog
                      title="Manual Entry"
                      message={
                        <Typography>
                          Enter number of received and damaged units.
                        </Typography>
                      }
                      label="Received"
                      label2="Damaged"
                      onConfirm={handleManualEntry}
                    >
                      {({ open: openManualInput }) => (
                        <ConfirmationDialog
                          title="Start Scan"
                          message={
                            <Typography>
                              Continue with Scanning or manually enter counts?
                            </Typography>
                          }
                          acceptText="Scan"
                          denyText="Manual"
                          onAccept={handleStartScan}
                          onDeny={openManualInput}
                        >
                          {({ open: openManual }) => (
                            <Button
                              data-test="po-primary-action"
                              variant="contained"
                              color="primary"
                              onClick={
                                // eslint-disable-next-line no-nested-ternary
                                !(variant?.barcode || variant?.dsin)
                                  ? setModeAssignUPC
                                  : !allowScanning
                                  ? setModeAssignBin
                                  : () =>
                                      handleMaybeStartScan(
                                        openManual,
                                        open,
                                        setExtra
                                      )
                              }
                            >
                              {/* eslint-disable-next-line no-nested-ternary */}
                              {!allowScanning
                                ? 'Assign Bin'
                                : !(variant?.barcode || variant?.dsin)
                                ? 'Assign UPC'
                                : 'Start Scan'}
                            </Button>
                          )}
                        </ConfirmationDialog>
                      )}
                    </InputDialog>
                  )}
                </ConfirmationDialog>
              )}
              {!(variant?.barcode || variant?.dsin) && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleAssignDsin}
                >
                  Assign DSIN
                </Button>
              )}
            </Box>
          ) : (
            <Skeleton width="359px" height="40px" />
          )}
        </Box>
      ),
      [SHOW_MODE.SCANNER]: (
        <InventoryCounter
          items={[inventoryItem(variant, quantity)]}
          onClose={setModeView}
          actions={pickerActions}
          type="po"
          parentId={id}
        />
      ),
      [SHOW_MODE.ASSIGN_BIN]: (
        <AssignBins
          lineItem={itemToLookupBarcode(variant, variantId)}
          maxQuantity={pastReceived - quantityInBins}
          onAssignBin={handleAssignBin}
          onBack={() => {
            setModeView()
          }}
          currentBins={
            variant?.available_for_sale_inventory?.bin
              ?.split(',')
              ?.map(b => b.trim()) || []
          }
        />
      ),
      [SHOW_MODE.ASSIGN_UPC]: (
        <AssignUPC
          lineItem={itemToLookupBarcode(variant, variantId)}
          onAssignUPC={handleAssignUPC}
          onBack={() => {
            setModeView()
          }}
        />
      ),
    }[mode] ?? null

  return view
}

export default withStyles(styles)(PurchaseOrderVariantShowScreen)
