import { useQuery, useMutation, useQueryClient } from 'react-query'

import { useAlerts, useLoaders } from 'hooks'
import { useAPIContext } from 'context'

// These are input processors to convert input data to a format that an endpoint expects.
export const defaultInputProcessor = name => resource => {
  return { [name]: resource }
}
export const fileInputProcessor = name => value => {
  const formData = new FormData()
  formData.append(name, value.files[0])
  return formData
}

export const multiFieldFileInputProcessor = baseName => payload => {
  const formData = new FormData()
  Object.entries(payload).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      // TODO: This isn't maximally generic, really this could be a
      // recursive function that unwraps js objects into rails compatible
      // multipart/form-data
      value.forEach(sub => {
        formData.append(`${baseName}[${key}][]`, sub)
      })
    } else {
      formData.append(`${baseName}[${key}]`, value)
    }
  })
  return formData
}

// These are general params that can be exported and re-used.
export const multiPartFormParams = {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
}

export const OPERATION_TYPE = {
  fetch: 'fetch',
  create: 'create',
  update: 'update',
  operate: 'operate',
  destroy: 'destroy',
}

const SERVER_ERROR_CODE = 422

/* Provides a React Query based interface to FastAF resource
   endpoints. Resource endpoints are usually a set of CRUD operations on
   an ID referenced resource on the server. */

/* Arguments:
 *
 * endpoint: a function that is invoked by useResourceRQ applying args.
 * args: arguments for endpoints functions, like a resource ID
 * name: the resource name as expected in server responses, used by the
   default input processor. This arg is required if inputProcessor
   is not specified.
 * dependents: an array of endpoint functions. If a mutation occurs,
   these endpoints are invalidated in the React Query cache
 * resetQueries: the same as dependents except the endpoint values
   are also cleared from the cache immediately
 * fetchingEnabled: if true (the default) React Query will fetch this
   resource when it deems necessary. This argument is useful when you
   want to prepare to fetch a resource but aren't ready to yet. A
   common case of this might be if your query is dependent on an ID
   for the resource but you don't have the ID yet; you can disabled
   fetching until you have the ID you need like using Boolean(id).
 * activeLoaders: (false by default) if true, it displays a loader for POST and PUT requests.
 * showServerError: (false by default) if true, the error message from the server will be displayed.
 * params: an object passed through to the API call. Can be query
   params, headers, etc.
 * inputProcessor: a function invoked when updating/mutating data.
   It receives in the input data as an argument and the
   return value is passed to the API call. The default
   is to wrap the data in an object keyed by 'name' like:
   { [name]: data }. 'name' must be specified if inputProcessor
   is not specified. See the top of this file for examples.
*  onSuccess: a function accepting one argument, that, if provided,
   is invoked when any query is successful. The function is passed
   the result of the query's request and the current operation.
*  onSuccess: it can also be an object containing key functions that, if provided and a string is returned,
   it will display a success alert message. For example:
    onSuccess: {
      updateMsg: (updatedData) => 'Updated. Custom successful message',
      createMsg: (createdData) => 'Created. Custom successful message'
    },
*  onError: a function accepting one argument, that, if provided,
   is invoked when any query is fails. The function is passed
   the error object.
*  onError: it can also be an object containing an errorMsg function that, if provided and a string is returned,
   it will display that custom error message: For example:
    onError: {
      errorMsg: (error) => 'Custom error message'
    },
*  options: Additional options for the useQuery. */

/* Example Usage:
 *
 * const useAdminPurchaseOrder = purchaseOrderId => {
 *   const {
 *     create: createPurchaseOrder,
 *     data: purchaseOrder,
 *     update: updatePurchaseOrder,
 *     isLoading: isLoadingPurchaseOrder,
 *     isError: isPurchaseOrderError,
 *     error: purchaseOrderError
 *   } = useResourceRQ({
 *     endpoint: purchaseOrderEndpoint,
 *     args: [purchaseOrderId],
 *     name: 'purchase_order',
 *     fetchingEnabled: Boolean(purchaseOrderId),
 *     dependents: [purchaseOrderListEndpoint, purchaseOrderSummaryEndpoint],
 *     onSuccess: (response, method) => console.info(method, response.data)
 *     onError: (err) => console.error(err)
 *   })
 *
 *   ...
 * } */

const useResourceRQ = ({
  endpoint,
  args = [],
  name,
  dependents = [],
  resetQueries = [],
  fetchingEnabled = true,
  disableOptimisticUpdate = false,
  params = {},
  mutateParams = {},
  inputProcessor,
  options = {},
  activeLoaders,
  showServerError,
  onSuccess = () => undefined,
  onError = () => undefined,
  onMutate = () => undefined,
  onSettled = () => undefined,
}) => {
  const { api } = useAPIContext()
  const { showLoading, hideLoading } = useLoaders()
  const { showAlertError, showAlertSuccess } = useAlerts()

  const queryClient = useQueryClient()

  const resourceUrl = endpoint(...args)
  const resourceQueryKey = [resourceUrl]

  const processInput = inputProcessor || defaultInputProcessor(name)

  const processMutate = (data, method) => {
    if (typeof onMutate === 'function') {
      onMutate(data)
    }

    if (method in onMutate) {
      onMutate[method](data)
    }

    if (activeLoaders) {
      showLoading()
    }
  }

  // TODO(exrhizo): I think it would be a good idea to have the interface
  // to onSuccess to match ReactQuery's
  const processSuccess = (data, method) => {
    dependents
      .map(d => d(...args))
      .forEach(q => queryClient.invalidateQueries(q))
    resetQueries.map(d => d(...args)).forEach(q => queryClient.resetQueries(q))

    if (typeof onSuccess === 'function') {
      onSuccess(data.data, method)
    }

    if (method in onSuccess) {
      onSuccess[method](data.data)
    }

    if ([`${method}Msg`] in onSuccess) {
      showAlertSuccess(onSuccess[`${method}Msg`](data.data))
    }
  }

  const handleError = (err, method) => {
    if (typeof onError === 'function') {
      onError(err, method)
    }

    if (method in onError) {
      onError[method](err)
    }

    if ('errorMsg' in onError) {
      showAlertError(onError.errorMsg(err))
      return
    }

    showServerError && err?.status === SERVER_ERROR_CODE
      ? showAlertError(
          (err.data.errors?.length && err.data.errors) ||
            (err.data?.message ?? `${name} error`)
        )
      : showAlertError(err.data?.message ?? `${name} error`)
  }

  const processSettled = (data, err, method) => {
    if (typeof onSettled === 'function') {
      onSettled(data, err, method)
    }

    if (method in onSettled) {
      onSettled[method](data, err, method)
    }
    hideLoading()
  }

  const {
    isLoading,
    isError,
    isFetching,
    isIdle,
    data: response,
    error,
    refetch,
  } = useQuery(resourceQueryKey, () => api.get(resourceUrl, { params }), {
    enabled: fetchingEnabled,
    onError: err => {
      handleError(err, OPERATION_TYPE.fetch)
    },
    ...options,
  })

  /* Resource updates are both optimistic and will invalidate the
     dependents */
  const {
    mutateAsync: update,
    isError: isUpdateError,
    error: updateError,
  } = useMutation(
    resource =>
      api.put(
        resource.id ? endpoint(resource.id) : resourceUrl,
        processInput(resource.payload || resource),
        {
          params: { ...params, ...mutateParams },
        }
      ),
    {
      onSuccess: data => {
        queryClient.setQueryData(resourceQueryKey, data)
        processSuccess(data, OPERATION_TYPE.update)
      },
      onMutate: async newResource => {
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries(resourceQueryKey)

        // Snapshot the previous value
        const previousResource = queryClient.getQueryData(resourceQueryKey)

        // Optimistically update to the new value
        if (!disableOptimisticUpdate) {
          queryClient.setQueryData(resourceQueryKey, { data: newResource })
        }

        processMutate(newResource, OPERATION_TYPE.update)
        // Return a context with the previous and new
        return { previousResource, newResource }
      },
      // If the mutation fails, use the context we returned above
      // to revert to the previous value.
      onError: (err, newResource, context) => {
        queryClient.setQueryData(resourceQueryKey, context.previousResource)
        handleError(err, OPERATION_TYPE.update)
      },
      onSettled: (data, err) =>
        processSettled(data, err, OPERATION_TYPE.update),
    }
  )

  // Use this when the server will operate on the resource, like for generating
  // a unique value and assigning it to the resource. This mutates the resource
  // without optimistically updating the cache and invalidates dependents as well.
  const {
    mutateAsync: operate,
    isError: isOperateError,
    error: operateError,
  } = useMutation(
    resource =>
      api.put(resourceUrl, processInput(resource), {
        params: { ...params, ...mutateParams },
      }),
    {
      onSuccess: data => {
        processSuccess(data, OPERATION_TYPE.operate)
      },
      onError: err => {
        handleError(err, OPERATION_TYPE.operate)
      },
    }
  )

  // This will create a new resource on the server and optimistically
  // update the cache while invalidating dependent queries.
  const {
    mutateAsync: create,
    isError: isCreateError,
    error: createError,
  } = useMutation(
    resource =>
      api.post(endpoint(...args), processInput(resource), {
        params: { ...params, ...mutateParams },
      }),
    {
      onSuccess: resp => {
        queryClient.setQueryData([endpoint.apply(resp.data.id)], resp)
        processSuccess(resp, OPERATION_TYPE.create)
      },
      onError: err => {
        handleError(err, OPERATION_TYPE.create)
      },
      onMutate: async newResource => {
        processMutate(newResource, OPERATION_TYPE.create)
      },
      onSettled: (data, err) =>
        processSettled(data, err, OPERATION_TYPE.create),
    }
  )

  const { mutate: destroy } = useMutation(
    () =>
      api.delete(endpoint(...args), null, {
        params: { ...params, ...mutateParams },
      }),
    {
      onSuccess: resp => {
        processSuccess(resp, OPERATION_TYPE.destroy)
      },
      onError: err => {
        handleError(err, OPERATION_TYPE.destroy)
      },
      onSettled: (data, err) =>
        processSettled(data, err, OPERATION_TYPE.destroy),
    }
  )

  return {
    data: response?.data,
    update,
    create,
    isLoading,
    isFetching,
    isIdle,
    isError: isError || isUpdateError || isOperateError || isCreateError,
    error: error || updateError || operateError || createError,
    operate,
    destroy,
    refetch,
  }
}

export default useResourceRQ
