/* WARNING: copied from dashboard; keep in sync */
const BorderSize = 9

const jssStyles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  text: {
    whiteSpace: 'nowrap',
  },
  borderDot: {
    width: BorderSize,
    height: BorderSize,
    borderRadius: 5,
    margin: '0 5px',
    borderWidth: 2,
    borderStyle: 'solid',
    position: 'relative',
  },
  liveEffect: {
    '&:before': {
      content: '""',
      position: 'absolute',
      top: -4,
      left: -4,
      width: 'calc(100% + 8px)',
      height: 'calc(100% + 8px)',
      backgroundColor: 'inherit',
      borderRadius: '100%',
      animation: '$pulse 2s cubic-bezier(0.4, 0.0, 0.2, 1) alternate infinite',
    },
  },
  filled: {},
  halfFilled: {
    '&:after': {
      content: '""',
      width: '100%',
      height: '40%',
      position: 'absolute',
      bottom: 0,
    },
  },
  grayStatus: {
    color: theme.palette.grays.dark,

    '& $borderDot': {
      borderColor: theme.palette.grays.dark,
    },

    '& $filled': {
      backgroundColor: theme.palette.grays.dark,
    },

    '& $halfFilled:after': {
      backgroundColor: theme.palette.grays.dark,
    },
  },
  blueStatus: {
    color: theme.palette.blues.fast,

    '& $borderDot': {
      borderColor: theme.palette.blues.fast,
    },

    '& $filled': {
      backgroundColor: theme.palette.blues.fast,
    },

    '& $halfFilled:after': {
      backgroundColor: theme.palette.blues.fast,
    },
  },
  greenStatus: {
    color: theme.palette.warnings.success,

    '& $borderDot': {
      borderColor: theme.palette.warnings.success,
    },

    '& $filled': {
      backgroundColor: theme.palette.warnings.success,
    },

    '& $halfFilled:after': {
      backgroundColor: theme.palette.warnings.success,
    },
  },
  orangeStatus: {
    color: theme.palette.error.light,

    '& $borderDot': {
      borderColor: theme.palette.error.light,
    },

    '& $filled': {
      backgroundColor: theme.palette.error.light,
    },

    '& $halfFilled:after': {
      backgroundColor: theme.palette.error.light,
    },
  },
  redStatus: {
    color: theme.palette.error.dark,

    '& $borderDot': {
      borderColor: theme.palette.error.dark,
    },

    '& $filled': {
      backgroundColor: theme.palette.error.dark,
    },

    '& $halfFilled:after': {
      backgroundColor: theme.palette.error.dark,
    },
  },
  '@keyframes pulse': {
    from: {
      opacity: 0,
      transform: 'scale3d(0.7, 0.7, 0.7)',
    },
    to: {
      opacity: 0.25,
      transform: 'scale3d(1.15, 1.15, 1.15)',
    },
  },
})

export default jssStyles
