import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { Box, Typography /* Fab */ } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'

/* import SearchIcon from '@material-ui/icons/Search' */

import BottomDrawerMenu from 'components/BottomDrawerMenu'
import HeaderWithLocation from 'components/HeaderWithLocation'
import PurchaseOrderStatus from 'components/PurchaseOrderStatus'
import Tabs from 'components/Tabs'

import { useAdminTransferOrderList } from 'hooks'

import { useLocationsContext } from 'context'

import { LIST_PATHS, API_PARAMS } from 'constants/queryParams'
import { URL } from 'constants/navigation'
import { TRANSFER_ORDER_STATE_TYPES } from 'constants/transferOrders'

import styles from './TransferOrderListScreenStyles'

const TABS = [
  [TRANSFER_ORDER_STATE_TYPES.submitted],
  [TRANSFER_ORDER_STATE_TYPES.receiving],
  [TRANSFER_ORDER_STATE_TYPES.closed],
]

const SCROLL_HEIGHT_OFFSET_PX = 50

const TransferOrderListScreen = ({ classes }) => {
  const [status, setStatus] = useState([TRANSFER_ORDER_STATE_TYPES.submitted])
  const [params, setParams] = useState({})
  const { location } = useLocationsContext()
  const {
    isFetchingTransferOrders,
    transferOrders,
    hasTransferOrdersNext,
    fetchTransferOrdersNext,
  } = useAdminTransferOrderList(params)

  useEffect(() => {
    setParams({
      [API_PARAMS.SORT]: LIST_PATHS.TRANSFER_ORDERS_ETA,
      [LIST_PATHS.TRANSFER_ORDERS_STATUS_FILTER]: status.join(','),
      [LIST_PATHS.TRANSFER_ORDERS_LOCATION_ID]: location?.id,
    })
  }, [status, location])

  const handleScroll = e => {
    const { scrollHeight, scrollTop, clientHeight } = e.target
    if (scrollTop + SCROLL_HEIGHT_OFFSET_PX >= scrollHeight - clientHeight) {
      hasTransferOrdersNext && fetchTransferOrdersNext()
    }
  }

  const Loading = (
    <Box className={classes.listContainer}>
      {new Array(4).fill(0).map((_, i) => (
        <Box key={0 - i} className={classes.tabContainer} mb="2px">
          <Skeleton width="30px" height="21px" />
          <Skeleton width="80px" height="21px" />
          <Skeleton width="80px" height="21px" />
          <Box className={classes.etaStatusRow}>
            <Skeleton width="160px" height="21px" />
            <Skeleton width="80px" height="21px" />
          </Box>
        </Box>
      ))}
    </Box>
  )

  const tabContents = (
    <Box className={classes.listContainer} onScroll={handleScroll}>
      {transferOrders.map(to => (
        <Box key={to.id} className={classes.tabContainer} data-test="po-item">
          <Link to={`${URL.TRANSFER_ORDERS}/${to.id}`} className={classes.link}>
            <Typography variant="body2">#{to.id}</Typography>
            <Box className={classes.etaStatusRow}>
              <Typography variant="body2" component="span">
                Estimated Arrival{' '}
                {moment(to.estimated_arrival_at).format('M/D/YY')}
              </Typography>
              <PurchaseOrderStatus
                className={classes.status}
                status={to.status}
              />
            </Box>
          </Link>
        </Box>
      ))}
      {isFetchingTransferOrders && Loading}
    </Box>
  )

  const handleTabChange = tabIndex => {
    setStatus(TABS[tabIndex])
  }

  return (
    <>
      <HeaderWithLocation title="TOs" />
      <Tabs
        tabLabels={['Incoming', 'Receiving', 'Received']}
        tabContents={[tabContents, tabContents, tabContents]}
        onChange={handleTabChange}
        classes={{
          tabPanel: classes.tabPanel,
          root: classes.tabRoot,
        }}
      />
      <Link to={URL.TRANSFER_ORDERS_SEARCH}>
        {/*         <Fab className={classes.searchIcon} data-test="po-search">
          <SearchIcon style={{ color: 'white' }} />
        </Fab> */}
      </Link>
      <BottomDrawerMenu />
    </>
  )
}

TransferOrderListScreen.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(TransferOrderListScreen)
