import { useResourceListRQ } from 'hooks'
import { uberOrderListEndpoint } from 'api/endpoints'

const useAdminUberOrderList = ({
  params,
  enabled = true,
  refetch = true,
} = {}) => {
  const {
    isFetching: isFetchingUberOrders,
    data: uberOrders,
    hasData: hasUberOrders,
    hasNextPage: hasUberOrdersNext,
    fetchNextPage: fetchUberOrdersNext,
    meta,
    count: totalUberOrders,
  } = useResourceListRQ({
    endpoint: uberOrderListEndpoint,
    params,
    enabled,
    options: { refetchInterval: refetch ? 1000 : false },
  })

  return {
    isFetchingUberOrders,
    uberOrders: uberOrders || [],
    hasUberOrders,
    hasUberOrdersNext,
    fetchUberOrdersNext,
    summary: meta?.summary,
    totalUberOrders,
  }
}

export default useAdminUberOrderList
