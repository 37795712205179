import React from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { useHistory, Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import MuiLink from '@material-ui/core/Link'

import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'

import { URL } from 'constants/navigation'
import { useAuth, useAlerts } from 'hooks'
import {
  makeObjectResolver,
  emailSchema,
  passwordSchema,
} from 'constants/yupSchemas'
import { isAdmin } from 'utils/user'
import TextField from 'components/TextField'

import styles from './LoginScreenStyles'

const LoginScreen = ({ classes }) => {
  const history = useHistory()

  const { currentUser, loginUser } = useAuth()

  const { showAlertError } = useAlerts()

  const { register, handleSubmit, errors } = useForm({
    mode: 'onSubmit',
    resolver: makeObjectResolver({
      email: emailSchema,
      password: passwordSchema,
    }),
  })

  const onSubmit = async data => {
    const result = await loginUser(data)

    if (!result) {
      showAlertError('Password invalid. Try again')
      return
    }

    history.push(URL.HOME)
  }

  if (currentUser) {
    return (
      <Box className={classes.fullScreen}>
        {!isAdmin(currentUser) ? (
          <Typography variant="h1">Unauthorized User</Typography>
        ) : (
          <Typography variant="h1">
            <Link to={URL.ORDERS}>Orders</Link>
          </Typography>
        )}
        <Typography variant="h1">
          <Link to={URL.LOGOUT}>Logout</Link>
        </Typography>
      </Box>
    )
  }

  return (
    <Container maxWidth="xs">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box mt={2} mb={3}>
          <Typography variant="h2">Sign In</Typography>
          <Typography variant="body2" color="textSecondary">
            Continue to the dashboard.
          </Typography>
        </Box>
        <Box>
          <TextField
            name="email"
            placeholder="Account Email"
            inputRef={register}
            error={Boolean(errors?.email?.message)}
            helperText={errors?.email?.message}
            fullWidth
          />
          <TextField
            name="password"
            placeholder="Password"
            type="password"
            inputRef={register}
            error={Boolean(errors?.password?.message)}
            helperText={errors?.password?.message}
            fullWidth
          />

          {process.env.REACT_APP_DASHBOARD_ROOT_URL !== null && (
            <Box mb={2}>
              <Typography variant="body2">
                <MuiLink
                  href={`${process.env.REACT_APP_DASHBOARD_ROOT_URL}/forgot-password`}
                >
                  Forgot Password?
                </MuiLink>
              </Typography>
            </Box>
          )}

          <Button
            fullWidth
            size="large"
            color="primary"
            variant="contained"
            type="submit"
          >
            Login
          </Button>
        </Box>
      </form>
    </Container>
  )
}

LoginScreen.defaultProps = {}

LoginScreen.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(LoginScreen)
