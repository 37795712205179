/* WARNING: derived from dashboard; keep common parts in sync */

import { URL } from './navigation'

const {
  LOGIN,
  LOGOUT,
  FORGOT_PASSWORD,
  ORDERS,
  PURCHASE_ORDERS,
  PURCHASE_ORDER_VARIANTS,
  SCANNER,
  CYCLE_COUNTS,
  CYCLE_COUNT_VARIANTS,
  CYCLE_COUNTS_CREATE,
} = URL

export const SITE_NAME = 'FastAF'
export const DEFAULT_TITLE_SUFFIX =
  'Shop the top brands with the fastest delivery'
export const DEFAULT_TITLE = `${SITE_NAME} - ${DEFAULT_TITLE_SUFFIX}`

export const PAGE_TITLES = {
  [LOGIN]: 'Login',
  [LOGOUT]: 'Logout',
  [FORGOT_PASSWORD]: 'Forgot Password',
  [ORDERS]: 'Orders',
  [PURCHASE_ORDERS]: 'Purchase Orders',
  [PURCHASE_ORDER_VARIANTS]: 'Purchase Order Variants',
  [SCANNER]: 'Scanner',
  [CYCLE_COUNTS]: 'Cycle Counts',
  [CYCLE_COUNT_VARIANTS]: 'Cycle Count Variants',
  [CYCLE_COUNTS_CREATE]: 'Create Cycle Counts',
}
