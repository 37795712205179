import React, { createContext, useReducer, useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import fromPairs from 'lodash/fromPairs'
import sum from 'lodash/sum'
import sumBy from 'lodash/sumBy'
import uniq from 'lodash/uniq'

import { CIRCLE_CHECK_STATUS } from 'constants/enums'
import { storageAvailable } from 'utils/general'
import { sortLineItems, getSortedBinList } from 'utils/bin'
import { preprocessLineItems } from 'utils/inventory'
import { STORAGE_INVENTORY_COUNT } from 'lib/config'

if (!storageAvailable('sessionStorage')) {
  console.error('This website requires sessionStorage enabled')
}

const { sessionStorage } = window

const ACTIONS = {
  UPDATE_ORDER: 'UPDATE_ORDER',
  CLEAR: 'CLEAR',
  CHECK_ITEM: 'CHECK_ITEM',
  UNCHECK_ITEM: 'UNCHECK_ITEM',
  FLAG_ITEM: 'FLAG_ITEM',
}

const getStatus = (checked, flagged) => {
  if (checked) {
    return CIRCLE_CHECK_STATUS.checked
  }
  if (flagged) {
    return CIRCLE_CHECK_STATUS.warning
  }
  return CIRCLE_CHECK_STATUS.unchecked
}

const hasSameItems = (a, b) => {
  const setA = new Set(a)
  return b.every(i => setA.has(i))
}

const getOrderKey = order => `order-${order.id}`

// Only save and load the counts.
const loadState = () => {
  try {
    const str = sessionStorage.getItem(STORAGE_INVENTORY_COUNT)
    if (!str) {
      return {}
    }
    return JSON.parse(str) ?? {}
  } catch (e) {
    console.error(
      `sessionStorage.getItem(${STORAGE_INVENTORY_COUNT}) failed`,
      e
    )
  }
  return {}
}

const saveState = ({ counts }) => {
  try {
    sessionStorage.setItem(STORAGE_INVENTORY_COUNT, JSON.stringify({ counts }))
  } catch (e) {
    console.error(
      `sessionStorage.setItem(${STORAGE_INVENTORY_COUNT}) failed`,
      e
    )
  }
}

const createStatusItems = (items, initialChecked, initialFlagged, allowExtra) =>
  items.flatMap(li => {
    const numberChecked = initialChecked[li.id]
    const numberFlagged = initialFlagged[li.id]
    return Array(
      Math.max(li.quantity, numberChecked + numberFlagged, allowExtra ? 2 : 0)
    )
      .fill(li)
      .map((item, idx) => {
        const checked = idx < numberChecked
        const flagged = !checked && idx < numberChecked + numberFlagged
        return [item.id, getStatus(checked, flagged)]
      })
  })

const updateWithOrder = (state, order, allowExtra) => {
  const orderKey = getOrderKey(order)
  const current = state.counts?.[orderKey]
  let newValues = {}

  if (
    !current ||
    Object.keys(current.checked)?.length !== order.line_items.length ||
    !hasSameItems(
      Object.keys(current.checked),
      order.line_items.map(li => String(li.id))
    ) ||
    // more are checked or flagged than there is quantity
    (!allowExtra &&
      order.line_items.some(
        li => li.quantity < current.flagged?.[li.id] + current.checked?.[li.id]
      ))
  ) {
    newValues = {
      [orderKey]: {
        checked: fromPairs(order.line_items.map(li => [li.id, 0])),
        flagged: fromPairs(order.line_items.map(li => [li.id, 0])),
      },
    }
  } else if (orderKey === state.orderKey) {
    // nothing relevant changed
    return state
  }

  const items = preprocessLineItems(order.line_items)
  const counts = {
    ...state.counts,
    ...newValues,
  }

  return {
    orderKey,
    items,
    statusItems: createStatusItems(
      items,
      counts[orderKey].checked,
      counts[orderKey].flagged,
      allowExtra
    ),
    counts,
  }
}

const getCounts = statusItems => {
  const ids = uniq(statusItems.map(si => si[0]))
  const checked = fromPairs(ids.map(id => [id, 0]))
  const flagged = fromPairs(ids.map(id => [id, 0]))
  // eslint-disable-next-line no-restricted-syntax
  for (const [id, status] of statusItems) {
    if (status === CIRCLE_CHECK_STATUS.checked) {
      checked[id] += 1
    }
    if (status === CIRCLE_CHECK_STATUS.warning) {
      flagged[id] += 1
    }
  }
  return { checked, flagged }
}

// Reducer
// state: {
//   orderKey,
//   items: [...],
//   statusItems: [[id, status], ],
//   counts: {
//     orderKey: {
//       checked: { id: number, ...}
//       flagged: { id: number, ...}
//   }
// }
const reducer = (state, action) => {
  const { type, payload } = action
  const { orderKey } = state ?? {}
  const { statusIdx, allowExtra } = payload ?? {}

  const updateItem = newState => {
    // eslint-disable-next-line no-param-reassign
    state.statusItems[statusIdx][1] = newState
    if (
      allowExtra &&
      statusIdx + 2 >= state.statusItems.length &&
      newState !== CIRCLE_CHECK_STATUS.unchecked
    ) {
      // this only makes sense if all items are the same variant
      state.statusItems.push([
        state.statusItems[statusIdx][0],
        CIRCLE_CHECK_STATUS.unchecked,
      ])
    }
    // eslint-disable-next-line no-param-reassign
    state.counts[orderKey] = getCounts(state.statusItems)
    saveState(state)
    return { ...state }
  }

  switch (type) {
    case ACTIONS.UPDATE_ORDER: {
      return updateWithOrder(state, payload.order, allowExtra)
    }

    case ACTIONS.CLEAR: {
      return {
        orderKey: null,
        items: [],
        statusItems: [],
        counts: state.counts ?? {},
      }
    }

    case ACTIONS.CHECK_ITEM: {
      return updateItem(CIRCLE_CHECK_STATUS.checked)
    }

    case ACTIONS.UNCHECK_ITEM: {
      return updateItem(CIRCLE_CHECK_STATUS.unchecked)
    }

    case ACTIONS.FLAG_ITEM: {
      return updateItem(CIRCLE_CHECK_STATUS.warning)
    }
    case ACTIONS.BIN_EMPTY: {
      const items = state.items.map(i => {
        return {
          ...i,
          bin: i.id === state.statusItems[statusIdx][0] ? i.bins[1] : i.bin,
          bins: i.id === state.statusItems[statusIdx][0] ? i.bins.slice(1) : i.bins,
        }
      }).sort(sortLineItems)
      return {
        ...state,
        items,
        statusItems: createStatusItems(
          items,
          state.counts[orderKey].checked,
          state.counts[orderKey].flagged,
          allowExtra
        ),
      }
    }

    default:
      throw new Error('Unknown action type dispatched')
  }
}

export const InventoryCountContext = createContext()

export const InventoryCountProvider = ({ children, order, allowExtra }) => {
  const orderKey = getOrderKey(order)
  const loadedState = useMemo(() => loadState(), [])
  const [state, dispatch] = useReducer(reducer, loadedState)

  const { items, statusItems, counts } = state ?? {}
  const checkedItems = counts?.[orderKey]?.checked
  const flaggedItems = counts?.[orderKey]?.flagged

  const getItem = id => items.find(item => item.id === id)

  useEffect(() => {
    if (!order?.line_items) {
      dispatch({ type: ACTIONS.CLEAR })
      return
    }
    dispatch({ type: ACTIONS.UPDATE_ORDER, payload: { order, allowExtra } })
  }, [order])

  const checkItem = statusIdx =>
    dispatch({
      type: ACTIONS.CHECK_ITEM,
      payload: { statusIdx, allowExtra },
    })

  const flagItem = statusIdx =>
    dispatch({
      type: ACTIONS.FLAG_ITEM,
      payload: { statusIdx, allowExtra },
    })

  const unsetItem = statusIdx =>
    dispatch({
      type: ACTIONS.UNCHECK_ITEM,
      payload: { statusIdx },
    })

  const binEmpty = statusIdx =>
    dispatch({
      type: ACTIONS.BIN_EMPTY,
      payload: { statusIdx, allowExtra },
    })

  const value = useMemo(() => {
    if (!state?.orderKey || !checkedItems) {
      return { loaded: false }
    }
    const itemCounts = items.map(item => ({
      item,
      quantity: item.quantity,
      numberChecked: checkedItems[item.id],
      numberFlagged: flaggedItems[item.id],
      bins: getSortedBinList(item.bin)
    }))

    const itemSingles = statusItems.map(([itemId, status], statusIdx) => ({
      item: getItem(itemId),
      checked: status === CIRCLE_CHECK_STATUS.checked,
      flagged: status === CIRCLE_CHECK_STATUS.warning,
      status,
      check: () => checkItem(statusIdx),
      flag: () => flagItem(statusIdx),
      unset: () => unsetItem(statusIdx),
      binEmpty: () => binEmpty(statusIdx)
    })).sort((a, b) => sortLineItems(a.item, b.item))

    const totalQuantity = items ? sumBy(items, li => li.quantity) : 0
    const totalChecked = checkedItems ? sum(Object.values(checkedItems)) : 0
    const totalFlagged = flaggedItems ? sum(Object.values(flaggedItems)) : 0

    return {
      loaded: true,
      checkItem,
      flagItem,
      unsetItem,
      items,
      itemCounts,
      itemSingles,
      totalQuantity,
      totalChecked,
      totalFlagged,
      binEmpty,
      allCheckedOrFlagged: totalQuantity <= totalChecked + totalFlagged,
    }
  }, [state])

  return (
    <InventoryCountContext.Provider value={value}>
      {children}
    </InventoryCountContext.Provider>
  )
}

InventoryCountProvider.propTypes = {
  children: PropTypes.node,
  order: PropTypes.object,
  allowExtra: PropTypes.bool, // only use this if all items are the same variant
}
