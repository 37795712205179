import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import HttpsRedirect from 'react-https-redirect'
import { StylesProvider } from '@material-ui/styles'

import SuspenseSpinner from 'components/SuspenseSpinner'

import App from 'App'
import AppProvider from 'context/AppProvider'

const rootElement = document.getElementById('root')

ReactDOM.render(
  <HttpsRedirect>
    <Suspense fallback={<SuspenseSpinner />}>
      <BrowserRouter>
        <AppProvider>
          <StylesProvider injectFirst>
            <App />
          </StylesProvider>
        </AppProvider>
      </BrowserRouter>
    </Suspense>
  </HttpsRedirect>,
  rootElement
)
