const jssStyles = theme => ({
  datePicker: {
    '& .MuiPickersCalendarHeader-monthTitleContainer': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }
  },
  label: {
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(1)
  },
  labelError: {
    color: theme.palette.warnings.danger
  },
  menuPaper: {
    background: theme.palette.background.paper,
    borderRadius: 5
  },
  disabled: {
    opacity: 0.65
  },
  input: {
    background: theme.palette.background.default,
    color: theme.palette.text.primary,
    borderRadius: 5,

    '&.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.error.main
    }
  },
  centeredInput: {
    padding: '20px 12px 20px'
  },
  centeredInputDense: {
    padding: '10px 12px 10px !important'
  },
  formControl: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    border: 0,
    margin: 0,
    display: 'inline-flex',
    padding: 0,
    position: 'relative',
    minWidth: 0,
    flexDirection: 'row',
    verticalAlign: 'top',
    width: '100%'
  }
})

export default jssStyles
