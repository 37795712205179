import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import 'moment-duration-format'
import { Box } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { deliveryIsSoon } from 'helpers/order'
import styles from './DeliveryTimerStyles'

const DeliveryTimer = ({
  classes,
  deliveryStartsAt,
  fontSize,
  normalColor,
  urgentColor,
  ...props
}) => {
  const [now, setNow] = useState(moment())

  // also forces the timer to tick every second
  useEffect(() => {
    const timer = setInterval(() => {
      setNow(moment())
    }, moment.duration(1, 'seconds').as('milliseconds'))
    return () => clearInterval(timer)
  }, [])

  const isUrgent = deliveryIsSoon(now, deliveryStartsAt)

  const timeUntilStart =
    deliveryStartsAt && moment.duration(moment(deliveryStartsAt).diff(now))

  const timerFormat =
    timeUntilStart &&
    timeUntilStart.format('d[:]hh[:]mm[:]ss', {
      stopTrim: 'm',
    })

  const textStyle = {
    fontSize,
    color: isUrgent ? urgentColor : normalColor,

    // Hide component if not urgent (don't interrupt element flow)
    visibility: isUrgent ? 'show' : 'hidden',
  }

  return (
    <Box className={classes.root} style={textStyle} {...props}>
      {deliveryStartsAt ? timerFormat : '--:--'}
    </Box>
  )
}

DeliveryTimer.defaultProps = {
  normalColor: 'black',
  urgentColor: '#E52B20',
}

DeliveryTimer.propTypes = {
  classes: PropTypes.object.isRequired,
  deliveryStartsAt: PropTypes.string,
  fontSize: PropTypes.number,
  normalColor: PropTypes.string,
  urgentColor: PropTypes.string,
}

export default withStyles(styles)(DeliveryTimer)
