const jssStyles = theme => ({
  root: {
    width: '100%',
    padding: 16,
    marginBottom: 70,
    position: 'relative',
  },
  closeBtn: {
    width: 48,
    height: 48,
    background: theme.palette.primary.main,
    padding: 0,
    position: 'fixed',
    right: 10,
    zIndex: 999,
  },
  drawer: {
    background: '#FFFFFF',
    boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.15)',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  closeContainer: {
    position: 'absolute',
    top: -66,
  },
})

export default jssStyles
