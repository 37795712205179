import React from 'react'
import PropTypes from 'prop-types'

const FlagIcon = ({ fill, size, className }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 19 19"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.86461 2C2.52571 2 2.25098 2.27982 2.25098 2.625V16.375C2.25098 16.7202 2.52571 17 2.86461 17C3.20351 17 3.47825 16.7202 3.47825 16.375V11.375H7.7737V12.625C7.7737 12.9702 8.04844 13.25 8.38734 13.25H15.1373C15.3711 13.25 15.5846 13.1147 15.6879 12.9011C15.7911 12.6875 15.7663 12.4327 15.6237 12.2439L12.8433 8.5625L15.6237 4.88107C15.7663 4.69234 15.7911 4.43752 15.6879 4.22391C15.5846 4.01029 15.3711 3.875 15.1373 3.875H9.00097V2.625C9.00097 2.27982 8.72624 2 8.38734 2H2.86461ZM7.7737 6.375C7.7737 6.72018 8.04844 7 8.38734 7C8.72624 7 9.00097 6.72018 9.00097 6.375V5.125H13.8911L11.5828 8.18143C11.4131 8.40609 11.4131 8.71891 11.5828 8.94357L13.8911 12H9.00097V10.75C9.00097 10.4048 8.72624 10.125 8.38734 10.125H3.47825V3.25H7.7737V6.375Z"
      fill={fill}
    />
  </svg>
)

FlagIcon.defaultProps = {
  size: 20,
  fill: 'currentColor',
}

FlagIcon.propTypes = {
  fill: PropTypes.string,
  size: PropTypes.number,
  className: PropTypes.string,
}

export default FlagIcon
