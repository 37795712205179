import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import {
  SwipeableDrawer,
  Box,
  ListItem,
  ListItemText,
  Fab,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import styles from './BottomDrawerStyles'

const MenuListItem = ({ classes, name, onClick, ...props }) => (
  <ListItem className={classes.root} button onClick={onClick} {...props}>
    <ListItemText classes={{ primary: classes.text }} primary={name} />
  </ListItem>
)

MenuListItem.propTypes = {
  classes: PropTypes.object,
  title: PropTypes.string,
  onClick: PropTypes.func,
  name: PropTypes.string,
}

const BottomDrawer = ({
  classes,
  Header,
  isOpen,
  onOpen,
  onClose,
  children,
}) => (
  <>
    <SwipeableDrawer
      anchor="bottom"
      open={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      classes={{ paper: classes.drawer }}
    >
      <Box className={classes.root}>
        {onClose && (
          <Box className={classes.closeContainer}>
            <Fab onClick={onClose} className={classes.closeBtn}>
              <CloseIcon style={{ color: 'white' }} />
            </Fab>
          </Box>
        )}
        {Header}
        {children}
      </Box>
    </SwipeableDrawer>
  </>
)

BottomDrawer.defaultProps = {
  onOpen: () => null,
}

BottomDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  Header: PropTypes.node,
  isOpen: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  children: PropTypes.node,
}

export default withStyles(styles)(BottomDrawer)
