import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import Typography from '@material-ui/core/Typography'

import { TEXT_FIELD_TYPES } from 'constants/enums'
import { binToTier, sortBinList } from 'utils/bin'
import { MaskedTextField } from 'components/TextField'

import styles from './BinSelectorStyles'

const BIN_TIERS = [
  { label: 'M', value: 'M-', mask: '`M`-0[0][0][0].a.0' },
  { label: '#.X.#', value: 'bins', mask: '0[0][0][0].a.0' },
  { label: 'FR', value: 'FR-', mask: '`F`R`-0[0][0][0]' },
  { label: 'FRZ', value: 'FRZ-', mask: '`F`R`Z`-0[0][0][0]' },
  { label: 'RSV', value: 'RSV-', mask: '`R`S`V`-0[0][0][0]' },
  { label: 'PA', value: 'PA-', mask: '`P`A`-0[0][0][0]' },
]

const BinSelector = ({ classes, onScan, header, children, currentBins }) => {
  const [binInput, setBinInput] = useState('')
  const [binTier, setBinTier] = useState(BIN_TIERS[1].value)
  const binTierComplete = BIN_TIERS.find(({ value }) => value === binTier)
  const [newValue, setNewValue] = useState('')

  const handleSubmit = () => {
    setBinInput('')
    onScan(binInput)
  }

  const handleAccept = (value, imask) => {
    setBinInput(value.toUpperCase())
    if (imask.masked.isComplete) {
      setNewValue(value)
    } else if (newValue) {
      setNewValue('')
    }
  }

  const handlePreSelect = bin => {
    const newBinTier = binToTier(bin)
    setBinTier(BIN_TIERS.find(({ value }) => value === newBinTier).value)
    setBinInput(bin)
  }

  const currentBinsSorted = currentBins.sort(sortBinList)

  return (
    <Box className={classes.root}>
      {header}
      <Box className={classes.container}>
        <ToggleButtonGroup
          color="primary"
          value={binTier}
          exclusive
          onChange={(_, tier) =>
            tier &&
            setBinTier(BIN_TIERS.find(({ value }) => value === tier).value)
          }
        >
          {BIN_TIERS.map(({ label, value }) => (
            <ToggleButton key={value} value={value}>
              {label}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
        <MaskedTextField
          data-test="bin-field"
          name="bin"
          fullWidth
          value={binInput}
          type={TEXT_FIELD_TYPES.text}
          onAccept={handleAccept}
          mask={binTierComplete.mask}
          lazy={false}
          overwrite
          inputClassName={classes.barcodeInput}
          className={classes.textField}
          InputProps={{ autoComplete: 'off' }}
        />
        <Box display="flex" flexDirection="column" marginBottom={2}>
          <Typography display="inline">Existing Bins:</Typography>
          <ButtonGroup
            color="secondary"
            aria-label="outlined secondary button group"
          >
            {currentBinsSorted.map(bin => (
              <Button key={bin} onClick={() => handlePreSelect(bin)}>
                {bin}
              </Button>
            ))}
          </ButtonGroup>
        </Box>
        <Button
          data-test="manual-submit"
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleSubmit}
          disabled={!newValue}
        >
          Submit
        </Button>
      </Box>
      {children}
    </Box>
  )
}

BinSelector.defaultProps = {
}

BinSelector.propTypes = {
  classes: PropTypes.object.isRequired,
  onScan: PropTypes.func,
  header: PropTypes.node,
  children: PropTypes.node,
  currentBins: PropTypes.arrayOf(PropTypes.string),
}

export default withStyles(styles)(BinSelector)
