const jssStyles = theme => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  message: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1, 2),
    width: '100%',
  },
  title: {
    textAlign: 'center',
    fontSize: 26,
    lineHeight: '32px',
    fontWeight: 400,
    marginBottom: theme.spacing(1),
  },
  subtitle: {
    width: '100%',
    textAlign: 'center',
    fontSize: 14,
    lineHeight: '21px',
    fontWeight: 400,
  },
  children: {
    padding: theme.spacing(2, 0),
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  actionContainer: {
    height: 101,
    width: '100%',
    borderTop: `1px solid ${theme.palette.colorsAF.semiGray}`,
    padding: theme.spacing(3, 2, 2),
  },
  errorActionButton: {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.primary.contrastText,
  },
  top: {
    display: 'flex',
    alignItems: 'center',
  },
  iconBox: {
    width: 105,
    height: 105,
    borderRadius: 105,
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 24,
  },
  icon: {
    color: theme.palette.primary.contrastText,
    fontSize: 60,
  },
})

export default jssStyles
