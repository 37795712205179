import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import { Fab } from '@material-ui/core'

import styles from './FloatingFabStyles'

const FloatingFab = ({ classes, className, onClick, children }) => (
  <Fab
    className={classNames({
      [classes.root]: true,
      [className]: Boolean(className),
    })}
    onClick={onClick}
  >
    {children}
  </Fab>
)

FloatingFab.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  toggleIsOpen: PropTypes.func,
  onClick: PropTypes.func,
  children: PropTypes.node,
}

export default withStyles(styles)(FloatingFab)
