/* WARNING: copied from dashboard; keep in sync */

import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { DEFAULT_TITLE, SITE_NAME } from 'constants/titles'

const TitleElement = ({ title, useSiteName = true }) => (
  <Helmet
    defaultTitle={DEFAULT_TITLE}
    titleTemplate={useSiteName ? `${SITE_NAME} - %s` : '%s'}
  >
    <title>{title}</title>
  </Helmet>
)

TitleElement.propTypes = {
  title: PropTypes.string,
  useSiteName: PropTypes.bool,
}

export default TitleElement
