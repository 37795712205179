const jssStyles = theme => ({
  root: {
    width: '100%',
    margin: '0',
    padding: theme.spacing(2.5, 2, 2.5, 2),
  },
  radio: {
    padding: 0,
  },
  unchecked: {
    width: 20,
    height: 20,
    borderRadius: 10,
    border: `1px solid ${theme.palette.border.default}`,
  },
  label: {
    width: '100%',
    paddingLeft: theme.spacing(4),
  },
  disabled: {
    opacity: 0.65,
  },
})

export default jssStyles
