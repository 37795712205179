import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useHistory } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import Fab from '@material-ui/core/Fab'
import Icon from 'assets/scanner_button.png'

import styles from './ScannerFabStyles'

const ScannerFab = ({ classes, className }) => {
  const history = useHistory()
  return (
    <Fab
      disabled
      className={classNames({
        [classes.root]: true,
        [className]: Boolean(className),
      })}
      onClick={() => history.push('/scanner')}
    >
      <img src={Icon} alt="Scanner Icon" />
    </Fab>
  )
}

ScannerFab.defaultProps = {}

ScannerFab.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
}

export default withStyles(styles)(ScannerFab)
