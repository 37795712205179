import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { useHistory } from 'react-router-dom'
import {
  Box,
  Typography,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Fab,
} from '@material-ui/core'
import { ExpandLess } from '@material-ui/icons'
import SearchIcon from '@material-ui/icons/Search'
import Skeleton from '@material-ui/lab/Skeleton'

import Header from 'components/Header'
import PurchaseOrderStatus from 'components/PurchaseOrderStatus'
import LineItem from 'components/LineItem'
import Scanner from 'components/Scanner'

import { useAdminTransferOrder, useAlerts, useRestoreScroll } from 'hooks'

import {
  SHIPMENT_ITEM_STATE_TYPES,
  TRANSFER_ORDER_STATE_TYPES,
} from 'constants/transferOrders'
import { URL } from 'constants/navigation'

import styles from './TransferOrderShowScreenStyles'
import { loadState } from './helpers'

// Create our number formatter.
const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})

const SummaryRow = ({ classes, label, amount }) => (
  <>
    <Typography variant="body2" className={classes.spaceTop}>
      {label}
    </Typography>
    <Box className={classes.spaceBetween}>
      <Typography variant="body2" className={classes.dark}>
        Total items {label.toLowerCase()}
      </Typography>
      <Typography variant="body2">{amount}</Typography>
    </Box>
  </>
)

SummaryRow.propTypes = {
  label: PropTypes.string,
  amount: PropTypes.string,
  classes: PropTypes.object,
}

const TransferOrderShowScreen = ({
  classes,
  match: {
    params: { id },
  },
}) => {
  const history = useHistory()
  const { showAlertError } = useAlerts()
  const listRef = useRef(null)
  useRestoreScroll(listRef, 'transfer-order-show')
  const [expanded, setExpanded] = useState(false)
  const [productFinderVisible, setProductFinderVisible] = useState(false)

  const { transferOrder } = useAdminTransferOrder(parseInt(id, 10))

  const shipmentItems =
    transferOrder?.shipment_items?.sort((a, b) => a.id - b.id) ?? []
  const shipmentItemsState = loadState().to?.[id] ?? {}

  const variantSummaries = transferOrder?.shipment_items.reduce(
    (acc, { variant: { price }, ...shipment_item }) => {
      const {
        damaged: thisDamaged,
        quantity: thisQuantity,
        received: thisReceived,
      } = shipment_item

      return {
        damaged: acc?.damaged + thisDamaged,
        quantity: acc?.quantity + thisQuantity,
        received: acc?.received + thisReceived,
        totalPrice: acc?.totalPrice + Number(price) * thisQuantity,
      }
    },
    {
      damaged: 0,
      quantity: 0,
      received: 0,
      totalPrice: 0,
    }
  )

  const {
    damaged = 0,
    quantity = 0,
    totalPrice = 0,
    received = 0,
  } = variantSummaries ?? {}

  const lineItems = shipmentItems?.map(({ variant, ...shipment_item }) => {
    const variantReceivedCount = shipment_item?.received ?? 0
    const variantDamagedCount = shipment_item?.damaged ?? 0
    const variantQuantity = shipment_item?.quantity
    const status =
      shipmentItemsState?.[shipment_item?.id]?.status ??
      SHIPMENT_ITEM_STATE_TYPES.readyToReceive

    return {
      id: variant?.id,
      numberPicked: variantReceivedCount,
      numberDamaged: variantDamagedCount,
      numberMissing:
        status !== SHIPMENT_ITEM_STATE_TYPES.assigningBinsComplete &&
        status !== SHIPMENT_ITEM_STATE_TYPES.scanningComplete
          ? 0
          : variantQuantity - variantReceivedCount - variantDamagedCount,
      complete: variantReceivedCount + variantDamagedCount === variantQuantity,
      quantity: variantQuantity,
      price: variant?.price,
      images: variant?.images,
      barcode: variant?.barcode,
      bin: variant?.available_for_sale_inventory?.bin,
      product: variant?.product,
      variant,
      sku: variant?.sku,
      color: variant?.color?.label,
      size: variant?.size,
      status,
    }
  })

  const missing =
    transferOrder?.status !== TRANSFER_ORDER_STATE_TYPES.submitted
      ? Math.max(0, (quantity || 0) - ((received || 0) + (damaged || 0)))
      : 0

  const handleContinueClick = () => {
    let variantId = null

    const firstReadyToRecieve = shipmentItems.filter(
      shipmentItem =>
        !Object.keys(shipmentItemsState)
          .map(key => Number(key))
          .includes(shipmentItem.id)
    )?.[0]?.variant?.id

    if (firstReadyToRecieve) {
      variantId = firstReadyToRecieve
    }

    const firstScanningStarted = Object.entries(shipmentItemsState)
      .map(([key, value]) =>
        value.status === SHIPMENT_ITEM_STATE_TYPES.scanningStarted
          ? shipmentItems.filter(
              shipmentItem => shipmentItem.id === Number(key)
            )
          : false
      )
      .flat()
      .filter(Boolean)?.[0]?.variant?.id

    if (firstScanningStarted) {
      variantId = firstScanningStarted
    }

    const firstScanningComplete = Object.entries(shipmentItemsState)
      .map(([key, value]) =>
        value.status === SHIPMENT_ITEM_STATE_TYPES.scanningComplete
          ? shipmentItems.filter(
              shipmentItem =>
                shipmentItem.id === Number(key) && value.received > 0
            )
          : false
      )
      .flat()
      .filter(Boolean)?.[0]?.variant?.id

    if (firstScanningComplete) {
      variantId = firstScanningComplete
    }

    if (typeof variantId === 'number') {
      history.push(
        `${URL.TRANSFER_ORDERS}/${id}${URL.TRANSFER_ORDER_VARIANTS}/${variantId}`
      )
    }
  }

  const cannotContinueFurther =
    Object.keys(shipmentItemsState).length === shipmentItems.length &&
    Object.values(shipmentItemsState).filter(
      i => i.status === SHIPMENT_ITEM_STATE_TYPES.assigningBinsComplete
    ).length === shipmentItems.length

  const handleProductScan = code => {
    const item = shipmentItems.find(
      i => i.variant.barcode === code || i.variant.dsin === code
    )
    if (item) {
      history.push(
        `${URL.TRANSFER_ORDERS}/${id}${URL.TRANSFER_ORDER_VARIANTS}/${item.variant.id}`
      )
    } else {
      showAlertError('Barcode not found')
    }
  }

  return productFinderVisible ? (
    <Scanner
      title="Find Product"
      onScan={handleProductScan}
      onClose={() => setProductFinderVisible(false)}
      numbersOnly={false}
      verifyRemainder={false}
    />
  ) : (
    <Box className={classes.root}>
      <Header
        onBackHistoryBack
        breadcrumbs={[
          { text: 'TO', href: URL.TRANSFER_ORDERS },
          { text: `#${id}`, active: true },
        ]}
      />
      <Box className={classes.content}>
        <Box className={classes.lineItems} ref={listRef}>
          {lineItems.map(lineItem => (
            <LineItem
              key={lineItem.id}
              lineItem={lineItem}
              showQuantity
              showCounts
              numberChecked={lineItem.numberPicked}
              numberDamaged={lineItem.numberDamaged}
              numberMissing={lineItem.numberMissing}
              quantity={lineItem.quantity}
              complete={lineItem.complete}
              showStatus
              setMissing={null}
              missing={missing}
              showPrice={false}
              showSku
              showColor
              showSize
              onClick={() =>
                history.push(
                  `${URL.TRANSFER_ORDERS}/${id}${URL.TRANSFER_ORDER_VARIANTS}/${lineItem.id}`
                )
              }
            />
          ))}
        </Box>
        <Fab
          className={classes.searchIcon}
          data-test="po-search"
          onClick={() => setProductFinderVisible(true)}
        >
          <SearchIcon style={{ color: 'white' }} />
        </Fab>
        <Box className={expanded ? classes.detailsExpanded : classes.details}>
          <Accordion
            className={classes.accordionRoot}
            square
            elevation={0}
            expanded={expanded}
            onChange={() => setExpanded(!expanded)}
          >
            <AccordionSummary
              classes={{
                root: classes.summaryRoot,
                content: classes.summaryContentRoot,
              }}
              expandIcon={<ExpandLess />}
            >
              <Box className={classes.summaryRow}>
                <Box>
                  <Typography variant="body2">Status</Typography>
                  {transferOrder?.status ? (
                    <PurchaseOrderStatus status={transferOrder.status} />
                  ) : (
                    <Skeleton width="60px" />
                  )}
                </Box>
                <Typography variant="body2">
                  {received || 0} Received
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails className={classes.detailsContainer}>
              <Typography variant="body2">Ordered</Typography>
              {!(Boolean(transferOrder) && shipmentItems.length !== 0) && (
                <Skeleton width="340px" />
              )}
              {shipmentItems.map(shipment_item => (
                <Box
                  key={shipment_item?.id}
                  className={classNames(classes.orderedRow, classes.dark)}
                >
                  <Typography variant="body1">
                    {[
                      shipment_item?.variant?.product.title,
                      shipment_item?.variant?.color?.label,
                      shipment_item?.variant?.size,
                    ]
                      .filter(variant => Boolean(variant))
                      .join(', ')
                      .substring(0, 32)}
                    ...
                  </Typography>
                  <Typography variant="body1" className={classes.orderedValue}>
                    ${shipment_item?.variant?.price} x {shipment_item?.quantity}
                  </Typography>
                </Box>
              ))}
              <Box
                className={classNames(classes.spaceBetween, classes.spaceTop)}
              >
                <Typography variant="body2">Quantity</Typography>
                <Typography variant="body2">{quantity}</Typography>
              </Box>
              <Box className={classes.spaceBetween}>
                <Typography variant="body2">Total</Typography>
                <Typography variant="body2">
                  {currencyFormatter.format(totalPrice)}
                </Typography>
              </Box>
              <Divider className={classes.divider} />
              {[
                { label: 'Received', amount: `${received ?? 0}` },
                { label: 'Damaged', amount: `${damaged ?? 0}` },
                { label: 'Missing', amount: `${missing ?? 0}` },
              ].map(summary => (
                <SummaryRow
                  key={summary.label}
                  {...{
                    ...summary,
                    classes,
                  }}
                />
              ))}
            </AccordionDetails>
          </Accordion>
          <Divider className={classes.closeDivider} />
          {!cannotContinueFurther && (
            <Button
              data-test="continue-button"
              variant="contained"
              onClick={handleContinueClick}
              color="primary"
              fullWidth
              disabled={
                transferOrder?.status === TRANSFER_ORDER_STATE_TYPES.closed
              }
            >
              {transferOrder?.status === TRANSFER_ORDER_STATE_TYPES.closed
                ? 'Closed'
                : 'Continue'}
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  )
}

TransferOrderShowScreen.propTypes = {
  classes: PropTypes.object,
  match: PropTypes.object,
}

export default withStyles(styles)(TransferOrderShowScreen)
