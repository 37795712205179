import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'

import EmptyIcon from '@material-ui/icons/NotInterested'

import Button from '@material-ui/core/Button'

import {
  useAdminOrders,
  useAdminPrinters,
  useAdminItemLocation,
  useAdminPredefinedParcelList,
} from 'hooks'
import {
  InventoryCountProvider,
  useInventoryCountContext,
  useLocalStorageContext,
} from 'context'
import { ORDER_STATES_TYPES } from 'constants/general'
import { PRINTER_TYPE } from 'constants/enums'
import { waitAtLeastSec } from 'utils/general'
import { IS_PRODUCTION } from 'lib/config'
import OrderView from 'components/OrderView'
import InventoryCount from 'components/InventoryCount'
import PrintingSplash from 'components/PrintingSplash'
import ScheduleDispatch from 'components/ScheduleDispatch'
import PrepareShipment from 'components/PrepareShipment'

import FlagIcon from 'icons/FlagIcon'

const HideUntilContextLoaded = ({ children }) => {
  const { loaded } = useInventoryCountContext()
  return loaded ? children : null
}

HideUntilContextLoaded.propTypes = {
  children: PropTypes.node,
}

const SHOW_MODE = {
  VIEW: 'VIEW',
  SCAN: 'SCAN',
  PREPARE_SHIPMENT: 'PREPARE_SHIPMENT',
  DISPATCH: 'DISPATCH',
  PRINT: 'PRINT',
}

const OrderShowScreen = ({
  match: {
    params: { id },
  },
}) => {
  const { locationId, printerId } = useLocalStorageContext()
  const [mode, setMode] = useState(SHOW_MODE.VIEW)
  const [hasPrinted, setHasPrinted] = useState(false)
  const [printerError, setPrinterError] = useState(null)
  const {
    order,
    findOrder,
    updateOrderStatus,
    updateOrderDeliveryMethod,
    printOrderLabel,
    inCycleCount,
    lookupOrder,
    short,
  } = useAdminOrders({ id })

  const { pick } = useAdminItemLocation()

  // using this to populate the RQ cache so it loads fast later
  useAdminPrinters({ locationId, type: PRINTER_TYPE.LABEL })
  useAdminPredefinedParcelList()

  useEffect(() => {
    id && findOrder(id)
  }, [id])

  const setModeView = () => setMode(SHOW_MODE.VIEW)
  const setModeScan = () => setMode(SHOW_MODE.SCAN)
  const setModePrint = () => setMode(SHOW_MODE.PRINT)
  const setModePrepareShipment = () => setMode(SHOW_MODE.PREPARE_SHIPMENT)
  const setModeDispatch = () => setMode(SHOW_MODE.DISPATCH)

  const updateToPicking = async () => {
    await updateOrderStatus(id, ORDER_STATES_TYPES.picking, {
      notifySuccess: false,
    })
    setModeScan()
    await findOrder(id)
  }

  const maybeUpdateToPicking = async (
    openPreventCycleCountDialog,
    setExtra,
    openPickingStartedDialog
  ) => {
    const cycleCountVariants = await inCycleCount(id)
    if (cycleCountVariants.length > 0) {
      setExtra({ message: `Cycle Counts: ${cycleCountVariants.join(',')}` })
      openPreventCycleCountDialog()
      return
    }
    if (order.status === ORDER_STATES_TYPES.paid) {
      const updatedOrder = await lookupOrder(id)
      if (updatedOrder.status === ORDER_STATES_TYPES.picking) {
        openPickingStartedDialog()
        return
      }
    }
    updateToPicking()
  }

  const print = async () => {
    setPrinterError(null)
    setHasPrinted(false)
    setModePrint()
    let err
    if (IS_PRODUCTION) {
      err = await waitAtLeastSec(1, () => printOrderLabel(id, printerId))
    }

    if (err) {
      setPrinterError(err?.data?.message ?? 'Unknown')
    } else {
      setHasPrinted(true)
    }
  }

  const updateToDispatched = async (
    deliveryMethod,
    roadieOrderSize,
    itemSingles
  ) => {
    const shorted = {}
    const checkedGrouped = {}
    itemSingles.forEach(({ item: { bin, variant }, checked, flagged }) => {
      if (checked) {
        const key = variant.id + bin
        checkedGrouped[key] = {
          location_id: locationId,
          label: bin,
          quantity: (checkedGrouped[key]?.quantity || 0) + 1,
          variant_id: variant.id,
        }
      } else if (flagged) {
        shorted[variant.id] = {
          variant_id: variant.id,
          sku: variant.sku,
          quantity: (shorted[variant.id]?.quantity || 0) + 1,
        }
      }
    })

    if (!isEmpty(shorted)) {
      short(id, Object.values(shorted))
    }
    if (!isEmpty(checkedGrouped)) {
      Object.values(checkedGrouped).forEach(({ label, quantity, variant_id }) =>
        pick({
          location_id: locationId,
          label,
          quantity,
          variant_id,
        })
      )
    }
    const err = await updateOrderDeliveryMethod(
      id,
      deliveryMethod,
      roadieOrderSize,
      {
        notify: false,
      }
    )

    if (err) {
      setModeDispatch()
      return err
    }
    await findOrder(id)
    print()
  }

  const pickerActions = ({
    currentItem,
    handleFlag,
    handleBinEmpty,
    className,
  }) =>
    currentItem.item.bins.length > 1 ? (
      <Button
        fullWidth
        variant="contained"
        size="large"
        disabled={!currentItem || currentItem.checked || currentItem.flagged}
        startIcon={<EmptyIcon />}
        onClick={handleBinEmpty}
        className={className}
        data-test="bin-empty-button"
      >
        Bin Empty
      </Button>
    ) : (
      <Button
        fullWidth
        variant="contained"
        size="large"
        disabled={!currentItem || currentItem.checked || currentItem.flagged}
        startIcon={<FlagIcon fill="red" />}
        onClick={handleFlag}
        className={className}
        data-test="flag-short-button"
      >
        Flag Short
      </Button>
    )

  const view =
    {
      [SHOW_MODE.VIEW]: (
        <OrderView
          order={order}
          onClickMaybeStartPicking={maybeUpdateToPicking}
          onClickStartPicking={updateToPicking}
          onClickContinuePicking={setModeScan}
          onClickPrint={print}
          onClickPrepareShipment={setModePrepareShipment}
          onClickDispatch={setModeDispatch}
        />
      ),
      [SHOW_MODE.SCAN]: (
        <InventoryCount actions={pickerActions} onClose={setModeView} />
      ),
      [SHOW_MODE.PREPARE_SHIPMENT]: (
        <PrepareShipment
          order={order}
          onComplete={setModeDispatch}
          onClose={setModeView}
        />
      ),
      [SHOW_MODE.DISPATCH]: (
        <ScheduleDispatch
          order={order}
          updateToDispatched={updateToDispatched}
          onClose={setModeView}
        />
      ),
      [SHOW_MODE.PRINT]: (
        <PrintingSplash
          order={order}
          error={printerError}
          hasPrinted={hasPrinted}
          print={print}
          onClose={setModeView}
        />
      ),
    }[mode] ?? null

  return (
    <InventoryCountProvider order={order}>
      <HideUntilContextLoaded>{view}</HideUntilContextLoaded>
    </InventoryCountProvider>
  )
}

OrderShowScreen.propTypes = {
  match: PropTypes.object,
}
export default OrderShowScreen
