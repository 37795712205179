import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import UPSIcon from 'icons/UPSIcon/UPSIcon'
import GLSIcon from 'icons/GLSIcon/GLSIcon'

import Box from '@material-ui/core/Box'
import Select from '@material-ui/core/Select'

import { ROADIE_ORDER_SIZES, DELIVERY_METHOD_KEYS } from 'constants/enums'
import RadioGroup from 'components/RadioGroup'
import MessageActionView from 'components/MessageActionView'
import PrinterSelect from 'components/PrinterSelect'
import { useInventoryCountContext } from 'context'

import styles from './ScheduleDispatchStyles'

const roadieSizeOptions = [
  { label: 'Small', value: ROADIE_ORDER_SIZES.small },
  { label: 'Medium', value: ROADIE_ORDER_SIZES.medium },
]

const dispatchOptionsMap = {
  doordash: { label: 'Doordash Driver', value: DELIVERY_METHOD_KEYS.doordash },
  fastAF: { label: 'Driver', value: DELIVERY_METHOD_KEYS.onfleet },
  fastAFManual: { label: 'Manual', value: DELIVERY_METHOD_KEYS.fastaf }, // Sanjar says to use this key
  roadie: {
    label: 'Roadie Driver',
    value: DELIVERY_METHOD_KEYS.roadie,
    expanded: null, // This is set during render.
  },
}

const uberDispatchOptionsMap = {
  uber: { label: 'Uber Driver', value: DELIVERY_METHOD_KEYS.uber },
}

const carrierDispatchOptionsMap = {
  global: { label: 'Carrier (Global)', value: DELIVERY_METHOD_KEYS.global },
  globalManual: { label: 'Manual', value: DELIVERY_METHOD_KEYS.fastaf },
}

const fastAFOnlyMethods = [
  DELIVERY_METHOD_KEYS.onfleet,
  DELIVERY_METHOD_KEYS.fastaf,
]

const dispatchOptionsRaw = Object.values(dispatchOptionsMap)
const uberDispatchOptions = Object.values(uberDispatchOptionsMap)
const carrierDispatchOptions = Object.values(carrierDispatchOptionsMap)

const ScheduleDispatch = ({ classes, order, updateToDispatched, onClose }) => {
  const { itemSingles } = useInventoryCountContext()

  const defaultSelection =
    order?.location?.delivery_type === DELIVERY_METHOD_KEYS.global
      ? DELIVERY_METHOD_KEYS.global
      : DELIVERY_METHOD_KEYS.onfleet
  const [dispatchSelection, setDispatchSelection] = useState(defaultSelection)
  const [roadieSize, setRoadieSize] = useState(roadieSizeOptions[0].value)

  const [dispatching, setDispatching] = useState(false)

  const dispatchOptions =
    defaultSelection === DELIVERY_METHOD_KEYS.global
      ? carrierDispatchOptions
      : dispatchOptionsRaw.filter(x => fastAFOnlyMethods.includes(x.value))

  const onClickLetsGo = async () => {
    setDispatching(true)
    await updateToDispatched(dispatchSelection, roadieSize, itemSingles)
    setDispatching(false)
  }

  dispatchOptionsMap.roadie.expanded = (
    <Select
      fullWidth
      className={classes.roadieSelect}
      value={roadieSize}
      items={roadieSizeOptions}
      onChange={({ target: { value } }) => setRoadieSize(value)}
    />
  )

  return !dispatching ? (
    <MessageActionView
      title="Dispatching"
      description="Select your delivery method"
      actionText="Dispatch and Print"
      onClick={onClickLetsGo}
      onBackClick={onClose}
    >
      <Box>
        {defaultSelection === DELIVERY_METHOD_KEYS.global && (
          <Box textAlign="center" fontSize={140}>
            {order?.shipment?.carrier_account?.carrier_name === 'UPS' ? (
              <UPSIcon size={140} fontSize="inherit" />
            ) : (
              <GLSIcon size={140} fontSize="inherit" />
            )}
          </Box>
        )}
        <RadioGroup
          options={order?.uber ? uberDispatchOptions : dispatchOptions}
          onChange={newValue => setDispatchSelection(newValue)}
        />
      </Box>

      <PrinterSelect />
    </MessageActionView>
  ) : (
    <MessageActionView
      title="Dispatching"
      description={<>For order #{order?.number}.</>}
      actionHasSpinner
      onBackClick={onClose}
    />
  )
}

ScheduleDispatch.defaultProps = {}

ScheduleDispatch.propTypes = {
  classes: PropTypes.object,
  order: PropTypes.object,
  updateToDispatched: PropTypes.func,
  onClose: PropTypes.func,
  onClickPrint: PropTypes.func,
}

export default withStyles(styles)(ScheduleDispatch)
