import React, { useState } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'

import ReplayIcon from '@material-ui/icons/Replay'

import LineItem from 'components/LineItem'
import Header from 'components/Header'
import TextField from 'components/TextField'
import Scanner from 'components/Scanner'
import DatePicker from 'components/DatePicker'

import styles from './AssignBinsStyles'

const AssignBins = ({
  classes,
  lineItem,
  maxQuantity,
  onAssignBin,
  onBack,
  currentBins,
}) => {
  const [quantity, setQuantity] = useState(maxQuantity)
  const [showScanner, setShowScanner] = useState(true)
  const [bin, setBin] = useState('')
  const [expirationDate, setExpirationDate] = useState(null)

  const handleSplit = () => {
    setQuantity(0)
  }

  const handleQuantityChange = e => {
    const { value } = e.target
    if (value === '') {
      setQuantity(0)
    } else {
      setQuantity(Math.min(parseInt(e.target.value, 10), maxQuantity))
    }
  }

  const handleAssignBin = () => {
    onAssignBin(bin, quantity, expirationDate)
    setQuantity(maxQuantity - quantity)
    setShowScanner(true)
  }

  const lineItemComponent = (
    <LineItem
      className={classes.lineItem}
      lineItem={lineItem}
      showColor
      showSize
      status={null}
      hideBarcode
      onStatusClick={null}
    />
  )

  if (showScanner) {
    return (
      <Scanner
        title="Assign Bin"
        onScan={thisBin => {
          setBin(thisBin)
          setShowScanner(false)
        }}
        onClose={() => setShowScanner(false)}
        numbersOnly={false}
        verifyRemainder={false}
        irScanner={false}
        manualPicker={false}
        imageScanner={false}
        binSelector
        currentBins={currentBins}
      >
        <Box mb={2}>
          <Box className={classes.itemBox}>{lineItemComponent}</Box>
        </Box>
      </Scanner>
    )
  }

  return (
    <Box className={classes.root}>
      <Header title="Scan Bin" onBackClick={onBack} />
      <Box>{lineItemComponent}</Box>
      <Box className={classes.binContainer}>
        <TextField
          className={classNames({
              [classes.quantity]: true,
              [classes.hidden]: quantity >= maxQuantity,
          })}
          type="number"
          placeholder="00"
          innerLabel="Qty"
          value={quantity === 0 ? '' : quantity}
          onChange={handleQuantityChange}
        />
        <Box
          className={classNames({
              [classes.hidden]: quantity < maxQuantity,
          })}
        >
          <Typography variant="overline">QTY</Typography>
          <Typography variant="body2">
            {maxQuantity} of {maxQuantity}
          </Typography>
        </Box>
        <Box className={classes.bin}>
          <Typography variant="overline">Bin</Typography>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="body2">{bin}</Typography>
            <IconButton size="small" onClick={() => setShowScanner(true)}>
              <ReplayIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Box className={classes.binContainer}>
        <DatePicker
          name="expiration_date"
          fullWidth
          value={expirationDate}
          onChange={setExpirationDate}
          label="Expiration Date"
          disablePortal
        />
      </Box>
      <Box className={classes.buttons}>
        {bin && maxQuantity > 1 && (
           <Button
             variant="outlined"
             color="default"
             onClick={handleSplit}
             disabled={quantity < maxQuantity}
             >
             Split Bin
           </Button>
        )}
        {bin && (
           <Button
             data-test="assign-bin-button"
             variant="contained"
             color="primary"
             disabled={quantity <= 0}
             onClick={handleAssignBin}
             >
             Assign Bin
           </Button>
        )}
        {!bin && (
           <Button
             variant="contained"
             color="primary"
             onClick={() => setShowScanner(true)}
             >
             Scan
           </Button>
        )}
      </Box>
    </Box>
  )
}

AssignBins.propTypes = {
  classes: PropTypes.object.isRequired,
  lineItem: PropTypes.object.isRequired,
  maxQuantity: PropTypes.number.isRequired,
  onAssignBin: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  currentBins: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default withStyles(styles)(AssignBins)
