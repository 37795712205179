import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'

import styles from './PillStyles'

export const PILL_COLORS = {
  gray: 'gray',
  blue: 'blue',
  yellow: 'yellow',
  red: 'red',
}

const Pill = ({ classes, color, children }) => (
  <Box
    className={classNames({
      [classes.root]: true,
      [classes.gray]: color === PILL_COLORS.gray,
      [classes.blue]: color === PILL_COLORS.blue,
      [classes.yellow]: color === PILL_COLORS.yellow,
      [classes.red]: color === PILL_COLORS.red,
    })}
  >
    {children}
  </Box>
)

Pill.propTypes = {
  classes: PropTypes.object,
  color: PropTypes.string,
  children: PropTypes.node,
}

export default withStyles(styles)(Pill)
