import React from 'react'
import PropTypes from 'prop-types'

const GiftIcon = ({ fill = '#111', size = 14 }) => (
  <svg
    width={size}
    height={size}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 14 14"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.169 1.416a.865.865 0 0 0 0 1.73h1.869a4.961 4.961 0 0 0-.248-.524c-.391-.711-.914-1.206-1.621-1.206Zm2.123 3.146v1.73H1.416v-1.73h4.876ZM2.058 3.146A2.28 2.28 0 0 1 4.168 0C5.633 0 6.519 1.033 7 1.885 7.481 1.033 8.368 0 9.832 0a2.281 2.281 0 0 1 2.11 3.146h1.35c.391 0 .708.317.708.708V7a.708.708 0 0 1-.708.708h-.55v5.584a.708.708 0 0 1-.708.708H1.966a.708.708 0 0 1-.708-.708V7.708h-.55A.708.708 0 0 1 0 7V3.854c0-.391.317-.708.708-.708h1.35Zm.616 4.562v4.876h3.618V7.708H2.674Zm5.034 0v4.876h3.618V7.708H7.708Zm4.876-1.416H7.708v-1.73h4.876v1.73ZM9.832 3.146a.865.865 0 0 0 0-1.73c-.708 0-1.23.495-1.622 1.206-.097.177-.18.356-.248.524h1.87Z"
      fill={fill}
    />
  </svg>
)

GiftIcon.propTypes = {
  fill: PropTypes.string,
  size: PropTypes.number,
}

export default GiftIcon
