import { useAPIContext } from 'context'
import { useResourceRQ, useResourceListRQ } from 'hooks'
import {
  purchaseOrderListEndpoint,
  purchaseOrderExportEndpoint,
  purchaseOrderSummaryEndpoint,
} from 'api/endpoints'
import { LIST_PATHS } from 'constants/queryParams'
const { PURCHASE_ORDERS_LOCATION_ID, PURCHASE_ORDERS_ETA } = LIST_PATHS

const useAdminPurchaseOrderList = (params, type, enabled = true) => {
  const { downloadApi } = useAPIContext()

  const {
    isFetching: isFetchingPurchaseOrders,
    data: purchaseOrders,
    hasData: hasPurchaseOrders,
    hasNextPage: hasPurchaseOrdersNext,
    fetchNextPage: fetchPurchaseOrdersNext,
    count: totalPurchaseOrders,
  } = useResourceListRQ({
    endpoint: purchaseOrderListEndpoint,
    params,
    args: [type],
    enabled,
  })

  const summaryParams = {}
  if (PURCHASE_ORDERS_LOCATION_ID in params) {
    summaryParams[PURCHASE_ORDERS_LOCATION_ID] =
      params[PURCHASE_ORDERS_LOCATION_ID]
  }
  const startRel = `${PURCHASE_ORDERS_ETA}.rel`
  if (PURCHASE_ORDERS_ETA in params && startRel in params) {
    summaryParams[PURCHASE_ORDERS_ETA] = params[PURCHASE_ORDERS_ETA]
    summaryParams[startRel] = params[startRel]
  }
  const { data: summary } = useResourceRQ({
    endpoint: purchaseOrderSummaryEndpoint,
    name: 'status_counts',
    params: summaryParams,
  })

  const getExport = async purchaseOrderId => {
    await downloadApi.get(purchaseOrderExportEndpoint(purchaseOrderId))
  }

  return {
    isFetchingPurchaseOrders,
    purchaseOrders: purchaseOrders || [],
    hasPurchaseOrders,
    hasPurchaseOrdersNext,
    fetchPurchaseOrdersNext,
    summary,
    totalPurchaseOrders,

    getExport,
  }
}

export default useAdminPurchaseOrderList
