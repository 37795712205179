import { useResourceRQ } from 'hooks'
import { useQueryClient } from 'react-query'

import { transferOrderEndpoint, shipmentItemEndpoint } from 'api/endpoints'

const useAdminShipmentItem = (shipmentItemId, transferOrderId) => {
  const queryClient = useQueryClient()
  const { update: updateShipmentItem } = useResourceRQ({
    endpoint: shipmentItemEndpoint,
    args: [shipmentItemId],
    name: 'shipment_item',
    fetchingEnabled: false,
    onSuccess: () => {
      queryClient.invalidateQueries(transferOrderEndpoint(transferOrderId))
    },
  })

  return {
    updateShipmentItem,
  }
}

export default useAdminShipmentItem
