import React from 'react'
import PropTypes from 'prop-types'
import sumBy from 'lodash/sumBy'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import moment from 'moment-timezone'
import { useHistory } from 'react-router-dom'
import {
  Box,
  Typography,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  MenuItem,
} from '@material-ui/core'
import { ExpandLess } from '@material-ui/icons'
import Skeleton from '@material-ui/lab/Skeleton'

import Header from 'components/Header'
import PurchaseOrderStatus from 'components/PurchaseOrderStatus'
import Pill, { PILL_COLORS } from 'components/Pill'
import LineItem from 'components/LineItem'
import ConfirmationDialog from 'components/ConfirmationDialog'
import KebabButton from 'components/KebabButton'

import { useAdminPurchaseOrder, useAdminPurchaseOrderVariantList } from 'hooks'

import { withSign } from 'utils/general'

import { PURCHASE_ORDER_STATE_TYPES } from 'constants/purchaseOrders'
import {
  CYCLE_COUNT_STATE_LABELS,
  CYCLE_COUNT_STATES,
} from 'constants/cycleCounts'
import { URL } from 'constants/navigation'

import styles from './CycleCountShowScreenStyles'

const AvailablePill = ({ quantity, scanned }) => (
  <Pill color={scanned ? PILL_COLORS.blue : PILL_COLORS.gray}>
    <Typography variant="body1">
      {quantity} {scanned ? 'Counted' : 'Available'}
    </Typography>
  </Pill>
)

AvailablePill.propTypes = {
  quantity: PropTypes.number,
  scanned: PropTypes.bool,
}

const PurchaseOrderShowScreen = ({
  classes,
  match: {
    params: { id },
  },
}) => {
  const history = useHistory()
  const [expanded, setExpanded] = React.useState(false)

  const { variants: purchaseOrderVariantsUnsorted, variantsMeta } =
    // eslint-disable-next-line radix
    useAdminPurchaseOrderVariantList(parseInt(id), { purchase_order_id: id })

  const purchaseOrderVariants = [...purchaseOrderVariantsUnsorted].sort(
    (a, b) => a.id - b.id
  )

  const {
    purchase_order_variant_damaged_sum: damaged,
    purchase_order_variant_quantity_sum: quantity,
    purchase_order_variant_received_sum: received,
    cycle_count_expected_quantity: expectedQuantity,
  } = variantsMeta || {}

  const { purchaseOrder, updatePurchaseOrder } = useAdminPurchaseOrder(
    // eslint-disable-next-line radix
    parseInt(id)
  )

  const countComplete = purchaseOrder?.status === CYCLE_COUNT_STATES.counted
  const counting = purchaseOrder?.status === CYCLE_COUNT_STATES.counting

  const expectedCount =
    purchaseOrder?.status !== CYCLE_COUNT_STATES.created ? expectedQuantity : 0
  const backstockTotal = sumBy(
    purchaseOrderVariants,
    v => v.purchase_order_variant_backstock_quantity
  )

  const lineItems = purchaseOrderVariants.map(v => {
    const variantRecievedCount = v.purchase_order_variant_received || 0
    const variantDamagedCount = v.purchase_order_variant_damaged || 0
    const variantQuantity = v.purchase_order_variant_quantity
    const complete = v.purchase_order_variant_receiving_complete
    return {
      id: v.id,
      numberPicked: variantRecievedCount,
      numberDamaged: variantDamagedCount,
      numberMissing: complete
        ? variantQuantity - variantRecievedCount - variantDamagedCount
        : 0,
      complete,
      quantity: v.purchase_order_variant_quantity,
      inventoryQuantity: v.available_for_sale_inventory.quantity,
      backstock: v.purchase_order_variant_backstock_quantity,
      price: v.price,
      images: v.images,
      barcode: v.barcode,
      bin: v.available_for_sale_inventory.bin,
      product: v.product,
      variant: v.variant,
      sku: v.sku,
      color: v?.color?.label,
      size: v?.size,
    }
  })

  const missing =
    purchaseOrder?.status === PURCHASE_ORDER_STATE_TYPES.receivingInProgress
      ? quantity - ((received || 0) + (damaged || 0))
      : 0

  const handleClosePoClick = async () => {
    await updatePurchaseOrder({ status: CYCLE_COUNT_STATES.counted })
  }

  const handleContinueClick = () => {
    const unfinishedScanIndex = purchaseOrderVariants.findIndex(
      v =>
        v.purchase_order_variant_receiving_started &&
        !v.purchase_order_variant_receiving_complete
    )
    const unstartedIndex = purchaseOrderVariants.findIndex(
      v => !v.purchase_order_variant_receiving_started
    )

    const navigateToIndex =
      unstartedIndex === -1 ? unfinishedScanIndex : unstartedIndex
    history.push(
      `${URL.CYCLE_COUNTS}/${id}${URL.CYCLE_COUNT_VARIANTS}/${purchaseOrderVariants[navigateToIndex].id}`
    )
  }

  const poCancelled = purchaseOrder?.status === CYCLE_COUNT_STATES.cancelled

  const poCloseable = !purchaseOrderVariants.find(
    v => !v.purchase_order_variant_receiving_complete
  )

  const detailDate = date => (date ? moment(date).calendar() : '-')

  const Detail = ({
    title,
    text,
    color = 'textSecondary',
    textVariant = 'body1',
    className,
  }) => (
    <Box
      className={classNames(
        className,
        classes.spaceBetween,
        classes.spaceTopSmall
      )}
    >
      <Typography variant={textVariant} color={color}>
        {title}
      </Typography>
      <Typography variant="body2" data-test={`${title}-summary`}>
        {text}
      </Typography>
    </Box>
  )

  Detail.propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
    color: PropTypes.string,
    textVariant: PropTypes.string,
    className: PropTypes.string,
  }

  const DetailSummary = props => (
    <Detail {...props} color="textPrimary" textVariant="body2" />
  )

  const handleCancel = async () => {
    await updatePurchaseOrder({ status: CYCLE_COUNT_STATES.cancelled })
  }

  return (
    <Box className={classes.root}>
      <Header
        breadcrumbs={[
          { text: 'Count', href: URL.CYCLE_COUNTS },
          { text: `#${id}`, active: true },
        ]}
      >
        <KebabButton dataTest="menu">
          <MenuItem onClick={handleCancel} data-test="cancel-cycle-count">
            Cancel (uncounted will remain unchanged)
          </MenuItem>
        </KebabButton>
      </Header>
      <Box className={classes.content}>
        <Box className={classes.lineItems}>
          {lineItems.map(lineItem => (
            <LineItem
              key={lineItem.id}
              lineItem={lineItem}
              showQuantity
              showCounts
              complete={lineItem.complete}
              showStatus
              setMissing={null}
              missing={missing}
              showPrice={false}
              showSku
              showColor
              showSize
              endSlot={
                <AvailablePill
                  quantity={
                    lineItem.complete
                      ? lineItem.quantity + lineItem.backstock
                      : lineItem.inventoryQuantity
                  }
                  scanned={lineItem.complete}
                />
              }
              onClick={() =>
                history.push(
                  `${URL.CYCLE_COUNTS}/${id}${URL.PURCHASE_ORDER_VARIANTS}/${lineItem.id}`
                )
              }
            />
          ))}
        </Box>
        <Box className={expanded ? classes.detailsExpanded : classes.details}>
          <Accordion
            className={classes.accordionRoot}
            square
            elevation={0}
            expanded={expanded}
            onChange={() => setExpanded(!expanded)}
          >
            <AccordionSummary
              classes={{
                root: classes.summaryRoot,
                content: classes.summaryContentRoot,
              }}
              expandIcon={<ExpandLess />}
            >
              <Box className={classes.summaryRow} data-test="summary-accordion">
                <Box>
                  <Typography variant="body2">Status</Typography>
                  {purchaseOrder?.status ? (
                    <PurchaseOrderStatus
                      status={purchaseOrder.status}
                      labels={CYCLE_COUNT_STATE_LABELS}
                    />
                  ) : (
                    <Skeleton width="60px" />
                  )}
                </Box>
                <Typography variant="body2">Details</Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails className={classes.detailsContainer}>
              <Detail title="User" text={purchaseOrder?.author} />
              <Detail
                title="Created"
                text={detailDate(purchaseOrder?.created_at)}
              />
              <Detail
                title="Started"
                text={detailDate(purchaseOrder?.started_counting_at)}
              />
              <Detail
                title="Completed"
                text={detailDate(purchaseOrder?.finished_counting_at)}
              />
              <Typography variant="body2" className={classes.spaceTop}>
                Variants
              </Typography>
              {!(
                Boolean(purchaseOrder) && purchaseOrderVariants.length !== 0
              ) && <Skeleton width="340px" />}
              {purchaseOrderVariants.map(v => (
                <Box
                  key={v.id}
                  className={classNames(classes.orderedRow, classes.dark)}
                >
                  <Typography variant="body1">
                    {[v.product.title, v?.color?.label, v?.size]
                      .filter(thisVariant => Boolean(thisVariant))
                      .join(', ')}
                  </Typography>
                  <Typography variant="body1" className={classes.orderedValue}>
                    {v.purchase_order_variant_receiving_complete
                      ? v.purchase_order_variant_quantity +
                        v.purchase_order_variant_backstock_quantity
                      : '-'}
                  </Typography>
                </Box>
              ))}
              <Box className={classes.spaceTop} />
              <DetailSummary
                title="Expected Count"
                text={counting || countComplete ? expectedCount : '-'}
              />
              <DetailSummary
                title="Actual Count"
                text={countComplete ? quantity + backstockTotal : '-'}
              />
              <DetailSummary
                title="Discrepancy"
                text={
                  countComplete
                    ? withSign(
                        (expectedCount - (quantity + backstockTotal)) * -1
                      )
                    : '-'
                }
              />
            </AccordionDetails>
          </Accordion>
          <Divider className={classes.closeDivider} />
          {/* eslint-disable-next-line no-nested-ternary */}
          {poCancelled ? (
            <Button
              variant="contained"
              disabled
              fullWidth
              data-test="cancelled-button"
            >
              Cancelled
            </Button>
          ) : poCloseable ? (
            <ConfirmationDialog
              title="Confirm Action"
              message="Are you sure you want to update QTY to match the new physical count?"
              onAccept={handleClosePoClick}
            >
              {({ open }) => (
                <Button
                  data-test="close-po"
                  variant="contained"
                  color="primary"
                  onClick={open}
                  fullWidth
                  disabled={
                    purchaseOrder?.status === CYCLE_COUNT_STATES.counted
                  }
                >
                  {purchaseOrder?.status === CYCLE_COUNT_STATES.counted
                    ? 'Closed'
                    : 'Close and Update Inventory'}
                </Button>
              )}
            </ConfirmationDialog>
          ) : (
            <Button
              data-test="continue-button"
              variant="contained"
              color="primary"
              onClick={handleContinueClick}
              fullWidth
            >
              Continue
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  )
}

PurchaseOrderShowScreen.propTypes = {
  classes: PropTypes.object,
  match: PropTypes.object,
}

export default withStyles(styles)(PurchaseOrderShowScreen)
