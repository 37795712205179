const jssStyles = theme => ({
  root: {
    position: 'fixed',
    width: 48,
    height: 48,
    zIndex: 99,
    background: theme.palette.primary.main,
    boxShadow: 'none',
  },
})
export default jssStyles
