export const fontFamily = [
  'Inter',
  'Helvetica Neue',
  'Arial',
  'sans-serif',
].join()

export const fontWeight = {
  light: 400,
  normal: 500,
  semiBold: 600,
  bold: 700,
  extraBold: 800,
  ultraBold: 900,
}

export default {
  fontFamily,
  fontWeight,
  h1: {
    // Unused
    fontFamily,
    fontSize: 32,
    fontWeight: 900,
    lineHeight: '36px',
  },
  h2: {
    fontFamily,
    fontSize: 26,
    fontWeight: 600,
    lineHeight: '32px',
  },
  h3: {
    fontFamily,
    fontSize: 26,
    fontWeight: 600,
    lineHeight: '32px',
  },
  h4: {
    fontFamily,
    fontSize: 18,
    fontWeight: 700,
    lineHeight: '24px',
  },
  h5: {
    fontFamily,
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '20px',
  },
  subtitle1: {
    fontFamily,
    fontSize: 10,
    fontWeight: 400,
    lineHeight: '12px',
  },
  subtitle2: {
    // Unused
    fontFamily,
    fontSize: 12,
    fontWeight: 700,
    lineHeight: '17px',
  },
  body1: {
    fontFamily,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '21px',
  },
  body2: {
    fontFamily,
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '21px',
  },
  caption: {
    // Unused
    fontFamily,
    fontSize: 10,
    fontWeight: 500,
    lineHeight: '14px',
    textTransform: 'uppercase',
  },
  overline: {
    fontFamily,
    fontSize: 10,
    fontWeight: 400,
    lineHeight: '12px',
    textTransform: 'uppercase',
  },
}
