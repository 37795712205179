import { useContext } from 'react'

import { UIContext } from './UIContext'
import { APIContext } from './APIContext'
import { AuthContext } from './AuthContext'
import { LocationsContext } from './LocationsContext'
import { LocalStorageContext } from './LocalStorageContext'

import {
  InventoryCountContext,
  InventoryCountProvider,
} from './InventoryCountContext'

export { UIContext }
export const useUIContext = () => useContext(UIContext)

export { APIContext }
export const useAPIContext = () => useContext(APIContext)

export { AuthContext }
export const useAuthContext = () => useContext(AuthContext)

export { LocationsContext }
export const useLocationsContext = () => useContext(LocationsContext)

export { LocalStorageContext }
export const useLocalStorageContext = () => useContext(LocalStorageContext)

export const useInventoryCountContext = () => useContext(InventoryCountContext)
export { InventoryCountContext, InventoryCountProvider }
