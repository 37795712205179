import { useResourceRQ } from 'hooks'

import { shipmentEndpoint, shipmentListEndpoint } from 'api/endpoints'

const useAdminShipment = ({ shipmentId, enabled = true }) => {
  const {
    create: createShipment,
    data: shipment,
    update: updateShipment,
    isLoading: isLoadingShipment,
    isError: isShipmentError,
    error: shipmentError,
  } = useResourceRQ({
    endpoint: shipmentEndpoint,
    args: [shipmentId],
    name: 'shipment',
    fetchingEnabled: enabled,
    dependents: [shipmentListEndpoint],
  })

  const { destroy: destroyShipment } = useResourceRQ({
    endpoint: shipmentEndpoint,
    args: [shipmentId],
    fetchingEnabled: false,
  })

  return {
    shipment,
    updateShipment,
    createShipment,
    isLoadingShipment,
    isShipmentError,
    shipmentError,
    destroyShipment,
  }
}

export default useAdminShipment
