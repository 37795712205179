import React from 'react'
import PropTypes from 'prop-types'

import { UIProvider } from './UIContext'
import { APIProvider } from './APIContext'
import { AuthProvider } from './AuthContext'
import { LocationsProvider } from './LocationsContext'
import { LocalStorageProvider } from './LocalStorageContext'

const AppProvider = ({ children }) => (
  <LocalStorageProvider>
    <APIProvider>
      <UIProvider>
        <AuthProvider>
          <LocationsProvider>{children}</LocationsProvider>
        </AuthProvider>
      </UIProvider>
    </APIProvider>
  </LocalStorageProvider>
)

AppProvider.propTypes = {
  children: PropTypes.node,
}

export default AppProvider
