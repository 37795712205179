/* WARNING: copied from dashboard; keep in sync */
import React from 'react'
import PropTypes from 'prop-types'

import { PURCHASE_ORDER_STATE_LABELS } from 'constants/purchaseOrders'
import { TRANSFER_ORDER_STATE_LABELS } from 'constants/transferOrders'

import StatusDot from 'components/StatusDot'

const PurchaseOrderStatus = ({
  className,
  status,
  labels = [...PURCHASE_ORDER_STATE_LABELS, ...TRANSFER_ORDER_STATE_LABELS],
}) => {
  if (!status) return null

  const { label, indicator } = labels?.find(t => t.value === status) ?? {}

  return <StatusDot className={className} text={label} variant={indicator} />
}

PurchaseOrderStatus.propTypes = {
  className: PropTypes.string,
  status: PropTypes.string,
  labels: PropTypes.arrayOf(PropTypes.object),
}

export default PurchaseOrderStatus
