const jssStyles = theme => ({
  drawerConfirmText: {
    letterSpacing: '0.3px',
    color: '#858585',
    opacity: 0.97,
  },
  cancelButton: {
    flex: 1,
    border: '1px solid #D5D5D5',
    width: 163,
    height: 53,
    backgroundColor: '#FFFFFF',
    color: '#000000',
    fontSize: 14,
    fontWeight: 500,
  },
  okButton: {
    flex: 1,
    marginLeft: 8,
    width: 163,
    height: 53,
    color: '#ffffff',
    backgroundColor: '#305BF2',
    fontSize: 14,
    fontWeight: 500,
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 50,
  },
})

export default jssStyles
