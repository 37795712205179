import React, { createContext, useMemo } from 'react'
import PropTypes from 'prop-types'

import axios from 'axios'

import { useLocalStorageContext } from 'context'
import { URL } from 'constants/navigation'
import { SERVER_URL } from 'lib/config'
import browserHistory from 'browserHistory'

const headers = { 'Content-Type': 'application/json' }

const api = axios.create({
  baseURL: SERVER_URL,
  headers,
  timeout: 200000,
})

api.interceptors.response.use(
  resp => Promise.resolve(resp.data),
  error => {
    if (error?.response?.status === 401) {
      browserHistory.push(URL.LOGOUT)
    }
    return Promise.reject(error.response || error)
  }
)

export const APIContext = createContext()

export const APIProvider = ({ children }) => {
  const { authToken } = useLocalStorageContext()

  // Had this in useEffect but wasn't triggered fast enough for log in.
  api.defaults.headers.common.Authorization = `Bearer ${authToken}`

  const value = useMemo(() => ({ api }), [])

  return <APIContext.Provider value={value}>{children}</APIContext.Provider>
}

APIProvider.propTypes = {
  children: PropTypes.node,
}
