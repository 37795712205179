import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered'
import RemoveIcon from '@material-ui/icons/Remove'
import ExposureZeroIcon from '@material-ui/icons/ExposureZero'

import { IconButton, Box, Typography } from '@material-ui/core'

import {
  useAdminPlateList,
  useAdminCycleCount,
  useConditionalEffect,
} from 'hooks'
import Header from 'components/Header'
import PlateItem from 'components/PlateItem'

import { useLocationsContext } from 'context'

import styles from './LocationScreenStyles'

const LocationScreen = ({
  classes,
  match: {
    params: { location },
  },
}) => {
  const history = useHistory()
  const { location: warehouse } = useLocationsContext()
  const { plates } = useAdminPlateList({
    params: { search: location, location_id: warehouse?.id },
  })
  const { cycleCount, isLoadingCycleCount, createCycleCount } =
    useAdminCycleCount({ enabled: false })

  // Unused until cycle count feature is complete
  useConditionalEffect(() => {
    if (cycleCount?.id && !isLoadingCycleCount) {
      history.push(
        `/cc/${cycleCount.id}/plate/${cycleCount.cycle_count_plates[0].id}`
      )
    }
  }, [cycleCount])

  return (
    <>
      <Header
        breadcrumbs={[
          { text: 'Location', href: URL.LOCATION },
          { text: `${location.toUpperCase()}`, active: true },
        ]}
        onBackClick={() => history.goBack()}
      />
      <Box className={classes.lineItems}>
        {plates.map(plate => (
          <Box key={plate.id}>
            <PlateItem plate={plate} />
            <Box display="flex" justifyContent="flex-end" mt="-20px" mb="8px">
              {false /* Hide until feature is complete */ && (
                <>
                  <IconButton
                    variant="contained"
                    color="primary"
                    aria-label="Cycle Count this location"
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      fontSize={40}
                      ml={2}
                      mr={2}
                    >
                      <ExposureZeroIcon fontSize="inherit" />
                      <Typography>Zero</Typography>
                    </Box>
                  </IconButton>
                  <IconButton
                    variant="contained"
                    color="primary"
                    aria-label="Cycle Count this location"
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      fontSize={40}
                      ml={2}
                      mr={2}
                    >
                      <RemoveIcon fontSize="inherit" />
                      <Typography>Pull All</Typography>
                    </Box>
                  </IconButton>
                  <IconButton
                    variant="contained"
                    color="primary"
                    aria-label="Cycle Count this location"
                    onClick={() =>
                      createCycleCount({
                        status: 'created',
                        plate_id: plate.id,
                      })
                    }
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      fontSize={40}
                      ml={2}
                      mr={2}
                    >
                      <FormatListNumberedIcon fontSize="inherit" />
                      <Typography>Cycle Count</Typography>
                    </Box>
                  </IconButton>
                </>
              )}
            </Box>
          </Box>
        ))}
      </Box>
    </>
  )
}

LocationScreen.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
}

export default withStyles(styles)(LocationScreen)
