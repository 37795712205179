import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Link as RouterLink } from 'react-router-dom'

import { URL } from 'constants/navigation'
import { useLocationsContext } from 'context'
import { IconButton } from '@material-ui/core'
import LocationDrawer from 'components/LocationDrawer'
import FloatingFab from 'components/FloatingFab'
import HomeIcon from '@material-ui/icons/Home'

import styles from './BottomDrawerMenuStyles'

const BottomDrawerMenu = ({ classes }) => {
  const [locationDrawerOpen, setLocationDrawerOpen] = useState(false)
  const { location, locations } = useLocationsContext()

  const needsLocation = !location && locations
  useEffect(() => {
    if (needsLocation) {
      setLocationDrawerOpen(true)
    }
  }, [locations, location])

  const handleLocationDrawerOpen = v => {
    setLocationDrawerOpen(v)
  }

  return (
    <>
      <FloatingFab className={classes.menuFab}>
        <IconButton
          component={RouterLink}
          aria-label="Home"
          data-test="menu-button"
          color="inherit"
          to={URL.HOME}
        >
          <HomeIcon color="inherit" />
        </IconButton>
      </FloatingFab>
      <LocationDrawer
        drawerOpen={locationDrawerOpen}
        setDrawerOpen={handleLocationDrawerOpen}
      />
    </>
  )
}

BottomDrawerMenu.propTypes = {
  classes: PropTypes.object,
}

export default withStyles(styles)(BottomDrawerMenu)
