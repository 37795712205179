import ReactDOM from 'react-dom'
import { useHistory } from 'react-router-dom'

import { URL } from 'constants/navigation'
import { useAPIContext, useAuthContext, useLocalStorageContext } from 'context'
import { useAlerts, useLoaders } from 'hooks'

// const USER_AUTH_ENDPOINTS = {
  // me: '/api/v1/app/users/me',
  // login: '/api/v1/login',
  // resetPassword: '/api/v1/reset_password',
  // forgotPassword: '/api/v1/forgot_password'
// }

const INTERNAL_USER_AUTH_ENDPOINTS = {
  me: 'api/v1/admin/me',
  login: 'api/v1/admin/login',
  resetPassword: 'api/v1/admin/reset_password',
  forgotPassword: 'api/v1/admin/forgot_password'
}

const useAuth = () => {
  const authEndpoints = INTERNAL_USER_AUTH_ENDPOINTS

  const {
    setAuthToken,
    setLocationId,
    needsUserDefaultLocation,
    setNeedsUserDefaultLocation,
    clearStorage,
  } = useLocalStorageContext()
  const history = useHistory()
  const { api } = useAPIContext()

  const { showAlertError } = useAlerts()
  const { showLoading, hideLoading } = useLoaders()
  const { currentUser, setCurrentUser } = useAuthContext()

  const setCurrentUserAndToken = user => {
    if (user.token) {
      setAuthToken(user.token)
    }
    setCurrentUser(user)
    if (needsUserDefaultLocation) {
      setLocationId(user.default_location_id)
      setNeedsUserDefaultLocation(false)
    }
  }

  const fetchMe = async () => {
    try {
      const resp = await api.get(authEndpoints.me)
      ReactDOM.unstable_batchedUpdates(() => {
        setCurrentUserAndToken(resp.data)
        hideLoading()
      })
      return resp.data
    } catch (e) {
      console.error(e)
    }
  }

  const loginUser = async user => {
    try {
      showLoading()
      const resp = await api.post(authEndpoints.login, {
        user,
      })
      ReactDOM.unstable_batchedUpdates(() => {
        setCurrentUserAndToken(resp.data)
        hideLoading()
      })
      return resp.data
    } catch (e) {
      hideLoading()
      showAlertError('Email or password is incorrect')
      console.error(e)
    }
  }

  const resetPassword = async (password, changeToken) => {
    try {
      showLoading()
      const resp = await api.post(authEndpoints.resetPassword, {
        password,
        change_password_token: changeToken,
      })
      hideLoading()
      return resp.data
    } catch (e) {
      hideLoading()
      console.error(e)
    }
  }

  const forgotPassword = async email => {
    try {
      showLoading()
      await api.post(authEndpoints.forgotPassword, {
        email,
      })
      hideLoading()
    } catch (e) {
      hideLoading()
      console.error(e)
    }
  }

  const logoutUser = () => {
    setCurrentUser(null)
    clearStorage()
    history.push(URL.LOGIN)
  }

  return {
    currentUser,
    fetchMe,
    loginUser,
    logoutUser,
    resetPassword,
    forgotPassword,
  }
}

export default useAuth
