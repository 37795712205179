import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import * as propTypes from 'constants/propTypes'
import { COUNT_STATUS } from 'constants/enums'
import { formatCurrency } from 'utils/general'
import { getSortedBinList } from 'utils/bin'
import { DEFAULT_PRODUCT_IMAGE } from 'constants/general'
import CircleCheckIndicator from 'components/CircleCheckIndicator'
import CountStatusPill from 'components/CountStatusPill'

import styles from './LineItemStyles'

const LineItem = ({
  classes,
  className,
  lineItem: {
    quantity,
    price,
    images,
    barcode,
    bin,
    product: { title, brand },
    variant,
    sku,
    color,
    size,
  },
  numberChecked,
  numberFlagged,
  numberDamaged,
  numberMissing,
  showCounts,
  carouselMode,
  status,
  onStatusClick,
  hideBarcode,
  showPrice,
  showSku,
  showColor,
  showSize,
  showQuantity,
  onClick,
  complete,
  endSlot,
  alwaysShowChecked,
  onCheckedClick,
  onDamagedClick
}) => {
  const fmtPrice = `$${price}`
  const fmtTotalPrice = formatCurrency(quantity * Number(price))

  const imgUrl =
    variant?.images?.[0]?.url ?? images?.[0]?.url ?? DEFAULT_PRODUCT_IMAGE

  const priceBreakdown = quantity > 1 ? `(${fmtPrice}x${quantity})` : ''

  const imageProps = carouselMode ? {} : { alignSelf: 'flex-start', mt: 2.5 }

  const sortedBins = getSortedBinList(bin)

  const currentBin = sortedBins.join(',')

  return (
    <Box
    className={classNames({
        [classes.root]: true,
        [classes.carouselCard]: carouselMode,
        [classes.listItem]: !carouselMode,
        [className]: Boolean(classNames),
      })}
    >
      <Box
        className={classes.content}
        onClick={onClick}
        data-test={`line-item-${barcode}`}
      >
        <Box width={100} ml={2} mr={2.2} {...imageProps}>
          <img alt="variant" src={imgUrl} className={classes.image} />
        </Box>
        <Box my={2}>
          {brand && (
            <Typography variant="overline" color="textSecondary">
              {brand}
            </Typography>
          )}
          <Typography variant="body2">{title}</Typography>
          {barcode && !hideBarcode && (
            <Typography variant="body1">UPC: {barcode}</Typography>
          )}
          {bin && <Typography variant="body1">BIN: {currentBin}</Typography>}
          {showPrice && (
            <Typography className={classes.description} variant="body2">
              {fmtTotalPrice} {priceBreakdown}
            </Typography>
          )}
          {showSku && (
            <Typography className={classes.description} variant="body1">
              SKU: {sku}
            </Typography>
          )}
          {showColor && color && (
            <Typography className={classes.description} variant="body1">
              Color: {color}
            </Typography>
          )}
          {showSize && size && (
            <Typography className={classes.description} variant="body1">
              Size: {size}
            </Typography>
          )}
          {showQuantity && Boolean(quantity) && (
            <Typography className={classes.description} variant="body1">
              Quantity: {quantity}
            </Typography>
          )}
          {showCounts && (
            <Box mt={1} display="flex">
              {alwaysShowChecked ||
              numberChecked + numberFlagged < quantity ||
              numberChecked > 0 ? (
                <CountStatusPill
                  count={numberChecked}
                  total={quantity}
                  status={
                    numberChecked + numberFlagged === quantity || complete
                      ? COUNT_STATUS.complete
                      : COUNT_STATUS.inProgress
                  }
                  onClick={onCheckedClick}
                  props={{
                    'data-test': 'count-status-pill-active',
                  }}
                />
              ) : null}
              {numberFlagged > 0 ? (
                <CountStatusPill
                  count={numberFlagged}
                  total={quantity}
                  status={COUNT_STATUS.flagged}
                  props={{
                    'data-test': 'count-status-pill-flagged',
                  }}
                />
              ) : null}
              {numberDamaged > 0 ? (
                <CountStatusPill
                  count={numberDamaged}
                  total={quantity}
                  status={COUNT_STATUS.broken}
                  onClick={onDamagedClick}
                  props={{
                    'data-test': 'count-status-pill-damaged',
                  }}
                />
              ) : null}
              {numberMissing > 0 ? (
                <CountStatusPill
                  count={numberMissing}
                  total={quantity}
                  status={COUNT_STATUS.missing}
                  props={{
                    'data-test': 'count-status-pill-missing',
                  }}
                />
              ) : null}
              {endSlot}
            </Box>
          )}
        </Box>
        {carouselMode && (
          <Box className={classes.carouselStatus} onClick={onStatusClick}>
            <CircleCheckIndicator status={status} />
          </Box>
        )}
      </Box>
    </Box>
  )
}

LineItem.defaultProps = {
  showPrice: true,
  showSku: false,
  showColor: false,
  showSize: false,
}

LineItem.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  lineItem: PropTypes.shape({
    quantity: PropTypes.number,
    price: PropTypes.string,
    images: PropTypes.arrayOf(PropTypes.object),
    barcode: PropTypes.string,
    bin: PropTypes.string,
    product: PropTypes.object,
    variant: PropTypes.object,
    sku: PropTypes.string,
    color: PropTypes.string,
    size: PropTypes.string,
  }).isRequired,
  numberChecked: PropTypes.number,
  numberFlagged: PropTypes.number,
  numberDamaged: PropTypes.number,
  numberMissing: PropTypes.number,
  complete: PropTypes.bool,
  showCounts: PropTypes.bool,
  carouselMode: PropTypes.bool,
  hideBarcode: PropTypes.bool,
  status: propTypes.circleCheckStatus,
  onStatusClick: PropTypes.func,
  setMissing: PropTypes.func,
  missing: PropTypes.number,
  showPrice: PropTypes.bool,
  showSku: PropTypes.bool,
  showColor: PropTypes.bool,
  showSize: PropTypes.bool,
  onClick: PropTypes.func,
  showQuantity: PropTypes.bool,
  endSlot: PropTypes.node,
  alwaysShowChecked: PropTypes.bool,
  onCheckedClick: PropTypes.func,
  onDamagedClick: PropTypes.func,
}

export default withStyles(styles)(LineItem)
