import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'

import { useLocationsContext, useLocalStorageContext } from 'context'
import Dialog, {
  DialogTitle,
  DialogContent,
  DialogActions,
} from 'components/Dialog'
import TextField from 'components/TextField'
import PrinterSelect from 'components/PrinterSelect'

import { IS_PRODUCTION } from 'lib/config'
import { TEXT_FIELD_TYPES, PRINTER_TYPE } from 'constants/enums'
import { useAdminVariant } from 'hooks'

import styles from './PrintLabelDialogStyles.js'

const PrintLabelDialog = ({ classes, children, variant }) => {
  const { barcodePrinterId } = useLocalStorageContext()
  const { location } = useLocationsContext()
  const [numLabels, setNumLabels] = useState('')

  const { printLabels } = useAdminVariant(variant?.id)

  const handlePrint = closeDialog => async () => {
    if (IS_PRODUCTION) {
      await printLabels({
        sku: variant.sku,
        locationId: location.id,
        quantity: numLabels,
        printerId: barcodePrinterId,
      })
    }
    closeDialog()
  }

  return (
    <Dialog
      className={classes.root}
      content={({ close: closeDialog }) => (
        <>
          <DialogTitle close={closeDialog}>Print Barcode</DialogTitle>
          <DialogContent>
            <TextField
              innerLabel="Number of labels"
              type={TEXT_FIELD_TYPES.number}
              value={`${numLabels}`}
              onChange={({ target: { value } }) =>
                setNumLabels(parseInt(value, 10))
              }
            />
            <PrinterSelect type={PRINTER_TYPE.BARCODE} />
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              onClick={handlePrint(closeDialog)}
              disabled={!(Boolean(numLabels) && numLabels > 0)}
            >
              Print
            </Button>
          </DialogActions>
        </>
      )}
    >
      {children}
    </Dialog>
  )
}

PrintLabelDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  variant: PropTypes.object,
}

export default withStyles(styles)(PrintLabelDialog)
