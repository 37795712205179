import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

export const makeObjectResolver = schema =>
  yupResolver(yup.object().shape(schema))

export const emailSchema = yup
  .string()
  .email('expected email address')
  .required('email address is required')

export const makePasswordConfirm = ref =>
  yup
    .string()
    .oneOf([yup.ref(ref)], 'Passwords must match')
    .required()

export const passwordSchema = yup.string().required('Password is required')

export const passwordMinStrengthSchema = yup
  .string()
  .min(8, 'Password must have minimum 6 characters')
  .required('Password is required')

export const passwordStrengthSchema = yup
  .string()
  .min(8, 'Password must have minimum 8 characters')
  .matches(/[A-Z]/, 'Password must have at least one uppercase character')
  .matches(/\d/, 'Password must contain a number')
  .matches(
    /[*.! @#$%^&(){}[\]:;<>,.?/~_+\-=|\\]/,
    'Password must contain a special character'
  )
  .required('Password is required')

export const nameSchema = yup.string().required('Name is required')

export const locationSchema = yup.number().required('A location is required')
