export default {
  root: {
    backgroundColor: 'transparent',
    '&.Mui-selected': {
      backgroundColor: 'transparent',
    },
    '&.Mui-selected:hover': {
      backgroundColor: 'transparent',
    },
  },
}
