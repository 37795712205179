import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { uniqBy, debounce } from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import { Box } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'

import Header from 'components/Header'
import ProductSummary from 'components/ProductSummary'
import ProductResultsPopover from 'components/ProductResultsPopover'
import ConfirmationDialog from 'components/ConfirmationDialog'

import {
  useAdminPurchaseOrderVariantList,
  useAdminPurchaseOrder,
  useAdminVariantsRQ,
  useAdminProductList,
} from 'hooks'

import { useLocationsContext } from 'context'

import { PRODUCT_RESULTS_POPOVER_TYPES } from 'constants/enums'
import { RECORD_TYPE } from 'constants/inventoryRecords'
import { URL } from 'constants/navigation'

import styles from './CycleCountCreateStyles'

const DEBOUNCE_MS = 300
const NO_SEARCH = Symbol('sym-no-search')
const CREATE_TYPES = {
  variant: 'variant',
  product: 'product',
}

const CycleCountListScreen = ({
  classes,
  match: {
    params: { type },
  },
}) => {
  const history = useHistory()
  const { location } = useLocationsContext()

  const [searchString, setSearchString] = useState(NO_SEARCH)
  const [selectedVariants, setSelectedVariants] = useState([])
  const [cycleCountCreated, setCycleCountCreated] = useState(false)

  const debounceSearch = debounce(setSearchString, DEBOUNCE_MS)

  const { variants, isLoading: isLoadingVariants } = useAdminVariantsRQ(
    {
      search: searchString,
      location_id: location?.id,
    },
    type === CREATE_TYPES.variant && searchString !== NO_SEARCH
  )

  const { products } = useAdminProductList(
    {
      search: searchString,
      location_id: location?.id,
    },
    type === CREATE_TYPES.product && searchString !== NO_SEARCH
  )

  const { createPurchaseOrder: createCycleCount, purchaseOrder: cycleCount } =
    useAdminPurchaseOrder(null, {
      onSuccess: () => {
        setCycleCountCreated(true)
      },
    })

  const { createPurchaseOrderVariants: createCycleCountVariants } =
    useAdminPurchaseOrderVariantList(
      cycleCount?.id,
      {},
      {
        onCreateSuccess: () => {
          history.push(`${URL.CYCLE_COUNTS}/${cycleCount.id}`)
        },
      }
    )

  const handleSearchChange = e => {
    debounceSearch(e.target.value)
  }

  const handleAddVariant = item => {
    setSelectedVariants(uniqBy([...selectedVariants, item], v => v.id))
  }

  const handleRemoveVariant = currVariant => {
    setSelectedVariants(
      selectedVariants.filter(variant => variant.id !== currVariant.id)
    )
  }

  const handleCreate = () => {
    createCycleCount({
      location_id: location.id,
      record_type: RECORD_TYPE.CYCLE_COUNT,
    })
  }

  // add variants to cycle count
  useEffect(() => {
    if (cycleCount?.id && cycleCountCreated) {
      const variantProc = v => {
        return { id: v.id }
      }
      const theseVariants =
        type === CREATE_TYPES.variant
          ? selectedVariants.map(variantProc)
          : selectedVariants
              .map(p => {
                return p.variants.map(variantProc)
              })
              .flat()
      createCycleCountVariants(theseVariants)
    }
  }, [cycleCount, cycleCountCreated])

  return (
    <ConfirmationDialog
      title="Confirm Action"
      message="Are you sure you want to discard this Cycle Count? This action can not be undone."
      acceptText="Discard"
      denyText="Nevermind"
      onAccept={() => history.push(URL.CYCLE_COUNTS)}
    >
      {({ open }) => (
        <>
          <Header title={`Search ${type}s`} onBackClick={open} />
          <Box className={classes.container}>
            <ProductResultsPopover
              options={type === CREATE_TYPES.variant ? variants : products}
              selectedOptions={selectedVariants}
              onTextChange={handleSearchChange}
              onAddItem={handleAddVariant}
              onRemoveItem={handleRemoveVariant}
              activeProducts={[]}
              type={PRODUCT_RESULTS_POPOVER_TYPES.po}
              loading={isLoadingVariants}
            />
          </Box>

          <Box ml={2} mr={2} overflow="auto" maxHeight="448px">
            {selectedVariants.map(variant => (
              <Box
                key={variant.id}
                className={classes.selectedItem}
                mt={1}
                pb={1}
              >
                <Box className={classes.productSummary}>
                  <ProductSummary product={variant} showSku />
                </Box>
                <Box className={classes.itemActions}>
                  <IconButton
                    edge="end"
                    onClick={() => handleRemoveVariant(variant)}
                  >
                    <RemoveCircleIcon />
                  </IconButton>
                </Box>
              </Box>
            ))}
          </Box>
          <Box className={classes.actionContainer}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleCreate}
              disabled={selectedVariants.length === 0}
              data-test="create-cycle-count-button"
            >
              Create Cycle Count
            </Button>
          </Box>
        </>
      )}
    </ConfirmationDialog>
  )
}

CycleCountListScreen.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object,
}

export default withStyles(styles)(CycleCountListScreen)
