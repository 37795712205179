import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import NativeSelect from '@material-ui/core/NativeSelect'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import { useLocalStorageContext } from 'context'
import { useAdminPrinters } from 'hooks'
import { PRINTER_TYPE } from 'constants/enums'
import { isEmpty } from 'lodash'

const PrinterSelect = ({ type = PRINTER_TYPE.LABEL }) => {
  const {
    printerId: labelPrinterId,
    setPrinterId: setLabelPrinterId,
    barcodePrinterId,
    setBarcodePrinterId,
    locationId
  } = useLocalStorageContext()
  const printerId = type === PRINTER_TYPE.LABEL ? labelPrinterId : barcodePrinterId
  const setPrinterId = type === PRINTER_TYPE.LABEL ? setLabelPrinterId : setBarcodePrinterId
  const printerIdSpecified = typeof printerId === 'number'

  const { printers } = useAdminPrinters({
    locationId,
    type,
  })

  useEffect(() => {
    // if a printer ID is not set or if it is set but not in the list of
    // printers then we should set a new printer
    if (
      (!printerIdSpecified && !isEmpty(printers)) ||
      (printerIdSpecified &&
        !isEmpty(printers) &&
        !printers.map(p => p.id).includes(printerId))
    ) {
      setPrinterId(printers[Math.floor(Math.random() * printers.length)].id)
    }
  })

  return (
    <Box p={1}>
      {!isEmpty(printers) ? (
        <NativeSelect
          fullWidth
          value={printerIdSpecified ? printerId : ''}
          onChange={e => setPrinterId(e.target.value)}
        >
          {printers?.map(printer => (
            <option key={printer.id} value={printer.id}>
              {printer.name}
            </option>
          ))}
        </NativeSelect>
      ) : (
        <Typography>There is no printer available for location</Typography>
      )}
    </Box>
  )
}

PrinterSelect.defaultProps = {}

PrinterSelect.propTypes = {
  printer: PropTypes.object,
  type: PropTypes.string,
}

export default PrinterSelect
