import React, { createContext, useState, useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'

import { storageAvailable } from 'utils/general'
import { ALL_LOCATIONS_ID } from 'constants/general'
import {
  STORAGE_AUTH_TOKEN_ID,
  STORAGE_LOCATION_ID,
  STORAGE_PRINTER_ID,
  STORAGE_BARCODE_PRINTER_ID,
} from 'lib/config'

if (!storageAvailable('localStorage')) {
  console.error('This website requires localStorage enabled')
}

// Could also use sessionStorage for more ephemeral data
const { localStorage } = window

const initialAuthToken = localStorage.getItem(STORAGE_AUTH_TOKEN_ID)

const rawPrinterId = localStorage.getItem(STORAGE_PRINTER_ID)
const rawBarcodePrinterId = localStorage.getItem(STORAGE_BARCODE_PRINTER_ID)
const rawInitialLocation = localStorage.getItem(STORAGE_LOCATION_ID)
// If it is null but 'null' isn't explicitly stored, this will get overwritten
let initialLocationId = null
let initialPrinterId = null
let initialBarcodePrinterId = null
try {
  initialLocationId = JSON.parse(rawInitialLocation)
  initialPrinterId = JSON.parse(rawPrinterId)
  initialBarcodePrinterId = JSON.parse(rawBarcodePrinterId)
  // eslint-disable-next-line no-empty
} catch {}
const hasInitialLocation =
  rawInitialLocation === JSON.stringify(ALL_LOCATIONS_ID) ||
  Number.isInteger(initialLocationId)

export const LocalStorageContext = createContext()

export const LocalStorageProvider = ({ children }) => {
  const [authToken, setAuthToken] = useState(initialAuthToken)
  const [locationId, setLocationId] = useState(initialLocationId)
  const [printerId, setPrinterId] = useState(initialPrinterId)
  const [barcodePrinterId, setBarcodePrinterId] = useState(initialBarcodePrinterId)
  const [needsUserDefaultLocation, setNeedsUserDefaultLocation] = useState(
    !hasInitialLocation
  )

  const clearStorage = () => {
    localStorage.clear()
    setAuthToken(null)
    setLocationId(null)
    setPrinterId(null)
    setBarcodePrinterId(null)
    setNeedsUserDefaultLocation(true)
  }

  useEffect(() => {
    localStorage.setItem(STORAGE_LOCATION_ID, locationId)
  }, [locationId])

  useEffect(() => {
    localStorage.setItem(STORAGE_PRINTER_ID, printerId)
  }, [printerId])

  useEffect(() => {
    localStorage.setItem(STORAGE_BARCODE_PRINTER_ID, barcodePrinterId)
  }, [barcodePrinterId])

  useEffect(() => {
    if (authToken !== null) {
      localStorage.setItem(STORAGE_AUTH_TOKEN_ID, authToken)
    } else {
      localStorage.removeItem(STORAGE_AUTH_TOKEN_ID)
    }
  }, [authToken])

  const value = useMemo(
    () => ({
      authToken,
      setAuthToken,
      locationId,
      setLocationId,
      printerId,
      setPrinterId,
      barcodePrinterId,
      setBarcodePrinterId,
      needsUserDefaultLocation,
      setNeedsUserDefaultLocation,
      clearStorage,
    }),
    [authToken, locationId, printerId, barcodePrinterId]
  )

  return (
    <LocalStorageContext.Provider value={value}>
    {children}
    </LocalStorageContext.Provider>
  )
}

LocalStorageProvider.propTypes = {
  children: PropTypes.node,
}
