const jssStyles = theme => ({
  actions: {
    display: 'flex',
    position: 'fixed',
    bottom: 0,
    width: '100%',
    background: theme.palette.common.white,
    '& button': {
      flexGrow: '1',
      margin: theme.spacing(1),
    },
  },
})

export default jssStyles
