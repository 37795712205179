import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import debounce from 'lodash/debounce'
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { Box, Typography } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'

import Header from 'components/Header'
import PurchaseOrderStatus from 'components/PurchaseOrderStatus'
import TextField from 'components/TextField'

import {
  useAdminPurchaseOrderList,
  useAdminBrands,
  useQuery,
  useRestoreScroll,
} from 'hooks'

import { useLocationsContext } from 'context'

import { LIST_PATHS, API_PARAMS, REL } from 'constants/queryParams'
import { URL } from 'constants/navigation'
import {
  PURCHASE_ORDER_STATE_TYPES,
  TERM_POSSIBILITIES,
} from 'constants/purchaseOrders'

import styles from './PurchaseOrderSearchScreenStyles'

const DEBOUNCE_MS = 300

const ALL_FILTERS = [
  PURCHASE_ORDER_STATE_TYPES.submitted,
  PURCHASE_ORDER_STATE_TYPES.receivingInProgress,
  PURCHASE_ORDER_STATE_TYPES.fullyReceived,
  PURCHASE_ORDER_STATE_TYPES.overReceived,
  PURCHASE_ORDER_STATE_TYPES.partiallyReceived,
  PURCHASE_ORDER_STATE_TYPES.closed,
].join(',')

const TERM_LOOKUP = {}
TERM_POSSIBILITIES.forEach(t => {
  TERM_LOOKUP[t.value] = t.label
})

const PurchaseOrderSearchScreen = ({ classes }) => {
  const [params, setParams] = useState({})
  const { location } = useLocationsContext()
  const listRef = useRef(null)
  useRestoreScroll(listRef, 'purchase-order-search')

  const { query, setQuery } = useQuery(false, {
    initFallback: {
      search: '',
    },
  })

  const searchText = query.search

  const { isFetchingPurchaseOrders, purchaseOrders } =
    useAdminPurchaseOrderList(params)

  const debounceList = debounce(setParams, DEBOUNCE_MS, {
    leading: true,
  })

  const { brands: allBrands, fetchBrands } = useAdminBrands()
  useEffect(() => {
    fetchBrands()
  }, [])
  const vendorMap = {}
  if (allBrands) {
    allBrands.forEach(v => {
      vendorMap[v.id] = v.name
    })
  }

  useEffect(() => {
    if (searchText !== '') {
      debounceList({
        [API_PARAMS.SORT]: LIST_PATHS.PURCHASE_ORDERS_ETA,
        [LIST_PATHS.PURCHASE_ORDERS_STATUS_FILTER]: ALL_FILTERS,
        [LIST_PATHS.PURCHASE_ORDERS_STATUS_REL]: REL.IN,
        [LIST_PATHS.PURCHASE_ORDERS_LOCATION_ID]: location?.id,
        [API_PARAMS.SEARCH]: searchText,
      })
    }
  }, [location, searchText])

  const Loading = (
    <Box className={classes.listContainer}>
      {new Array(4).fill(0).map((_, i) => (
        <Box key={0 - i} className={classes.itemContainer} mb="2px">
          <Skeleton width="30px" height="21px" />
          <Skeleton width="80px" height="21px" />
          <Skeleton width="80px" height="21px" />
          <Box className={classes.etaStatusRow}>
            <Skeleton width="160px" height="21px" />
            <Skeleton width="80px" height="21px" />
          </Box>
        </Box>
      ))}
    </Box>
  )

  const handleSearch = e => {
    setQuery({ search: e.target.value })
  }

  return (
    <>
      <Header title="PO Search" onBackHistoryBack />
      <TextField
        className={classes.searchInput}
        placeholder="Search..."
        onChange={handleSearch}
        value={searchText}
        data-test="po-search-field"
      />
      <Box className={classes.searchContainer}>
        {searchText !== '' && (
          <Box className={classes.listContainer} ref={listRef}>
            {purchaseOrders.map(po => (
              <Box
                key={po.id}
                className={classes.itemContainer}
                data-test="po-item"
              >
                <Link
                  to={`${URL.PURCHASE_ORDERS}/${po.id}`}
                  className={classes.link}
                >
                  <Typography variant="body2">#{po.id}</Typography>
                  <Typography variant="body1">
                    {allBrands ? (
                      vendorMap[po.brand_id]
                    ) : (
                      <Skeleton width="80px" height="21px" />
                    )}
                  </Typography>
                  <Typography variant="body1">
                    {TERM_LOOKUP[po.terms]}
                  </Typography>
                  <Box className={classes.etaStatusRow}>
                    <Typography variant="body2" component="span">
                      Estimated Arrival{' '}
                      {moment(po.estimated_arrival_at).format('M/D/YY')}
                    </Typography>
                    <PurchaseOrderStatus
                      className={classes.status}
                      status={po.status}
                    />
                  </Box>
                </Link>
              </Box>
            ))}
            {isFetchingPurchaseOrders && Loading}
          </Box>
        )}
      </Box>
    </>
  )
}

PurchaseOrderSearchScreen.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(PurchaseOrderSearchScreen)
