import { colorsAF, warnings, white } from 'theme/colors'

export default {
  root: {
    minWidth: 200,
    background: white,
    borderRadius: 3,
    '&.Mui-error': {
      color: warnings.error,
    },
    // All the notchedOutline borderColors need to be set from here.
    // The first wasn't working from the normal overried, the rest need
    // the context of the root class.
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: colorsAF.semiGray,
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: colorsAF.fast,
    },
    '&.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: warnings.danger,
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: colorsAF.fast,
    },
    '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      borderColor: colorsAF.semiGray,
    },
    '&:hover.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      borderColor: colorsAF.semiGray,
    },
  },
  input: {
    lineHeight: '20px',
    padding: '26px 12px 14px',
  },
  inputMarginDense: {
    padding: '16px 12px 4px !important',
  },
}
