import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import head from 'lodash/head'
import isEmpty from 'lodash/isEmpty'
import { withStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import CloseIcon from '@material-ui/icons/Close'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'
import IconButton from '@material-ui/core/IconButton'
import { FixedSizeList as List } from 'react-window'

import { PRODUCT_RESULTS_POPOVER_TYPES } from 'constants/enums'

import TextField from 'components/TextField'
import UnknownProduct from 'assets/unknown_product.jpg'

import styles from './ProductResultsPopoverStyles'

const ITEM_SIZE_PX = 100
const MAX_LIST_SIZE_PX = 484

const ProductResultsPopover = ({
  classes,
  className,
  options,
  activeProducts,
  selectedOptions,
  onTextChange,
  onAddItem,
  onRemoveItem,
  type,
  loading,
}) => {
  const [items, setItems] = useState(options)
  const [isActive, setIsActive] = useState(false)

  useEffect(() => {
    setItems(options)
  }, [options])

  const handleTextChange = e => {
    const { value } = e.target
    setItems(
      options.filter(
        opt =>
          opt?.product?.title.toLowerCase().includes(value.toLowerCase()) ||
          opt?.product?.vendor.toLowerCase().includes(value.toLowerCase())
      )
    )
    onTextChange && onTextChange(e)
  }

  const getProductImage = product =>
    head(product?.images)?.thumbnail_url ||
    head(product?.product?.images)?.thumbnail_url ||
    head(product?.variant?.images)?.thumbnail_url

  const hasPODetails = item =>
    type === PRODUCT_RESULTS_POPOVER_TYPES.po &&
    (item?.color?.label || item?.size)

  const renderItemAction = item => {
    if (activeProducts.find(p => p.id === item?.id)) {
      return <Typography className={classes.addedLabel}>added</Typography>
    }

    if (selectedOptions.find(p => p.id === item?.id)) {
      return (
        <RemoveCircleIcon
          className={classes.removeIcon}
          onClick={() => onRemoveItem && onRemoveItem(item)}
        />
      )
    }

    return (
      <AddCircleIcon
        className={classes.addIcon}
        onClick={() => onAddItem && onAddItem(item)}
        data-test="add-product-button"
      />
    )
  }

  return (
    <Box
      className={classNames({
        [classes.root]: true,
        [className]: Boolean(className),
      })}
    >
      <Box className={classes.innerRoot}>
        <TextField
          placeholder="Search name, upc, or sku"
          onFocus={() => setIsActive(true)}
          onChange={handleTextChange}
          fullWidth
          data-test="product-search"
          loading={loading}
        />

        {!isEmpty(items) && isActive && (
          <Box className={classes.popover}>
            <Box className={classes.popoverHeader}>
              <Typography className={classes.label}>Results</Typography>
              <IconButton
                edge="end"
                onClick={() => setIsActive(false)}
                className={classes.closePopover}
                data-test="close-popover-button"
              >
                <CloseIcon />
              </IconButton>
            </Box>

            <Box className={classes.popoverBody}>
              <List
                itemSize={ITEM_SIZE_PX}
                itemCount={items.length}
                width="100%"
                height={
                  items.length * ITEM_SIZE_PX > MAX_LIST_SIZE_PX
                    ? MAX_LIST_SIZE_PX
                    : items.length * ITEM_SIZE_PX
                }
              >
                {({ index, style }) => (
                  <Box
                    display="flex"
                    mb={2}
                    pl={2}
                    pr={2}
                    style={style}
                    key={`${items[index]?.title}-${index}`}
                  >
                    <Box flexShrink={0} height={80} width={80} mr="24px">
                      <img
                        src={getProductImage(items[index]) || UnknownProduct}
                        alt={items[index]?.product?.title}
                        className={classes.image}
                      />
                    </Box>
                    <Box flexGrow={1} width={160}>
                      <Typography variant="overline" className={classes.vendor}>
                        {items[index]?.vendor || items[index]?.product?.vendor}
                      </Typography>
                      <Typography
                        className={classNames({
                          [classes.title]: true,
                          [classes.oneLine]: hasPODetails(items[index]),
                        })}
                      >
                        {items[index]?.product?.title || items[index]?.title}
                      </Typography>
                      {hasPODetails(items[index]) && (
                        <Typography className={classes.subtitle}>
                          {items[index]?.color?.label}
                          {items[index]?.color?.label && items[index]?.size
                            ? ' / '
                            : null}
                          {items[index]?.size}
                        </Typography>
                      )}
                    </Box>
                    <Box display="flex" justifyContent="space-between">
                      {renderItemAction(items[index])}
                    </Box>
                  </Box>
                )}
              </List>
            </Box>
          </Box>
        )}
      </Box>
      {isActive && (
        <Box
          className={classes.overlay}
          onClick={() => setIsActive(false)}
          data-test="popover-overlay"
        />
      )}
    </Box>
  )
}

ProductResultsPopover.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  selectedOptions: PropTypes.arrayOf(PropTypes.object),
  onTextChange: PropTypes.func,
  onAddItem: PropTypes.func,
  onRemoveItem: PropTypes.func,
  type: PropTypes.string,
  activeProducts: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
}

export default withStyles(styles)(ProductResultsPopover)
