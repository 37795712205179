import React from 'react'
import PropTypes from 'prop-types'
import { TAB_VARIANT_TYPES } from 'constants/enums'
import { withStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import MuiTab from '@material-ui/core/Tab'
import MuiTabs from '@material-ui/core/Tabs'
import Typography from '@material-ui/core/Typography'

import styles from './TabsStyles'

const FormattedChildren = ({ children }) =>
  typeof children === 'string' ? (
    <Box p={3}>
      <Typography>{children}</Typography>
    </Box>
  ) : (
    children
  )
FormattedChildren.propTypes = {
  children: PropTypes.node,
}

const TabPanel = ({ className, children, value, index, ...other }) => (
  <div
    className={className}
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    {...other}
  >
    {value === index && <FormattedChildren>{children}</FormattedChildren>}
  </div>
)

TabPanel.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  value: PropTypes.number,
  index: PropTypes.number,
}

const Tabs = ({ classes, tabLabels, tabContents, onChange, variant, defaultTab = 0 }) => {
  const [value, setValue] = React.useState(defaultTab)

  const handleChange = (e, newValue) => {
    setValue(newValue)
    onChange && onChange(newValue)
  }

  return (
    <>
      <div className={classes.tabsItem}>
        <MuiTabs
          classes={{
            indicator: classes.indicator,
            root: classes.root ? classes.root : {},
          }}
          value={value}
          onChange={handleChange}
          variant={variant}
          className={classes.tabs}
          centered
        >
          {tabLabels.map(label => (
            <MuiTab
              key={label}
              classes={{ root: classes.root }}
              label={label}
              wrapped
            />
          ))}
        </MuiTabs>
      </div>
      {tabContents.map((content, contentIndex) => (
        <TabPanel
          key={contentIndex}
          className={classes.tabPanel}
          value={value}
          index={contentIndex}
        >
          {content}
        </TabPanel>
      ))}
    </>
  )
}

Tabs.defaultProps = {
  tabLabels: [],
  tabContents: [],
  variant: TAB_VARIANT_TYPES.standard,
}

Tabs.propTypes = {
  classes: PropTypes.object.isRequired,
  tabLabels: PropTypes.arrayOf(PropTypes.string),
  tabContents: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.node, PropTypes.string])
  ),
  onChange: PropTypes.func,
  variant: PropTypes.oneOf(Object.values(TAB_VARIANT_TYPES)),
  defaultTab: PropTypes.number
}

export default withStyles(styles)(Tabs)
