const jssStyles = theme => ({
  root: {
    color: theme.palette.text.primary,
    fontSize: 16,
    fontWeight: 'bold',
    textTransform: 'none',
  },
  indicator: {
    backgroundColor: theme.palette.primary.main,
    top: '0px',
  },
  tabs: {
    borderTop: '1px solid #e9e9e9',
  },
  tabPanel: {
    color: theme.palette.text.primary,
  },
  tabsItem: {
    position: 'fixed',
    bottom: '0',
    width: '100%',
    zIndex: 999,
    backgroundColor: '#FFFFFF',
    height: 70,
  },
})

export default jssStyles
