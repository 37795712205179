const jssStyles = theme => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    '& > div': {
      margin: theme.spacing(0, 0.5, 2),
    },
  },
  buttons: {
    display: 'flex',
    '& button': {
      flexGrow: '1',
      margin: theme.spacing(1),
    },
  },
  binContainer: {
    display: 'flex',
    borderRadius: 6,
    backgroundColor: theme.palette.colorsAF.ultraLightGray,
    padding: theme.spacing(1),
    '& > div': {
      width: '50%',
    },
    '& div': {
      minWidth: 0,
    },
  },
  bin: {
    marginLeft: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  quantity: {
    marginBottom: 0,
  },
  hidden: {
    display: 'none',
  },
  lineItem: {
    backgroundColor: theme.palette.colorsAF.ultraLightGray,
    padding: theme.spacing(1),
    borderRadius: 6,
  },
  itemBox: {
    marginLeft: '30px',
    marginRight: '30px',
  },
})

export default jssStyles
