export const API_PARAMS = {
  SEARCH: 'search',
  SEARCH_BY_NUMBER: 'search_by_number',
  SORT: 'sort_key',
  SORT_DIRECTION: 'sort_direction',
}

export const LIST_PATHS = {
  PRODUCTS_VENDOR: 'products.vendor',
  PRODUCTS_TITLE: 'products.title',
  PRODUCTS_INVENTORY_LOCATION_ID: 'inventory.location_id',
  PRODUCTS_SUMMARY_LOCATION_ID: 'location_id',
  ORDERS_STATUS: 'orders.status',
  ORDERS_FIRST_NAME: 'orders.first_name',
  ORDERS_NUMBER: 'orders.number',
  ORDERS_DELIVERY_STARTS: 'orders.delivery_starts_at',
  ORDERS_LOCATION_ID: 'orders.location_id',
  VARIANTS_SKU: 'variants.sku',
  VARIANTS_TITLE: 'variants.title',
  VARIANTS_PRICE: 'variants.price',
  VARIANTS_LOCATIONS_ID: 'locations.id',
  USERS_ROLE: 'users.role',
  USERS_FIRST_NAME: 'users.first_name',
  USERS_LAST_NAME: 'users.last_name',

  PURCHASE_ORDERS_ETA: 'purchase_orders.estimated_arrival_at',
  PURCHASE_ORDERS_PO_NUMBER: 'purchase_orders.id',
  PURCHASE_ORDERS_TERMS: 'purchase_orders.terms',
  PURCHASE_ORDERS_ORDER_DATE: 'purchase_orders.order_date',
  PURCHASE_ORDERS_ETA_SORT: 'purchase_orders.estimated_arrival_at',
  PURCHASE_ORDERS_LOCATION_ID: 'locations.id',
  PURCHASE_ORDERS_VENDOR_FILTER: 'brands.id',
  PURCHASE_ORDERS_TERM_FILTER: 'purchase_orders.terms',
  PURCHASE_ORDERS_STATUS_FILTER: 'purchase_orders.status',
  PURCHASE_ORDERS_STATUS_REL: 'purchase_orders.status.rel',


  TRANSFER_ORDERS_ETA: 'transfer_orders.estimated_arrival_at',
  TRANSFER_ORDERS_PO_NUMBER: 'transfer_orders.id',
  TRANSFER_ORDERS_TERMS: 'transfer_orders.terms',
  TRANSFER_ORDERS_ORDER_DATE: 'transfer_orders.order_date',
  TRANSFER_ORDERS_ETA_SORT: 'transfer_orders.estimated_arrival_at',
  TRANSFER_ORDERS_LOCATION_ID: 'transfer_orders.destination_location_id',
  TRANSFER_ORDERS_TERM_FILTER: 'transfer_orders.terms',
  TRANSFER_ORDERS_STATUS_FILTER: 'transfer_orders.status',
  TRANSFER_ORDERS_STATUS_REL: 'transfer_orders.status.rel',

  CYCLE_COUNT_CREATED_AT: 'purchase_orders.created_at',
}

export const SORT = {
  ASC: 'asc',
  DESC: 'desc',
}

export const SORT_DEFAULT = SORT.ASC

export const REL = {
  IN: 'in',
}

/* The below is copied from dashboard; keep in sync */
export const FIELD_PATHS = {
  ORDER_STATUS: 'order.status',
  ORDER_NOTES: 'order.notes',
  ORDER_DELIVERY_METHOD: 'order.delivery_method',
  INVENTORY_QUANTITY: 'inventory.quantity',
}

// rel can be ['=', '!=', '>', '>=', '<', '<=', 'between'], default '='
const makeFilter = (field, relationship, value) => ({
  [field]: value,
  ...(relationship === '=' ? {} : { [`${field}.rel`]: relationship }),
})

// So these can fit on one line
const FP = FIELD_PATHS

export const LOW_STOCK_INVENTORY = makeFilter(FP.INVENTORY_QUANTITY, '<', 2)

export const HIGH_STOCK_INVENTORY = makeFilter(FP.INVENTORY_QUANTITY, '>=', 5)

export const SOLD_OUT_INVENTORY = makeFilter(FP.INVENTORY_QUANTITY, '=', 0)
