import { useResourceRQ } from 'hooks'

import {
  purchaseOrderEndpoint,
  purchaseOrderListEndpoint,
  purchaseOrderSummaryEndpoint,
  purchaseOrderInOrderEndpoint,
  purchaseOrderInCycleCountEndpoint,
} from 'api/endpoints'

import { useAPIContext } from 'context'

const useAdminPurchaseOrder = (purchaseOrderId, { type, onSuccess } = {}) => {
  const { api } = useAPIContext()

  const {
    create: createPurchaseOrder,
    data: purchaseOrder,
    update: updatePurchaseOrder,
    isLoading: isLoadingPurchaseOrder,
    isError: isPurchaseOrderError,
    error: purchaseOrderError,
  } = useResourceRQ({
    endpoint: purchaseOrderEndpoint,
    args: [purchaseOrderId, type],
    name: 'purchase_order',
    fetchingEnabled: typeof purchaseOrderId === 'number',
    dependents: [purchaseOrderListEndpoint, purchaseOrderSummaryEndpoint],
    onSuccess,
  })

  const inOrder = async () => {
    const res = await api.get(purchaseOrderInOrderEndpoint(purchaseOrderId))
    return res.data.order_numbers
  }

  const inCycleCount = async () => {
    const res = await api.get(
      purchaseOrderInCycleCountEndpoint(purchaseOrderId)
    )
    return res.data.purchase_order_ids
  }

  return {
    purchaseOrder,
    updatePurchaseOrder,
    createPurchaseOrder,
    isLoadingPurchaseOrder,
    isPurchaseOrderError,
    purchaseOrderError,
    inOrder,
    inCycleCount,
  }
}

export default useAdminPurchaseOrder
