import merge from 'deepmerge'
import { storageAvailable } from 'utils/general'
import { STORAGE_TRANSFER_ORDER_SHIPMENT_ITEMS } from 'lib/config'

if (!storageAvailable('sessionStorage')) {
  console.error('This website requires sessionStorage enabled')
}

const { sessionStorage } = window

export const loadState = () => {
  try {
    const str = sessionStorage.getItem(STORAGE_TRANSFER_ORDER_SHIPMENT_ITEMS)
    if (!str) {
      return { to: {} }
    }
    return JSON.parse(str) ?? { to: {} }
  } catch (e) {
    console.error(
      `sessionStorage.getItem(${STORAGE_TRANSFER_ORDER_SHIPMENT_ITEMS}) failed`,
      e
    )
  }
  return {}
}

export const saveState = state => {
  try {
    sessionStorage.setItem(
      STORAGE_TRANSFER_ORDER_SHIPMENT_ITEMS,
      JSON.stringify(state)
    )
    return state
  } catch (e) {
    console.error(
      `sessionStorage.setItem(${STORAGE_TRANSFER_ORDER_SHIPMENT_ITEMS}) failed`,
      e
    )
  }
}

export const saveStatus = (toId, shipmentId, status) => {
  saveState(
    merge(loadState(), { to: { [toId]: { [shipmentId]: { status } } } })
  )
}

export const saveReceived = (toId, shipmentId, received) => {
  saveState(
    merge(loadState(), { to: { [toId]: { [shipmentId]: { received } } } })
  )
}

export const saveDamaged = (toId, shipmentId, damaged) => {
  saveState(
    merge(loadState(), { to: { [toId]: { [shipmentId]: { damaged } } } })
  )
}

export const saveAssignedToBins = (toId, shipmentId, assignedToBins) => {
  saveState(
    merge(loadState(), { to: { [toId]: { [shipmentId]: { assignedToBins } } } })
  )
}
