import React, { useEffect, useState, useRef } from 'react'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Link, useHistory } from 'react-router-dom'
import debounce from 'lodash/debounce'

import moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'

import { Box, Typography } from '@material-ui/core'

import { useLocationsContext } from 'context'
import { deliveryIsSoon } from 'helpers/order'
import { API_PARAMS, LIST_PATHS } from 'constants/queryParams'
import { ORDER_STATES_TYPES } from 'constants/general'
import { URL } from 'constants/navigation'
import { TEXT_FIELD_TYPES } from 'constants/enums'
import { GIFT_ICON_POSITIONING } from 'constants/styles'
import {
  useAdminOrders,
  useLatestCallback,
  useQuery,
  useRestoreScroll,
} from 'hooks'

import DeliveryTimer from 'components/DeliveryTimer'
import Header from 'components/Header'
import TextField from 'components/TextField'

import GiftIcon from 'icons/GiftIcon'

import styles from './OrderSearchScreenStyles'

const UPDATE_TIMER_MS = 1000
const DEBOUNCE_MS = 300

momentDurationFormatSetup(moment)

const OrderSearchScreen = ({ classes }) => {
  const history = useHistory()
  const { orders, findOrders } = useAdminOrders({})
  const { location } = useLocationsContext()
  const listRef = useRef(null)
  useRestoreScroll(
    listRef,
    'order-search',
    Array.isArray(orders) && orders.length > 0
  )

  const [now, setNow] = useState(moment())
  const [params, setParams] = useState()

  const { query, setQuery } = useQuery(false, {
    initFallback: {
      search: '',
    },
  })

  const searchText = query.search

  const fetchOrders = useLatestCallback(() => {
    findOrders(params)
  })

  const debounceList = debounce(setParams, DEBOUNCE_MS, {
    leading: true,
  })

  // also forces the timer to tick every second
  useEffect(() => {
    const timer = setInterval(() => {
      setNow(moment())
    }, UPDATE_TIMER_MS)
    return () => clearInterval(timer)
  }, [])

  useEffect(() => {
    if (location && params) {
      fetchOrders()
    }
  }, [params, fetchOrders, location])

  useEffect(() => {
    if (searchText !== '') {
      debounceList({
        [LIST_PATHS.ORDERS_LOCATION_ID]: location?.id,
        [API_PARAMS.SEARCH_BY_NUMBER]: searchText,
        [LIST_PATHS.ORDERS_STATUS]: [
          ORDER_STATES_TYPES.paid,
          ORDER_STATES_TYPES.picking,
          ORDER_STATES_TYPES.dispatched,
        ].join(','),
      })
    }
  }, [location, searchText])

  const handleSearch = e => {
    setQuery({ search: e.target.value })
  }

  return (
    <>
      <Box className={classes.root}>
        <Header
          breadcrumbs={[
            { text: 'Orders', href: URL.ORDERS },
            { text: 'Search', active: true },
          ]}
          onBackClick={() => history.push(URL.ORDERS)}
        />
        <TextField
          className={classes.searchInput}
          placeholder="Search..."
          onChange={handleSearch}
          value={searchText}
          data-test="order-search-field"
          type={TEXT_FIELD_TYPES.number}
        />
        <Box className={classes.tabRoot}>
          <Box className={classes.listContainer} ref={listRef}>
            {Array.isArray(orders) &&
              orders.map(order => {
                const deliveryStartsAtText =
                  order.delivery_starts_at &&
                  moment(order.delivery_starts_at).format('h')
                const deliveryEndsAtText =
                  order.delivery_ends_at &&
                  moment(order.delivery_ends_at).format('ha')

                const shouldHighlight = deliveryIsSoon(
                  now,
                  order.delivery_starts_at
                )

                const borderColorStyle = {
                  borderLeft: shouldHighlight ? '4px solid #E52B20' : 'none',
                }

                return (
                  <div key={order.id}>
                    <Link
                      to={`/orders/${order.id}`}
                      className={classes.rowLink}
                    >
                      <Box
                        className={classes.listRow}
                        style={borderColorStyle}
                        data-test="order-item"
                      >
                        <Box className={styles.listLeft} color="black">
                          <Typography variant="body2">
                            {order.total_line_items_quantity} Items
                          </Typography>
                          <Typography variant="body1">
                            Order #{order.number}
                            {!isEmpty(order.gift_detail) && (
                              <span style={GIFT_ICON_POSITIONING}>
                                <GiftIcon />
                              </span>
                            )}
                          </Typography>
                          <Typography variant="body1">
                            {order.first_name} {order.last_name}
                          </Typography>
                          <Box mt={1.8}>
                            <Typography variant="body2">
                              Scheduled for {deliveryStartsAtText}-
                              {deliveryEndsAtText}
                            </Typography>
                          </Box>
                        </Box>
                        <DeliveryTimer
                          deliveryStartsAt={order.delivery_starts_at}
                        />
                      </Box>
                    </Link>
                  </div>
                )
              })}
          </Box>
        </Box>
      </Box>
    </>
  )
}

OrderSearchScreen.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(OrderSearchScreen)
