import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'

import styles from './SuspenseSpinnerStyles'

const SuspenseSpinner = ({ classes }) => (
  <Backdrop className={classes.backdrop} open>
    <CircularProgress variant="indeterminate" size={70} thickness={5} />
  </Backdrop>
)

SuspenseSpinner.propTypes = {
  classes: PropTypes.object,
}

export default withStyles(styles)(SuspenseSpinner)
