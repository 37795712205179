const jssStyles = theme => ({
  root: {
    padding: 0,
    position: 'relative',
  },
  minHeight: {
    minHeight: 148,
  },
  content: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  image: {
    width: '100%',
    height: 95,
    objectFit: 'cover',
    borderRadius: 6,
  },
  carouselStatus: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  listItem: {
    borderTop: `1px solid ${theme.palette.colorsAF.ultraLightGray}`,
  },
  carouselCard: {
    borderRadius: 6,
    minHeight: 148,
    backgroundColor: theme.palette.colorsAF.ultraLightGray,
  },
})

export default jssStyles
