import { colorsAF, white, primary } from 'theme/colors'
import { fontWeight } from 'theme/typography'

export default {
  root: {
    height: 40,
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: fontWeight.light,
    textTransform: 'none',
  },
  contained: {
    backgroundColor: colorsAF.ultraLightGray,
    color: colorsAF.darkGray,
    boxShadow: 'none',
    '&$disabled': {
      opacity: 0.2,
      backgroundColor: white,
      color: colorsAF.lightGray,
    },
    '&$disabled[disabled]:hover': {
      backgroundColor: white,
    },
  },
  containedPrimary: {
    color: white,
    backgroundColor: primary,
  },
  sizeSmall: {
    height: 37,
  },
  sizeLarge: {
    height: 53,
  },
}
