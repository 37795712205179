import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'

import * as propTypes from 'constants/propTypes'
import { COUNT_STATUS } from 'constants/enums'
import PlainCheckIcon from 'icons/PlainCheckIcon'
import FlagIcon from 'icons/FlagIcon'
import DamagedIcon from 'icons/DamagedIcon'

import styles from './CountStatusPillStyles'

const CountStatusPill = ({ classes, count, total, status, onClick, props }) => (
  <Box
    className={classNames({
      [classes.root]: true,
      [classes.gray]: status === COUNT_STATUS.inProgress,
      [classes.blue]: status === COUNT_STATUS.complete,
      [classes.yellow]: status === COUNT_STATUS.flagged,
      [classes.red]: status === COUNT_STATUS.broken,
      [classes.gray]: status === COUNT_STATUS.missing,
    })}
    onClick={onClick}
    {...props}
  >
    {
      {
        [COUNT_STATUS.inProgress]: <PlainCheckIcon size={12} />,
        [COUNT_STATUS.complete]: <PlainCheckIcon size={12} />,
        [COUNT_STATUS.flagged]: <FlagIcon size={12} />,
        [COUNT_STATUS.broken]: <DamagedIcon size={12} />,
        [COUNT_STATUS.missing]: <FlagIcon size={12} />,
      }?.[status]
    }
    {count}/{total}
  </Box>
)

CountStatusPill.propTypes = {
  classes: PropTypes.object,
  count: PropTypes.number,
  total: PropTypes.number,
  status: propTypes.countStatus,
  onClick: PropTypes.func,
  props: PropTypes.object,
}

export default withStyles(styles)(CountStatusPill)
