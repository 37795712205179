const TABS_HEIGHT_PX = 60

const jssStyles = theme => ({
  menuFab: {
    bottom: TABS_HEIGHT_PX + 16,
    right: 16,
    color: 'white',
  },
  fullList: {
    padding: 0,
    marginTop: theme.spacing(1),
  },
  listItem: {
    padding: '16px 0',
    marginBottom: 8,
    border: '1px solid #E0E0E0',
    borderRadius: 5,
    textAlign: 'center',
  },
  listItemText: {
    fontSize: 14,
    fontWeight: 600,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  logoContainer: {
    height: 48,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
})

export default jssStyles
