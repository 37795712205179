// const vh = window.innerHeight * 0.01

const jssStyles = theme => ({
  backdrop: {
    backgroundColor: 'rgb(0, 0, 0, 0.5)',
    zIndex: 9999,
  },
})

export default jssStyles
