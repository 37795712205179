/* WARNING: copied from dashboard; keep in sync */
import { useResource, useResourceList } from 'hooks'

const API_LOCATIONS = '/api/v1/admin/locations'
const API_RESOURCE_NAME = 'location'

const useAdminLocations = () => {
  const {
    isLoading: isLoadingLocations,
    data: locations,
    hasData: hasLocations,
    list: fetchLocations,
  } = useResourceList({ url: API_LOCATIONS })

  const { update: updateLocations } = useResource({
    url: API_LOCATIONS,
    name: API_RESOURCE_NAME,
  })

  return {
    isLoadingLocations,
    updateLocations,
    locations,
    hasLocations,
    fetchLocations,
  }
}

export default useAdminLocations
