import { useAPIContext } from 'context'
import { useResourceRQ } from 'hooks'

import { uberOrderEndpoint, uberOrderListEndpoint } from 'api/endpoints'

const useAdminUberOrder = uberOrderId => {
  const { api } = useAPIContext()

  const {
    create: createUberOrder,
    data: uberOrder,
    update: updateUberOrder,
    isLoading: isLoadingUberOrder,
    isError: isUberOrderError,
    error: uberOrderError,
  } = useResourceRQ({
    endpoint: uberOrderEndpoint,
    args: [uberOrderId],
    name: 'uber_eats_order',
    fetchingEnabled: Boolean(uberOrderId),
    dependents: [uberOrderListEndpoint],
  })

  const inCycleCount = async orderId => {
    const res = await api.get(
      `/api/v1/admin/uber_eats_orders/${orderId}/in_cycle_count`
    )
    return res.data.purchase_order_ids
  }

  const printOrderLabel = async (orderId, printerId) => {
    await api.post(`${uberOrderEndpoint(orderId)}/print?printer_id=${printerId}`)
  }

  const short = async (orderId, shorts) => {
    await api.post(`${uberOrderEndpoint(orderId)}/short`, { shorts })
  }

  return {
    uberOrder,
    updateUberOrder,
    createUberOrder,
    isLoadingUberOrder,
    isUberOrderError,
    uberOrderError,

    inCycleCount,
    printOrderLabel,
    short
  }
}

export default useAdminUberOrder
