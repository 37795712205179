import React, { useEffect } from 'react'
import { Route, Redirect } from 'react-router'
import { Switch } from 'react-router-dom'

import { useAuth } from 'hooks'
import { URL } from 'constants/navigation'
import AdminRoute from 'containers/AdminRoute'
import LoginScreen from 'screens/auth/LoginScreen'
import OrderShowScreen from 'screens/orders/OrderShowScreen'
import UberOrderShowScreen from 'screens/orders/UberOrderShowScreen'
import OrderSearchScreen from 'screens/orders/OrderSearchScreen'
import OrderListScreen from 'screens/orders/OrderListScreen'
import {
  PurchaseOrderListScreen,
  PurchaseOrderShowScreen,
  PurchaseOrderSearchScreen,
} from 'screens/PurchaseOrders'
import { HomeScreen } from 'screens/Home'
import { LocationLookupScreen, LocationScreen } from 'screens/Location'
import { CCScreen } from 'screens/CC'
import PurchaseOrderVariantShowScreen from 'screens/PurchaseOrders/PurchaseOrderVariantShowScreen'
import {
  TransferOrderListScreen,
  TransferOrderShowScreen,
  TransferOrderSearchScreen,
} from 'screens/TransferOrders'
import TransferOrderVariantShowScreen from 'screens/TransferOrders/TransferOrderVariantShowScreen'
import { CycleCountListScreen, CycleCountShowScreen } from 'screens/CycleCounts'
import CycleCountCreate from 'screens/CycleCounts/Create'
import CycleCountVariantShowScreen from 'screens/CycleCounts/CycleCountVariantShowScreen'

const Logout = () => {
  const { logoutUser } = useAuth()
  useEffect(() => {
    logoutUser()
  }, [])

  return null
}

const Routes = () => (
  <Switch>
    <Route path={URL.LOGOUT} component={Logout} />
    <Route path={`${URL.LOGIN}/:token`} component={LoginScreen} />
    <Route path={URL.LOGIN} component={LoginScreen} />
    <AdminRoute path={URL.HOME} component={HomeScreen} exact />
    <AdminRoute
      path={URL.PURCHASE_ORDERS}
      component={PurchaseOrderListScreen}
      exact
    />
    <AdminRoute
      path={URL.PURCHASE_ORDERS_SEARCH}
      component={PurchaseOrderSearchScreen}
      exact
    />
    <AdminRoute
      path={`${URL.PURCHASE_ORDERS}/:id${URL.PURCHASE_ORDER_VARIANTS}/:variantId`}
      component={PurchaseOrderVariantShowScreen}
    />
    <AdminRoute
      path={`${URL.PURCHASE_ORDERS}/:id`}
      component={PurchaseOrderShowScreen}
    />

    <AdminRoute
      path={URL.TRANSFER_ORDERS}
      component={TransferOrderListScreen}
      exact
    />
    <AdminRoute
      path={URL.TRANSFER_ORDERS_SEARCH}
      component={TransferOrderSearchScreen}
      exact
    />
    <AdminRoute
      path={`${URL.TRANSFER_ORDERS}/:id${URL.TRANSFER_ORDER_VARIANTS}/:variantId`}
      component={TransferOrderVariantShowScreen}
    />
    <AdminRoute
      path={`${URL.TRANSFER_ORDERS}/:id`}
      component={TransferOrderShowScreen}
    />
    <AdminRoute
      path={URL.CYCLE_COUNTS}
      component={CycleCountListScreen}
      exact
    />
    <AdminRoute
      path={`${URL.CYCLE_COUNTS_CREATE}/:type`}
      component={CycleCountCreate}
    />
    <AdminRoute
      path={`${URL.CYCLE_COUNTS}/:id${URL.CYCLE_COUNT_VARIANTS}/:variantId`}
      component={CycleCountVariantShowScreen}
    />
    <AdminRoute
      path={`${URL.CYCLE_COUNTS}/:id`}
      component={CycleCountShowScreen}
    />

    <AdminRoute path={URL.ORDERS} component={OrderListScreen} exact />
    <AdminRoute
      path={`${URL.ORDERS}${URL.ORDERS_SEARCH}`}
      component={OrderSearchScreen}
      exact
    />
    <AdminRoute
      path={`${URL.ORDERS}/uber/:id`}
      component={UberOrderShowScreen}
    />
    <AdminRoute path={`${URL.ORDERS}/:id`} component={OrderShowScreen} />
    <AdminRoute
      path={`${URL.ORDERS}/uber/:id`}
      component={UberOrderShowScreen}
    />
    <AdminRoute path={`${URL.ORDERS}/:id`} component={OrderShowScreen} />
    <AdminRoute path={URL.LOCATION} component={LocationLookupScreen} exact />
    <AdminRoute path={`${URL.LOCATION}/:location`} component={LocationScreen} />
    <AdminRoute
      path="/cc/:cycle_count_id/plate/:cycle_count_plate_id"
      component={CCScreen}
    />

    <Redirect to={LoginScreen} />
  </Switch>
)

export default Routes
