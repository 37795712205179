import { locationPrintersEndpoint } from 'api/endpoints'
import { useAPIContext } from 'context'
import { useResourceRQ } from 'hooks'
import { useQuery } from 'react-query'

const useAdminPrinters = ({ locationId, printerId, type }) => {
  const { api } = useAPIContext()

  const { create, update, destroy } = useResourceRQ({
    endpoint: locationPrintersEndpoint,
    args: [locationId, printerId],
    name: 'printer',
    dependents: [() => locationPrintersEndpoint(locationId)],
  })

  const {
    isLoading,
    isFetching,
    data: response,
  } = useQuery(
    [locationPrintersEndpoint(locationId)],
    () => api.get(locationPrintersEndpoint(locationId)),
    {
      enabled: true,
    }
  )

  return {
    isLoading,
    isFetching,
    createPrinter: create,
    printers: response?.data
      ? response.data.filter(type ? p => p.type === type : () => true)
      : null,
    updatePrinter: update,
    destroyPrinter: destroy,
  }
}

export default useAdminPrinters
