import React from 'react'
import PropTypes from 'prop-types'

const PlainCheckIcon = ({ fill, size, className }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 12 12"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.984 3.14806C11.1676 3.34548 11.1676 3.66556 10.984 3.86298L5.33017 9.93973L2.33698 6.72262C2.1533 6.52521 2.1533 6.20513 2.33698 6.00771C2.52065 5.81029 2.81845 5.81029 3.00213 6.00771L5.33017 8.50991L10.3188 3.14806C10.5025 2.95065 10.8003 2.95065 10.984 3.14806Z"
      fill={fill}
    />
  </svg>
)

PlainCheckIcon.defaultProps = {
  size: 24,
  fill: 'currentColor',
}

PlainCheckIcon.propTypes = {
  fill: PropTypes.string,
  size: PropTypes.number,
  className: PropTypes.string,
}

export default PlainCheckIcon
