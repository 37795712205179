const jssStyles = theme => ({
  image: {
    width: '100%',
    height: 286,
    objectFit: 'cover',
  },
  row: {
    margin: theme.spacing(2),
  },
  colorDark: {
    color: theme.palette.grays.dark,
  },
  actions: {
    display: 'flex',
    position: 'fixed',
    bottom: 0,
    width: '100%',
    background: theme.palette.common.white,
    '& button': {
      flexGrow: '1',
      margin: theme.spacing(1),
    },
  },
  completeScan: {
    width: '100%',
  },
  spacer: {
    height: 46,
  },
})

export default jssStyles
