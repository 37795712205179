const jssStyles = theme => ({
  root: {
    backgroundColor: theme.palette.colorsAF.white,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  headerIcon: {
    color: theme.palette.text.primary,
    fontSize: 18,
    marginLeft: theme.spacing(2),
  },
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    overflow: 'hidden',
    padding: theme.spacing(0, 4),
    flexDirection: 'column',
    marginBottom: 0,
  },
  barcodeHint: {
    marginRight: 0,
  },
  barcodeInput: {
    padding: '20px 2px',
  },
  carouselContainer: {
    '-webkit-tap-highlight-color': 'rgba(0,0,0,0)',
    // eslint-disable-next-line no-dupe-keys
    '-webkit-tap-highlight-color': 'transparent',
    '& .slider-slide:focus': {
      outline: 'none',
    },
  },
  itemBox: {
    padding: theme.spacing(0, 0.5, 2),
  },
})

export default jssStyles
