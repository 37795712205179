import { useResourceRQ } from 'hooks'

import {
  cycleCountPlateEndpoint,
  cycleCountPlateListEndpoint,
} from 'api/endpoints'

const useAdminCycleCountPlate = ({ cycleCountId, plateId, enabled = true }) => {
  const {
    create: createCycleCountPlate,
    data: cycleCountPlate,
    update: updateCycleCountPlate,
    isLoading: isLoadingCycleCountPlate,
    isError: isCycleCountPlateError,
    error: cycleCountPlateError,
  } = useResourceRQ({
    endpoint: cycleCountPlateEndpoint,
    args: [cycleCountId, plateId],
    name: 'cycle_count_plate',
    fetchingEnabled: enabled,
    dependents: [cycleCountPlateListEndpoint],
  })

  const { destroy: destroyCycleCountPlate } = useResourceRQ({
    endpoint: cycleCountPlateEndpoint,
    args: [cycleCountPlate],
    fetchingEnabled: false,
  })

  return {
    cycleCountPlate,
    updateCycleCountPlate,
    createCycleCountPlate,
    isLoadingCycleCountPlate,
    isCycleCountPlateError,
    cycleCountPlateError,
    destroyCycleCountPlate,
  }
}

export default useAdminCycleCountPlate
