import React, { useState } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import MuiRadioGroup from '@material-ui/core/RadioGroup'
import Box from '@material-ui/core/Box'

import Radio from 'components/Radio'

import styles from './RadioGroupStyles'

const RadioGroup = ({ classes, options, disabled, onChange }) => {
  const [value, setValue] = useState(options[0]?.label)

  const handleChange = ({ target: { value: newValue } }) => {
    setValue(newValue)
    onChange && onChange(options.find(o => o.label === newValue)?.value)
  }

  const rootClassNames = classNames({
    [classes.root]: true,
    [classes.disabled]: disabled,
  })

  return (
    <MuiRadioGroup
      className={rootClassNames}
      value={value}
      disabled={disabled}
      onChange={handleChange}
    >
      {options &&
        options.map(({ label, disabled: thisOptionDisabled, expanded }) => (
          <Box key={label} className={classes.item}>
            <Radio label={label} disabled={thisOptionDisabled} />
            {value === label ? expanded : null}
          </Box>
        ))}
    </MuiRadioGroup>
  )
}

RadioGroup.defaultProps = {
  disabled: false,
}

RadioGroup.propTypes = {
  classes: PropTypes.object.isRequired,
  labels: PropTypes.arrayOf(PropTypes.string),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      disabled: PropTypes.bool,
      expanded: PropTypes.node,
    })
  ),
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
}

export default withStyles(styles)(RadioGroup)
