const jssStyles = theme => ({
  iconButton: {
    minWidth: 'auto',
    width: 44,
    '& .MuiButton-startIcon': {
      margin: 0,
    },
  },
})

export default jssStyles
