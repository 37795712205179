import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import LineItem from 'components/LineItem'
import Header from 'components/Header'
import TextField from 'components/TextField'
import Scanner from 'components/Scanner'

import styles from './AssignUPCStyles.js'

const AssignUPC = ({ classes, lineItem, onAssignUPC, onBack }) => {
  const [internalUPC, setInternalUPC] = useState('')
  const [showScanner, setShowScanner] = useState(false)

  const handleAssignUPC = () => {
    onAssignUPC(internalUPC)
  }

  const handleUPCChange = e => {
    const { value } = e.target
    setInternalUPC(value)
  }

  const lineItemComponent = (
    <LineItem
      className={classes.lineItem}
      lineItem={lineItem}
      showColor
      showSize
      status={null}
      hideBarcode
      onStatusClick={null}
    />
  )

  if (showScanner) {
    return (
      <Scanner
        title="Assign UPC"
        onScan={thisUPC => {
          setInternalUPC(thisUPC)
          setShowScanner(false)
        }}
        onClose={() => setShowScanner(false)}
        numbersOnly={false}
        verifyRemainder={false}
      >
        <Box mb={2}>
          <Box className={classes.itemBox}>{lineItemComponent}</Box>
        </Box>
      </Scanner>
    )
  }

  return (
    <Box className={classes.root}>
      <Header title="Assign UPC" onBackClick={onBack} />
      <Box>{lineItemComponent}</Box>
      <Box className={classes.upcContainer}>
        <Typography variant="overline">UPC</Typography>
        <TextField
          innerLabel="UPC"
          value={internalUPC}
          onChange={handleUPCChange}
        />
      </Box>
      <Box className={classes.buttons}>
        <Button
          variant="outlined"
          color="default"
          onClick={() => setShowScanner(true)}
        >
          Scan
        </Button>
        <Button variant="contained" color="primary" onClick={handleAssignUPC}>
          Assign UPC
        </Button>
      </Box>
    </Box>
  )
}

AssignUPC.propTypes = {
  classes: PropTypes.object.isRequired,
  lineItem: PropTypes.object.isRequired,
  onAssignUPC: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
}

export default withStyles(styles)(AssignUPC)
