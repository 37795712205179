// code copied from dashboard; keep in sync
import { useEffect } from 'react'
import createPersistedState from 'use-persisted-state'
import { usePrevious } from 'hooks'
const useScrollPositionState = createPersistedState('scroll')

// sets scrollY position of window based on a setting condition, i.e. when api calls are done
// also sets the scroll position when unmounting, i.e. a user navigates to a different page
export default function useRestoreScroll(element, key, setCondition = true) {
  const ensureBooleanSetCondition = Boolean(setCondition) // to prevent unnecessary re-restores

  const [scrollYStorage, setScrollYStorage] = useScrollPositionState({
    [key]: 0,
  })
  const prev = usePrevious(element?.current)
  const prevSetCondition = usePrevious(ensureBooleanSetCondition)

  useEffect(() => {
    const handler = () => {
      setScrollYStorage({ [key]: element.current.scrollTop })
    }
    if (key && element?.current) {
      element.current.addEventListener('scroll', handler)
    }
    return () => element?.current?.removeEventListener('scroll', handler)
  }, [element])

  useEffect(() => {
    // if our element changes or if our set condition goes from false to true
    if (
      key &&
      ((element?.current && prev !== element?.current) ||
        (ensureBooleanSetCondition && prevSetCondition === false))
    ) {
      // eslint-disable-next-line no-param-reassign
      element.current.scrollTop = scrollYStorage[key]
    }
  }, [prev, element, ensureBooleanSetCondition])
}
