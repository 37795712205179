import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Route } from 'react-router'
import { useHistory } from 'react-router-dom'

import {
  useLocationsContext,
  useLocalStorageContext,
  useAuthContext,
} from 'context'
import { URL } from 'constants/navigation'
import { ALL_LOCATION } from 'constants/general'
import { isAdmin } from 'utils/user'

const AdminRoute = ({ component, ...rest }) => {
  const history = useHistory()
  const { currentUser, isInitialized } = useAuthContext()
  const { location, locations } = useLocationsContext()
  const { setLocationId } = useLocalStorageContext()

  useEffect(() => {
    if (isInitialized && (locations || currentUser === null)) {
      if (!isAdmin(currentUser)) {
        console.info('User not authorized', URL.LOGIN, history)
        history.push(URL.LOGIN)
      }
      if (
        locations &&
        currentUser.default_location_id !== ALL_LOCATION.id &&
        location?.id !== currentUser.default_location_id
      ) {
        setLocationId(currentUser.default_location_id)
      }
      if (locations && !location) {
        history.push(URL.ADMIN_ORDERS)
      }
    }
  }, [currentUser, isInitialized, locations])

  return isAdmin(currentUser) ? (
    <Route {...rest} component={component} />
  ) : (
    <></>
  )
}

AdminRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.node, [PropTypes.element]]),
  redirect: PropTypes.string,
}

export default AdminRoute
