import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import isEmpty from 'lodash/isEmpty'
import { withStyles } from '@material-ui/core/styles'

import Snackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import styles from './AlertStyles'

const Alert = ({ classes, variant, open, message, onClose }) => {
  // Alternative implentation could be added to useAlert if we want clickAway
  // Alex: This is wrapped in a timeout because somehow the onClickAway can be
  // called immediately and the alert would close.
  // setAlert: a => setTimeout(() => setAlert(a), 200),

  const handleOnClose = (evt, reason) => {
    // clickaway seems to get triggered immediately after the message is shown
    if (reason !== 'clickaway') {
      onClose()
    }
  }

  return (
    <Snackbar
      className={classNames({
        [classes.snackbar]: true,
        [classes[variant]]: true,
      })}
      open={open}
      autoHideDuration={4000}
      onClose={handleOnClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      message={
        Array.isArray(message) && !isEmpty(message) ? (
          <ul>
            {message.map((msg, idx) => (
              <li key={idx}>{msg}</li>
            ))}
          </ul>
        ) : (
          <span className={classes.message}>{message}</span>
        )
      }
      action={
        <IconButton key="close" color="inherit" onClick={onClose}>
          <CloseIcon className={classes.icon} />
        </IconButton>
      }
    />
  )
}

Alert.propTypes = {
  classes: PropTypes.object.isRequired,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  variant: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
}

export default withStyles(styles)(Alert)
