import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'

import Dialog, {
  DialogTitle,
  DialogContent,
  DialogActions,
} from 'components/Dialog'
import TextField from 'components/TextField'

import { TEXT_FIELD_TYPES } from 'constants/enums'

import styles from './InputDialogStyles.js'

const isNumber = v => typeof v === 'number'

const InputDialog = ({
  classes,
  children,
  title,
  label,
  label2,
  min = 0,
  message,
  onConfirm,
  onCancel,
  number = true,
}) => {
  const [val, setVal] = useState('')
  const [val2, setVal2] = useState('')
  const handleAccept = closeDialog => () => {
    onConfirm && onConfirm(val, val2)
    closeDialog()
  }
  const handleDeny = closeDialog => () => {
    onCancel && onCancel()
    closeDialog()
  }
  const type = number ? TEXT_FIELD_TYPES.number : TEXT_FIELD_TYPES.text
  return (
    <Dialog
      className={classes.root}
      content={({ close: closeDialog }) => (
        <>
          <DialogTitle close={closeDialog}>{title}</DialogTitle>
          <DialogContent>
            {message}
            <TextField
              innerLabel={label}
              type={type}
              value={`${val}`}
              onChange={({ target: { value } }) =>
                setVal(number ? parseInt(value, 10) : value)
              }
              dataTest={`${label}-input`}
            />
            {label2 && (
              <TextField
                innerLabel={label2}
                type={type}
                value={`${val2}`}
                onChange={({ target: { value } }) =>
                  setVal2(number ? parseInt(value, 10) : value)
                }
                dataTest={`${label2}-input`}
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button
              data-test="button-deny"
              variant="contained"
              onClick={handleDeny(closeDialog)}
            >
              Cancel
            </Button>
            <Button
              data-test="button-accept"
              variant="contained"
              color="primary"
              disabled={
                number
                  ? !(isNumber(val) && val >= min) ||
                    (label2 && !(isNumber(val2) && val2 >= min))
                  : val.length === 0 || (label2 && val2.length === 0)
              }
              onClick={handleAccept(closeDialog)}
            >
              Confirm
            </Button>
          </DialogActions>
        </>
      )}
    >
      {children}
    </Dialog>
  )
}

InputDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.object,
    PropTypes.func,
  ]),
  title: PropTypes.string,
  label: PropTypes.string,
  label2: PropTypes.string,
  min: PropTypes.number,
  message: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  number: PropTypes.bool,
}

export default withStyles(styles)(InputDialog)
