const jssStyles = theme => ({
  snackbar: {
    position: 'fixed',
    width: '100%',
    marginLeft: '-8px',
  },
  success: {
    backgroundColor: theme.palette.primary.main,
  },
  error: {
    backgroundColor: theme.palette.warnings.danger,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: theme.palette.primary.main,
  },
  variantIcon: {
    fontSize: 20,
    opacity: 0.9,
    marginRight: '10px',
  },
  icon: {
    color: 'white',
    fontSize: 20,
  },
  message: {
    color: 'white',
    display: 'flex',
    alignItems: 'center',
  },
})

export default jssStyles
