import React, { createContext, useState, useMemo } from 'react'
import PropTypes from 'prop-types'

export const UIContext = createContext()

export const UIProvider = ({ children }) => {
  const [alert, setAlert] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [showMenu, setShowMenu] = useState(false)

  const value = useMemo(
    () => ({
      alert,
      setAlert,
      closeAlert: () => setAlert({ ...alert, open: false }),
      isLoading,
      setIsLoading,
      showMenu,
      handleToggleMenu: () => setShowMenu(!showMenu),
    }),
    [alert, setAlert, isLoading, setIsLoading, showMenu]
  )

  return <UIContext.Provider value={value}>{children}</UIContext.Provider>
}

UIProvider.propTypes = {
  children: PropTypes.node,
}
