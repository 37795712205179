const jssStyles = theme => ({
  locationText: {
    color: '#305BF2',
    fontWeight: 600,
    fontSize: 10,
    display: 'flex',
    alignItems: 'center',
    textTransform: 'uppercase',
    marginRight: theme.spacing(2),
  },
})

export default jssStyles
