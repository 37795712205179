import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

import { Check } from '@material-ui/icons'
import Header from 'components/Header'

import styles from './MessageActionViewStyles'

const MessageActionView = ({
  classes,
  title,
  description,
  success,
  actionHasError,
  actionHasSpinner,
  actionText,
  children,
  onClick,
  onBackClick,
  disabled = false,
}) => (
  <Box className={classes.root}>
    <Header onBackClick={onBackClick} />
    <Box flex={2} className={classes.top}>
      {success && (
        <Box className={classes.iconBox}>
          <Check className={classes.icon} />
        </Box>
      )}
    </Box>
    <Box flex={3} className={classes.message}>
      <Typography className={classes.title} variant="h3">
        {title}
      </Typography>
      <Typography className={classes.subtitle} variant="h4">
        {description}
      </Typography>
      <Box className={classes.children}>{children}</Box>
    </Box>
    <Box className={classes.actionContainer}>
      <Button
        className={actionHasError && classes.errorActionButton}
        variant="contained"
        color="primary"
        size="large"
        fullWidth
        onClick={onClick}
        disabled={disabled}
        data-test="message-action-button"
      >
        {actionHasSpinner ? (
          <CircularProgress size={21} color="secondary" />
        ) : (
          actionText
        )}
      </Button>
    </Box>
  </Box>
)

MessageActionView.defaultProps = {}

MessageActionView.propTypes = {
  classes: PropTypes.object.isRequired,
  success: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node,
  onClick: PropTypes.func,
  onBackClick: PropTypes.func,
  actionText: PropTypes.string,
  actionHasError: PropTypes.bool,
  actionHasSpinner: PropTypes.bool,
  disabled: PropTypes.bool,
}

export default withStyles(styles)(MessageActionView)
