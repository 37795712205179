const TABS_HEIGHT_PX = 60
const HEADER_TEXT_HEIGHT_PX = 74

const jssStyles = theme => ({
  menuFab: {
    bottom: TABS_HEIGHT_PX + 16,
    right: 16,
  },
  tabContainer: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(1),
  },
  loading: {
    textAlign: 'center',
  },
  tabRoot: {
    height: TABS_HEIGHT_PX,
  },
  tabPanel: {
    position: 'absolute',
    width: '100%',
    bottom: 0,
    top: HEADER_TEXT_HEIGHT_PX,
  },
  listContainer: {
    position: 'relative',
    overflowY: 'scroll',
    height: `calc(100% - ${TABS_HEIGHT_PX}px)`,
    maxHeight: '100%',
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
  },
  status: {
    display: 'inline-flex',
  },
  etaStatusRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  searchIcon: {
    width: 48,
    height: 48,
    background: theme.palette.primary.main,
    padding: 0,
    position: 'absolute',
    bottom: TABS_HEIGHT_PX + 16,
    right: 72,
  },
})

export default jssStyles
