import React from 'react'
import PropTypes from 'prop-types'

const DamagedIcon = ({ fill, size, className }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.08495 3.52011C4.23416 3.20286 4.5556 3 4.90909 3H18.9091C19.2514 3 19.5646 3.19032 19.7196 3.49242L21.9014 7.74492C21.9662 7.87121 22 8.01085 22 8.1525V19.7346C22 20.1176 21.7552 20.4586 21.3899 20.5845C21.0245 20.7105 20.6187 20.5938 20.3785 20.2936L17.628 16.858L17.4519 19.132C17.4284 19.4353 17.2518 19.7063 16.9824 19.8525C16.7129 19.9986 16.3872 20.0002 16.1163 19.8566L14.1738 18.8269L14.2699 20.029C14.2899 20.2793 14.2034 20.5264 14.0313 20.7108C13.8592 20.8952 13.6172 21 13.3636 21H2.90909C2.40701 21 2 20.5971 2 20.1V8.1525C2 8.02125 2.029 7.89159 2.08495 7.77261L4.08495 3.52011ZM12.9996 4.8H10.8178V10.3989H12.9996V4.8ZM8.99959 4.8H5.48861L4.33515 7.25255L8.99959 7.25272V4.8ZM8.99959 9.05272L3.81818 9.05253V19.2H12.3798L12.2301 17.327C12.2039 16.9989 12.3606 16.6828 12.6389 16.5027C12.9171 16.3226 13.2724 16.3072 13.5655 16.4626L15.7456 17.6183L15.9926 14.4296C16.0212 14.0603 16.2753 13.7461 16.6331 13.6378C16.9908 13.5294 17.3791 13.6491 17.6114 13.9394L20.1818 17.1501V9.05251L14.8178 9.05258V11.2989C14.8178 11.796 14.4108 12.1989 13.9087 12.1989H9.90868C9.4066 12.1989 8.99959 11.796 8.99959 11.2989V9.05272ZM14.8178 7.25258V4.8H18.3512L19.6095 7.25252L14.8178 7.25258Z"
      fill={fill}
    />
  </svg>
)

DamagedIcon.defaultProps = {
  size: 20,
  fill: 'currentColor',
}

DamagedIcon.propTypes = {
  fill: PropTypes.string,
  size: PropTypes.number,
  className: PropTypes.string,
}

export default DamagedIcon
