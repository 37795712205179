const jssStyles = theme => ({
  container: {
    height: '100%',
  },
  fullScreen: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& > *': {
      margin: theme.spacing(2),
    },
  },
})

export default jssStyles
