import { useResourceRQ } from 'hooks'

import { cycleCountEndpoint, cycleCountListEndpoint } from 'api/endpoints'

const useAdminCycleCount = ({ cycleCountId, enabled = true }) => {
  const {
    create: createCycleCount,
    data: cycleCount,
    update: updateCycleCount,
    isLoading: isLoadingCycleCount,
    isError: isCycleCountError,
    error: cycleCountError,
  } = useResourceRQ({
    endpoint: cycleCountEndpoint,
    args: [cycleCountId],
    name: 'cycle_count',
    fetchingEnabled: enabled,
    dependents: [cycleCountListEndpoint],
  })

  const { destroy: destroyCycleCount } = useResourceRQ({
    endpoint: cycleCountEndpoint,
    args: [cycleCountId],
    fetchingEnabled: false,
  })

  return {
    cycleCount,
    updateCycleCount,
    createCycleCount,
    isLoadingCycleCount,
    isCycleCountError,
    cycleCountError,
    destroyCycleCount,
  }
}

export default useAdminCycleCount
