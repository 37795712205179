import { fade } from '@material-ui/core/styles'
import { white } from 'theme/colors'

export default {
  headerCell: {
    color: 'inherit',
    backgroundColor: 'transparent',
    padding: '22px 4px 38px',
    border: 'none',
  },
  fixedLeft: {
    color: 'inherit',
    border: 'none',
  },
  checkboxRoot: {
    color: fade(white, 0.5),
  },
}
