import uniqBy from 'lodash/uniqBy'
import groupBy from 'lodash/groupBy'

import { sortLineItems, getSortedBinList } from 'utils/bin'

export const preprocessLineItems = lineItems => {
  const lineItemsCopy = [...lineItems]
  const groupedItems = groupBy(lineItemsCopy, x => x.barcode)
  const compressedLineItems = uniqBy(lineItemsCopy, 'barcode')
    .map(x => { return { ...x, quantity: groupedItems[x.barcode].reduce((p, c) => c.quantity + p, 0) }})
  const items = compressedLineItems.sort(sortLineItems).map(i => {
    const binList = getSortedBinList(i.bin)
    return {
      ...i,
      bin: binList[0],
      bins: binList
    }})
  return items
}
