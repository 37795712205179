// This file copied from dashboard, keep in sync
import { useEffect, useState, useCallback, useRef } from 'react'
import { useLocation, useHistory } from 'react-router-dom'

import queryString from 'query-string'
import debounce from 'lodash/debounce'
import isEmpty from 'lodash/isEmpty'

import { useLocationsContext } from 'context'
import { useLatestCallback } from 'hooks'
import { SORT_DEFAULT, API_PARAMS } from 'constants/queryParams'

const clearDefaultSort = query => {
  const queryCopy = { ...query }
  if (
    query[API_PARAMS.SORT_DIRECTION] === SORT_DEFAULT ||
    !(API_PARAMS.SORT in query)
  ) {
    delete queryCopy[[API_PARAMS.SORT_DIRECTION]]
  }
  return queryCopy
}

const useQuery = (
  makeQuery,
  { initFallback, locationFilter, clearParamsOnLocationChange } = {}
) => {
  const location = useLocation()
  const history = useHistory()
  const { locationId, showAllLocationIds } = useLocationsContext()

  const searchParams = queryString.parse(location.search)

  const [query, setQuery] = useState(() => {
    if (isEmpty(searchParams)) {
      const includeLocation = Boolean(locationFilter) && !showAllLocationIds
      return {
        ...initFallback,
        ...(includeLocation ? { [locationFilter]: locationId } : {}),
      }
    }
    return searchParams
  })

  const firstQueryRef = useRef(true)
  const firstLocationIdChangeRef = useRef(true)

  const currentMakeQuery = useLatestCallback(() => {
    history.replace({
      pathname: location.pathname,
      search: queryString.stringify(query),
    })
    makeQuery && makeQuery(query)
  })

  const debouncedMakeQuery = useCallback(
    debounce(currentMakeQuery, 600, { leading: true, trailing: false }),
    []
  )

  const handleQueryChange = ({ target: { name, value } }) => {
    const newQuery = {
      ...query,
      [name]: value,
    }

    if (value === '') {
      delete newQuery[name]
    }

    setQuery(clearDefaultSort(newQuery))
  }

  const updateQuery = params => {
    const paramsCopy = { ...params }
    Object.entries(params).forEach(([key, value]) => {
      if (value === '') {
        delete query[key]
        delete paramsCopy[key]
      }
    })
    setQuery(clearDefaultSort({ ...query, ...paramsCopy }))
  }

  useEffect(() => {
    if (firstQueryRef.current) {
      makeQuery && makeQuery(query)
      firstQueryRef.current = false
    } else {
      debouncedMakeQuery()
    }
    return () => {
      debouncedMakeQuery.cancel()
    }
  }, [query])

  useEffect(() => {
    if (firstLocationIdChangeRef.current || !locationFilter) {
      // Ignore initial render because this would override URL params
      firstLocationIdChangeRef.current = false
      return
    }

    updateQuery({
      [locationFilter]: locationId ?? '',
      ...(clearParamsOnLocationChange || [])
        .filter(key => key in query)
        .reduce((keys, curr) => ({ ...keys, [curr]: '' }), {}),
    })
  }, [locationId])

  return {
    query,
    setQuery,
    updateQuery,
    handleQueryChange,
  }
}

export default useQuery
