import React, { useEffect } from 'react'
import { ReactQueryDevtools } from 'react-query/devtools'
import { QueryClient, QueryClientProvider } from 'react-query'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { CssBaseline } from '@material-ui/core'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'

import theme from 'theme'
import Routes from 'routes'
import { useAuth } from 'hooks'
import { useUIContext, useAuthContext, useLocalStorageContext } from 'context'

import Alert from 'components/Alert'
import SiteTitle from 'components/SiteTitle'

const queryClient = new QueryClient()

const App = ({ ...props }) => {
  const { alert, closeAlert } = useUIContext()
  const { authToken } = useLocalStorageContext()
  const { setIsInitialized } = useAuthContext()
  const { fetchMe } = useAuth()

  useEffect(() => {
    ;(async () => {
      if (authToken) {
        // failed fetch will cause logout and redirect to login
        await fetchMe()
      }
      setIsInitialized(true)
    })()
  }, [])

  return (
    <QueryClientProvider client={queryClient}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <SiteTitle />
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <Alert
            open={alert?.open}
            message={alert?.message}
            variant={alert?.variant}
            onClose={closeAlert}
          />
          <Routes {...props} />
        </MuiThemeProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </MuiPickersUtilsProvider>
    </QueryClientProvider>
  )
}

export default App
