import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import Scanner from 'components/Scanner'
import { URL } from 'constants/navigation'

import styles from './LocationLookupScreenStyles'

const LocationLookupScreen = ({ classes }) => {
  const history = useHistory()

  const onClose = () => history.goBack()
  const onScan = code =>
    history.push(`${URL.LOCATION}/${encodeURIComponent(code)}`)

  return (
    <Scanner
      title="Scan Location"
      onScan={onScan}
      onClose={onClose}
      numbersOnly={false}
    />
  )
}

LocationLookupScreen.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(LocationLookupScreen)
