import { useAPIContext } from 'context'
import { useResourceListRQ } from 'hooks'
import {
  transferOrderListEndpoint,
  transferOrderExportEndpoint,
} from 'api/endpoints'

const useAdminTransferOrderList = (params, type, enabled) => {
  const { downloadApi } = useAPIContext()

  const {
    isFetching: isFetchingTransferOrders,
    data: transferOrders,
    hasData: hasTransferOrders,
    hasNextPage: hasTransferOrdersNext,
    fetchNextPage: fetchTransferOrdersNext,
    count: totalTransferOrders,
  } = useResourceListRQ({
    endpoint: transferOrderListEndpoint,
    params,
    enabled,
    args: [type],
  })

  const getExport = async transferOrderId => {
    await downloadApi.get(transferOrderExportEndpoint(transferOrderId))
  }

  return {
    isFetchingTransferOrders,
    transferOrders: transferOrders || [],
    hasTransferOrders,
    hasTransferOrdersNext,
    fetchTransferOrdersNext,
    totalTransferOrders,

    getExport,
  }
}

export default useAdminTransferOrderList
