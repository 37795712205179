const moment = require('moment')

export const isDate = value => {
  if (!value) {
    return false
  }
  const date = moment(value)
  return date.isValid()
}

export const isEmail = value => {
  if (!value) {
    return false
  }
  const regEx = /\S+@\S+\.\S+/

  if (value && !regEx.test(value)) {
    return false
  }
  return true
}

export const isUrl = string => {
  try {
    // eslint-disable-next-line
    new URL(string)
    return true
  } catch (e) {
    return false
  }
}

export const isEmpty = value => {
  if (
    value === null ||
    value === undefined ||
    value === '' ||
    value.length === 0
  ) {
    return true
  }
  return false
}

export const notEmpty = value => {
  return !isEmpty(value)
}

export const isFalse = value => {
  if (!value) {
    return false
  }
  // eslint-disable-next-line
  return value === false ? true : false
}

export const isMinLength = (value, length) => {
  if (!value) {
    return false
  }
  if (value && value.length < length) {
    return false
  }
  return true
}

export const isLinkedinUrl = url => {
  let isValid = false
  if (url.match('linkedin.com/in/')) {
    isValid = true
  }
  return isValid
}
