export default theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(2),
    marginTop: 0,
    '& .MuiInputBase-root': {
      height: 60,
    },
  },
  selectedItem: {
    display: 'flex',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.grays.med,
  },
  productSummary: {
    width: '80%',
    marginRight: '2%',
  },
  itemActions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    fontSize: '14px',
    fontWeight: 500,
    color: theme.palette.grays.dark,
    width: '18%',

    '& .MuiButtonBase-root': {
      padding: 0,
      marginRight: 'unset',
    },
  },
  actionContainer: {
    position: 'absolute',
    bottom: 0,
    display: 'flex',
    width: '100%',
    '& > *': {
      margin: theme.spacing(1),
      flexGrow: 1,
    },
  },
})
