import { useQuery } from 'react-query'

import { useAPIContext } from 'context'
import { APP_VERSION } from 'lib/config'

const VERSION_PATH = '/version.json'

export default function useAppVersion() {
  const { api } = useAPIContext()
  const { protocol, host } = document.location

  // use timestamp to force a refetch and not use browser cache
  const { data: response } = useQuery(
    [VERSION_PATH],
    () => api.get(`${protocol}//${host}${VERSION_PATH}?timestamp=${new Date().getTime()}`),
    {
      refetchInterval: 60000, // 60s
    }
  )

  return {
    // BE VERY CAREFUL HERE
    // if this logic is wrong it could trigger an infinite reload loop
    updatePending: Boolean(
      response?.version && APP_VERSION && response?.version !== APP_VERSION
    ),
  }
}
