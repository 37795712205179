const jssStyles = theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
  },
  summaryRoot: {
    padding: theme.spacing(2.5, 0),
    alignItems: 'flex-end',
  },
  summaryContentRoot: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: 0,
    '&.Mui-expanded': {
      margin: 0,
    },
  },
  summaryExpandIcon: {
    padding: 0,
  },
  detailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
  },
  totalAmount: {
    fontSize: 16,
    fontWeight: theme.typography.fontWeight.bold,
  },
  divider: {
    width: '100vw',
    margin: theme.spacing(2, -3),
  },
})

export default jssStyles
