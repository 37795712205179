/** MULTY CITY */
export const ALL_LOCATIONS_ID = null

/** PRODUCT */
export const PRODUCT_STATES = [
  { value: 'all', label: 'All' },
  { value: 'published', label: 'Published' },
  { value: 'unpublished', label: 'Unpublished' },
]

export const PRODUCT_TRANSITIONS = [
  { value: 'publish', label: 'Publish' },
  { value: 'unpublish', label: 'Unpublish' },
]

export const ORDER_STATES_TYPES = {
  all: 'all',
  pending: 'pending',
  paid: 'paid',
  picking: 'picking',
  dispatched: 'dispatched',
  shipped: 'shipped',
  completed: 'completed',
  returned: 'returned',
  canceled: 'canceled',
  declined: 'declined',
  refunded: 'refunded',
  delivered: 'delivered',
}

export const UBER_ORDER_STATES_TYPES = {
  accepted: 'accepted',
  picking: 'picking',
  fulfilled: 'fulfilled',
  cancelled: 'cancelled',
  delivered: 'delivered',
}

export const UBER_ORDER_STATE_MAP = {
  accepted: ORDER_STATES_TYPES.paid,
  picking: ORDER_STATES_TYPES.picking,
  fulfilled: ORDER_STATES_TYPES.dispatched,
  canceled: ORDER_STATES_TYPES.canceled,
  delivered: ORDER_STATES_TYPES.delivered,
}

/** ORDER */
export const ORDER_TRANSITIONS = [
  { value: ORDER_STATES_TYPES.pending, label: 'Pending' },
  { value: ORDER_STATES_TYPES.paid, label: 'Paid' },
  { value: ORDER_STATES_TYPES.picking, label: 'Picking' },
  { value: ORDER_STATES_TYPES.dispatched, label: 'Dispatched' },
  { value: ORDER_STATES_TYPES.shipped, label: 'Delivered' },
  { value: ORDER_STATES_TYPES.completed, label: 'Completed' },
  { value: ORDER_STATES_TYPES.returned, label: 'Returned' },
  { value: ORDER_STATES_TYPES.canceled, label: 'Canceled' },
  { value: ORDER_STATES_TYPES.declined, label: 'Declined' },
  { value: ORDER_STATES_TYPES.refunded, label: 'Refunded' },
]

export const ORDER_STATES = [
  { value: ORDER_STATES_TYPES.all, label: 'All' },
  ...ORDER_TRANSITIONS,
]

export const ORDER_STATES_CATEGORIES = {
  total: {
    key: 'total',
    label: 'Total',
    state: ORDER_STATES_TYPES.all,
  },
  picking: {
    key: 'picking',
    label: 'Picking',
    state: ORDER_STATES_TYPES.picking,
  },
  dispatched: {
    key: 'dispatched',
    label: 'Dispatched',
    state: ORDER_STATES_TYPES.dispatched,
  },
  enroute: {
    key: 'enroute',
    label: 'En Route',
    state: ORDER_STATES_TYPES.shipped,
  },
}

export const PAYMENT_STATUS = [
  { value: 'all', label: 'All' },
  { value: 'unpaid', label: 'Pending' },
  { value: 'refunded', label: 'Refunded' },
  { value: 'declined', label: 'Declined' },
  { value: 'failed', label: 'Failed' },
  { value: 'fraud', label: 'Fraud' },
]

/** USER */
export const USER_STATES = [
  { value: 'all', label: 'All' },
  { value: 'pending', label: 'Active' },
  { value: 'rejected', label: 'Blocked' },
]

export const USER_TRANSITIONS = [
  { value: 'pending', label: 'Active' },
  { value: 'rejected', label: 'Blocked' },
]

export const USER_ROLES = [
  { value: 'admin', label: 'Admin' },
  { value: 'brand', label: 'Brand' },
  { value: 'user', label: 'User' },
  { value: 'staff', label: 'Staff' },
]

export const USER_TYPES = [{ value: 'all', label: 'All' }, ...USER_ROLES]

export const YESNO = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
]

export const DEFAULT_PRODUCT_IMAGE =
  'https://cdn.iconscout.com/icon/premium/png-256-thumb/image-missing-1178918.png'

export const ALL_LOCATION = {
  id: null,
  name: 'All Locations',
  code: 'ALL',
}

export const DEFAULT_VARIANT_TITLE = 'Default Title'
