import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import sumBy from 'lodash/sumBy'

import { withStyles } from '@material-ui/core/styles'
import Slide from '@material-ui/core/Slide'
import Box from '@material-ui/core/Box'

import LineItem from 'components/LineItem'
import Scanner from 'components/Scanner'

import { usePrevious, useAlerts } from 'hooks'

import { storageAvailable } from 'utils/general'
import { STORAGE_INVENTORY_COUNT_V2 } from 'lib/config'

import { CIRCLE_CHECK_STATUS } from 'constants/enums'

import styles from './InventoryCounterStyles'

if (!storageAvailable('sessionStorage')) {
  console.error('This website requires sessionStorage enabled')
}

const { sessionStorage } = window

const loadState = () => {
  try {
    const str = sessionStorage.getItem(STORAGE_INVENTORY_COUNT_V2)
    if (!str) {
      return { inventory: {} }
    }
    return JSON.parse(str) ?? { inventory: {} }
  } catch (e) {
    console.error(
      `sessionStorage.getItem(${STORAGE_INVENTORY_COUNT_V2}) failed`,
      e
    )
  }
  return {}
}

const saveState = ({ loadedState, type, id, parentId, item }) => {
  try {
    const newState = {
      ...loadedState,
      inventory: {
        ...loadedState.inventory,
        [type]: {
          ...loadedState.inventory[type],
          [parentId]: {
            ...loadedState.inventory[type]?.[parentId],
            [id]: item,
          },
        },
      },
    }
    sessionStorage.setItem(STORAGE_INVENTORY_COUNT_V2, JSON.stringify(newState))
    return newState
  } catch (e) {
    console.error(
      `sessionStorage.setItem(${STORAGE_INVENTORY_COUNT_V2}) failed`,
      e
    )
  }
}

const CHECKED = 'checked'
const FLAGGED = 'flagged'

const itemsToMap = (items, inventoryState) => {
  const m = {}
  items.forEach(i => {
    m[i.id] = {
      ...i,
      [CHECKED]: inventoryState[i.id]?.[CHECKED] || 0,
      [FLAGGED]: inventoryState[i.id]?.[FLAGGED] || 0,
    }
  })
  return m
}

const nextItem = items => Object.values(items)[0]

const InventoryCounter = ({
  classes,
  items: itemsIn = [],
  onScan,
  onClose,
  actions,
  type,
  parentId,
}) => {
  const { showAlertError } = useAlerts()
  const [state, setState] = useState(loadState())
  const [items, setItems] = useState(
    itemsToMap(itemsIn, state.inventory[type]?.[parentId] || {})
  )
  const [currentItem, setCurrentItem] = useState(nextItem(items))
  const [reversed, setReversed] = useState(false)
  const [, setAnimationValue] = useState(0) // to trigger re-renders
  const previousItem = usePrevious(currentItem)

  const updateStatus = (key, change, newReversed) => {
    const newItem = { ...currentItem, [key]: currentItem[key] + change }
    setItems({ ...items, [currentItem.id]: newItem })
    setCurrentItem(newItem)
    setState(
      saveState({
        loadedState: state,
        type,
        id: currentItem.id,
        parentId,
        item: { [CHECKED]: newItem[CHECKED], [FLAGGED]: newItem[FLAGGED] },
      })
    )
    setReversed(newReversed)
  }

  const handleUndoChecked = () => {
    if (currentItem[CHECKED] > 0) {
      updateStatus(CHECKED, -1, true)
    }
  }

  const handleUndoDamaged = () => {
    updateStatus(FLAGGED, -1, true)
  }

  const lineItemComponent = (
    <LineItem
      className={classes.lineItem}
      lineItem={currentItem}
      showColor
      showSize
      status={currentItem?.status || CIRCLE_CHECK_STATUS.unchecked}
      showCounts
      numberChecked={currentItem[CHECKED]}
      numberDamaged={currentItem[FLAGGED]}
      alwaysShowChecked
      onCheckedClick={handleUndoChecked}
      onDamagedClick={handleUndoDamaged}
    />
  )

  const handleFlag = () => {
    updateStatus(FLAGGED, 1, false)
  }

  useEffect(() => {
    if (currentItem !== previousItem && previousItem !== null) {
      const timer = setTimeout(() => {
        setAnimationValue(value => value + 1)
      }, 300)
      return () => clearTimeout(timer)
    }
  })

  const moveFrom = reversed ? 'left' : 'right'
  const moveTo = reversed ? 'right' : 'left'
  const direction = currentItem === previousItem ? moveTo : moveFrom

  const clearCache = () => {
    saveState({
      loadedState: state,
      type,
      id: currentItem.id,
      parentId,
      item: {},
    })
  }

  return (
    <Scanner
      title={`${sumBy(
        Object.values(items),
        i => i[CHECKED] + i[FLAGGED]
      )} of ${sumBy(Object.values(items), 'quantity')}`}
      onScan={code => {
        const barcode = currentItem?.barcode
        if (barcode && barcode !== code) {
          showAlertError('Barcode does not match')
          return false
        }
        updateStatus(CHECKED, 1, false)
        onScan && onScan(code)
        return true
      }}
      onClose={onClose}
      numbersOnly={false}
      barcode={currentItem?.barcode}
    >
      <Box className={classes.carouselContainer} mb={2}>
        <Slide
          direction={direction}
          in={currentItem === previousItem}
          timeout={200}
        >
          <Box className={classes.itemBox}>{lineItemComponent}</Box>
        </Slide>
      </Box>
      <Box mx={4} mb={2} display="flex">
        {actions({ currentItem, handleFlag, clearCache })}
      </Box>
    </Scanner>
  )
}

InventoryCounter.propTypes = {
  classes: PropTypes.object,
  items: PropTypes.arrayOf(PropTypes.object),
  onScan: PropTypes.func,
  onClose: PropTypes.func,
  actions: PropTypes.func,
  type: PropTypes.string,
  parentId: PropTypes.string,
}

export default withStyles(styles)(InventoryCounter)
