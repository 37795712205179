const jssStyles = theme => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(0.5),
    top: 0,
    color: theme.palette.grey[500],
  },
  paper: {
    backgroundColor: theme.palette.colorsAF.white,
    boxShadow: '0px 0px 18px 0px rgba(141, 141, 141, 0.32)',
  },
  scrollPaper: {
    alignItems: 'baseline', // default center
  },
})

export default jssStyles
