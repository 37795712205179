import isEmpty from 'lodash/isEmpty'

import { RECORD_TYPE, RECORD_TYPE_GET_PARAM } from 'constants/inventoryRecords'

// path roots

const ROOT = '/api'
const V1 = `${ROOT}/v1`
const ADMIN = `${V1}/admin`

// when arg is truthy encode it and prepend with a slash otherwise return ''
const when = arg => (arg ? `/${encodeURIComponent(arg)}` : '')
const fullPath = path => ADMIN + path
const setQueryParams = (path, params) =>
  isEmpty(params) ? path : `${path}?${new URLSearchParams(params).toString()}`

const inventoryRecord = (path, type = RECORD_TYPE.PURCHASE_ORDER) =>
  setQueryParams(path, { [RECORD_TYPE_GET_PARAM]: type })

const purchaseOrderPath = id => fullPath(`/purchase_orders${when(id)}`)
const purchaseOrderListPath = purchaseOrderPath

export const purchaseOrderEndpoint = (id, type) =>
  inventoryRecord(`${purchaseOrderPath(id)}`, type)
export const purchaseOrderExportEndpoint = id =>
  `${purchaseOrderPath(id)}/export`
export const purchaseOrderListEndpoint = type =>
  inventoryRecord(purchaseOrderListPath(), type)
export const purchaseOrderSummaryEndpoint = type =>
  inventoryRecord(`${purchaseOrderListPath()}/summary`, type)
export const purchaseOrderCsvImportEndpoint = id =>
  `${purchaseOrderPath(id)}/import_variants`
export const purchaseOrderVariantsEndpoint = id =>
  `${purchaseOrderPath(id)}/variants`
export const purchaseOrderVariantsUpdateEndpoint = (id, variant) =>
  `${purchaseOrderPath(id)}/purchase_order_variants/${variant.id}`
export const purchaseOrderVariantsReceiveEndpoint = (id, variant) =>
  `${purchaseOrderPath(id)}/purchase_order_variants/${variant.id}/receive`
export const purchaseOrderInOrderEndpoint = id =>
  `${purchaseOrderPath(id)}/in_order`
export const purchaseOrderInCycleCountEndpoint = id =>
  `${purchaseOrderPath(id)}/in_cycle_count`

export const variantEndpoint = idOrSku => fullPath(`/variants${when(idOrSku)}`)
export const variantGenDsinEndpoint = id => `${variantEndpoint(id)}/gen_dsin`
export const variantBulkUpdateEndpoint = id =>
  `${variantEndpoint(id)}/bulk_update`
export const variantPrintLabelEndpoint = sku =>
  `${variantEndpoint(sku)}/print_label`
export const variantListEndpoint = () => variantEndpoint()
export const variantPurchaseOrderListEndpoint = () =>
  fullPath('/variants/po_index')

// TRANSFER ORDER SUPPORT FOR NEW ENDPOINTS
const transferOrderPath = id => fullPath(`/transfer_orders${when(id)}`)
const transferOrderListPath = transferOrderPath

export const transferOrderEndpoint = id => transferOrderPath(id)
export const transferOrderHistoryEndpoint = id =>
  `${transferOrderPath(id)}/transfer_order_history`
export const transferOrderExportEndpoint = id =>
  `${transferOrderPath(id)}/export`
export const transferOrderListEndpoint = () => transferOrderListPath()
export const transferOrderSummaryEndpoint = () =>
  `${transferOrderListPath()}/summary`
export const transferOrderCsvImportEndpoint = id =>
  `${transferOrderPath(id)}/import_variants`
export const transferOrderSubmitEndpoint = id =>
  `${transferOrderPath(id)}/submit`
export const transferOrderCloseEndpoint = id => `${transferOrderPath(id)}/close`

export const transferOrderInOrderEndpoint = id =>
  `${transferOrderPath(id)}/in_order`
export const transferOrderInCycleCountEndpoint = id =>
  `${transferOrderPath(id)}/in_cycle_count`

export const shipmentItemEndpoint = shipmentItemId =>
  `${ADMIN}/shipment_items/${shipmentItemId}`

export const brandEndpoint = id => fullPath(`/brands${when(id)}`)

export const productsListEndpoint = () => fullPath('/products')

const locationEndpoint = locationId => fullPath(`/locations${when(locationId)}`)
export const locationPrintersEndpoint = (locationId, printerId) =>
  `${locationEndpoint(locationId)}/printers${when(printerId)}`

// Uber Orders
const UBER_EATS_ORDERS = '/uber_eats_orders'
export const uberOrderEndpoint = id =>
  fullPath(`${UBER_EATS_ORDERS}${when(id)}`)
export const uberOrderListEndpoint = () => fullPath(UBER_EATS_ORDERS)

// ITEM LOCATIONS
export const pickEndpoint = () => fullPath('/item_locations/pick')

// PLATES
export const plateEndpoint = id => fullPath(`/plates${when(id)}`)
export const plateListEndpoint = () => fullPath('/plates')
export const plateBulkUpdateEndpoint = () => fullPath('/plates/bulk_update')
export const plateBulkDeactivateEndpoint = () =>
  fullPath('/plates/bulk_deactivate')
export const plateTransferEndpoint = id => fullPath(`/plates/${id}/transfer`)

// Cycle Counts
export const cycleCountEndpoint = id => fullPath(`/cycle_counts${when(id)}`)
export const cycleCountListEndpoint = () => fullPath('/cycle_counts')
export const cycleCountPlateEndpoint = (id, plateId) =>
  fullPath(`/cycle_counts/${id}/cycle_count_plates${when(plateId)}`)
export const cycleCountPlateListEndpoint = id =>
  fullPath(`${cycleCountEndpoint(id)}/plates`)

// Predefined Parcels
export const predefinedParcelsEndpoint = () => fullPath('/predefined_parcels')

// Shipments
const shipmentPath = fullPath('/shipments')
export const shipmentEndpoint = id => `${shipmentPath}${when(id)}`
export const shipmentListEndpoint = () => shipmentPath
