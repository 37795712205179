import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import { Button, CircularProgress } from '@material-ui/core'

import styles from './LoadingButtonStyles'

const LoadingButton = ({ classes, loading, children, disabled, ...props }) => (
  <Button disabled={loading || disabled} {...props}>
    {loading ? <CircularProgress /> : children}
  </Button>
)

LoadingButton.defaultProps = {}
LoadingButton.propTypes = {
  classes: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.node,
}
export default withStyles(styles)(LoadingButton)
