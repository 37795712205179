import debounce from 'lodash/debounce'

import { DELIVERY_METHOD_KEYS } from 'constants/enums'
import { useAPIContext } from 'context'
import { ORDER_STATES_TYPES } from 'constants/general'
import {
  useAlerts,
  useLoaders,
  useResourceFulfillment as useResource,
} from 'hooks'

const DEBOUNCE_MS = 600
const API_ADMIN_FULFILLMENT_ORDERS_URL = '/api/v1/admin/fulfillment_orders'
const API_ADMIN_ORDERS_URL = '/api/v1/admin/orders'

const useAdminOrders = ({ id, ...props }) => {
  const { showLoading, hideLoading } = useLoaders()
  const { showAlertError, showAlertSuccess } = useAlerts()

  const {
    isLoading,
    isLoaded,
    isEmpty,
    findOne,
    findAll,
    update,
    create,
    destroy,
    goToPage,
    uploadFile,
    deleteFile,
    data,
    meta,
    params,
    page,
    numPages,
    perPage,
  } = useResource({
    id,
    url: id ? API_ADMIN_ORDERS_URL : API_ADMIN_FULFILLMENT_ORDERS_URL,
    name: 'order',
  })

  // This can be used to lookup an order without causing `order` to be
  // changed which can be useful when we want the update status on an
  // order but don't want to re-render with that new status
  const { findOne: lookupOrder } = useResource({
    id,
    url: API_ADMIN_ORDERS_URL,
    name: 'order',
  })

  const { api } = useAPIContext()

  const debounceGet = debounce(api.get, DEBOUNCE_MS, { leading: true })

  const findOrderVariantByBarcode = async (orderId, barcode) =>
    debounceGet(
      `${API_ADMIN_ORDERS_URL}/${orderId}/variants/by_barcode?variant[barcode]=${barcode}`
    )

  const updateOrderStatus = async (
    orderId,
    status,
    { notifySuccess = true, notifyError = true, notify = true } = {}
  ) => {
    try {
      showLoading()
      await api.put(`${API_ADMIN_ORDERS_URL}/${orderId}`, {
        status,
      })
      if (notify !== false && notifySuccess) {
        showAlertSuccess('Order successfully updated')
      }
      hideLoading()
    } catch (e) {
      console.error(e)
      if (notify !== false && notifyError) {
        showAlertError('There was an error updating the status')
      }
      hideLoading()
      return e
    }
  }

  const updateOrderDeliveryMethod = async (
    orderId,
    deliveryMethod,
    roadieOrderSize,
    { notifySuccess = true, notifyError = true, notify = true } = {}
  ) => {
    showLoading()
    try {
      const roadieParams =
        deliveryMethod === DELIVERY_METHOD_KEYS.roadie
          ? { roadie_order_size: roadieOrderSize }
          : {}
      await api.put(`${API_ADMIN_ORDERS_URL}/${orderId}`, {
        status: ORDER_STATES_TYPES.dispatched,
        delivery_method: deliveryMethod,
        ...roadieParams,
      })
      if (notify !== false && notifySuccess) {
        showAlertSuccess('Order successfully dispatched')
      }
    } catch (e) {
      console.error(e)

      if (notify !== false && notifyError) {
        showAlertError('There was an error setting the dispatch method')
      }
      if (e.status === 400 && e.data?.data?.errors.length) {
        showAlertError(e.data.data.errors)
      } else if (e.status === 400) {
        showAlertError('There was an error trying to dispatch the order')
      }

      return e
    } finally {
      hideLoading()
    }
  }

  const printOrderLabel = async (orderId, printerId) => {
    showLoading()
    try {
      await api.post(
        `${API_ADMIN_ORDERS_URL}/${orderId}/print?printer_id=${printerId}`
      )
      hideLoading()
    } catch (e) {
      console.error(e)
      hideLoading()
      return e
    }
  }

  const inCycleCount = async orderId => {
    const res = await api.get(
      `${API_ADMIN_ORDERS_URL}/${orderId}/in_cycle_count`
    )
    return res.data.purchase_order_ids
  }

  const short = async (orderId, shorts) => {
    try {
      await api.post(`${API_ADMIN_ORDERS_URL}/${orderId}/short`, { shorts })
    } catch (e) {
      console.error(e)
      return e
    }
  }

  return {
    orderId: id,
    isLoading,
    isLoaded,
    isEmpty,
    order: data,
    orders: data,
    findOrder: findOne,
    findOrders: findAll,
    updateOrder: update,
    createOrder: create,
    deleteOrder: destroy,
    uploadFile,
    deleteFile,
    goToPage,
    updateOrderStatus,
    updateOrderDeliveryMethod,
    printOrderLabel,
    meta,
    params,
    page,
    numPages,
    perPage,
    totalCount: meta?.total_count,
    findOrderVariantByBarcode,
    inCycleCount,
    lookupOrder,
    short,
  }
}

export default useAdminOrders
