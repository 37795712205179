import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import escapeRegExp from 'lodash/escapeRegExp'

import Carousel from 'nuka-carousel'
import Box from '@material-ui/core/Box'

import { useInventoryCountContext } from 'context'
import { useAlerts } from 'hooks'

import Scanner from 'components/Scanner'
import LineItem from 'components/LineItem'

import styles from './InventoryCountStyles'

/* When the barcodes are entered in the spreadsheet, it reads as a
   number and strips the leading 0, this corrects for that. */
const lineItemByBarcode = (items = [], barcode) => {
  const escapedCode = escapeRegExp(barcode.replace(/^0+/, ''))
  const barcodeRegex = new RegExp(`^0*${escapedCode}$`)
  return items.find(item => barcodeRegex.test(item.barcode))
}

const InventoryCount = ({ classes, actions, onClose, showSizeAndColor }) => {
  const { showAlertError } = useAlerts()

  const {
    items,
    itemSingles,
    totalQuantity,
    totalChecked,
    totalFlagged,
    checkItem,
    flagItem,
    binEmpty
  } = useInventoryCountContext()

  const [currentIndex, setCurrentIndex] = useState(
    itemSingles.findIndex(s => !s.checked && !s.flagged)
  )

  const currentItem = itemSingles[currentIndex]

  const moveToNext = () => {
    const newIndex = itemSingles.findIndex(
      (s, idx) => idx !== currentIndex && !s.checked && !s.flagged
    )
    if (newIndex === -1) {
      onClose()
    } else {
      setCurrentIndex(newIndex)
    }
  }

  const handleFlag = () => {
    flagItem(currentIndex)
    moveToNext()
  }

  const handleBinEmpty = () => {
    binEmpty(currentIndex)
    const newIndex = itemSingles.findIndex(
      s => !s.checked && !s.flagged
    )
    setCurrentIndex(newIndex)
  }

  const handleScan = code => {
    // TODO: Could check if the barcode matches something on the server,
    // for better messaging here.
    const lineItem = lineItemByBarcode(items, code)
    if (!lineItem) {
      // TODO: Improve error messaging
      showAlertError(`Failed to find item for barcode ${code}`)
      console.error(`Failed to find item for barcode ${code}`)
      return false
    }

    if (lineItem.id !== currentItem.item.id) {
      // TODO: Improve error messaging
      console.error('Current item not scanned')
      return false
    }

    checkItem(currentIndex)

    moveToNext()
    return true
  }

  return (
    <Scanner
      title={`${totalChecked + totalFlagged} of ${totalQuantity}`}
      onScan={handleScan}
      onClose={onClose}
      barcode={currentItem?.item?.barcode}
      numbersOnly={false}
    >
      <Box className={classes.carouselContainer} mb={2}>
        <Carousel
          slideIndex={currentIndex}
          afterSlide={slideIndex => setCurrentIndex(slideIndex)}
          length={itemSingles.length}
          cellAlign="center"
          slidesToShow={1.19}
          withoutControls
        >
          {itemSingles.map(({ item, status, unset }, idx) => (
            <Box key={idx} className={classes.itemBox}>
              <LineItem
                lineItem={item}
                carouselMode
                status={status}
                onStatusClick={unset}
                showColor={showSizeAndColor}
                showSize={showSizeAndColor}
              />
            </Box>
          ))}
        </Carousel>
      </Box>
      <Box mx={4} mb={2} display="flex">
        {actions({ currentItem, handleFlag, handleBinEmpty })}
      </Box>
    </Scanner>
  )
}

InventoryCount.propTypes = {
  classes: PropTypes.object.isRequired,
  onScan: PropTypes.func,
  actions: PropTypes.node,
  onClose: PropTypes.func,
  showSizeAndColor: PropTypes.bool,
}

export default withStyles(styles)(InventoryCount)
