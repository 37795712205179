import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import { TEXT_FIELD_TYPES } from 'constants/enums'
import Input from '@material-ui/core/Input'

import styles from './IRScannerStyles'

const IRScanner = ({ classes, onScan, header, children }) => {
  const [scannedCode, setScannedCode] = useState('')
  const inputRef = useRef(null)

  const refocus = () => {
    document.activeElement !== inputRef.current &&
    inputRef?.current?.focus &&
    inputRef.current.focus()
  }

  const onInputChange = async ({ target: { value } }) => {
    const multiCharChange = value.length - scannedCode.length > 1
    const newValue = multiCharChange ? value.slice(scannedCode.length) : value

    setScannedCode(newValue)

    if (onScan) {
      if (!onScan(newValue)) {
        refocus()
      }
      setScannedCode('')
    }
  }

  return (
    <Box className={classes.root} onClick={refocus}>
      {header}
      <Input
        className={classes.input}
        fullWidth
        disableUnderline
        name="upc"
        placeholder="->  <-"
        value={scannedCode}
        onChange={onInputChange}
        type={TEXT_FIELD_TYPES.text}
        autoFocus
        inputProps={{
          'data-test': 'barcode-input',
        }}
        inputRef={inputRef}
      />
      {children}
      <Box
        mx={4}
        mb={2}
        flex={1}
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
      >
        <Typography variant="overline" color="textSecondary" align="center">
          IR Scanner
        </Typography>
      </Box>
    </Box>
  )
}

IRScanner.propTypes = {
  classes: PropTypes.object.isRequired,
  onScan: PropTypes.func,
  header: PropTypes.node,
  children: PropTypes.node,
}

export default withStyles(styles)(IRScanner)
