import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'

import MuiRadio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import RadioIcon from 'icons/RadioIcon'

import styles from './RadioStyles'

const Radio = ({ classes, label, disabled, onChange }) => (
  <FormControlLabel
    classes={{
      root: classNames({
        [classes.root]: true,
        [classes.disabled]: disabled,
      }),
      label: classes.label,
    }}
    control={
      <MuiRadio
        className={classes.radio}
        value={label}
        disabled={disabled}
        onChange={onChange}
        icon={<div className={classes.unchecked} />}
        checkedIcon={<RadioIcon size={20} />}
        data-test={`radio-${label}`}
      />
    }
    label={label}
    disabled={disabled}
  />
)

Radio.defaultProps = {
  disabled: false,
}

Radio.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.node,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
}

export default withStyles(styles)(Radio)
