import { useUIContext } from 'context'

const useAlerts = () => {
  const { setAlert } = useUIContext()

  const showAlertError = message =>
    setAlert({ open: true, message, variant: 'error' })
  const showAlertWarning = message =>
    setAlert({ open: true, message, variant: 'warning' })
  const showAlertSuccess = message =>
    setAlert({ open: true, message, variant: 'success' })

  return {
    showAlertError,
    showAlertWarning,
    showAlertSuccess,
  }
}

export default useAlerts
