import React, { useState, useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import classNames from 'classnames'
import { Box, Typography, Button } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import Skeleton from '@material-ui/lab/Skeleton'

import Header from 'components/Header'
import InventoryCounter from 'components/InventoryCounter'
import AssignBins from 'components/AssignBins'
import ConfirmationDialog from 'components/ConfirmationDialog'
import InputDialog from 'components/InputDialog'
import AdjustmentDialog from 'components/AdjustmentDialog'

import {
  useAdminPurchaseOrderVariantList,
  useAdminPurchaseOrder,
  useAdminPlateList,
} from 'hooks'
import { useLocationsContext } from 'context'

import { withSign } from 'utils/general'

import { CYCLE_COUNT_STATES } from 'constants/cycleCounts'
import { URL } from 'constants/navigation'
import { DEFAULT_PRODUCT_IMAGE } from 'constants/general'
import { RECORD_TYPE } from 'constants/inventoryRecords'

import { STORAGE_VARIANT_STATE } from 'lib/config'

import styles from './CycleCountVariantShowScreenStyles'

const SHOW_MODE = {
  VIEW: 'VIEW',
  SCANNER: 'SCANNER',
  ASSIGN_BIN: 'ASSIGN_BIN',
}

const inventoryItem = (variant, quantity) => {
  if (!variant) {
    return {}
  }
  return {
    barcode: variant.barcode,
    bin: '',
    consignment: null,
    images: variant.images,
    price: variant.price,
    product: variant.product,
    quantity,
    shopify_id: variant.shopify_id,
    sku: variant.sku,
    total_discount: 0,
    color: variant?.color?.label,
    size: variant?.size,
    id: variant.id,
    variant,
  }
}

const itemToLookupBarcode = (variant, id) => {
  if (!variant) {
    return {}
  }
  return {
    barcode: '',
    bin: '',
    consignment: null,
    images: variant.images,
    price: variant.price,
    product: variant.product,
    quantity: 1,
    shopify_id: variant.shopify_id,
    sku: variant.sku,
    total_discount: 0,
    color: variant?.color?.label,
    size: variant?.size,
    variant,
  }
}

const variantStats = variant => {
  const receivingComplete = variant?.purchase_order_variant_receiving_complete
  const received = variant?.purchase_order_variant_received || 0
  const damaged = variant?.purchase_order_variant_damaged || 0
  const quantity = variant?.purchase_order_variant_quantity || 0
  const missing = receivingComplete
    ? Math.max(0, quantity - received - damaged)
    : 0

  const quantityInBins = Number.isInteger(
    variant?.purchase_order_variant_quantity_in_bins
  )
    ? variant.purchase_order_variant_quantity_in_bins
    : 0
  const allReceivedInBins = receivingComplete && quantityInBins === received
  return {
    receivingComplete,
    received,
    damaged,
    quantity,
    missing,
    quantityInBins,
    allReceivedInBins,
  }
}

const loadState = () => {
  try {
    const str = sessionStorage.getItem(STORAGE_VARIANT_STATE)
    if (!str) {
      return { cycleCount: {} }
    }
    return JSON.parse(str) ?? { cycleCount: {} }
  } catch (e) {
    console.error(`sessionStorage.getItem(${STORAGE_VARIANT_STATE}) failed`, e)
  }
  return {}
}

const saveState = ({ loadedState, id, variant }) => {
  try {
    sessionStorage.setItem(
      STORAGE_VARIANT_STATE,
      JSON.stringify({
        ...loadedState,
        cycleCount: { ...loadedState.cycleCount, [id]: variant },
      })
    )
  } catch (e) {
    console.error(`sessionStorage.setItem(${STORAGE_VARIANT_STATE}) failed`, e)
  }
}

const CycleCountVariantShowScreen = ({
  classes,
  match: {
    params: { id, variantId },
  },
}) => {
  const history = useHistory()
  const { location } = useLocationsContext()
  const loadedState = useMemo(() => loadState(), [id, variantId])

  const [variantState, setVariantState] = useState(
    loadedState.cycleCount[`${id}::${variantId}`] || {
      receivingComplete: false,
    }
  )
  const [mode, setMode] = useState(SHOW_MODE.VIEW)
  const [assignBinQuantity, setAssignBinQuantity] = useState(0)
  const [newBins, setNewBins] = useState([])
  const [changes, setChanges] = useState([])

  const { receivingComplete } = variantState

  const {
    updatePurchaseOrderVariants,
    isFetching: isLoadingPurchaseOrderVariants,
    variants: purchaseOrderVariants,
  } = useAdminPurchaseOrderVariantList(
    // eslint-disable-next-line radix
    parseInt(id),
    { purchase_order_id: id },
    { type: RECORD_TYPE.CYCLE_COUNT }
  )

  const { purchaseOrder, updatePurchaseOrder, inOrder } = useAdminPurchaseOrder(
    // eslint-disable-next-line radix
    parseInt(id),
    { type: RECORD_TYPE.CYCLE_COUNT }
  )

  const { bulkUpdatePlates } = useAdminPlateList({
    fetchList: false,
  })

  // eslint-disable-next-line radix
  const variantIdParsed = parseInt(variantId)
  // we have to do it this way currently because it is the only way
  // the server will attach PO specific variant fields to a variant
  const variant = purchaseOrderVariants.find(v => v.id === variantIdParsed)

  useEffect(() => {
    setVariantState(
      loadedState.cycleCount[`${id}::${variantId}`] || {
        receivingComplete: false,
      }
    )
  }, [id, variantId])

  const { receivingComplete: cycleCountSubmitted, quantity } =
    variantStats(variant)

  const actualCount =
    variant && variant?.purchase_order_variant_quantity !== null
      ? variant.purchase_order_variant_quantity +
        (variant.purchase_order_variant_backstock_quantity || 0)
      : null

  const quantityInBins = Number.isInteger(
    variant?.purchase_order_variant_quantity_in_bins
  )
    ? variant.purchase_order_variant_quantity_in_bins
    : 0

  const discrepancy =
    actualCount !== null ? withSign((quantityInBins - actualCount) * -1) : null

  const showActions =
    !cycleCountSubmitted &&
    (!receivingComplete ||
      (receivingComplete && assignBinQuantity !== quantity) ||
      (receivingComplete && actualCount === 0))

  const parentHref = `${URL.CYCLE_COUNTS}/${id}`

  const setModeView = () => setMode(SHOW_MODE.VIEW)
  const setModeScanner = () => setMode(SHOW_MODE.SCANNER)
  const setModeAssignBin = () => setMode(SHOW_MODE.ASSIGN_BIN)

  const DetailsRow = ({ title, value }) => (
    <Box className={classes.row}>
      <Typography variant="subtitle1" className={classes.colorDark}>
        {title}
      </Typography>
      <Typography
        variant="body2"
        className={classNames({ [classes.colorDark]: !value })}
        data-test={`${title}-details`}
      >
        {typeof value === 'undefined' ? (
          <Skeleton width="100px" />
        ) : (
          value ?? 'Not Set'
        )}
      </Typography>
    </Box>
  )

  DetailsRow.propTypes = {
    classes: PropTypes.object,
    title: PropTypes.string,
    value: PropTypes.string,
  }

  const handleCompleteScan = (newQuantity, clearCache) => {
    setModeView()
    clearCache && clearCache()
    const newVariantState = { ...variantState, receivingComplete: true }
    setVariantState(newVariantState)
    saveState({
      loadedState,
      id: `${id}::${variantId}`,
      variant: newVariantState,
    })
    updatePurchaseOrderVariants(
      [
        {
          id: variant?.id,
          quantity: newQuantity,
          receiving_complete: false,
          backstock_quantity: 0,
        },
      ],
      false
    )
  }

  // Using a component so we can access the inventory count context
  const CompleteScanButton = ({ item, clearCache, className }) => {
    return (
      <ConfirmationDialog
        title="Complete Scan"
        message="Are you sure you want to complete the scan?"
        onAccept={() => handleCompleteScan(item.checked, clearCache)}
      >
        {({ open }) => (
          <Button
            data-test="complete-scan"
            variant="contained"
            size="large"
            className={className}
            onClick={open}
          >
            Complete Scan
          </Button>
        )}
      </ConfirmationDialog>
    )
  }
  CompleteScanButton.propTypes = {
    item: PropTypes.object,
    clearCache: PropTypes.bool,
    className: PropTypes.string,
  }

  const tryStartScan = async (
    openScanDialog,
    openPreventScanDialog,
    setExtra
  ) => {
    if (purchaseOrder?.status === CYCLE_COUNT_STATES.created) {
      const orderVariants = await inOrder()
      if (orderVariants.length > 0) {
        setExtra({ message: `Orders: ${orderVariants.join(',')}` })
        openPreventScanDialog()
        return
      }
    }
    openScanDialog()
  }

  const handleContinueClick = () => {
    setAssignBinQuantity(0)
    const unfinishedScanIndex = purchaseOrderVariants.findIndex(
      v =>
        v.purchase_order_variant_receiving_started &&
        !v.purchase_order_variant_receiving_complete
    )
    const unstartedIndex = purchaseOrderVariants.findIndex(
      v => !v.purchase_order_variant_receiving_started
    )

    const navigateToIndex =
      unstartedIndex === -1 ? unfinishedScanIndex : unstartedIndex

    const fullPath =
      unstartedIndex === -1 && unfinishedScanIndex === -1
        ? parentHref
        : `${URL.CYCLE_COUNTS}/${id}${URL.CYCLE_COUNT_VARIANTS}/${purchaseOrderVariants[navigateToIndex].id}`
    history.push(fullPath)
  }

  const pickerActions =
    !receivingComplete && (variant?.barcode || variant?.dsin)
      ? ({ currentItem, clearCache }) => (
          <>
            <CompleteScanButton
              item={currentItem}
              clearCache={clearCache}
              className={classes.completeScan}
            />
          </>
        )
      : () => <></>

  const handleStartScan = () => {
    if (purchaseOrder?.status === CYCLE_COUNT_STATES.created) {
      updatePurchaseOrder({
        status: CYCLE_COUNT_STATES.counting,
      })
    }
    updatePurchaseOrderVariants(
      [
        {
          id: variant?.id,
          receiving_started: true,
        },
      ],
      false
    )
    setModeScanner()
  }

  const handleAssignBin = (bin, thisQuantity, expirationDate) => {
    setAssignBinQuantity(assignBinQuantity + thisQuantity)
    setNewBins([...newBins, bin])
    const newChanges = {
      id: variant.id,
      label: bin,
      quantity: thisQuantity,
      expiration_date: expirationDate,
    }
    setChanges(existingChanges => [...existingChanges, newChanges])
    if (
      !(
        thisQuantity <
        variant.purchase_order_variant_quantity - assignBinQuantity
      )
    ) {
      bulkUpdatePlates({
        location_id: location.id,
        create: true,
        is_adjust: false,
        zero_other_locations: true,
        variants: [...changes, newChanges],
      })
      updatePurchaseOrderVariants(
        [
          {
            id: variant?.id,
            new_bins: [...newBins, bin].join(','),
            receiving_complete: true,
          },
        ],
        false
      )
      setModeView()
    }
  }

  const assignBinBack = setModeAssignBin

  const handleNotes = note => {
    if (actualCount === 0) {
      bulkUpdatePlates({
        location_id: location.id,
        create: true,
        is_adjust: false,
        zero_other_locations: true,
        variants: [{ id: variant.id }],
      })
    }
    updatePurchaseOrderVariants(
      [
        {
          id: variant?.id,
          note,
          ...(actualCount === 0
            ? {
                quantity: 0,
                receiving_complete: true,
                backstock_quantity: 0,
              }
            : {}),
        },
      ],
      false
    )
  }

  const handleManualInput = openManualInput => {
    if (purchaseOrder?.status === CYCLE_COUNT_STATES.created) {
      updatePurchaseOrder({
        status: CYCLE_COUNT_STATES.counting,
      })
      updatePurchaseOrderVariants(
        [
          {
            id: variant?.id,
            receiving_started: true,
          },
        ],
        false
      )
    }
    openManualInput()
  }

  const view =
    {
      [SHOW_MODE.VIEW]: (
        <Box className={classes.root}>
          <Header
            breadcrumbs={[
              { text: 'Cycle', href: URL.CYCLE_COUNTS },
              { text: `#${id}`, href: parentHref },
              { text: variantId, active: true },
            ]}
            onBackClick={() => history.push(parentHref)}
          />
          <Box width="100%">
            {/* eslint-disable-next-line no-nested-ternary */}
            {variant?.images.length > 0 ? (
              <img
                src={variant.images[0].thumbnail_url}
                alt="variant"
                className={classes.image}
              />
            ) : isLoadingPurchaseOrderVariants ? (
              <Skeleton width="375px" height="286px" />
            ) : (
              <img
                src={DEFAULT_PRODUCT_IMAGE}
                alt="placeholder variant"
                className={classes.image}
              />
            )}
          </Box>
          <DetailsRow title="Brand" value={variant?.product?.brand} />
          <DetailsRow title="Product Name" value={variant?.product?.title} />
          <DetailsRow title="Color" value={variant?.color?.label} />
          <DetailsRow title="Size" value={variant?.size} />
          <DetailsRow title="SKU" value={variant?.sku} />
          <DetailsRow
            title="UPC/DSIN"
            value={variant?.barcode || variant?.dsin}
          />
          <DetailsRow
            title="Bin"
            value={variant?.available_for_sale_inventory?.bin}
          />
          <DetailsRow
            title="Current Available for Sale"
            value={variant?.available_for_sale_inventory?.quantity}
          />
          <DetailsRow
            title="Expected Quantity in Bins"
            value={
              purchaseOrder?.status === CYCLE_COUNT_STATES.created
                ? null
                : variant?.purchase_order_variant_quantity_in_bins
            }
          />
          <DetailsRow
            title="Known Physical Units"
            value={variant?.plates.reduce((p, c) => c.quantity + p, 0)}
          />
          <DetailsRow title="Actual Count" value={actualCount} />
          <DetailsRow title="Discrepancy" value={discrepancy} />
          <DetailsRow
            title="Discrepancy Notes"
            value={variant?.purchase_order_variant_note}
          />
          <Box className={classes.spacer} />
          {/* eslint-disable-next-line no-nested-ternary */}
          {purchaseOrder?.status === CYCLE_COUNT_STATES.cancelled ? (
            <Button variant="contained" disabled fullWidth>
              Cancelled
            </Button>
          ) : purchaseOrderVariants.length > 0 ? (
            <Box className={classes.actions} data-test="action-container">
              {showActions ? (
                <ConfirmationDialog
                  title="Order In Progress"
                  message={
                    <Typography>
                      An Order is being picked with an item in this cycle count.
                      Cycle Count cannot be started.
                    </Typography>
                  }
                  acceptText="OK"
                  showDeny={false}
                >
                  {({ open: openPreventStart, setExtra }) => (
                    <InputDialog
                      title="Manual Entry"
                      message={
                        <Typography>
                          Enter number of received and damaged units.
                        </Typography>
                      }
                      label="Received"
                      label2="Damaged"
                      onConfirm={newReceived => handleCompleteScan(newReceived)}
                    >
                      {({ open: openManualInput }) => (
                        <ConfirmationDialog
                          title="Start Scan"
                          message="Continue with Scanning or manually enter counts?"
                          acceptText="Scan"
                          denyText="Manually Enter"
                          onAccept={handleStartScan}
                          onDeny={() => handleManualInput(openManualInput)}
                        >
                          {({ open: openManual }) => (
                            <AdjustmentDialog
                              label="Notes"
                              onConfirm={handleNotes}
                            >
                              {({ open: openNotesInput }) =>
                                receivingComplete &&
                                quantityInBins - actualCount !== 0 &&
                                !variant?.purchase_order_variant_note ? (
                                  <Button
                                    data-test="po-primary-action"
                                    variant="contained"
                                    color="primary"
                                    onClick={openNotesInput}
                                    disabled={!purchaseOrder}
                                  >
                                    Set Adjustment Note
                                  </Button>
                                ) : (
                                  <Button
                                    data-test="po-primary-action"
                                    variant="contained"
                                    color="primary"
                                    onClick={
                                      receivingComplete
                                        ? setModeAssignBin
                                        : () =>
                                            tryStartScan(
                                              variant
                                                ?.available_for_sale_inventory
                                                ?.quantity > 20
                                                ? openManual
                                                : handleStartScan,
                                              openPreventStart,
                                              setExtra
                                            )
                                    }
                                    disabled={!purchaseOrder}
                                  >
                                    {/* eslint-disable-next-line no-nested-ternary */}
                                    {receivingComplete
                                      ? 'Assign Bin'
                                      : purchaseOrder?.status ===
                                        CYCLE_COUNT_STATES.created
                                      ? 'Start Count'
                                      : 'Start Scan'}
                                  </Button>
                                )
                              }
                            </AdjustmentDialog>
                          )}
                        </ConfirmationDialog>
                      )}
                    </InputDialog>
                  )}
                </ConfirmationDialog>
              ) : (
                <Button
                  data-test="po-primary-action"
                  variant="contained"
                  disabled={isLoadingPurchaseOrderVariants}
                  onClick={handleContinueClick}
                  color="primary"
                >
                  {isLoadingPurchaseOrderVariants ? (
                    <CircularProgress />
                  ) : (
                    'Continue'
                  )}
                </Button>
              )}
            </Box>
          ) : (
            <Skeleton width="359px" height="40px" />
          )}
        </Box>
      ),
      [SHOW_MODE.SCANNER]: (
        <InventoryCounter
          items={[
            inventoryItem(
              variant,
              variant?.available_for_sale_inventory?.quantity
            ),
          ]}
          onClose={
            receivingComplete || !(variant?.barcode || variant?.dsin)
              ? assignBinBack
              : setModeView
          }
          actions={pickerActions}
          type="cycle_count"
          parentId={id}
        />
      ),
      [SHOW_MODE.ASSIGN_BIN]: (
        <AssignBins
          lineItem={itemToLookupBarcode(variant, variantId)}
          maxQuantity={quantity - assignBinQuantity}
          onAssignBin={handleAssignBin}
          onBack={() => {
            setModeView()
          }}
          currentBins={
            variant?.available_for_sale_inventory?.bin
              ?.split(',')
              ?.map(b => b.trim()) || []
          }
        />
      ),
    }[mode] ?? null

  return view
}

export default withStyles(styles)(CycleCountVariantShowScreen)
