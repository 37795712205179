import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import InputAdornment from '@material-ui/core/InputAdornment'
import Button from '@material-ui/core/Button'

import { TEXT_FIELD_TYPES } from 'constants/enums'
import TextField from 'components/TextField'

import styles from './OrderManualPickerStyles'

const CHARACTERS_TO_CONFIRM = 4

const OrderManualPicker = ({
  classes,
  numbersOnly,
  onScan,
  barcode,
  header,
  children,
  verifyRemainder = true,
}) => {
  const [barcodeConfirm, setBarcodeConfirm] = React.useState('')

  const barcodeHint =
    barcode && barcode?.substring(0, barcode.length - CHARACTERS_TO_CONFIRM)
  const barcodeRemainder =
    barcode &&
    barcode.substring(barcode.length - CHARACTERS_TO_CONFIRM, barcode.length)

  const onInputChange = async ({ target: { value } }) => {
    setBarcodeConfirm(value)
    if (barcode) {
      if (value.length < CHARACTERS_TO_CONFIRM) {
        return
      }
      setBarcodeConfirm('')
      if (value !== barcodeRemainder && barcodeRemainder && !verifyRemainder) {
        onScan &&
          onScan(
            barcode.slice(0, barcode.length - CHARACTERS_TO_CONFIRM) + value
          )
      } else if (value === barcodeRemainder && barcodeRemainder) {
        onScan && onScan(barcode)
      }
    }
  }

  const handleSubmit = () => {
    setBarcodeConfirm('')
    onScan(barcodeConfirm)
  }

  return (
    <Box className={classes.root}>
      {header}
      <Box className={classes.container}>
        <TextField
          data-test="barcode-field"
          name="upc"
          placeholder={barcode && 'XXXX'}
          fullWidth
          value={barcodeConfirm}
          onChange={onInputChange}
          type={numbersOnly ? TEXT_FIELD_TYPES.number : TEXT_FIELD_TYPES.text}
          InputProps={
            barcode && {
              startAdornment: (
                <InputAdornment
                  position="start"
                  classes={{ positionStart: classes.barcodeHint }}
                >
                  {barcodeHint}
                </InputAdornment>
              ),
            }
          }
          inputClassName={classes.barcodeInput}
        />
        {!barcode && (
          <Button
            data-test="manual-submit"
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSubmit}
          >
            Submit
          </Button>
        )}
      </Box>
      {children}
    </Box>
  )
}

OrderManualPicker.defaultProps = {
  numbersOnly: true,
  barcodeHint: '',
}

OrderManualPicker.propTypes = {
  classes: PropTypes.object.isRequired,
  numbersOnly: PropTypes.bool,
  onScan: PropTypes.func,
  onSubmit: PropTypes.func,
  error: PropTypes.string,
  barcodeHint: PropTypes.string,
  barcode: PropTypes.string,
  header: PropTypes.node,
  children: PropTypes.node,
  verifyRemainder: PropTypes.bool,
}

export default withStyles(styles)(OrderManualPicker)
