const jssStyles = theme => ({
  root: {
    backgroundColor: theme.palette.colorsAF.white,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  input: {
    color: theme.palette.colorsAF.lightGray,
    border: 'none',
    height: 5,
    overflow: 'hidden',
    marginBottom: 4,
    '& input': {
      textAlign: 'center',
      padding: 0,
      marginTop: -3,
    },
    '&.Mui-focused': {
      color: theme.palette.colorsAF.fast,
    },
  },
  header: {
    backgroundColor: theme.palette.colorsAF.white,
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
})

export default jssStyles
