import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { Box, Typography, Fab } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'

import SearchIcon from '@material-ui/icons/Search'

import BottomDrawerMenu from 'components/BottomDrawerMenu'
import HeaderWithLocation from 'components/HeaderWithLocation'
import PurchaseOrderStatus from 'components/PurchaseOrderStatus'
import Tabs from 'components/Tabs'

import { useAdminPurchaseOrderList } from 'hooks'

import { useLocationsContext } from 'context'

import { LIST_PATHS, API_PARAMS, REL } from 'constants/queryParams'
import { URL } from 'constants/navigation'
import {
  PURCHASE_ORDER_STATE_TYPES,
  TERM_POSSIBILITIES,
} from 'constants/purchaseOrders'

import styles from './PurchaseOrderListScreenStyles'

const TABS = [
  [PURCHASE_ORDER_STATE_TYPES.submitted],
  [PURCHASE_ORDER_STATE_TYPES.receivingInProgress],
  [
    PURCHASE_ORDER_STATE_TYPES.fullyReceived,
    PURCHASE_ORDER_STATE_TYPES.overReceived,
    PURCHASE_ORDER_STATE_TYPES.partiallyReceived,
    PURCHASE_ORDER_STATE_TYPES.closed,
  ],
]

const SCROLL_HEIGHT_OFFSET_PX = 50

const TERM_LOOKUP = {}
TERM_POSSIBILITIES.forEach(t => {
  TERM_LOOKUP[t.value] = t.label
})

const PurchaseOrderListScreen = ({ classes }) => {
  const [status, setStatus] = useState([PURCHASE_ORDER_STATE_TYPES.submitted])
  const [params, setParams] = useState({})
  const { location } = useLocationsContext()
  const {
    isFetchingPurchaseOrders,
    purchaseOrders,
    hasPurchaseOrdersNext,
    fetchPurchaseOrdersNext,
  } = useAdminPurchaseOrderList(params)

  useEffect(() => {
    setParams({
      [API_PARAMS.SORT]: LIST_PATHS.PURCHASE_ORDERS_ETA,
      [LIST_PATHS.PURCHASE_ORDERS_STATUS_FILTER]: status.join(','),
      [LIST_PATHS.PURCHASE_ORDERS_STATUS_REL]: REL.IN,
      [LIST_PATHS.PURCHASE_ORDERS_LOCATION_ID]: location?.id,
    })
  }, [status, location])

  const handleScroll = e => {
    const { scrollHeight, scrollTop, clientHeight } = e.target
    if (scrollTop + SCROLL_HEIGHT_OFFSET_PX >= scrollHeight - clientHeight) {
      hasPurchaseOrdersNext && fetchPurchaseOrdersNext()
    }
  }

  const Loading = (
    <Box className={classes.listContainer}>
      {new Array(4).fill(0).map((_, i) => (
        <Box key={0 - i} className={classes.tabContainer} mb="2px">
          <Skeleton width="30px" height="21px" />
          <Skeleton width="80px" height="21px" />
          <Skeleton width="80px" height="21px" />
          <Box className={classes.etaStatusRow}>
            <Skeleton width="160px" height="21px" />
            <Skeleton width="80px" height="21px" />
          </Box>
        </Box>
      ))}
    </Box>
  )

  const tabContents = (
    <Box className={classes.listContainer} onScroll={handleScroll}>
      {purchaseOrders.map(po => (
        <Box key={po.id} className={classes.tabContainer} data-test="po-item">
          <Link to={`${URL.PURCHASE_ORDERS}/${po.id}`} className={classes.link}>
            <Typography variant="body2">#{po.id}</Typography>
            <Typography variant="body1">{po?.supplier?.name}</Typography>
            <Typography variant="body1">{TERM_LOOKUP[po.terms]}</Typography>
            <Box className={classes.etaStatusRow}>
              <Typography variant="body2" component="span">
                Estimated Arrival{' '}
                {moment(po.estimated_arrival_at).format('M/D/YY')}
              </Typography>
              <PurchaseOrderStatus
                className={classes.status}
                status={po.status}
              />
            </Box>
          </Link>
        </Box>
      ))}
      {isFetchingPurchaseOrders && Loading}
    </Box>
  )

  const handleTabChange = tabIndex => {
    setStatus(TABS[tabIndex])
  }

  return (
    <>
      <HeaderWithLocation title="POs" />
      <Tabs
        tabLabels={['Incoming', 'Receiving', 'Received']}
        tabContents={[tabContents, tabContents, tabContents]}
        onChange={handleTabChange}
        classes={{
          tabPanel: classes.tabPanel,
          root: classes.tabRoot,
        }}
      />
      <Link to={URL.PURCHASE_ORDERS_SEARCH}>
        <Fab className={classes.searchIcon} data-test="po-search">
          <SearchIcon style={{ color: 'white' }} />
        </Fab>
      </Link>
      <BottomDrawerMenu />
    </>
  )
}

PurchaseOrderListScreen.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(PurchaseOrderListScreen)
