const jssStyles = theme => ({
  root: {
    '& > *:not(:first-child)': {
      marginTop: theme.spacing(1),
    },
  },
  disabled: {
    opacity: 0.65,
  },
  item: {
    border: '1px solid',
    borderColor: theme.palette.border.default,
    borderRadius: 4,
  },
})

export default jssStyles
