const PREFIX_PALLET = 'PA-'
const PREFIX_RESERVE = 'RSV-'
const PREFIX_FRIDGE = 'FR-'
const PREFIX_FREEZER = 'FRZ-'
const PREFIX_MISC = 'M-'

const MULTIPLIER_UNKNOWN = 10 ** 20
const MULTIPLIER_PALLET = 10 ** 18
const MULTIPLIER_RESERVE = 10 ** 16
const MULTIPLIER_FRIDGE = 10 ** 14
const MULTIPLIER_FREEZER = 10 ** 14
const MULTIPLIER_RACK = 10 ** 9
const MULTIPLIER_ROW = 10 ** 4

const letterToNumber = l => l.toUpperCase()?.charCodeAt(0) - 65
const unprefixBin = (bin, prefix) => Number(bin.substr(prefix.length))

/**
 * threeDotBinToNumber
 * For sorting, a bin such as AA.B.C (AA & C are numbers, and B a letter)
 * is converted to a number by breaking it into three parts, and using
 * MULTIPLIERS like AA * 10^9 so that the bin can be mapped to comparable integers.
 *
 * Three dot labels correspond to Rack.Row.Section
 *
 * A simplified example would be 2.3.6:
 * 2*100 + 3*10 + 6 = 236
 */
const threeDotBinToNumber = bin => {
  const parts = bin.split('.')
  if (parts.length !== 3) {
    return MULTIPLIER_UNKNOWN
  }
  const [a, b, c] = parts
  const aNum = Number(a)
  const bNum = [...b]
    .map((l, idx) => idx * 100 + letterToNumber(l))
    .reduce((thisA, thisB) => thisA + thisB, 0)
  const cNum = Number(c)

  const num = MULTIPLIER_RACK * aNum + MULTIPLIER_ROW * bNum + cNum
  return Number.isNaN(num) ? MULTIPLIER_UNKNOWN : num
}

/**
 * binToNumber
 * Uses binToNumber & threeDotBinToNumber to convert bin to a comparable number.
 */
const binToNumber = bin => {
  if (bin.startsWith(PREFIX_FREEZER)) {
    return unprefixBin(bin, PREFIX_FREEZER) * MULTIPLIER_FREEZER
  }
  if (bin.startsWith(PREFIX_FRIDGE)) {
    return unprefixBin(bin, PREFIX_FRIDGE) * MULTIPLIER_FRIDGE
  }
  if (bin.startsWith(PREFIX_PALLET)) {
    return unprefixBin(bin, PREFIX_PALLET) * MULTIPLIER_PALLET
  }
  if (bin.startsWith(PREFIX_RESERVE)) {
    return unprefixBin(bin, PREFIX_RESERVE) * MULTIPLIER_RESERVE
  }
  if (bin.startsWith(PREFIX_MISC)) {
    // bins that start with PREFIX_MISC will always come first
    // up through 1000.D.5
    return threeDotBinToNumber(bin.substr(PREFIX_MISC.length)) - 10 ** 13
  }
  return threeDotBinToNumber(bin)
}

export const sortBinList = (a, b) => {
  const aNum = binToNumber(a)
  const bNum = binToNumber(b)
  if (!a && !b) {
    return 0
  }
  if (!a && b) {
    return 1
  }
  if (a && !b) {
    return -1
  }
  if (aNum === bNum) {
    return 0
  }
  return aNum < bNum ? -1 : 1
}

export const getSortedBinList = bins => {
  if (!bins) {
    return ['']
  }
  const sortedBins = bins
    .split(',')
    .map(b => b.trim())
    .sort(sortBinList)
  return sortedBins
}

const getFirstBin = bins => getSortedBinList(bins)[0]

/**
 * sortLineItems is passed to the sort function, to sort by bin in the warehouse.
 * Uses binToNumber and threeDotBinToNumber for comparison.
 */
export const sortLineItems = ({ bin: aRaw }, { bin: bRaw }) => {
  const a = getFirstBin(aRaw)
  const b = getFirstBin(bRaw)
  if (!a && !b) {
    return 0
  }
  if (!a && b) {
    return 1
  }
  if (a && !b) {
    return -1
  }
  const aNum = binToNumber(a)
  const bNum = binToNumber(b)
  if (aNum === bNum) {
    return 0
  }
  return aNum < bNum ? -1 : 1
}

export const binToTier = bin => {
  if (bin.startsWith(PREFIX_FREEZER)) {
    return PREFIX_FREEZER
  }
  if (bin.startsWith(PREFIX_FRIDGE)) {
    return PREFIX_FRIDGE
  }
  if (bin.startsWith(PREFIX_PALLET)) {
    return PREFIX_PALLET
  }
  if (bin.startsWith(PREFIX_RESERVE)) {
    return PREFIX_RESERVE
  }
  if (bin.startsWith(PREFIX_MISC)) {
    return PREFIX_MISC
  }
  return 'bins'
}
