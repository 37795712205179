import { fade } from '@material-ui/core/styles'
import { white } from 'theme/colors'

export default {
  paper: {
    boxShadow: 'none',
    borderRadius: 0,
  },
  tableRoot: {
    color: fade(white, 0.5),
  },
  responsiveBase: {
    overflow: 'visible',
  },
}
