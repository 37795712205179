import { useMutation, useQueryClient } from 'react-query'

import { useAPIContext } from 'context'

import { useResourceRQ, useResourceListRQ } from 'hooks'
import {
  fileInputProcessor,
  multiPartFormParams,
} from 'hooks/common/useResourceRQ'

import {
  purchaseOrderVariantsUpdateEndpoint,
  purchaseOrderVariantsEndpoint,
  purchaseOrderCsvImportEndpoint,
  variantListEndpoint,
  variantPurchaseOrderListEndpoint,
  purchaseOrderVariantsReceiveEndpoint,
} from 'api/endpoints'

const useAdminPurchaseOrderVariantList = (
  purchaseOrderId,
  params,
  { onImportCSVSuccess, onCreateSuccess, type } = {}
) => {
  const { api } = useAPIContext()
  const queryClient = useQueryClient()

  const {
    isFetching,
    data: variants,
    hasData: hasVariants,
    meta: variantsMeta,
  } = useResourceListRQ({
    endpoint: variantPurchaseOrderListEndpoint,
    params,
    args: [],
    enabled: typeof purchaseOrderId === 'number',
  })

  const { operate: createPurchaseOrderVariants } = useResourceRQ({
    endpoint: purchaseOrderVariantsEndpoint,
    args: [purchaseOrderId],
    fetchingEnabled: false,
    inputProcessor: res => {
      return {
        purchase_order: {
          variant_quantities: res,
        },
      }
    },
    resetQueries: [
      () => [variantListEndpoint(), { purchase_order_id: purchaseOrderId }],
    ],
    onSuccess: onCreateSuccess,
  })

  const { operate: importCSV } = useResourceRQ({
    endpoint: purchaseOrderCsvImportEndpoint,
    args: [purchaseOrderId],
    fetchingEnabled: false,
    inputProcessor: fileInputProcessor('purchase_order[csv]'),
    params: multiPartFormParams,
    resetQueries: [
      () => [variantListEndpoint(), { purchase_order_id: purchaseOrderId }],
    ],
    onSuccess: onImportCSVSuccess,
  })

  const { mutate: updateVariant } = useMutation(
    ({ variant, payload, updateInventory = false }) =>
      api.put(
        purchaseOrderVariantsUpdateEndpoint(purchaseOrderId, variant, type),
        {
          purchase_order_variant: payload,
          updateInventory,
        },
        { params }
      ),
    {
      onSuccess: ({ variant }) => {
        queryClient.invalidateQueries(variantPurchaseOrderListEndpoint(), {
          purchase_order_id: purchaseOrderId,
        })
      },
    }
  )

  const updatePurchaseOrderVariants = async resources => {
    resources.forEach(variant => {
      const payload = {
        quantity: variant?.quantity,
        received: variant?.received,
        damaged: variant?.damaged,
        pastReceived: variant?.past_received,
        pastDamaged: variant?.past_damaged,
        receiving_complete: variant?.receiving_complete,
        quantity_in_bins: variant?.quantity_in_bins,
        receiving_started: variant?.receiving_started,
        backstock_quantity: variant?.backstock_quantity,
        new_bins: variant?.new_bins,
        note: variant?.note,
      }
      // only update things that have been specified
      Object.keys(payload).forEach(
        key => payload[key] === undefined && delete payload[key]
      )
      updateVariant({
        variant,
        payload,
        updateInventory: variant?.update_inventory,
      })
    })
  }

  const { operate: receivePurchaseOrderVariant } = useMutation(
    ({ variant, payload }) =>
      api.put(purchaseOrderVariantsReceiveEndpoint(purchaseOrderId, variant)),
    {
      onSuccess: ({ variant }) => {
        queryClient.invalidateQueries(variantPurchaseOrderListEndpoint(), {
          purchase_order_id: purchaseOrderId,
        })
      },
    }
  )

  const { mutate: deletePurchaseOrderVariant } = useMutation(
    variant =>
      api.delete(purchaseOrderVariantsUpdateEndpoint(purchaseOrderId, variant)),
    {
      onSuccess: () => {
        queryClient.resetQueries(variantListEndpoint(), {
          purchase_order_id: purchaseOrderId,
        })
      },
    }
  )

  return {
    createPurchaseOrderVariants,
    updatePurchaseOrderVariants,
    receivePurchaseOrderVariant,
    deletePurchaseOrderVariant,
    importCSV,
    isFetching,
    variants: variants || [],
    hasVariants,
    variantsMeta,
  }
}

export default useAdminPurchaseOrderVariantList
