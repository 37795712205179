import React from 'react'
import PropTypes from 'prop-types'
import { compact, isEmpty } from 'lodash'

import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Divider from '@material-ui/core/Divider'
import { ExpandLess } from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

import GiftIcon from 'icons/GiftIcon'

import { formatCurrency } from 'utils/general'

import styles from './OrderDetailsStyles'

const fullName = o => compact([o?.first_name, o?.last_name]).join(' ')

const customerName = order => order?.user?.name ?? fullName(order)

const customerEmail = order =>
  order?.user?.checkout_email ?? order?.user?.email ?? order?.email

const customerPhone = order => order?.user?.phone

const formatAddress = ({ address1, address2, city, zipcode }) =>
  compact([address1, address2, `${city} ${zipcode}`])

const renderAddress = order =>
  formatAddress(order).map((line, index) => (
    <React.Fragment key={index}>
      {line}
      <br />
    </React.Fragment>
  ))

const renderTotalQuantity = quantity =>
  quantity === 1 ? `${quantity} item` : `${quantity ?? 0} items`

const DetailItem = ({ title, left, right }) => (
  <Box mb={2}>
    {title && <Typography variant="body2">{title}</Typography>}
    <Box display="flex" justifyContent="space-between">
      {typeof left === 'string' ? (
        <Typography variant="body1" color="textSecondary">
          {left}
        </Typography>
      ) : (
        left
      )}
      {typeof right === 'string' ? (
        <Typography variant="body2" color="textSecondary">
          {right}
        </Typography>
      ) : (
        right
      )}
    </Box>
  </Box>
)

DetailItem.propTypes = {
  title: PropTypes.string,
  left: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  right: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}

const OrderDetails = ({ classes, order, expanded, onChange }) => {
  const {
    stripe_amount: stripeAmount,
    credit_applied: storeCredit,
    total_line_items_price: subtotal,
    total_discounts: discounts,
    discount_codes: promoCodes,
    total_tax: tax,
    gift_detail: gift,
  } = order

  return (
    <Accordion
      className={classes.root}
      square
      elevation={0}
      expanded={expanded}
      onChange={onChange}
    >
      <AccordionSummary
        classes={{
          root: classes.summaryRoot,
          content: classes.summaryContentRoot,
          expandIcon: classes.summaryExpandIcon,
        }}
        expandIcon={<ExpandLess />}
      >
        <Box display="flex" flexDirection="column">
          <Typography variant="body2">Subtotal</Typography>
          <Typography variant="body1">
            {renderTotalQuantity(order?.total_line_items_quantity)}
          </Typography>
        </Box>
        <Box
          mr={1}
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
        >
          <Typography variant="body1">{formatCurrency(subtotal)}</Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails className={classes.detailsContainer}>
        <Box display="flex" flexDirection="column">
          {Boolean(Number(discounts)) && (
            <DetailItem left="Discount" right={formatCurrency(discounts)} />
          )}
          {promoCodes?.length === 1 && (
            <Typography variant="body2">Promo Code</Typography>
          )}
          {promoCodes?.length > 1 && (
            <Typography variant="body2">Promo Codes</Typography>
          )}
          {promoCodes?.map(promo => (
            <DetailItem
              key={promo.code}
              left={promo.code}
              right={`-${formatCurrency(promo.amount)}`}
            />
          ))}
          <DetailItem left="Tax" right={formatCurrency(tax || 0)} />
          {Boolean(Number(storeCredit)) && (
            <DetailItem
              left="Store Credit"
              right={`-${formatCurrency(storeCredit)}`}
            />
          )}
          <Box display="flex" justifyContent="space-between" mb={3}>
            <Typography variant="body1">Total</Typography>
            <Typography variant="body1" className={classes.totalAmount}>
              {formatCurrency(stripeAmount)}
            </Typography>
          </Box>
        </Box>

        <Divider className={classes.divider} />

        <Box display="flex" flexDirection="column">
          <DetailItem title="Customer" left={customerName(order)} />
          {!isEmpty(gift) && (
            <DetailItem
              title={
                <>
                  <span
                    style={{ position: 'relative', top: 1, paddingRight: 4 }}
                  >
                    <GiftIcon />
                  </span>{' '}
                  Recipient
                </>
              }
              left={`${gift.recipient_first_name} ${gift.recipient_last_name}`}
            />
          )}
          <DetailItem
            title="Contact Information"
            left={customerEmail(order)}
            right={
              <Typography variant="body2" color="primary">
                {customerPhone(order)}
              </Typography>
            }
          />
          <DetailItem
            title="Address"
            left={
              <Typography variant="body1" color="textSecondary">
                {renderAddress(order)}
              </Typography>
            }
          />
        </Box>
      </AccordionDetails>
    </Accordion>
  )
}

OrderDetails.defaultProps = {}

OrderDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired,
  expanded: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default withStyles(styles)(OrderDetails)
