/* WARNING: copied from dashboard; keep in sync */
import { useRef } from 'react'

const useApiCancel = () => {
  const cancelRef = useRef()

  const cancel = () => {
    cancelRef.current && cancelRef.current()
    cancelRef.current = null
  }

  const getToken = cancelFunc => {
    cancelRef.current = cancelFunc
  }

  return [cancel, getToken]
}

export default useApiCancel
