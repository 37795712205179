import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import debounce from 'lodash/debounce'
import { withStyles } from '@material-ui/core/styles'
import { useHistory, Link } from 'react-router-dom'
import { Box, Typography, Fab } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import Button from '@material-ui/core/Button'

import SearchIcon from '@material-ui/icons/Search'
import AddIcon from '@material-ui/icons/Add'

import BottomDrawerMenu from 'components/BottomDrawerMenu'
import HeaderWithLocation from 'components/HeaderWithLocation'
import Tabs from 'components/Tabs'
import TextField from 'components/TextField'
import Dialog, {
  DialogTitle,
  DialogContent,
  DialogActions,
} from 'components/Dialog'

import { useAdminPurchaseOrderList } from 'hooks'

import { useLocationsContext } from 'context'

import { LIST_PATHS, API_PARAMS, REL, SORT } from 'constants/queryParams'
import { URL } from 'constants/navigation'

import { CYCLE_COUNT_STATES } from 'constants/cycleCounts'
import { RECORD_TYPE } from 'constants/inventoryRecords'

import styles from './CycleCountListScreenStyles'

const DEBOUNCE_MS = 300

const TABS = [
  [CYCLE_COUNT_STATES.created],
  [CYCLE_COUNT_STATES.counting],
  [CYCLE_COUNT_STATES.counted],
]

const ALL_FILTERS = TABS.flat().join(',')

const CycleCountListScreen = ({ classes }) => {
  const history = useHistory()
  const [status, setStatus] = useState([CYCLE_COUNT_STATES.created])
  const [searchMode, setSearchMode] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [params, setParams] = useState({})
  const { location } = useLocationsContext()
  const { isFetchingPurchaseOrders, purchaseOrders } =
    useAdminPurchaseOrderList(params, RECORD_TYPE.CYCLE_COUNT)

  const debounceList = debounce(setParams, DEBOUNCE_MS, {
    leading: true,
  })

  useEffect(() => {
    if (!searchMode) {
      setParams({
        [API_PARAMS.SORT]: LIST_PATHS.CYCLE_COUNT_CREATED_AT,
        [API_PARAMS.SORT_DIRECTION]: SORT.DESC,
        [LIST_PATHS.PURCHASE_ORDERS_STATUS_FILTER]: status.join(','),
        [LIST_PATHS.PURCHASE_ORDERS_STATUS_REL]: REL.IN,
        [LIST_PATHS.PURCHASE_ORDERS_LOCATION_ID]: location?.id,
      })
    }
  }, [status, location, searchMode])

  useEffect(() => {
    if (searchMode && searchText !== '') {
      debounceList({
        [API_PARAMS.SORT]: LIST_PATHS.PURCHASE_ORDERS_ETA,
        [LIST_PATHS.PURCHASE_ORDERS_STATUS_FILTER]: ALL_FILTERS,
        [LIST_PATHS.PURCHASE_ORDERS_STATUS_REL]: REL.IN,
        [LIST_PATHS.PURCHASE_ORDERS_LOCATION_ID]: location?.id,
        [API_PARAMS.SEARCH]: searchText,
      })
    }
  }, [location, searchMode, searchText])

  const Loading = (
    <Box className={classes.listContainer}>
      {new Array(4).fill(0).map((_, i) => (
        <Box key={0 - i} className={classes.tabContainer} mb="2px">
          <Skeleton width="30px" height="21px" />
          <Skeleton width="80px" height="21px" />
          <Skeleton width="80px" height="21px" />
          <Box className={classes.etaStatusRow}>
            <Skeleton width="160px" height="21px" />
            <Skeleton width="80px" height="21px" />
          </Box>
        </Box>
      ))}
    </Box>
  )

  const tabContents = (
    <Box className={classes.listContainer}>
      {purchaseOrders.map(po => (
        <Box key={po.id} className={classes.tabContainer} data-test="po-item">
          <Link to={`${URL.CYCLE_COUNTS}/${po.id}`} className={classes.link}>
            <Typography variant="body2">{po.variants_count} Items</Typography>
            <Typography variant="body1">Count #{po.id}</Typography>
            <Typography variant="body1">Created by {po.author}</Typography>
          </Link>
        </Box>
      ))}
      {isFetchingPurchaseOrders && Loading}
    </Box>
  )

  const handleTabChange = tabIndex => {
    setStatus(TABS[tabIndex])
  }

  const handleSearch = e => {
    setSearchText(e.target.value)
  }

  return (
    <>
      <HeaderWithLocation
        title="Cycle Counts"
        onBackClick={searchMode ? () => setSearchMode(false) : null}
      />
      {!searchMode && (
        <>
          <Tabs
            tabLabels={['Created', 'Counting', 'Counted']}
            tabContents={[tabContents, tabContents, tabContents]}
            onChange={handleTabChange}
            classes={{
              tabPanel: classes.tabPanel,
              root: classes.tabRoot,
            }}
          />
          <Fab
            className={classes.searchIcon}
            data-test="po-search"
            onClick={() => setSearchMode(true)}
          >
            <SearchIcon style={{ color: 'white' }} />
          </Fab>
          <Dialog
            content={({ close }) => (
              <>
                <DialogTitle close={close}>New Cycle Count</DialogTitle>
                <DialogContent>
                  Select which kind of Cycle Count you would like to perform.
                </DialogContent>
                <DialogActions>
                  <Button
                    data-test="button-product"
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      history.push(`${URL.CYCLE_COUNTS_CREATE}/product`)
                    }
                  >
                    Product
                  </Button>
                  <Button
                    data-test="button-variant"
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      history.push(`${URL.CYCLE_COUNTS_CREATE}/variant`)
                    }
                  >
                    Variant
                  </Button>
                </DialogActions>
              </>
            )}
          >
            {({ open }) => (
              <Fab
                className={classes.newIcon}
                data-test="new-cycle-count"
                onClick={open}
              >
                <AddIcon style={{ color: 'white' }} />
              </Fab>
            )}
          </Dialog>
          <BottomDrawerMenu />
        </>
      )}
      {searchMode && (
        <>
          <TextField
            className={classes.searchInput}
            placeholder="Search..."
            onChange={handleSearch}
            value={searchText}
            data-test="po-search-field"
          />
          <Box className={classes.searchContainer}>
            {searchText !== '' && tabContents}
          </Box>
        </>
      )}
    </>
  )
}

CycleCountListScreen.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(CycleCountListScreen)
