import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import { getProductImage } from 'utils/products'
import { DEFAULT_VARIANT_TITLE } from 'constants/general'

import UnknownProduct from 'assets/unknown_product.jpg'

import styles from './ProductSummaryStyles'

// product could be a variant object
const ProductSummary = ({
  classes,
  product,
  showQuantity,
  showSku,
  showVariantTitle,
  endAdornment,
  onClick,
  className,
}) => {
  const image = getProductImage(product)

  const vendor = product.vendor || product.product?.vendor

  const title =
    product.title === DEFAULT_VARIANT_TITLE || showVariantTitle
      ? product.product?.title
      : product.title || product.product_title

  const subtitle = product.title === DEFAULT_VARIANT_TITLE ? '' : product.title

  return (
    <Box
      className={classNames({
        [className]: Boolean(className),
        [classes.clickable]: Boolean(onClick),
      })}
      display="flex"
      justifyContent="flex-start"
      onClick={onClick}
      onAuxClick={onClick}
    >
      <Box flexShrink={0} height={80} width={80} mr="24px">
        <img
          src={image || UnknownProduct}
          alt={title}
          className={classes.image}
        />
      </Box>
      <Box flexGrow={1}>
        <Typography variant="overline" className={classes.brand}>
          {vendor}
        </Typography>
        <Typography variant="body2">{title}</Typography>
        {showVariantTitle && (
          <Typography variant="body1">{subtitle}</Typography>
        )}
        {product?.barcode && (
          <Typography variant="body1">UPC: {product.barcode}</Typography>
        )}
        {product?.sku && (
          <Typography variant="body1">SKU: {product.sku}</Typography>
        )}
        {product?.color?.label && (
          <Typography variant="body1">
            Color: {product?.color?.label}
          </Typography>
        )}
        {product?.variants && (
          <Typography variant="body1">
            Variants: {product?.variants?.length}
          </Typography>
        )}
        {product?.inventory_quantity && (
          <Typography variant="body1">
            Qty: {product?.inventory_quantity}
          </Typography>
        )}
      </Box>
    </Box>
  )
}

ProductSummary.propTypes = {
  classes: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
  showQuantity: PropTypes.bool,
  showSku: PropTypes.bool,
  showVariantTitle: PropTypes.bool,
  endAdornment: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  onClick: PropTypes.func,
  className: PropTypes.string,
}

export default withStyles(styles)(ProductSummary)
