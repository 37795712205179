import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import { Link, useHistory } from 'react-router-dom'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import ArrowBackIos from '@material-ui/icons/ArrowBackIos'

import styles from './HeaderStyles'

const Header = ({
  classes,
  onBackClick,
  title,
  children,
  colorWhite,
  breadcrumbs,
  className,
  onBackHistoryBack, // use browser history to figure out where to go back to
}) => {
  const history = useHistory()
  return (
    <Box
      className={classNames({
        [classes.root]: true,
        [classes.white]: Boolean(colorWhite),
        [className]: className,
      })}
    >
      {(onBackClick || onBackHistoryBack) && (
        <IconButton
          size="small"
          onClick={onBackClick || history.goBack}
          className={classes.backButton}
          data-test="back-button"
        >
          <ArrowBackIos className={classes.backIcon} />
        </IconButton>
      )}
      {title && (
        <Typography className={classes.title} variant="h2">
          {title}
        </Typography>
      )}
      {breadcrumbs && (
        <Breadcrumbs
          aria-label="navigation-breadcrumb"
          separator={<Typography variant="h2">/</Typography>}
        >
          {breadcrumbs.map(({ text, href, active }) => (
            <Typography
              key={href + text}
              variant="h2"
              className={classNames({ [classes.active]: Boolean(active) })}
              data-test="breadcrumb"
            >
              {href ? (
                <Link to={href} className={classes.breadcrumb}>
                  {text}
                </Link>
              ) : (
                text
              )}
            </Typography>
          ))}
        </Breadcrumbs>
      )}
      <Box flex={1} />
      {children && <Box>{children}</Box>}
    </Box>
  )
}

Header.defaultProps = {}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  onBackClick: PropTypes.func,
  children: PropTypes.node,
  colorWhite: PropTypes.bool,
  breadcrumbs: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  className: PropTypes.string,
  onBackHistoryBack: PropTypes.bool, // use browser history to figure out where to go back to
}

export default withStyles(styles)(Header)
