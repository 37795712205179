export const white = '#FFFFFF'
export const black = '#000000'
export const yellow = '#fcff3b'

// Colors our Design System
export const colorsAF = {
  fast: '#305BF2',
  darkFast: '#264FE1',
  lightFast: '#F2F6FC',
  fastRed: '#E75656',
  black,
  darkGray: '#111111',
  mediumGray: '#505050',
  lightGray: '#808080',
  semiGray: '#E5E5E5',
  ultraLightGray: '#F6F6F6',
  white,
}

export const warnings = {
  success: '#4CAF50',
  error: '#ED2E7E',
  warning: '#F49740',
  danger: '#E52B20',
}

export const primary = colorsAF.fast
export const secondary = black

// TODO: Migrate the dashboard to use design colorsAF
// Below are legacy colors that match dashboard
export const grays = {
  darkest: '#515151',
  dark: '#858585',
  med: '#E0E0E0',
  light: '#F7F7FA',
  // TODO: Below are legacy colors used in dark mode
  lightest: '#F9F9F9',
}

export const blues = {
  fast: primary,
  dark: '#7F99F3',
  med: '#A7B8F4',
  light: '#BBC7F5',
  // TODO: Below are legacy colors used in dark mode
  neue: '#1F3A8F',
  denim: '#15265F',
}
